import React, { Component } from 'react';
import Rating from './Rating';

class ReviewChart extends Component {

    renderBarChart(rating)
    {
        return <React.Fragment>
        {/* <div className="row"> */}
            <div class="review-chart-side">
                <div>{rating.Rating} star</div>
            </div>
            <div class="review-chart-middle">
                <div class="review-chart-bar-container">
                <div class="review-chart-bar" style={{width:rating.Percentage + "%"}}></div>
                </div>
            </div>
            <div class="review-chart-side-right">
                <div>{rating.ReviewCount}</div>
            </div>
        {/* </div> */}
        </React.Fragment>
    }

    render(){
        return <React.Fragment>

            {this.props.reviewRating && <React.Fragment>
                <div className="review-chart-header">Rating</div>
                <Rating rating={this.props.reviewRating.OverAllRating} mode={"view"}/>
                <div>{this.props.reviewRating.OverAllRating} average based on {this.props.reviewRating.TotalReviewCount} reviews.</div>
                {this.props.reviewRating.Ratings.map((rating)=>this.renderBarChart(rating))}
            </React.Fragment>}

            {!this.props.reviewRating && <div>No reviews.</div>}

        </React.Fragment>
    }
}

export default ReviewChart;