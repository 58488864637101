import React, { Component } from 'react';
// import './style.scss';
// import {showCart} from '../../services/cart/actions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {SSO, setUser, getSubDomain,mainWebsitePath, isMobile} from '../../services/util'

class Footer extends Component {

    constructor()
    {
        super()
        this.state = {
            // showSearch: false,
            // searchText: 'search',
            // showCart:false
        }
        this.onViewCartClick = this.onViewCartClick.bind(this)
    }

   async  onViewCartClick(){
            //if this phone pe then ask for user permission to get user details
            let subDomain = getSubDomain()
            if(subDomain.toLowerCase()==="phonepe")
            {
                let sdk = await window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
                try{
                    const result =  await sdk.fetchAuthToken()
                    // alert(JSON.stringify(result))
                    const grantToken = result.grantToken
                    // alert(grantToken)     
                    try{
                        const response = await SSO(this.props.config.key, '', 'PhonePe', {grantToken:grantToken})
                        if(response.data.success)
                        {
                            await setUser(this.props.config.CheckoutConfig.StoreID, response.data.data)
                            this.props.history.push(mainWebsitePath() + "checkout")
                        }  
                        else{
                            this.props.history.push(mainWebsitePath() + "checkout")
                        }
                    } catch(err){
                        alert(err)
                    }
                } catch(err){
                    alert(err)
                }
            }
            else
            {
                this.props.history.push(mainWebsitePath() + "checkout")
            }
    }

    render() {
        return <div >
           {this.props.show && <React.Fragment>
                {this.props.lineItemTotal.billQty > 0 && <div className="footer" style={{width:this.props.width ? this.props.width : "100%"}}onClick={this.onViewCartClick}>
                <div className="pull-left footer-cart-text"> 
                    {this.props.lineItemTotal.billQty} {this.props.lineItemTotal.billQty >1 ? "Items" : "Item"} 
                    {(this.props.config.hasOwnProperty('CheckoutConfig') && this.props.config.CheckoutConfig && this.props.config.CheckoutConfig.ShowProductPrice) ? " | ₹ " + this.props.lineItemTotal.billSubTotal : ""}                
                </div>
                <div className="pull-right footer-cart-text">{this.props.label ? this.props.label : "VIEW CART"} </div>
                </div>}
            </React.Fragment>}
        </div>
    }
}

const mapStateToProps = state => ({
    // cartIsOpen: state.cart.cartIsOpen,
    lineItemTotal:state.cart.lineItemTotal,
    lineItems: state.cart.lineItems,
    config: state.api.config

  });
  
  export default withRouter(connect(mapStateToProps,{ })(Footer));

//   showCart