import React, { Component } from "react";
import { connect } from "react-redux";
import {isLocationCheckerEnabled} from "../../services/util";

class LocationLabel extends Component {

    render(){
        return <React.Fragment>
            {isLocationCheckerEnabled(this.props.config)&& <div
                className="location"
                onClick={()=>{
                    if(this.props.hasOwnProperty('onClick'))
                    {this.props.onClick()}
                }}
                >
                <i className="fa fa-map-marker "></i>
                {this.props.pincode ==="" ?  "Please enter location" : "Delivering to " + this.props.pincode}
            </div>}
        </React.Fragment>
    }
}

const mapStateToProps = (state) => ({
    pincode:state.api.pincode,
    config:state.api.config
});

export default connect(mapStateToProps, {})(LocationLabel);

