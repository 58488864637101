import React ,{Component} from 'react';
// import './Style.css';

class Alert extends Component {

    render()
    {
        return <div className="overlay">
            <div className="alert-container">
                <div className="alert-title">{this.props.title}</div>
                <div className="alert-content">{this.props.content}</div>
                <div className="alert-buttons">
                    <a onClick={this.props.noClicked}>NO</a>
                    <a onClick={this.props.yesClicked}>YES</a>
                </div>
            </div>
        </div>
    }
}

export default Alert
