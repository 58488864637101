import { LOAD_CART, ADD_PRODUCT, REMOVE_PRODUCT, CHANGE_PRODUCT_QUANTITY, UPDATE_TOTAL, SHOW_CART,
COUPON_UPDATE } from './actionTypes';

export const updateCoupon = coupon =>({
  type:COUPON_UPDATE,
  payload:coupon
})

export const loadCart = lineItems => ({
  type: LOAD_CART,
  payload: lineItems
});

export const loadCartTotal = lineItemTotal => ({
  type: UPDATE_TOTAL,
  payload: lineItemTotal
});


export const addProduct = product => ({
  type: ADD_PRODUCT,
  payload: product
});

export const removeProduct = product => ({
  type: REMOVE_PRODUCT,
  payload: product
});

export const changeProductQuantity = product => ({
  type: CHANGE_PRODUCT_QUANTITY,
  payload: product
});

export const showCart = show => (
  {
  type: SHOW_CART,
  payload: show
});


