import React,{Component} from 'react';
import { connect } from 'react-redux';
import {saveCustomerDetails, isMobile,getCustomerDetails} from '../../services/util';
import {setCustomer} from '../../services/api/actions';
import CustomerAddresses from './CustomerAddresses';
import Header from "../Header";
import PageFooter from "../PageFooter";
import Input from "../Common/Input";

class CustomerProfile extends Component {

    constructor(props)
    {
        super(props)
        this.state={
            firstName:'',
            lastName:'',
            phoneNo:'',
            emailID:'',
            addresses:[],
            firstNameErrorMsg:'',
            phoneNoErrorMsg:'',
            ageConfirmation:false,
            ageConfirmationError:""
        }
        this.onSaveClicked= this.onSaveClicked.bind(this);
        this.renderPersonalDetails= this.renderPersonalDetails.bind(this);
        this.renderDesktop = this.renderDesktop.bind(this);
        this.renderMobile=this.renderMobile.bind(this);
        this.renderSaveButton = this.renderSaveButton.bind(this);
    }
    
    componentDidMount()
    {
         if(this.props.new)
        {
            this.setState({phoneNo:this.props.phoneNo ? this.props.phoneNo :"" })
        }
        else if(this.props.customer)
        {
            this.setState({
                firstName: this.props.customer.FirstName,
                lastName:this.props.customer.LastName,
                phoneNo: this.props.customer.PhoneNo,
                emailID: this.props.customer.EmailID,
                addresses: this.props.customer.Addresses
            })
        }
    }

    async onSaveClicked()
    {
        let error = false;
        if(this.state.firstName.trim()==="")
        {
            this.setState({firstNameErrorMsg:'Please provide your first name'});
            error= true;
        }
        if(this.state.phoneNo.trim()==="")
        {
            this.setState({phoneNoErrorMsg:'Please provide your mobile no'});
            error= true;
        }
        if(this.state.phoneNo.length!==10)
        {
            this.setState({phoneNoErrorMsg:'Please provide correct mobile no'});
            error= true;
        }
        if(this.props.config && this.props.config.CheckoutConfig && this.props.config.CheckoutConfig.AgeConfirmation){
            if(!this.state.ageConfirmation){
                this.setState({ageConfirmationError:"Please confirm your age"});
                error= true;
            }
        } 

        if(error){return;}
        
        let customer = null
        let key = ""
        if(!this.props.new)
        {
            
            key = this.props.customer.key
            
            customer = {
                FirstName: this.state.firstName,
                LastName: this.state.lastName,
                EmailID: this.state.emailID,
                Addresses: this.state.addresses,
                PhoneNo: this.state.phoneNo,
            } 
            
            if (this.props.employee) {
                customer.AffiliateID = this.props.employee.key
                customer.AffiliateCode = this.props.employee.EmployeeCode
            }
        }
        else
        {
            //check if customer exists 
            let customerTmp = await getCustomerDetails(
                this.state.phoneNo,
                this.props.config.key
            );
            console.log('customerTmp',customerTmp);
            if(customerTmp){
                this.setState({phoneNoErrorMsg:'This number is already registered. Pls login.'});
                return;
            }
            customer = {
                CustomerCode: '',
                AadharNo: '',
                FirstName: this.state.firstName,
                LastName: this.state.lastName,
                EmailID: this.state.emailID,
                PhoneNo: this.state.phoneNo,
                DOB: null,
                Anniversary: null,
                DOBNew: { Day: '', Month: '', Year: '' },
                AnniversaryNew: { Day: '', Month: '', Year: '' },
                Membership: {},
                CustomerAcceptsMarketing: false,
                Note: '',
                Gender: '',
                Addresses: this.state.addresses,
                CustomField1: '',
                CustomField2: '',
                CustomField3: '',
                CustomField4: '',
                CustomField5: '',
                CustomField6: '',
                RegistrationID: this.props.config.key,
                StoreID: this.props.config.CheckoutConfig.StoreID,
                ReferredBy: '',
                IsDeleted: false,
                BackEndUpdate: false,
            }

            if (this.props.employee) {
                customer.AffiliateID = this.props.employee.key
                customer.AffiliateCode = this.props.employee.EmployeeCode
            }
        } 
        let res = await saveCustomerDetails(key, customer)
        if(res!=="")
        {
            customer.key = res;
            this.props.setCustomer(this.props.config.CheckoutConfig.StoreID, customer)
            if(this.props.returnStatus)
            {
                this.props.onSave()
            }
        }
        else
        {
            alert('unable to save customer')
        }
    }

    renderPersonalDetails()
    {
        return <React.Fragment>
            <div className="mb-3">
                <Input type="text" 
                    label="First Name"
                    value={this.state.firstName}
                    onChange={(value) => { this.setState({ firstName:value,firstNameErrorMsg:''}) }}
                    error={this.state.firstNameErrorMsg}
                />
            </div>
            <div className="mb-3">
                <Input type="text" 
                    label="Last Name"
                    value={this.state.lastName}
                    onChange={(value) => { this.setState({ lastName:value}) }}
                />
            </div>
            <div className="mb-3">
                <Input type="mobileno" 
                    label="Mobile No"
                    value={this.state.phoneNo}
                    disabled = {!this.props.new}
                    onChange={(value) => { this.setState({phoneNo: value.length > 10 ? this.state.phoneNo : value, phoneNoErrorMsg:''}) }}
                    error={this.state.phoneNoErrorMsg}
                />
            </div>
            <div className="mb-3">
                <Input type="emailid" 
                    label="Email ID"
                    value={this.state.emailID}
                    onChange={(value) => { this.setState({emailID:value}) }}
                />
            </div>

            {this.props.config && this.props.config.CheckoutConfig && this.props.config.CheckoutConfig.AgeConfirmation && <div className="mb-3">
                <br/>
                <Input 
                error={this.state.ageConfirmationError}
                value={this.state.ageConfirmation}
                onChange={(value)=>{this.setState({ageConfirmation:value, ageConfirmationError:""})}}
                type="checkbox" 
                label={"I am over " + this.props.config.CheckoutConfig.AgeConfirmation + " yrs"}/>
            </div>}

        </React.Fragment>
    }

    renderSaveButton()
    {
        return <button className="btn btn-flat generate-otp " 
        // style={{width:"100%"}} 
        onClick={this.onSaveClicked}>{this.props.new ? "Register": "Save"}</button>
    }

    renderDesktop()
    {
        return <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <div className="section-sub-title">General Details</div>
                    {this.renderPersonalDetails()}
                </div>
                <div className="col-md-6">
                <div className="section-sub-title">Address</div>
                    <CustomerAddresses 
                    addresses={this.state.addresses} 
                    onAddressUpdated={(addresses)=> this.setState({addresses:addresses})}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {this.renderSaveButton()}
                </div>
            </div>
        </React.Fragment>
    }

    renderMobile()
    {
        return <React.Fragment>
                <div className="section-sub-title">General Details</div>
                {this.renderPersonalDetails()}
                <div className="section-sub-title">Address</div>
                <CustomerAddresses 
                addresses={this.state.addresses} 
                onAddressUpdated={(addresses)=> this.setState({addresses:addresses})}/>

                 <div className="window-footer">
                    <button className="btn btn-flat btn-success" onClick={this.onSaveClicked}>Save</button>
                </div>

    </React.Fragment>
    }

    render()
    {
        return <React.Fragment>
        
        {!this.props.new && <React.Fragment>
            <Header/>
            <div className="container">
                <div className="profile-page"> 
                    <div className="page-title">My Account</div>
                    {!isMobile() && this.renderDesktop()}   
                    {isMobile() && this.renderMobile()}   
                </div>
            </div>
            {!isMobile() &&  <PageFooter/>}
        </React.Fragment>}
        
        {this.props.new &&  <React.Fragment>
            {/* <div className="container"> */}
            <div className="row">
                <div className="col-md-12">
                {this.renderPersonalDetails()}
                </div>  
            </div>
            <div className="row">
                <div className="col-md-12">
                    {this.renderSaveButton()}
                </div>
            </div>
            {/* </div> */}

        </React.Fragment>}

        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    customer: state.api.customer,
    config: state.api.config,
    employee:state.api.employee
});

export default connect(mapStateToProps,{setCustomer})(CustomerProfile);