import React, { Component } from "react";
import { connect } from "react-redux";
import {validatePincode} from "../../services/util";
import { setPincode } from "../../services/api/actions";
import Window from "../Common/Window";
import Input from "../Common/Input";

class Location extends Component {

    constructor(){
        super();
        this.state={
            pincode:'',
            pincodeError:'',
            checking:false,
            processing:false
        }
        this.onValidatePinCode = this.onValidatePinCode.bind(this)
    }

    async onValidatePinCode(){
        this.setState({processing:true})
        if(this.state.pincode==="")
        {
            this.setState({pincodeError:'Please enter pincode', processing:false});
            return;
        }
        let pincodeValidated = await validatePincode(this.state.pincode, this.props.config.key);
        // console.log('pincodeValidated',pincodeValidated)
        if(pincodeValidated)
        {
            //set pincode to redux
            this.props.setPincode(this.state.pincode);
            this.setState({showLocation:true});
            if(this.props.hasOwnProperty('onClose')){
                this.props.onClose();
            }
        }
        else
        {
            this.setState({pincodeError:'We currently do not deliver at the specified pincode', processing:false});
            return;
        }
    }

    render(){
        return <React.Fragment>

            <Window 
                onClose={()=>this.props.onClose(false)}
                header={"Delivery Location"}  
                width={"300px"}
                height={"250px"}
                modal={true}
                processing={this.state.processing}
                onButtonClick={this.onValidatePinCode}
                buttons={[{label:"OK"}]}>
                    <Input  type="number" 
                    label="Please enter pincode where you want the delivery" 
                    error={this.state.pincodeError}
                    onChange={(value)=>this.setState({pincode:value, pincodeError:""})}/>
            </Window>
        </React.Fragment>
    }
}


const mapStateToProps = (state) => ({
    config: state.api.config,
    pincode:state.api.pincode
});

export default connect(mapStateToProps, {setPincode})(Location);

