import React, { Component } from 'react';
import Icon from './Icon';
import './common.css';
/* global google */

class GoogleCurrentLocation extends Component {

    constructor()
    {
        super();
        this.state={
            error:''
        }
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount(){
        
    }
  
    onClick(){
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position){
                // console.log('position', position)
                let lat = position.coords.latitude;
                let lng = position.coords.longitude;
                var latlng = new google.maps.LatLng(lat, lng);
                // This is making the Geocode request
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({ 'latLng': latlng },  function(results, status){
                    if (status !== google.maps.GeocoderStatus.OK) {
                        alert(status);
                    }
                    // This is checking to see if the Geoeode Status is OK before proceeding
                    if (status == google.maps.GeocoderStatus.OK) {
                        console.log(results);
                        var address = (results[0].formatted_address);
                        this.props.onChange({Address:address, Lat: results[0].geometry.location.lat(), Lng: results[0].geometry.location.lng()});

                    }
                }.bind(this));
            }.bind(this));
          } else {
            this.setState({error:"Geolocation is not supported by this browser."})
          }
    }

    // showPosition(position) {
    //     console.log('position', position)
        // x.innerHTML = "Latitude: " + position.coords.latitude +
        // "<br>Longitude: " + position.coords.longitude;
    //   }

    render(){
      return <React.Fragment>
          <button className="btn btn-flat  btn-current-location" onClick={this.onClick}>Find my current location</button>
          {this.state.error && <div className="error-msg">{this.state.error}</div>}
      </React.Fragment>
    }
}

export default GoogleCurrentLocation;