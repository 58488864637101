import React from "react";
// import {getImage} from '../../services/util'
import { useHistory } from "react-router-dom";
import { mainWebsitePath } from "../../services/util";
import noimage from './no-image.png';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import "./menuthumbnails.css";

const MenuThumbNails = ({
    menu,
    businessUniqueName,
    type = "category",
    displayMode = "",
}) => {
    const history = useHistory();

    // const image =(m)=>{
    //     let imageURL = getImage(m)
    //     <img src={getImage(m)} className="card-img-top" alt="..."/>
    // }

    const getThumbNailImage = (m) => {
        let url = "";
        if (m.Image) {
            if (m.Image.Preview !== "") {
                url = m.Image.Preview;
            }
        }
        if (url !== "") {
            return <img src={url} alt="" />;
        } else {
            return (
                <svg
                    className="bd-placeholder-img"
                    width="100%"
                    height="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false"
                    role="img"
                >
                    <rect width="100%" height="100%" fill="#777"></rect>
                    <text
                        x="40%"
                        y="50%"
                        fill="#eceeef"
                        style={{ fontSize: "50px" }}
                    >
                        {m.Name.substring(0, 1)}
                    </text>
                </svg>
            );
        }
    };

    // history.push((window.location.hostname ==="omni.posible.in"  ? ("/" + businessUniqueName ) : "") + (type === "category" ? "/l1/" : "/l2/") + m.Name)

    const renderCicularImages = () => {
        return (
            <div className="menu-rounded">
                {menu.map((m) => (
                    <div
                        key={m.ID}
                        className="menu-rounded-element"
                        onClick={() =>history.push(mainWebsitePath() +(type === "category" ? "l1/" : "l2/") + m.OMNITag)}
                    >
                        {m.Image && m.Image.Preview && m.Image.Preview !== "" && (<img src={m.Image.Preview} className="menu-rounded-image" alt=""/>)}
                        {!m.Image && (<img src={noimage} className="menu-rounded-image" alt=""/>)}

                        <div className="menu-rounded-label">{m.Name}</div>
                    </div>
                ))}
            </div>
        );
    };

    const renderOldStyle = () => {
        return (
            <div className="row">
                {menu.map((m) => (
                    <div key={m.ID} className="col-6 col-md-4 col-lg-3">
                        <div
                            onClick={() =>
                                history.push(
                                    mainWebsitePath() +
                                        (type === "category" ? "l1/" : "l2/") +
                                        m.OMNITag
                                )
                            }
                        >
                            {getThumbNailImage(
                                m,
                                "card-img-top menu-thumbnail-image"
                            )}
                            <div className="card-body menu-thumbnail-body">
                                <span className="menu-thumbnail-title">
                                    {m.Name}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }; 

    return (
        <React.Fragment>
            <div className="row">
                {menu.map((m) => (
                    <div key={m.ID} className="col-6 col-md-3">
                        <div 
                            className="menu-thunbnail-new" 
                            onClick={() => history.push(mainWebsitePath() + (type === "category" ? "l1/" : "l2/") + m.OMNITag) }>
                            <div className="menu-thunbnail-new-img">{getThumbNailImage(m)}</div>
                            <div className="menu-thunbnail-new-title">
                                {m.Name}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {/* {renderOldStyle()} */}
            {/* {displayMode === "" && renderOldStyle()} */}
            {/* {displayMode === "rounded-image" && renderCicularImages()} */}
        </React.Fragment>
    );
};

export default MenuThumbNails;
