import React, { Component } from "react";
import { connect } from "react-redux";
import Product from "../ProductList/Product";
import  {omniValidateCoupon} from "../../services/util";
import Input from "../Common/Input";

class Cart extends Component {

    constructor(props) {
        super(props);
        this.state={
            couponApplied: false,
            couponValidating: false,
            couponErrorMsg:'',
            couponSuccessMsg:'',
            enableCoupon:false,
            
        }        
        this.validateCoupon = this.validateCoupon.bind(this);
        this.computeSelectedOfferCoupon = this.computeSelectedOfferCoupon.bind(this);
        this.renderEmptyCart = this.renderEmptyCart.bind(this);
        this.renderCart = this.renderCart.bind(this);
    }

    componentDidMount(){

        // console.log('lineItemTotal', this.props.lineItemTotal);
        let enableCoupon= false;
        
        if(this.props.config.CheckoutConfig.hasOwnProperty("EnableCoupon") ){
            enableCoupon = this.props.config.CheckoutConfig.EnableCoupon;
        }
        this.setState({
            enableCoupon:enableCoupon,
        })
    }

    async validateCoupon() {
        this.setState({ couponValidating: true });
        let coupon = await omniValidateCoupon(this.props.config.key,this.state.couponCode);
        let couponErrorMsg = "";
        let couponSuccessMsg = "";
        if (coupon) {
            let status = await this.computeSelectedOfferCoupon(coupon);
            if (status) {
                this.props.onApplyCoupon(coupon)
                couponSuccessMsg ="Coupon applied. Coupon Code:" +coupon.CouponCode +". " +coupon.Description;
            } else {
                couponErrorMsg = "Coupon not applicable. " + coupon.Description;
            }
        } else {
            couponErrorMsg = "Invalid coupon code";
        }
        // console.log('validateCoupon response', response)
        this.setState({
            couponValidating: false,
            couponErrorMsg: couponErrorMsg,
            couponSuccessMsg: couponSuccessMsg,
        });
    }

    async computeSelectedOfferCoupon(offer) {
        var days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];

        if (offer.SpecificDay != "" && offer.SpecificDay.toLowerCase() === "yes") {
            // Specific Day Selected
            if (!offer.SpecificDays.includes(days[new Date().getDay()])) {
                // Check Specific Day
                return false;
            }
        } else if (offer.SpecificTime !== "" &&offer.SpecificTime.toLowerCase() === "yes"
        ) {
            var today = new Date();
            var currentTime = today.getHours() + "." + today.getMinutes();
            var startTime =Number(offer.OfferStartTime.HH) + (offer.OfferStartTime.AMPM === "PM" ? 12 : 0) + "." +offer.OfferStartTime.MM;
            var endTime =Number(offer.OfferEndTime.HH) +(offer.OfferEndTime.AMPM === "PM" ? 12 : 0) +"." +offer.OfferEndTime.MM;
            if (Number(startTime) <= Number(currentTime) &&Number(currentTime) <= endTime) {
                // console.log('Time based offer applicable')
            } else {return false;}
        }

        let lineItems = this.props.lineItems;
        if (offer.OfferTypeID.toLowerCase() === "invoicediscount") {
            //check if offer is still valid
            let invoiceAmount = Number(this.props.lineItemTotal.billAmount);
            if (invoiceAmount < offer.InvoiceAmount) {
                return false;
            }
            //check if any discountable item exists
            let dicountableLineItemExists = false;
            // console.log('lineItems', lineItems)
            for (let l = 0; l < lineItems.length; l++) {
                let lineItem = lineItems[l];
                if (lineItem.Product.hasOwnProperty("AllowDiscount")) {
                    if (lineItem.Product.AllowDiscount) {
                        dicountableLineItemExists = true;
                        break;
                    }
                } else {
                    dicountableLineItemExists = true;
                    break;
                }
            }
            // console.log('dicountableLineItemExists', dicountableLineItemExists)
            if (!dicountableLineItemExists) {
                // console.log('Non discontanble items')
                return false;
            }
        }
        return true;
    }

    renderEmptyCart()
    {
        return <React.Fragment>
            <div className="checkout-empty">
                <svg viewBox="0 0 450.391 450.391"><g><g><g><path d="M143.673,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02     c25.969,0,47.02-21.052,47.02-47.02C190.694,371.374,169.642,350.322,143.673,350.322z M143.673,423.465     c-14.427,0-26.122-11.695-26.122-26.122c0-14.427,11.695-26.122,26.122-26.122c14.427,0,26.122,11.695,26.122,26.122     C169.796,411.77,158.1,423.465,143.673,423.465z" /><path d="M342.204,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02s47.02-21.052,47.02-47.02     C389.224,371.374,368.173,350.322,342.204,350.322z M342.204,423.465c-14.427,0-26.122-11.695-26.122-26.122     c0-14.427,11.695-26.122,26.122-26.122s26.122,11.695,26.122,26.122C368.327,411.77,356.631,423.465,342.204,423.465z" /><path d="M448.261,76.037c-2.176-2.377-5.153-3.865-8.359-4.18L99.788,67.155L90.384,38.42     C83.759,19.211,65.771,6.243,45.453,6.028H10.449C4.678,6.028,0,10.706,0,16.477s4.678,10.449,10.449,10.449h35.004     c11.361,0.251,21.365,7.546,25.078,18.286l66.351,200.098l-5.224,12.016c-5.827,15.026-4.077,31.938,4.702,45.453     c8.695,13.274,23.323,21.466,39.184,21.943h203.233c5.771,0,10.449-4.678,10.449-10.449c0-5.771-4.678-10.449-10.449-10.449     H175.543c-8.957-0.224-17.202-4.936-21.943-12.539c-4.688-7.51-5.651-16.762-2.612-25.078l4.18-9.404l219.951-22.988     c24.16-2.661,44.034-20.233,49.633-43.886l25.078-105.012C450.96,81.893,450.36,78.492,448.261,76.037z M404.376,185.228     c-3.392,15.226-16.319,26.457-31.869,27.69l-217.339,22.465L106.58,88.053l320.261,4.702L404.376,185.228z" /></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                <div className="content">
                    Your cart is empty.
                </div>
            </div>
        </React.Fragment>
    }

    renderCart() {
        const { lineItems } = this.props;
        const { lineItemTotal } = this.props;
        const { coupon } = this.props;

        return <React.Fragment>
            
            {lineItems.map((m, index) => (
                <Product
                    product={m.Product}
                    onChange={this.props.onLineItemUpdate}
                    key={"checkout" + index}
                    displayMode="list"
                    checkOut={true}
                    index={index}
                />
            ))}

            <div className="cart-seperator" />
            
            {this.state.enableCoupon &&  <React.Fragment>
                {!coupon && (<React.Fragment>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                                this.setState({couponCode: e.target.value.toUpperCase(),})
                            }
                            placeholder="Enter coupon code"
                            disabled={this.state.couponValidating}
                            value={this.state.couponCode}
                        />
                        <div className="input-group-append">
                            <button disabled={this.state.couponValidating}
                                className="form-control"
                                onClick={this.validateCoupon}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                    <div className="cart-seperator" />
                </React.Fragment>)}

                {this.state.couponErrorMsg !== "" && (
                    <div
                        className="alert alert-danger"
                        role="alert"
                    >
                        {this.state.couponErrorMsg}
                    </div>
                )}
                
                {coupon && (
                    <button
                        type="submit"
                        className="btn btn-block"
                        onClick={()=>{
                            this.setState({couponCode:'',couponErrorMsg:''});
                            this.props.onRemoveCoupon();
                        }}
                    >
                        REMOVE COUPON
                    </button>
                )}

                {coupon && (
                    <div
                        className="alert alert-success"
                        role="alert"
                    >
                        {this.state.couponSuccessMsg}
                    </div>
                )}
            </React.Fragment>}
             
            <div className="row">
                <div className="col-6 pull-left">
                    <b>Total Item(s)</b>
                </div>
                <div className="col-6 " style={{ textAlign: "right" }}>
                    <b>{lineItemTotal.billQty}</b>
                </div>
            </div>
            <div className="cart-seperator" />
            {this.props.config.hasOwnProperty("CheckoutConfig") && this.props.config.CheckoutConfig && this.props.config.CheckoutConfig.ShowProductPrice && (
                    <React.Fragment>
                        {(lineItemTotal.billDiscount > 0) && <React.Fragment>
                            <div className="row">
                                <div className="col-6 pull-left">
                                    <b>Discount</b>
                                </div>
                                <div className="col-6 "style={{textAlign: "right",}}>
                                    <b>₹{" "}{lineItemTotal.billDiscount}</b>
                                </div>
                            </div>
                            <div className="cart-seperator" />
                        </React.Fragment>
                        }
                        
                        {(this.props.hasOwnProperty("packagingCharge") && (this.props.packagingCharge >  0)) && 
                        <React.Fragment>
                            <div className="row">
                                    <div className="col-6 pull-left">
                                        <b>Packaging Charges</b>
                                    </div>
                                    <div className="col-6 " style={{textAlign: "right"}}>
                                        <b>₹{" "}{this.props.packagingCharge}</b>
                                    </div>
                        </div>
                        <div className="cart-seperator" />
                        </React.Fragment>}
                        
                        {this.props.shippingOption && this.props.deliveryCharge > 0 && (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-6 pull-left">
                                        <b>Delivery Charges</b>
                                    </div>
                                    <div className="col-6 " style={{textAlign: "right"}}>
                                        <b>₹{" "}{this.props.deliveryCharge}</b>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="checkout-mycart-shippingnotes">      
                                                {this.props.shippingOption.Notes}
                                        </div>
                                    </div>
                                </div>
                                <div className="cart-seperator" />
                            </React.Fragment>
                        )}

                        {lineItemTotal.billTaxAmount > 0 && <React.Fragment>
                                <div className="row">
                                    <div className="col-6 pull-left">
                                        <b>GST</b>
                                    </div>
                                    <div className="col-6 " style={{textAlign: "right"}}>
                                        <b>₹{" "}{lineItemTotal.billTaxAmount}</b>
                                    </div>
                                </div>
                                <div className="cart-seperator" />
                        </React.Fragment>}

                        <div className="row">
                            <div className="col-6 pull-left">
                                <b>To Pay</b>
                            </div>
                            <div className="col-6 "style={{textAlign: "right",}}>
                                <b>₹{" "}{this.props.billAmount}</b>
                            </div>
                        </div>
                    </React.Fragment>)}
        </React.Fragment>
    }

    render(){
        const { lineItems } = this.props;
        return <React.Fragment>
        {lineItems.length===0 && this.renderEmptyCart()}
        {lineItems.length>0 && this.renderCart()}
        </React.Fragment>
    }
}

const mapStateToProps = (state) => ({
    lineItems: state.cart.lineItems,
    lineItemTotal: state.cart.lineItemTotal,
    config: state.api.config
});

export default connect(mapStateToProps, {})(Cart);
