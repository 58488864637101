import axios from "axios";
import React from "react";

export const getImage = (imageType) => {
  if (imageType === "back") {
    return (
      <svg viewBox="0 0 511.991 511.991">
        <g>
          <g>
            <path d="M153.433,255.991L381.037,18.033c4.063-4.26,3.917-11.01-0.333-15.083c-4.229-4.073-10.979-3.896-15.083,0.333    L130.954,248.616c-3.937,4.125-3.937,10.625,0,14.75L365.621,508.7c2.104,2.188,4.896,3.292,7.708,3.292    c2.646,0,5.313-0.979,7.375-2.958c4.25-4.073,4.396-10.823,0.333-15.083L153.433,255.991z" />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    );
  }
};

export const isLocationCheckerEnabled = (config) => {
  // console.log('config.CheckoutConfig',config.CheckoutConfig)
  // return false;
  if (
    config.CheckoutConfig.hasOwnProperty("CheckoutDetailPageOptions") &&
    config.CheckoutConfig.CheckoutDetailPageOptions.hasOwnProperty(
      "EnablePincodeChecker"
    ) &&
    config.CheckoutConfig.CheckoutDetailPageOptions.EnablePincodeChecker
  ) {
    return true;
  } else {
    return false;
  }
};

export const isMobile = () => {
  if (window.screen.width <= 480) {
    return true;
  } else {
    return false;
  }
};

export const getCustomerFromLocalStorage = (registrationID) => {
  let customer = window.localStorage.getItem(registrationID + "customerkey1");
  // console.log('getCustomerFromLocalStorage',customer)
  return customer;
  // if(customer)
  // {
  //     return window.localStorage.getItem(JSON.stringify(registrationID + 'customerkey');
  // }
  // else
  // {
  //   return "";
  // }
};

export const onLineItemChange = (
  lineItem,
  productTaxes,
  discountType = "",
  discountID = "",
  discountInfo = "",
  discountAmt = 0,
  transactionLevelDiscount = false
) => {
  let subTotal = roundUpTo2(
    Number(lineItem.RetailPrice) * Number(lineItem.Qty)
  );
  // let discountAmt = 0
  let discountPer = 0;
  let allowDiscount = true;
  if (lineItem.Product.hasOwnProperty("AllowDiscount")) {
    allowDiscount = lineItem.Product.AllowDiscount;
  }
  if (allowDiscount) {
    if (
      Number(lineItem.DiscountAmount) > 0 &&
      Number(lineItem.DiscountPer) === 0
    ) {
      //calculate discount per based on discount amount
      discountPer = Number(
        (
          (Number(lineItem.DiscountAmount) * 100) /
          Number(lineItem.SubTotal)
        ).toFixed(3)
      );
      discountAmt = roundUpTo2(lineItem.DiscountAmount);
    } else {
      discountPer = Number(lineItem.DiscountPer);
      discountAmt = roundUpTo2(
        (Number(subTotal) * Number(lineItem.DiscountPer)) / 100
      );
    }
    if (discountInfo) {
      if (discountInfo.trim() !== "") {
        // console.log('discountInfo', discountInfo)
        lineItem.Discounts = [];
        lineItem.Discounts.push({
          DiscountType: discountType,
          DiscountID: discountID,
          DiscountInfo: discountInfo,
          DiscountAmount: discountAmt,
          DiscountPercentage: discountPer,
          TransactionLevelDiscount: transactionLevelDiscount,
        });
      }
    } else if (discountAmt === 0) {
      lineItem.Discounts = [];
    }
  } else {
    lineItem.Discounts = [];
  }

  let subTotalWithDiscount = roundUpTo2(Number(subTotal) - Number(discountAmt));
  let chargeTaxOnProduct = lineItem.ChargeTaxOnProduct;
  let priceBasedTax = lineItem.PriceBasedTax;
  let taxInclusive = lineItem.TaxInclusive;
  let taxableAmount = 0;
  let totalTax = 0;
  let amount = 0;
  let taxComponentAmount = [];

  if (chargeTaxOnProduct) {
    if (priceBasedTax) {
      let unitPriceAfterDiscount = roundUpTo2(
        Number(subTotalWithDiscount) / Number(lineItem.Qty)
      );
      lineItem.Product.TaxPriceRange.forEach((t) => {
        if (
          Number(unitPriceAfterDiscount) >= Number(t.FromPrice) &&
          Number(unitPriceAfterDiscount) <= Number(t.ToPrice)
        ) {
          lineItem.TaxID = t.TaxID;
          lineItem.TaxGroup = t.TaxGroup;
          return;
        }
      });
    }
    let taxfound = false;
    let customerFromSameState = true; //TBD IMP IMP
    // console.log('this.props.productTaxes.',this.props.productTaxes)
    productTaxes
      .filter((t) => t.key === lineItem.TaxID.trim())
      .forEach((taxGroup, index) => {
        taxfound = true;
        let taxAmount = 0;
        let taxComponentTemp = [];
        taxGroup.Taxes.forEach((t) => {
          if (
            customerFromSameState &&
            (t.TaxType === "CGST" || t.TaxType === "SGST")
          ) {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: 0,
            });
          } else if (!customerFromSameState && t.TaxType === "IGST") {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: 0,
            });
          } else if (
            t.TaxType !== "CGST" &&
            t.TaxType !== "IGST" &&
            t.TaxType !== "SGST"
          ) {
            taxComponentTemp.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: 0,
            });
          }
        });

        if (taxInclusive) {
          //tax inclusive
          let totalTaxRate = 0;
          taxComponentTemp.forEach((t) => {
            totalTaxRate = roundUpTo2(
              Number(t.TaxPercentage) + Number(totalTaxRate)
            );
          });
          totalTax = roundUpTo2(
            (Number(subTotalWithDiscount) * Number(totalTaxRate)) /
              (100 + Number(totalTaxRate))
          );
          taxableAmount = roundUpTo2(
            Number(subTotalWithDiscount) - Number(totalTax)
          );
          amount = roundUpTo2(subTotalWithDiscount);
          taxComponentTemp.forEach((t) => {
            taxAmount = roundUpTo2(
              (Number(totalTax) *
                ((Number(t.TaxPercentage) * 100) / Number(totalTaxRate))) /
                100
            );
            taxComponentAmount.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: taxAmount,
            });
          });
        } else {
          taxableAmount = subTotalWithDiscount;
          taxComponentTemp.forEach((t) => {
            taxAmount = roundUpTo2(
              (Number(taxableAmount) * Number(t.TaxPercentage)) / 100
            );
            totalTax = roundUpTo2(Number(totalTax) + Number(taxAmount));
            taxComponentAmount.push({
              TaxName: t.TaxName,
              TaxPercentage: Number(t.TaxPercentage),
              TaxType: t.TaxType,
              TaxAmount: taxAmount,
            });
          });
          amount = roundUpTo2(taxableAmount + totalTax);
        }
      });
    //tax is not found then
    if (!taxfound) {
      //alert("Tax not found for selected product. Please check item ")
      taxableAmount = 0;
      taxComponentAmount = [];
      totalTax = 0;
      amount = subTotalWithDiscount;
    }
  } else {
    taxableAmount = 0;
    taxComponentAmount = [];
    totalTax = 0;
    amount = subTotalWithDiscount;
  }
  lineItem.SubTotal = roundUpTo2(subTotal);
  lineItem.ChargeTaxOnProduct = chargeTaxOnProduct;
  lineItem.TaxInclusive = taxInclusive;
  lineItem.DiscountPer = roundUpTo2(discountPer);
  lineItem.DiscountAmount = roundUpTo2(discountAmt);
  lineItem.SubTotalWithDiscount = roundUpTo2(subTotalWithDiscount);
  lineItem.TaxableAmount = roundUpTo2(taxableAmount);
  lineItem.TaxComponentAmount = taxComponentAmount;
  lineItem.TotalTax = roundUpTo2(totalTax);
  lineItem.Amount = roundUpTo2(amount);
  return lineItem;
};

export const removeCoupon = (
  lineItems,
  productTaxes,
  offer,
  loadCart,
  loadCartTotal,
  updateCoupon
) => {
  let updatedLineItems = [];
  for (let i = 0; i < lineItems.length; i++) {
    let lineItem = lineItems[i];
    if (lineItem.hasOwnProperty("Discounts")) {
      if (
        lineItem.Discounts.filter((f) => f.DiscountID === offer.key).length > 0
      ) {
        lineItem.DiscountPer = 0;
        lineItem.DiscountAmount = 0;
        lineItem.Discounts = [];
        lineItem = onLineItemChange(lineItem, productTaxes);
      }
    }
    updatedLineItems.push(lineItem);
  }
  let cartTotal = calculateBillTotal(updatedLineItems);
  loadCart(lineItems);
  loadCartTotal(cartTotal);
  updateCoupon(null);
};

export const calculateBillTotal = (lineItems) => {
  // shippingOption = null
  let billQty = 0;
  let billAmount = 0;
  let billSubTotal = 0;
  let billTaxAmount = 0;
  let billDiscount = 0;
  let taxSummaryList = [];
  let roundOff = 0;
  lineItems.forEach((lineItem) => {
    if (lineItem.TaxID !== "") {
      let indexFound = -1;
      taxSummaryList.forEach((t, index) => {
        if (
          t.TaxID === lineItem.TaxID &&
          t.HSNSACCode === lineItem.HSNSACCode
        ) {
          let sameTaxComponent = true;
          t.TaxComponentAmount.forEach((a) => {
            if (
              lineItem.TaxComponentAmount.filter((f) => f.TaxName === a.TaxName)
                .length === 0
            ) {
              sameTaxComponent = false;
              return;
            }
          });
          if (sameTaxComponent) {
            indexFound = index;
            return;
          }
        }
      });
      if (indexFound < 0) {
        let taxSummary = {
          TaxID: lineItem.TaxID,
          TaxGroup: lineItem.TaxGroup,
          HSNSACCode: lineItem.HSNSACCode,
          TaxableAmount: roundUpTo2(lineItem.TaxableAmount),
          TotalTax: roundUpTo2(lineItem.TotalTax),
          TotalQty: roundUpTo2(lineItem.Qty),
          TaxComponentAmount: [],
        };
        lineItem.TaxComponentAmount.forEach((a) => {
          taxSummary.TaxComponentAmount.push({ ...a });
        });
        taxSummaryList.push(taxSummary);
      } else {
        taxSummaryList[indexFound].TaxableAmount = roundUpTo2(
          Number(taxSummaryList[indexFound].TaxableAmount) +
            Number(lineItem.TaxableAmount)
        );
        taxSummaryList[indexFound].TotalTax = roundUpTo2(
          Number(taxSummaryList[indexFound].TotalTax) +
            Number(lineItem.TotalTax)
        );
        taxSummaryList[indexFound].TotalQty = roundUpTo2(
          Number(taxSummaryList[indexFound].TotalQty) + Number(lineItem.Qty)
        );
        taxSummaryList[indexFound].TaxComponentAmount.forEach((t, index) => {
          t.TaxAmount = roundUpTo2(
            Number(t.TaxAmount) +
              Number(lineItem.TaxComponentAmount[index].TaxAmount)
          );
        });
      }
    }
    billQty = roundUpTo2(Number(billQty) + Number(lineItem.Qty));
    billSubTotal = roundUpTo2(Number(billSubTotal) + Number(lineItem.SubTotal));
    billAmount = roundUpTo2(Number(billAmount) + Number(lineItem.Amount));
    billTaxAmount = roundUpTo2(
      Number(billTaxAmount) + Number(lineItem.TotalTax)
    );
    billDiscount = roundUpTo2(
      Number(billDiscount) + Number(lineItem.DiscountAmount)
    );
  });
  // console.log('billDiscount',billDiscount)
  roundOff = roundUpTo2(Math.round(billAmount) - billAmount);
  billAmount = Math.round(billAmount);
  console.log("calculateBillTotal", {
    billQty: billQty,
    billSubTotal: billSubTotal,
    billAmount: billAmount,
    billTaxAmount: billTaxAmount,
    billDiscount: billDiscount,
    taxSummaryList: taxSummaryList,
    roundOff: roundOff,
  });

  return {
    billQty: billQty,
    billSubTotal: billSubTotal,
    // deliveryCharge:deliveryCharge,
    billAmount: billAmount,
    billTaxAmount: billTaxAmount,
    billDiscount: billDiscount,
    taxSummaryList: taxSummaryList,
    roundOff: roundOff,
    // shippingOption:shippingOption
  };
};

// export const computeDeliveryCharges=(shippingOption, billAmount) =>{

//   let deliveryCharge = 0;
//   // let billAmount = this.props.lineItemTotal.billAmount;

//   if (
//       shippingOption &&
//       shippingOption.DeliveryRates &&
//       shippingOption.DeliveryRates.length > 0
//   ) {
//       let deliveryRates = shippingOption.DeliveryRates;

//       for (let index = 0; index < deliveryRates.length; index++) {
//           const deliveryRate = deliveryRates[index];
//           if (
//               billAmount > deliveryRate.StartRange &&
//               billAmount < deliveryRate.EndRange
//           ) {
//               deliveryCharge = deliveryRate.DeliveryCharge;
//           }
//       }
//   }

//   return deliveryCharge;
//   // if (deliveryCharge > 0) {
//   //     billAmount = Number(billAmount) + Number(deliveryCharge);
//   // }

//   // console.log('computeDeliveryCharges',deliveryCharge)
//   // this.setState({
//   //     billAmount: billAmount,
//   //     deliveryCharge: deliveryCharge,
//   // });
//   // this.props.
//   // this.props.loadCartTotal(cartTotal);
// }

export const roundUpTo2 = (num) => {
  return Math.round(Number(num) * 100) / 100;
};

export const formatPrice = (x, currency) => {
  // switch (currency) {
  // case 'BRL':
  //   return x.toFixed(2).replace('.', ',');
  // default:
  //   return x.toFixed(2);
  // }
  return x;
};

export const mainWebsitePath = () => {
  let businessName = "";
  if (
    window.location.hostname === "omni.posible.in" ||
    window.location.hostname === "localhost"
  ) {
    let path = window.location.pathname;
    // console.log('path',path)
    let pathParams = path.split("/");
    if (pathParams.length > 1 && pathParams[1] !== "") {
      businessName = "/" + pathParams[1];
      // console.log('if1',businessName);
    }
    // console.log('if2',businessName);
  }
  // console.log('if3',businessName);
  // alert(businessName + "/")
  return businessName + "/";
};

// https://maps.googleapis.com/maps/api/place/details/json?placeid={placeid}&key={key}

export const fetchBusinessNameByDomainName = (domainName) => {
  return axios
    .post(domainNameAPI, { domainName: domainName })
    .then((res) => {
      // console.log('res.data', res.data)
      let { uniqueName } = res.data;
      // console.log('fetchProduct - key',key);
      // console.log('uniqueName', uniqueName)
      return uniqueName;
    })
    .catch((err) => {
      console.log(
        "Could not get businessname for provided domain name. Try again later."
      );
    });
};

export const getRecordByOmniTag = (
  registrationID,
  categoryOmniTag,
  subCategoryOmniTag,
  brandOmniTag,
  productGroupOmniTag
) => {
  return axios
    .post(getRecordByOmniTagAPI, {
      registrationID: registrationID,
      categoryOmniTag: categoryOmniTag,
      subCategoryOmniTag: subCategoryOmniTag,
      brandOmniTag: brandOmniTag,
      productGroupOmniTag: productGroupOmniTag,
    })
    .then((res) => {
      // console.log('res.data', res.data)
      let { uniqueName } = res.data;
      // console.log('fetchProduct - key',key);
      // console.log('uniqueName', uniqueName)
      return uniqueName;
    })
    .catch((err) => {
      console.log(
        "Could not get businessname for provided domain name. Try again later."
      );
    });
};

export const fetchProduct = (registrationID, productName, productID) => {
  return axios
    .post(productAPI, {
      registrationID: registrationID,
      productName: productName,
      productID: productID,
    })
    .then((res) => {
      let { product } = res.data;
      // console.log('fetchProduct - key',key);
      return product;
    })
    .catch((err) => {
      console.log("Could not fetch products. Try again later.");
    });
};

export const fetchProducts = (
  from,
  size,
  categoryID,
  subCategoryID,
  productGroupID,
  brand,
  registrationID,
  storeID = ""
) => {
  // console.log('productGroupID',productGroupID)
  return axios
    .post(productsAPI, {
      from: from,
      size: size,
      categoryID: categoryID,
      subCategoryID: subCategoryID,
      productGroupID: productGroupID,
      brand: brand,
      registrationID: registrationID,
      storeID: storeID,
    })
    .then((res) => {
      let { products } = res.data;
      return products;
    })
    .catch((err) => {
      console.log("Could not fetch products. Try again later.");
    });
};

export const fetchLatestProducts = (key, from, size) => {
  return axios
    .post(latestProductsAPI, { from: from, size: size, key: key })
    .then((res) => {
      // let { products } = res.data;
      return res.data;
    })
    .catch((err) => {
      console.log("Could not fetch products. Try again later.");
    });
};

export const searchProducts = (key, from, size, searchText) => {
  return axios
    .post(searchProductsAPI, {
      key: key,
      from: from,
      size: size,
      searchText: searchText,
    })
    .then((res) => {
      let { products } = res.data;
      return products;
    })
    .catch((err) => {
      console.log("Could not fetch products. Try again later.");
    });
};

// export const fetchLatestCarousels = (key) => {
//   return axios
//     .post(carouselAPI, { key: key })
//     .then(res => {
//       let { carousels } = res.data;
//       // console.log('products')
//       return carousels;
//     })
//     .catch(err => {
//       console.log('Could not fetch carousels. Try again later.');
//     });
// };

export const submitInquiry = (inquiry) => {
  // console.log('submitInquiry inquiry',inquiry)
  return axios
    .post(submitInquiryAPI, { ...inquiry })
    .then((res) => {
      // let { products } = res.data;
      // console.log('submitInquiry', res)
      return res;
    })
    .catch((err) => {
      console.log("submitInquiry", err);
    });
};

export const checkOut = (checkout) => {
  // console.log('checkOut ',checkout)
  return axios
    .post(checkOutAPI, { Sale: { ...checkout } })
    .then((res) => {
      // let { products } = res.data;
      console.log("checkOut response", res);
      return res;
    })
    .catch((err) => {
      console.log("checkOut error", err);
    });
};

export const updateSale = (sale) => {
  // console.log('updateSale ',sale)
  return axios
    .post(updateSaleAPI, { Sale: { ...sale } })
    .then((res) => {
      // let { products } = res.data;
      console.log("updateSale response", res);
      return res;
    })
    .catch((err) => {
      console.log("updateSale error", err);
    });
};

export const generateOTP = (
  phoneNo,
  registrationID,
  storeID,
  moduleID,
  customerCode
) => {
  // console.log(phoneNo +' - '+ registrationID + ' - ' + storeID)
  return axios
    .post(generateOTPAPI, {
      phoneNo: phoneNo,
      registrationID: registrationID,
      storeID: storeID,
      moduleID: moduleID,
      customerCode,
    })
    .then((res) => {
      // console.log('otp sent', res)
      return res;
    })
    .catch((err) => {
      console.log("Could not fetch products. Try again later.");
    });
};

export const validateOTP = (
  phoneNo,
  otp,
  firstName,
  lastName,
  registrationID,
  storeID,
  customerCode
) => {
  return axios
    .post(validateOTPAPI, {
      phoneNo: phoneNo,
      otp: otp,
      firstName: firstName,
      lastName: lastName,
      registrationID: registrationID,
      storeID: storeID,
      customerCode,
    })
    .then((res) => {
      // console.log('otp validated', res)
      return res;
    })
    .catch((err) => {
      console.log("Could not fetch products. Try again later.");
    });
};

export const validateOTPV1 = (
  phoneNo,
  otp,
  registrationID,
  storeID,
  moduleID
) => {
  return axios
    .post(validateOTPV1API, {
      phoneNo: phoneNo,
      otp: otp,
      registrationID: registrationID,
      storeID: storeID,
      moduleID: moduleID,
    })
    .then((res) => {
      // console.log('otp validated', res)
      if (res.status === 200 && res.data.status === "Success") {
        return true;
      } else {
        return false;
      }
      // return res;
    })
    .catch((err) => {
      console.log("Could not fetch products. Try again later.");
    });
};

export const getCustomerDetails = (phoneNo, registrationID, customerCode) => {
  // console.log('phoneNo',phoneNo)
  // console.log('registrationID',registrationID)

  return axios
    .post(getCustomerDetailsAPI, {
      phoneNo: phoneNo,
      registrationID: registrationID,
      customerCode: customerCode,
    })
    .then((res) => {
      // console.log('customer data', res)
      if (res.status === 200 && res.data.status === "Success") {
        return res.data.message;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log("Could not fetch customer. Try again later.");
    });
};

export const omniGetCustomerByKey = (key) => {
  //console.log('key',key)
  return axios
    .post(omniGetCustomerByKeyAPI, { key: key })
    .then((res) => {
      // console.log('customer data', res)
      if (res.status === 200 && res.data.status === "Success") {
        return res.data.message;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log("Could not fetch products. Try again later.");
    });
};

export const myOrders = (from, size, customerID, storeID) => {
  return axios
    .post(myOrdersAPI, {
      from: from,
      size: size,
      customerID: customerID,
      storeID: storeID,
    })
    .then((res) => {
      let { sales } = res.data;
      // console.log('sales', sales)
      return sales;
    })
    .catch((err) => {
      console.log("Could not fetch products. Try again later.");
    });
};

export const omniGetCustomerProducts = (from, size, customerID) => {
  return axios
    .post(getCustomerProducts, {
      from: from,
      size: size,
      customerID: customerID,
    })
    .then((res) => {
      //console.log('res  ',res.data.products)
      // let products = res.products;
      //  console.log('otp validated', sales)
      return res.data.products;
    })
    .catch((err) => {
      console.log("Could not fetch products. Try again later.");
    });
};

export const initiatePayment = (
  sale,
  paymentProvider,
  selectedPaymentMode,
  returnURl = ""
) => {
  // console.log('IntiatePayment inside ', paymentProvider)
  return axios
    .post(initiatePaymentAPI, {
      Sale: sale,
      PaymentProvider: paymentProvider,
      PaymentMode: selectedPaymentMode,
      ReturnURL: returnURl,
    })
    .then((res) => {
      // console.log('res', res)
      return res;
    })
    .catch((err) => {
      console.log("Could not initiate payments. Try again later.");
    });
};

export const getAtomPaymentStatus = (docID) => {
  return axios
    .get("https://tmpposiblepg.el.r.appspot.com/checkPaymentStatus/" + docID)
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((err) => {
      console.log("Could not initiate payments. Try again later.");
    });
};

export const checkIfSaleExistsForAtomInitiatePaymentID = (docID) => {
  return axios
    .post(
      "https://us-central1-posible-app-prod.cloudfunctions.net/omniCheckIfSaleExistsForAtomInitiatePaymentID",
      {
        ID: docID,
      }
    )
    .then((res) => {
      console.log("res", res);
      return res.data.result;
    })
    .catch((err) => {
      console.log("Could not initiate payments. Try again later.");
    });
};

export const initiatePaymentAtom = (
  registrationID,
  amount,
  customerEmailID,
  customerNo,
  sale,
  returnURl = ""
) => {
  console.log("IntiatePayment inside ", returnURl);
  // cons
  return axios
    .post("https://tmpposiblepg.el.r.appspot.com/initiatePayment", {
      AppID: registrationID,
      Amount: amount,
      CustomerEmailID: customerEmailID,
      CustomerNo: customerNo,
      Cart: sale,
      ReturnURL: returnURl,
    })
    .then((res) => {
      console.log("res", res);
      return res;
    })
    .catch((err) => {
      console.log("Could not initiate payments. Try again later.");
    });
};

export const SSO = (registrationID, storeID, platform, data) => {
  return axios
    .post(ssoAPI, {
      registrationID: registrationID,
      storeID: storeID,
      platform: platform,
      data: data,
    })
    .then((res) => {
      // console.log('res', res)
      return res;
    })
    .catch((err) => {
      console.log("Could not initiate paymentss. Try again later.", err);
      return err;
    });
};

export const setUser = (storeID, user) => {
  // alert(JSON.stringify(user))
  window.localStorage.setItem(storeID + "user", JSON.stringify(user));
};

export const getSubDomain = () => {
  let full = window.location.host;
  let parts = full.split(".");
  if (parts.length > 0) {
    return parts[0];
  } else {
    return "";
  }
};

export const omniValidateCoupon = (registrationID, couponCode) => {
  return axios
    .post(validateCoupon, {
      registrationID: registrationID,
      couponCode: couponCode,
    })
    .then((res) => {
      // console.log('res  ',res)
      if (res && res.data && res.data.coupon) {
        return res.data.coupon;
      } else {
        return null;
      }
      // return res;
    })
    .catch((err) => {
      console.log("Could not fetch coupon. Try again later.");
    });
};

export const saveCustomerDetails = (customerID, customer) => {
  return axios
    .post(saveCustomerDetailsAPI, { key: customerID, customer: customer })
    .then((res) => {
      // console.log('res',res)
      if (res.status === 200 && res.data.status === "Success") {
        return res.data.key;
      } else {
        return "";
      }

      // console.log('res  ',res)
      // return res;
    })
    .catch((err) => {
      console.log("Could not save customer. Try again later.");
    });
};

export const validatePincode = (pincode, registrationID) => {
  return axios
    .post(validatePincodeAPI, {
      pincode: pincode,
      registrationID: registrationID,
    })
    .then((res) => {
      // console.log('otp validated', res)
      if (res.status === 200 && res.data.status === "Success") {
        return true;
      } else {
        return false;
      }
      // return res;
    })
    .catch((err) => {
      console.log("Could not validate pincode. Try again later.");
    });
};

export const cancelOrder = (
  orderKey,
  cancellationReason,
  cancellationRemarks
) => {
  return axios
    .post(cancelOrderAPI, {
      key: orderKey,
      cancellationReason: cancellationReason,
      cancellationRemarks: cancellationRemarks,
    })
    .then((res) => {
      // console.log('otp validated', res)
      if (res.status === 200 && res.data.status === "Success") {
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.log("Could not cancel order. Try again later.");
    });
};

export const saveReview = (productID, review) => {
  return axios
    .post(saveReviewAPI, { productID: productID, review: review })
    .then((res) => {
      // console.log('review', review)
      // console.log('productID', productID)

      if (res.status === 200 && res.data.status === "Success") {
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.log("Could not cancel order. Try again later.");
    });
};

export const getReviews = (productKey, from, size) => {
  return axios
    .post(getReviewsAPI, { productKey: productKey, from: from, size: size })
    .then((res) => {
      let { reviews } = res.data;
      return reviews;
    })
    .catch((err) => {
      console.log("Could not fetch reviews. Try again later.");
    });
};

export const getLoyaltyDetails = (customerKey, registrationID) => {
  return axios
    .post(getLoyaltyDetailsAPI, {
      customerKey: customerKey,
      registrationID: registrationID,
    })
    .then((res) => {
      // console.log('getLoyaltyDetails res.data',res.data)
      let { loyaltyDetails } = res.data;
      return loyaltyDetails;
    })
    .catch((err) => {
      console.log("Could not fetch customer wallet balance. Try again later.");
    });
};

export const getLoyaltyLedger = (customerKey, registrationID) => {
  return axios
    .post(getLoyaltyLedgerAPI, {
      customerKey: customerKey,
      registrationID: registrationID,
    })
    .then((res) => {
      // console.log('getWalletLedger res.data',res.data)
      let { loyaltyLedgers } = res.data;
      return loyaltyLedgers;
    })
    .catch((err) => {
      console.log("Could not fetch reviews. Try again later.");
    });
};

export const getWalletBalance = (customerKey, registrationID) => {
  return axios
    .post(getWalletBalanceAPI, {
      customerKey: customerKey,
      registrationID: registrationID,
    })
    .then((res) => {
      // console.log('getWalletBalance res.data',res.data)
      let { walletBalance } = res.data;
      return walletBalance;
    })
    .catch((err) => {
      console.log("Could not fetch customer wallet balance. Try again later.");
    });
};

export const getWalletLedger = (customerKey, registrationID) => {
  return axios
    .post(getWalletLedgerAPI, {
      customerKey: customerKey,
      registrationID: registrationID,
    })
    .then((res) => {
      // console.log('getWalletLedger res.data',res.data)
      let { walletLedgers } = res.data;
      return walletLedgers;
    })
    .catch((err) => {
      console.log("Could not fetch reviews. Try again later.");
    });
};

export const getStores = (registrationID) => {
  return axios
    .post(getStoresAPI, { registrationID: registrationID })
    .then((res) => {
      // console.log('getStores res.data',res.data)
      let { stores } = res.data;
      return stores;
    })
    .catch((err) => {
      console.log("Could not fetch reviews. Try again later.");
    });
};

export const getStoresDetails = (storeID) => {
  return axios
    .post(getStoreDetails, { storeID: storeID })
    .then((res) => {
      // console.log('getStores res.data',res.data)
      let { store } = res.data;
      return store;
    })
    .catch((err) => {
      console.log("Could not fetch store details. Try again later.");
    });
};

export const log = (key, action, data) => {
  return (
    axios
      .post(logAPI, { key: key, action: action, data: data })
      // .then(res => {

      // if (res.status === 200 && res.data.status === "Success") {
      //   return true;
      // }
      // else
      // {
      //   return false;
      // }
      // })
      .catch((err) => {
        console.log("Could not cancel order. Try again later.");
      })
  );
};

export const updatePaymentForTransaction = (
  transactionID,
  paidAmount,
  paymentMode,
  paymentModeRemarks,
  paymentProviderID,
  paymentProvider
) => {
  // console.log('paymentModeRemarks ',paymentModeRemarks)
  return axios
    .post(omniUpdateTransactionPaymentStatusAPI, {
      TransactionID: transactionID,
      PaidAmount: paidAmount,
      PaymentMode: paymentMode,
      PaymentModeRemarks: paymentModeRemarks,
      PaymentProviderID: paymentProviderID,
      PaymentProvider: paymentProvider,
    })
    .then((res) => {
      // let { products } = res.data;
      // console.log('checkOut response', res)
      return res;
    })
    .catch((err) => {
      console.log("checkOut error", err);
    });
};

export const getPaymentStatus = (transactionID) => {
  return axios
    .post(getPaymentStatusAPI, {
      TransactionID: transactionID,
    })
    .then((res) => {
      // let { products } = res.data;
      // console.log('getPaymentStatus response', res)
      return res;
    })
    .catch((err) => {
      console.log("getPaymentStatus error", err);
    });
};

export const getID = (type, tag, registrationID) => {
  // console.log('type',type);
  // console.log('tag',tag);
  // console.log('registrationID',registrationID);

  return axios
    .post(getIDAPI, { type: type, tag: tag, registrationID: registrationID })
    .then((res) => {
      // console.log('getID', res)
      if (res.status === 200 && res.data.Success) {
        return res.data.Data.obj;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log("Could not get obj. Try again later.");
    });
};

export const logAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniLog";
export const validateCoupon =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniValidateCoupon";
export const menuAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetMenuv1";
export const carouselAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetCarousels";
export const configAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetConfig";
export const getRecordByOmniTagAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetRecordByOmniTag"; //does not exists
export const domainNameAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetBusinessNameByDomainName";
export const productsAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetProducts";
export const searchProductsAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniSearchProducts";
export const productAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetProductv1";
export const latestProductsAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetLatestProducts";
export const submitInquiryAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniSumbitInquiry";
export const productTaxesAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniProductTaxes";
export const checkOutAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/apiCreateSales";
export const updateSaleAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/apiUpdateSales";
export const generateOTPAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGenerateOTP";
export const validateOTPAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniValidateOTP";
export const myOrdersAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniMyOrders";
export const getCustomerProducts =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetCustomerProducts";
export const initiatePaymentAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniInitiatePayment";
export const ssoAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniSSO";
export const customFieldsAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetCustomFields";
export const pagesAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetPages";
export const validateOTPV1API =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniValidateOTPV1";
export const getCustomerDetailsAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetCustomerDetails";
export const saveCustomerDetailsAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniSaveCustomer";
export const omniGetCustomerByKeyAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetCustomerByKey";
export const employeeAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetEmployeeAPI";
export const deliveryServiceAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetDeliveryProduct";
export const validatePincodeAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniValidatePincode";
export const cancelOrderAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniCancelOrder";
export const saveReviewAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniSaveReview";
export const getReviewsAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetReviews";
export const getWalletBalanceAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetWalletBalance";
export const getWalletLedgerAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetWalletLedger";
export const getLoyaltyDetailsAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetLoyaltyDetails";
export const getLoyaltyLedgerAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetLoyaltyLedger";
export const getStoresAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetStores";
export const omniUpdateTransactionPaymentStatusAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniUpdateTransactionPaymentStatus";
export const getIDAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetID";
export const getPaymentStatusAPI =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetPaymentStatus";
export const getStoreDetails =
  "https://us-central1-posible-app-prod.cloudfunctions.net/omniGetStoreDetails";
