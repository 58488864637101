import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MenuThumbNails from "../MenuThumbNails";
import { fetchLatestProducts, isMobile, mainWebsitePath } from "../../services/util";
import ProductList from "../ProductList";
import PageFooter from "../PageFooter";
import Footer from "../Footer";
import Spinner from "../Spinner";
import Slider from "react-slick";
import ProductSection from "../ProductSection";
import Header from "../Header";
import './home.css';

class Home extends Component {
    static propTypes = {
        config: PropTypes.object,
        menu: PropTypes.array,
    };

    constructor() {
        super();
        this.state = {
            carousels: [],
            latestProducts: [],
            itemsCountPerPage: 40,
            totalItemsCount: 0,
            currentPage: 1,
            recordFrom: 0,
            scrolling: false,
        };
        this.renderStaticContent = this.renderStaticContent.bind(this);
        this.renderDynamicContent = this.renderDynamicContent.bind(this);
    }

    async componentDidMount() {
        // let carousels = await fetchLatestCarousels(this.props.config.key);
        // this.setState({ carousels: carousels })
        
        // let result = await fetchLatestProducts(this.props.config.key, this.state.recordFrom, this.state.itemsCountPerPage);
        // this.setState({ latestProducts: result.products, totalItemsCount: result.totalRecords });
        // console.log('home',this.props.carousels);

        // console.log('{this.props.config.CheckoutConfig.HomeSections', this.props.config.CheckoutConfig.HomeSections)
        if( !this.props.config.CheckoutConfig.hasOwnProperty('HomeSections') )
        // && this.props.config.CheckoutConfig.HomeSections.length > 0){
           {
                fetchLatestProducts(
                this.props.config.key,
                this.state.recordFrom,
                this.state.itemsCountPerPage
            ).then((result) => {
                this.setState({
                    latestProducts: result.products,
                    totalItemsCount: result.totalRecords,
                });
            });
        }
        
    }

    handleScroll = async () => {
        this.setState({ scrolling: true });
        let pageNumber = Number(this.state.currentPage) + 1;
        // console.log("pageNumber", pageNumber)
        let recordFrom = (pageNumber - 1) * this.state.itemsCountPerPage;
        this.setState({ recordFrom: recordFrom, currentPage: pageNumber });
        let result = await fetchLatestProducts(
            this.props.config.key,
            recordFrom,
            this.state.itemsCountPerPage
        );
        let products = result.products;

        let latestProducts = this.state.latestProducts;
        if (products && products.length > 0) {
            for (let index = 0; index < products.length; index++) {
                const obj = products[index];
                latestProducts.push(obj);
            }
            this.setState({ latestProducts: latestProducts });
        }
        this.setState({ scrolling: false });
    };

    renderSlider() {
        return (
            <div
                id="carouselExampleIndicators"
                className="carousel slide"
                data-ride="carousel"
            >
                <ol className="carousel-indicators">
                    <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="0"
                        className="active"
                    ></li>
                    <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="1"
                    ></li>
                    <li
                        data-target="#carouselExampleIndicators"
                        data-slide-to="2"
                    ></li>
                </ol>
                <div className="carousel-inner">
                    {this.state.carousels
                        .filter((f) => !f.IsDeleted)
                        .map((m, index) => (
                            <div
                                key={"carousel_" + index}
                                className="carousel-item"
                            >
                                <img
                                    className="d-block w-100"
                                    src={m.Image.Preview}
                                    alt=""
                                />
                            </div>
                        ))}
                    {/* <div className="carousel-item active">
          <img className="d-block w-100" src="..." alt="First slide"/>
        </div>
        <div className="carousel-item">
          <img className="d-block w-100" src="..." alt="Second slide"/>
        </div>
        <div className="carousel-item">
          <img className="d-block w-100" src="..." alt="Third slide"/>
        </div> */}
                </div>
                <a
                    className="carousel-control-prev"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="prev"
                >
                    <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                    ></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a
                    className="carousel-control-next"
                    href="#carouselExampleIndicators"
                    role="button"
                    data-slide="next"
                >
                    <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                    ></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        );
    }

    renderStaticContent() {
        var settings = {
            dots: true,
            infinite: true,
            // speed: 5,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 5000,
            arrows: false,
        };

        return (
            <React.Fragment>
                {this.props.carousels && (
                        <Slider {...settings}>
                            {this.props.carousels
                                .filter((f) => !f.IsDeleted)
                                .map((m, index) => (<React.Fragment>
                                    {m.Image && m.Image.Preview && <div key={"carousule_" + index}>
                                        <img
                                            src={m.Image.Preview}
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                            }}
                                            alt=""
                                        />
                                    </div>}
                                    </React.Fragment>))}
                        </Slider>
                    )}
                <div className="container">
                    {this.showHomeSections() && <React.Fragment>

                        <div className="section-title-new">Shop by category</div>
                        <MenuThumbNails
                            displayMode="rounded-image"
                            menu={this.props.menu.filter((f) => f.ParentID === "")}
                            businessUniqueName={
                                this.props.config.BusinessUniqueName
                            }
                        />
                        {/* <br /> */}
                        <div className="section-title-new">Product(s)</div>
                        {/* <br /> */}
                        <ProductList
                            products={this.state.latestProducts}
                            displayMode={ "thumbnail"}
                            // isMobile() ? "list" :
                        />
                        {this.state.recordFrom < this.state.totalItemsCount && (
                            <div className="show-more-btn">
                                <button onClick={this.handleScroll}>
                                    Show More
                                </button>
                            </div>
                        )}
                        
                    </React.Fragment>}
                    
                </div>
            </React.Fragment>
        );
    }

    sectionCSS(section){
        let css={}
        if(section.hasOwnProperty("SectionCSSName") && section.SectionCSSName.trim()!==""){
            return css;
        }
        if(section.hasOwnProperty('SectionBackgroundColor') && section.SectionBackgroundColor!=="")
        {
            css= {...css , backgroundColor: section.SectionBackgroundColor}
        }
        if(section.hasOwnProperty('SectionMargin'))
        {
            css={...css , margin: section.SectionMargin.Top + "px " + section.SectionMargin.Right + "px " + section.SectionMargin.Bottom + "px " + section.SectionMargin.Left + "px "}
        }
        if(section.hasOwnProperty('SectionPadding'))
        {
            css={...css , padding: section.SectionPadding.Top + "px " + section.SectionPadding.Right + "px " + section.SectionPadding.Bottom + "px " + section.SectionPadding.Left + "px "}
        }

        return css
    }

    sectionTitleCSS(section){
        let css={}
        if(section.hasOwnProperty('SectionTitleAlignment') && section.SectionTitleAlignment!=="")
        {
            css={...css , textAlign:section.SectionTitleAlignment}
        }
        if(section.hasOwnProperty('SectionTitleColor') && section.SectionTitleColor!=="")
        {
            css={...css , color:section.SectionTitleColor}
        }
        if(section.hasOwnProperty('SectionTitleFontSize') && section.SectionTitleFontSize!=="")
        {
            css={...css , fontSize:section.SectionTitleFontSize + "px"}
        }
        if(section.hasOwnProperty('SectionTitleUnderlineColor') && section.SectionTitleUnderlineColor!=="")
        {
            css={...css , borderBottom:"solid 1px " + section.SectionTitleUnderlineColor}
        }
        if(section.hasOwnProperty('SectionTitleMargin'))
        {
            css={...css , margin: section.SectionTitleMargin.Top + "px " + section.SectionTitleMargin.Right + "px " + section.SectionTitleMargin.Bottom + "px " + section.SectionTitleMargin.Left + "px "}
        }
        if(section.hasOwnProperty('SectionTitlePadding'))
        {
            css={...css , padding: section.SectionTitlePadding.Top + "px " + section.SectionTitlePadding.Right + "px " + section.SectionTitlePadding.Bottom + "px " + section.SectionTitlePadding.Left + "px "}
        }
        // console.log('css', css)
        return css
    }

    renderContentContorl(section){
        return <React.Fragment>
        {/* <div className="section-title">
            {section.Title}
        </div> */}
        <div
            className="section-content"
            dangerouslySetInnerHTML={{
                __html: section.Content,
            }}
        />
        </React.Fragment>
    }

    returnBlockClassName(section, block){
        if(isMobile()){
            if(Number(section.SectionRows)===1 && Number(section.SectionColumns) < 3){
                return "col-" + (12/section.SectionColumns);
            }
            else
            {
                return "col-4" ;
            }
        }else
        {
            return block.Class;
        }
    }

    onBlockClick(block){
        if(block.BlockLinkType==="category"){
            this.props.history.push(mainWebsitePath() + "l1/" + block.BlockOmniTag);
        }
        if(block.BlockLinkType==="subcategory"){
            this.props.history.push(mainWebsitePath() + "l2/" + block.BlockOmniTag);
        }
       
    }

    onViewCollectionClicked(section){
        if(section.SubCategoryID!==""){
            this.props.history.push(mainWebsitePath() + "l2/" + section.SectionOmniTag);
        }
        else if(section.CategoryID!==""){
            this.props.history.push(mainWebsitePath() + "l1/" + section.SectionOmniTag);
        }
        else if(section.ProductGroupID!==""){

        }
    }

    showHomeSections(){
        let showHomeSections= true;
        if(this.props.config.CheckoutConfig.hasOwnProperty("PrivateCatalogue") 
            && this.props.config.CheckoutConfig.PrivateCatalogue){
            if(!this.props.customer){
                showHomeSections=false;
            }
        }

        return showHomeSections;
    }


    renderDynamicContent() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 5,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 2000,
            autoplaySpeed: 5000,
            arrows: false,
        };

        return (
            <React.Fragment>
                <div className="slider">
                    <Slider {...settings}>
                        {this.props.carousels && this.props.carousels.filter((f) => !f.IsDeleted).map((m, index) => (
                                <div key={"carousule_" + index}>
                                    <img src={m.Image.Preview} style={{width: "100%",height: "auto"}} alt=""/>
                                </div>
                            ))}
                    </Slider>
                </div>

                {this.showHomeSections() &&  this.props.config.CheckoutConfig.HomeSections.map((section, index) => <div key={"section_" + index} className={section.SectionCSSName} style={this.sectionCSS(section)}>
                    <div className="container">
                        {section.Type === "blocks" && <React.Fragment>
                            <div className="section-title">{section.Title}</div>
                            <div className="section-sub-title">{section.SubTitle}</div>
                            <div className="row">
                            {section.SectionBlocks.map((block,index)=><div  key={"section-block-"+ index} className={this.returnBlockClassName(section, block)} style={{cursor:"pointer"}}>
                                    <div className="section-block" onClick={()=>this.onBlockClick(block)}>
                                        {block.BlockType ==="image" && <img src={block.BlockImage.Preview} />}
                                        {block.BlockImageText !=="" && <div className="section-block-image-text">{block.BlockImageText}</div>}
                                    </div>
                                </div>)}
                            </div>
                        </React.Fragment>}
                        {section.Type === "products" && <React.Fragment>
                            <div className="section-title"
                            // style={this.sectionTitleCSS(section)}
                            >{section.Title}</div>
                            <div className="section-sub-title">{section.SubTitle}</div>
                            <ProductSection section={section} />
                            {section.hasOwnProperty("SectionOmniTag") && section.SectionOmniTag !=="" && <div className="section-btn-view-collection"><button onClick={()=>this.onViewCollectionClicked(section)}>View Collection</button></div>}
                        </React.Fragment>}

                        {section.Type === "content" && (this.renderContentContorl(section))}

                        {section.Type === "menu" && (
                            <React.Fragment>
                                <div className="section-title">{section.Title}</div>
                                <MenuThumbNails
                                    displayMode="rounded-image"
                                    menu={this.props.menu.filter((f) => f.ParentID === "")}
                                    businessUniqueName={this.props.config.BusinessUniqueName}
                                />
                                <div className="section-content">
                                    {section.Content}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>)}

            </React.Fragment>
        );
    }

    showHomeSections(){
        let hasHomeSections =true;
        if(this.props.config.CheckoutConfig.hasOwnProperty("PrivateCatalogue") 
            && this.props.config.CheckoutConfig.PrivateCatalogue){
            if(!this.props.customer){
                hasHomeSections=false;
            }
        }
        // console.log("hasHomeSections",hasHomeSections)
        return hasHomeSections;
    }

    showStaticSection(){
        let staticSection=true;
        if(this.props.config.CheckoutConfig.hasOwnProperty("HomeSections") 
            && this.props.config.CheckoutConfig.HomeSections.length>0)
        {
            staticSection= false;
        }
        return staticSection;
    }

    render() {
        return (<div>
                
                <Header />

                {(this.showStaticSection()) && this.renderStaticContent()}

                {(!this.showStaticSection()) && this.renderDynamicContent()}

                {/* {!this.props.config.CheckoutConfig.hasOwnProperty("HomeSections") && this.renderStaticContent()}

                {this.props.config.CheckoutConfig.hasOwnProperty("HomeSections") && this.renderDynamicContent()} */}
                {/* {this.props.config.CheckoutConfig.hasOwnProperty("HomeSections") 
                && (
                    <div className="container">
                        {" "}
                        <br />
                        <div className="section-title">Our Product(s)</div>
                        <br />
                        <ProductList
                            products={this.state.latestProducts}
                            displayMode={isMobile() ? "list" : "thumbnail"}
                        />
                        {this.state.recordFrom < this.state.totalItemsCount && (
                            <div className="show-more-btn">
                                <button onClick={this.handleScroll}>
                                    Show More
                                </button>
                            </div>
                        )}
                    </div>
                )
                } */}
                {this.state.scrolling && <Spinner />}
                {/* <br />
                <br />
                <br />
                <br />
                <br /> */}
                <Footer
                    config={this.props.config}
                    show={isMobile()}
                    width="100%"
                />
                {!isMobile() &&<PageFooter config={this.props.config} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    menu: state.api.menu,
    config: state.api.config,
    carousels: state.api.carousels,
    customer: state.api.customer,
});

export default connect(mapStateToProps, {})(Home);
