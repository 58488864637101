import React, { Component } from "react";
import { connect } from "react-redux";
import { isMobile, mainWebsitePath } from "../../services/util";
import "./UnderMaintenance.css";

class UnderMaintenance extends Component {
  componentDidMount() {
    if (!this.props.config.CheckoutConfig.UnderMaintenance) {
      console.log("not UnderMaintenance");
      this.props.history.push(mainWebsitePath());
    }
  }

  showLogo() {
    let logo = "";
    let businessName = this.props.config.BusinessName;
    if (isMobile()) {
      if (
        this.props.config.CheckoutConfig &&
        this.props.config.CheckoutConfig.AppLogo &&
        this.props.config.CheckoutConfig.AppLogo.Preview
      ) {
        logo = this.props.config.CheckoutConfig.AppLogo.Preview;
      }
    } else {
      if (
        this.props.config.CheckoutConfig &&
        this.props.config.CheckoutConfig.WebsiteImageLogo &&
        this.props.config.CheckoutConfig.WebsiteImageLogo.Preview
      ) {
        logo = this.props.config.CheckoutConfig.WebsiteImageLogo.Preview;
      }
    }

    return (
      <React.Fragment>
        {logo !== "" && (
          <div className="brand-logo" onClick={() => this.onHomeClick()}>
            <img src={logo} />
          </div>
        )}
        {logo === "" && (
          <div className="brand-logo" onClick={() => this.onHomeClick()}>
            {businessName}
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="maintenance-container">
            {this.showLogo()}
            <h1>{this.props.config.CheckoutConfig.UnderMaintenanceTitle}</h1>
            <p>{this.props.config.CheckoutConfig.UnderMaintenanceContent}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.api.config,
});

export default connect(mapStateToProps, {})(UnderMaintenance);
