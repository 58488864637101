import React, { Component } from 'react';
import Icon from './Icon';
import './common.css';
/* global google */

class GoogleMaps extends Component {

    constructor()
    {
        super();
        this.state={
        }
        this.showMap= this.showMap.bind(this);
    }

    componentDidMount(){
        this.showMap(this.props.value ? this.props.value : {Address: "", Lat:19.080832611939652, Lng:72.87719816363291})
    }

    componentWillReceiveProps(nextProps) {
        // console.log('GoogleMaps',nextProps);
        this.showMap(nextProps.value ? nextProps.value : {Address: "", Lat:19.080832611939652, Lng:72.87719816363291})
        
      }

    showMap(location) {
        console.log('location',location)
        var latlng = new google.maps.LatLng(location.Lat, location.Lng);
        var myOptions = {
        zoom: 16,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        var map = new google.maps.Map(document.getElementById("map_canvas"),myOptions);
        let marker = new google.maps.Marker({
            map:map,
            draggable:true,
            animation: google.maps.Animation.DROP,
            position: latlng
            });

        google.maps.event.addListener(marker, 'dragend', function(position){
            // console.log('position', position)
            var latlng = position.latLng;
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'latLng': latlng },  function(results, status){
                if (status !== google.maps.GeocoderStatus.OK) {
                    alert(status);
                }
                // This is checking to see if the Geoeode Status is OK before proceeding
                if (status == google.maps.GeocoderStatus.OK) {
                    console.log(results);
                    var address = (results[0].formatted_address);
                    this.props.onChange({Address:address, Lat: results[0].geometry.location.lat(), Lng: results[0].geometry.location.lng()});

                }
            }.bind(this));
        }.bind(this));
     }

     
    render(){
        return <React.Fragment>
            {/* <div style= {{width:this.props.width ?  this.props.width : "100%", height:this.props.height ? this.props.height : "100%"}}> */}
                <div id="map_canvas" style= {{width:this.props.width ?  this.props.width : "100%", height:this.props.height ? this.props.height : "100%"}}></div>
            {/* </div> */}
        </React.Fragment>
    }
}

export default GoogleMaps;