import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { mainWebsitePath, isMobile } from "../../services/util";
import { setCustomer, setConfig } from "../../services/api/actions";
import Search from "../Search";
import Product from "../ProductList/Product";
import Login from "../Login";
import Location from "../Common/Location";
import LocationLabel from "../Common/LocationLabel";
import Logo from "../Common/Logo";
import Window from "../Common/Window";
import StoreLocator from "../Common/StoreLocator";
import Icon from "../Common/Icon";
import "./header.css";

/* global google */

class Header extends Component {
  static propTypes = {
    menu: PropTypes.array.isRequired,
    config: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      showCart: false,
      subMenu: [],
      selectedMenu: "",
      selectedID: "",
      searchText: "",
      showProfileMenu: false,
      showLogin: false,
      showLocation: false,
      lat: "",
      lng: "",
      stores: [],
      availableStores: [],
      selectedStore: null,
      storesNotAvailableMsg: "",
      deliveryLocation: "",
      storeLocatorButtons: [{ label: "Continue" }],
      checkoutOption: "1",
    };
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.onMenuItemSelected = this.onMenuItemSelected.bind(this);
    this.onHomeClick = this.onHomeClick.bind(this);
    this.onContactClick = this.onContactClick.bind(this);
    this.onWhatsAppClick = this.onWhatsAppClick.bind(this);
    this.renderMobileHeader = this.renderMobileHeader.bind(this);
    this.renderCart = this.renderCart.bind(this);
    this.showLogo = this.showLogo.bind(this);
    this.renderContactInfo = this.renderContactInfo.bind(this);
    this.onSignOutClick = this.onSignOutClick.bind(this);
    this.onLoginClick = this.onLoginClick.bind(this);
    this.renderMobileMenu = this.renderMobileMenu.bind(this);
    this.onPageClick = this.onPageClick.bind(this);
    this.renderDesktopMenu = this.renderDesktopMenu.bind(this);
    this.onMenuSelected = this.onMenuSelected.bind(this);
  }

  async componentDidMount() {
    if (this.props.config.CheckoutConfig.UnderMaintenance) {
      console.log("UnderMaintenance");
      console.log("mainWebsitePath()", mainWebsitePath());
      this.props.history.push(mainWebsitePath() + "undermaintenance");
    }

    let checkoutOption = "1";
    let showStoreSelection = false;

    if (
      this.props.config.CheckoutConfig.hasOwnProperty(
        "CheckoutDetailPageOptions"
      ) &&
      this.props.config.CheckoutConfig.CheckoutDetailPageOptions &&
      this.props.config.CheckoutConfig.CheckoutDetailPageOptions.hasOwnProperty(
        "CheckoutOption"
      ) &&
      this.props.config.CheckoutConfig.CheckoutDetailPageOptions
        .CheckoutOption !== ""
    ) {
      checkoutOption =
        this.props.config.CheckoutConfig.CheckoutDetailPageOptions
          .CheckoutOption;
    }
    if (
      this.props.config.CheckoutConfig.hasOwnProperty("ShowMultipleStores") &&
      this.props.config.CheckoutConfig.ShowMultipleStores &&
      this.props.config.CheckoutConfig.StoreID === ""
    ) {
      showStoreSelection = true;
    }
    this.setState({
      showStoreSelection: showStoreSelection,
      checkoutOption: checkoutOption,
    });
  }

  openMenu() {
    this.setState({ isOpen: true });
  }

  closeMenu() {
    this.setState({ isOpen: false });
  }

  onMenuItemSelected(name, id, type) {
    // console.log('type',type)
    if (type === "category") {
      //check if this has sun catgories
      let subMenu = this.props.menu.filter((f) => f.ParentID === id);
      if (subMenu.length === 0) {
        //show products beloging to this category
        this.props.history.push(mainWebsitePath() + "l1/" + name);
        this.closeMenu();
      } else {
        //show sub categories
        this.setState({
          subMenu: subMenu,
          selectedMenu: name,
          selectedID: id,
        });
      }
    } else if (type === "back") {
      this.setState({ subMenu: [], selectedMenu: "", selectedID: "" });
    } else if (type === "subcategory") {
      // history1.push('/' + this.props.config.BusinessUniqueName + '/l1/'+ name);
      // this.props.history.push((window.location.hostname ==="omni.posible.in"  ? ( "/" + this.props.config.BusinessUniqueName ) : "") + '/l2/' + name);
      this.props.history.push(mainWebsitePath() + "l2/" + name);
      this.closeMenu();
    } else if (type === "productgroup") {
      this.props.history.push(mainWebsitePath() + "pg/" + name);
      this.closeMenu();
    } else if (type === "brand") {
      this.props.history.push(mainWebsitePath() + "b/" + name);
      this.closeMenu();
    }
  }

  onMenuSelected(menu) {
    // console.log('menu',menu)
    if (menu.Type.toLowerCase() === "home") {
      this.onHomeClick();
    } else if (menu.Type.toLowerCase() === "categorylink") {
      this.props.history.push(mainWebsitePath() + "l1/" + menu.Tag);
      this.closeMenu();
    } else if (menu.Type.toLowerCase() === "subcategorylink") {
      this.props.history.push(mainWebsitePath() + "l2/" + menu.Tag);
      this.closeMenu();
    } else if (menu.Type.toLowerCase() === "productgrouplink") {
      this.props.history.push(mainWebsitePath() + "pg/" + menu.Tag);
      this.closeMenu();
    } else if (menu.Type.toLowerCase() === "productlink") {
      this.props.history.push(mainWebsitePath() + "l3/" + menu.Tag);
      this.closeMenu();
    } else if (menu.Type.toLowerCase() === "pagelink") {
      this.props.history.push(mainWebsitePath() + "pages/" + menu.Tag);
      this.closeMenu();
    } else if (menu.Type.toLowerCase() === "brandlink") {
      this.props.history.push(mainWebsitePath() + "b/" + menu.Tag);
      this.closeMenu();
    } else if (menu.Type.toLowerCase() === "contactuslink") {
      this.props.history.push(mainWebsitePath() + "contact/");
      this.closeMenu();
    }
  }

  onHomeClick() {
    this.setState({
      subMenu: [],
      selectedMenu: "",
      selectedID: "",
      isOpen: false,
    });
    this.props.history.push(mainWebsitePath());
  }

  onContactClick() {
    this.setState({
      subMenu: [],
      selectedMenu: "",
      selectedID: "",
      isOpen: false,
    });
    this.props.history.push(mainWebsitePath() + "Contact");
  }

  onWhatsAppClick() {
    window.open(
      "https://wa.me/91" + this.props.config.CheckoutConfig.WhatsAppNo.trim()
    );
  }

  onSignOutClick() {
    this.props.setCustomer(this.props.config.key, null);
    this.props.history.push(mainWebsitePath());
  }

  onLoginClick() {
    this.setState({ showLogin: true });
    this.closeMenu();
  }

  logoExists() {
    if (
      this.props.config.CheckoutConfig &&
      this.props.config.CheckoutConfig.AppLogo &&
      this.props.config.CheckoutConfig.AppLogo.Preview !== ""
    )
      return true;
    else return false;
  }

  showLogo() {
    let logo = "";
    let businessName = this.props.config.BusinessName;
    if (isMobile()) {
      if (
        this.props.config.CheckoutConfig &&
        this.props.config.CheckoutConfig.AppLogo &&
        this.props.config.CheckoutConfig.AppLogo.Preview
      ) {
        logo = this.props.config.CheckoutConfig.AppLogo.Preview;
      }
    } else {
      if (
        this.props.config.CheckoutConfig &&
        this.props.config.CheckoutConfig.WebsiteImageLogo &&
        this.props.config.CheckoutConfig.WebsiteImageLogo.Preview
      ) {
        logo = this.props.config.CheckoutConfig.WebsiteImageLogo.Preview;
      }
    }

    return (
      <React.Fragment>
        {logo !== "" && (
          <div className="logo" onClick={() => this.onHomeClick()}>
            <img src={logo} />
          </div>
        )}
        {logo === "" && (
          <div className="logo-text" onClick={() => this.onHomeClick()}>
            {businessName}
          </div>
        )}
      </React.Fragment>
    );
  }

  showCartButton() {
    return (
      <div
        className="cart"
        onClick={() => {
          if (this.state.checkoutOption === "1") {
            this.setState({ showCart: true });
          }
          if (this.state.checkoutOption === "2") {
            this.props.history.push(mainWebsitePath() + "checkout");
          }
        }}
      >
        <svg viewBox="0 0 450.391 450.391">
          <g>
            <g>
              <g>
                <path d="M143.673,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02     c25.969,0,47.02-21.052,47.02-47.02C190.694,371.374,169.642,350.322,143.673,350.322z M143.673,423.465     c-14.427,0-26.122-11.695-26.122-26.122c0-14.427,11.695-26.122,26.122-26.122c14.427,0,26.122,11.695,26.122,26.122     C169.796,411.77,158.1,423.465,143.673,423.465z" />
                <path d="M342.204,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02s47.02-21.052,47.02-47.02     C389.224,371.374,368.173,350.322,342.204,350.322z M342.204,423.465c-14.427,0-26.122-11.695-26.122-26.122     c0-14.427,11.695-26.122,26.122-26.122s26.122,11.695,26.122,26.122C368.327,411.77,356.631,423.465,342.204,423.465z" />
                <path d="M448.261,76.037c-2.176-2.377-5.153-3.865-8.359-4.18L99.788,67.155L90.384,38.42     C83.759,19.211,65.771,6.243,45.453,6.028H10.449C4.678,6.028,0,10.706,0,16.477s4.678,10.449,10.449,10.449h35.004     c11.361,0.251,21.365,7.546,25.078,18.286l66.351,200.098l-5.224,12.016c-5.827,15.026-4.077,31.938,4.702,45.453     c8.695,13.274,23.323,21.466,39.184,21.943h203.233c5.771,0,10.449-4.678,10.449-10.449c0-5.771-4.678-10.449-10.449-10.449     H175.543c-8.957-0.224-17.202-4.936-21.943-12.539c-4.688-7.51-5.651-16.762-2.612-25.078l4.18-9.404l219.951-22.988     c24.16-2.661,44.034-20.233,49.633-43.886l25.078-105.012C450.96,81.893,450.36,78.492,448.261,76.037z M404.376,185.228     c-3.392,15.226-16.319,26.457-31.869,27.69l-217.339,22.465L106.58,88.053l320.261,4.702L404.376,185.228z" />
              </g>
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
        {this.props.lineItemTotal.billQty > 0 && (
          <div className="cart-qty">{this.props.lineItemTotal.billQty}</div>
        )}
        <span>Cart</span>
      </div>
    );
  }

  showProfileButton() {
    return (
      <React.Fragment>
        {this.props.customer && (
          <React.Fragment>
            <div
              className="profile"
              onMouseOver={() => {
                this.setState({ showProfileMenu: true });
              }}
            >
              <svg width="24px" viewBox="-77 -19 629 629.33435">
                <path d="m233.371094 294.5c81.328125 0 147.25-65.925781 147.25-147.25 0-81.320312-65.921875-147.25-147.25-147.25-81.320313 0-147.25 65.929688-147.25 147.25.0625 81.296875 65.953125 147.1875 147.25 147.25zm0-269.542969c67.542968 0 122.292968 54.75 122.292968 122.292969s-54.75 122.292969-122.292968 122.292969c-67.535156 0-122.292969-54.75-122.292969-122.292969.113281-67.492188 54.800781-122.183594 122.292969-122.292969zm0 0" />
                <path d="m233.371094 331.3125c-64.011719 0-123.539063 24.832031-167.710938 70.007812-44.800781 45.796876-69.386718 108.3125-69.386718 176.203126.019531 6.882812 5.597656 12.457031 12.480468 12.476562h449.238282c6.886718-.019531 12.457031-5.59375 12.476562-12.476562 0-67.761719-24.582031-130.40625-69.378906-176.078126-44.175782-45.175781-103.699219-70.132812-167.71875-70.132812zm-211.886719 233.730469c2.746094-56.402344 24.582031-107.941407 61.894531-146.128907 39.433594-40.308593 92.71875-62.515624 149.871094-62.515624s110.4375 22.207031 149.867188 62.515624c37.4375 38.1875 59.152343 89.726563 61.898437 146.128907zm0 0" />
              </svg>
              <span>{this.props.customer.FirstName}</span>
              <i className="fa fa-caret-down"></i>
              {this.state.showProfileMenu && (
                <div
                  className="profile-menu-container"
                  onMouseOver={() => {
                    this.setState({ showProfileMenu: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ showProfileMenu: false });
                  }}
                >
                  <div className="profile-menu">
                    <div
                      className="profile-menu-item"
                      onClick={() => {
                        this.props.history.push(
                          mainWebsitePath() + "myprofile"
                        );
                      }}
                    >
                      My Account
                    </div>
                    <hr />
                    <div
                      className="profile-menu-item"
                      onClick={() => {
                        this.props.history.push(mainWebsitePath() + "myorders");
                      }}
                    >
                      My Orders
                    </div>
                    {this.props.customer &&
                      this.props.config.CheckoutConfig.hasOwnProperty(
                        "PaymentModes"
                      ) &&
                      this.props.config.CheckoutConfig.PaymentModes.filter(
                        (f) => f.Value.toLowerCase() === "wallet"
                      ).length > 0 && (
                        <React.Fragment>
                          <hr />
                          <div
                            className="profile-menu-item"
                            onClick={() => {
                              this.props.history.push(
                                mainWebsitePath() + "myWallet"
                              );
                            }}
                          >
                            My Wallet
                          </div>
                        </React.Fragment>
                      )}
                    {this.props.customer &&
                      this.props.config.CheckoutConfig.hasOwnProperty(
                        "PaymentModes"
                      ) &&
                      this.props.config.CheckoutConfig.PaymentModes.filter(
                        (f) => f.Value.toLowerCase() === "loyalty"
                      ).length > 0 && (
                        <React.Fragment>
                          <hr />
                          <div
                            className="profile-menu-item"
                            onClick={() => {
                              this.props.history.push(
                                mainWebsitePath() + "loyalty"
                              );
                            }}
                          >
                            Loyalty
                          </div>
                        </React.Fragment>
                      )}
                    <hr />
                    <div
                      className="profile-menu-item"
                      onClick={this.onSignOutClick}
                    >
                      Sign Out
                    </div>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  showLoginButton() {
    return (
      <React.Fragment>
        {!this.props.customer && (
          <div className="login" onClick={this.onLoginClick}>
            <Icon type="login" />
            <span>Login / Sign Up</span>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderContactInfo() {
    return (
      <div className="contatinfo">
        <div className="container">
          <LocationLabel
            onClick={() => {
              this.setState({ showLocation: true });
            }}
          />

          {this.props.config.CheckoutConfig.hasOwnProperty("EmailID") &&
            this.props.config.CheckoutConfig.EmailID.trim() !== "" && (
              <div
                className="emailid"
                onClick={() => {
                  this.onContactClick();
                }}
              >
                <i className="fa fa-envelope"></i>
                <span> {this.props.config.CheckoutConfig.EmailID.trim()}</span>
              </div>
            )}

          {this.props.config.CheckoutConfig.hasOwnProperty("ContactNo") &&
            this.props.config.CheckoutConfig.ContactNo.trim() !== "" && (
              <div className="contactno">
                <a
                  href={
                    "tel:" + this.props.config.CheckoutConfig.ContactNo.trim()
                  }
                >
                  <i className="fa fa-phone"></i>
                  <span>
                    {this.props.config.CheckoutConfig.ContactNo.trim()}
                  </span>
                </a>
              </div>
            )}

          {this.props.config.CheckoutConfig.hasOwnProperty("WhatsAppNo") &&
            this.props.config.CheckoutConfig.WhatsAppNo.trim() !== "" && (
              <div
                className="whatsapp"
                onClick={() => {
                  this.onWhatsAppClick();
                }}
              >
                <i className="fa fa-whatsapp"></i>
                <span>
                  {this.props.config.CheckoutConfig.WhatsAppNo.trim()}
                </span>
              </div>
            )}
        </div>
      </div>
    );
  }

  renderMenuItem(m) {
    let parentID = m.ID;
    return (
      <React.Fragment>
        <div
          key={"category_" + m.ID}
          className="menu-row seperator"
          onClick={() => {
            if (this.state.selectedID !== parentID) {
              this.onMenuItemSelected(m.OMNITag, m.ID, "category");
            } else {
              this.setState({ selectedID: "" });
            }
          }}
        >
          <div className="menu-row-text">{m.Name}</div>
          {this.state.selectedID !== parentID && this.renderMenuCollapse()}
          {this.state.selectedID === parentID && this.renderMenuExpand()}
        </div>
        {this.state.selectedID === parentID && (
          <React.Fragment>
            {this.props.menu
              .filter(
                (f) =>
                  f.ParentID === this.state.selectedID &&
                  this.state.selectedID !== ""
              )
              .map((m) => (
                <div
                  key={"subcategory_" + m.ID}
                  className="menu-row seperator"
                  onClick={() =>
                    this.onMenuItemSelected(m.OMNITag, m.ID, "subcategory")
                  }
                >
                  <div className="menu-row-text menu-sub-item">{m.Name}</div>
                </div>
              ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  renderMobileMenu() {
    let showCategoriesAsMenu = true;
    // console.log("this.props.customer",this.props.customer)
    if (
      this.props.config.CheckoutConfig.hasOwnProperty("Menus") &&
      this.props.config.CheckoutConfig.Menus.length > 0
    ) {
      showCategoriesAsMenu = false;
    }

    return (
      <React.Fragment>
        <div
          className={
            this.state.isOpen ? "mobile-menu-show" : "mobile-menu-hide"
          }
        >
          <div className="menu-logo">
            <Logo size={"lg"} showWebsiteLogo={true} />
          </div>

          {!this.props.customer && (
            <div className="menu-login-signup">
              <button
                className="btn btn-flat checkout-login-signup-btn"
                onClick={() => {
                  this.closeMenu();
                  this.setState({ showLogin: true });
                }}
              >
                Login or Signup
              </button>
            </div>
          )}

          {this.props.customer && (
            <div className="menu-customer-welcome">
              <span className="menu-customer-welcome-msg">
                Welcome {this.props.customer.FirstName}
              </span>
              <br />
              <button
                className="btn btn-flat checkout-login-signup-btn"
                onClick={() => this.onSignOutClick()}
              >
                Signout
              </button>
            </div>
          )}

          <div className="menu-content">
            <div
              className="menu-row seperator"
              onClick={() => this.onHomeClick()}
            >
              <Icon type="home" />
              <div className="menu-row-text" style={{ paddingLeft: "10px" }}>
                Home
              </div>
            </div>

            {this.props.customer && (
              <div
                className="menu-row seperator"
                onClick={() => {
                  this.closeMenu();
                  this.props.history.push(mainWebsitePath() + "myprofile");
                }}
              >
                <svg width="24px" viewBox="-77 -19 629 629.33435">
                  <path d="m233.371094 294.5c81.328125 0 147.25-65.925781 147.25-147.25 0-81.320312-65.921875-147.25-147.25-147.25-81.320313 0-147.25 65.929688-147.25 147.25.0625 81.296875 65.953125 147.1875 147.25 147.25zm0-269.542969c67.542968 0 122.292968 54.75 122.292968 122.292969s-54.75 122.292969-122.292968 122.292969c-67.535156 0-122.292969-54.75-122.292969-122.292969.113281-67.492188 54.800781-122.183594 122.292969-122.292969zm0 0" />
                  <path d="m233.371094 331.3125c-64.011719 0-123.539063 24.832031-167.710938 70.007812-44.800781 45.796876-69.386718 108.3125-69.386718 176.203126.019531 6.882812 5.597656 12.457031 12.480468 12.476562h449.238282c6.886718-.019531 12.457031-5.59375 12.476562-12.476562 0-67.761719-24.582031-130.40625-69.378906-176.078126-44.175782-45.175781-103.699219-70.132812-167.71875-70.132812zm-211.886719 233.730469c2.746094-56.402344 24.582031-107.941407 61.894531-146.128907 39.433594-40.308593 92.71875-62.515624 149.871094-62.515624s110.4375 22.207031 149.867188 62.515624c37.4375 38.1875 59.152343 89.726563 61.898437 146.128907zm0 0" />
                </svg>
                <div className="menu-row-text" style={{ paddingLeft: "10px" }}>
                  My Profile
                </div>
              </div>
            )}

            {this.props.customer && (
              <div
                className="menu-row seperator"
                onClick={() => {
                  this.closeMenu();
                  this.props.history.push(mainWebsitePath() + "myorders");
                }}
              >
                <svg viewBox="0 -13 456.75885 456" width="24px">
                  <path d="m150.355469 322.332031c-30.046875 0-54.402344 24.355469-54.402344 54.402344 0 30.042969 24.355469 54.398437 54.402344 54.398437 30.042969 0 54.398437-24.355468 54.398437-54.398437-.03125-30.03125-24.367187-54.371094-54.398437-54.402344zm0 88.800781c-19 0-34.402344-15.402343-34.402344-34.398437 0-19 15.402344-34.402344 34.402344-34.402344 18.996093 0 34.398437 15.402344 34.398437 34.402344 0 18.996094-15.402344 34.398437-34.398437 34.398437zm0 0" />
                  <path d="m446.855469 94.035156h-353.101563l-7.199218-40.300781c-4.4375-24.808594-23.882813-44.214844-48.699219-48.601563l-26.101563-4.597656c-5.441406-.96875-10.632812 2.660156-11.601562 8.097656-.964844 5.441407 2.660156 10.632813 8.101562 11.601563l26.199219 4.597656c16.53125 2.929688 29.472656 15.871094 32.402344 32.402344l35.398437 199.699219c4.179688 23.894531 24.941406 41.324218 49.199219 41.300781h210c22.0625.066406 41.546875-14.375 47.902344-35.5l47-155.800781c.871093-3.039063.320312-6.3125-1.5-8.898438-1.902344-2.503906-4.859375-3.980468-8-4zm-56.601563 162.796875c-3.773437 12.6875-15.464844 21.367188-28.699218 21.300781h-210c-14.566407.039063-27.035157-10.441406-29.5-24.800781l-24.699219-139.398437h336.097656zm0 0" />
                  <path d="m360.355469 322.332031c-30.046875 0-54.402344 24.355469-54.402344 54.402344 0 30.042969 24.355469 54.398437 54.402344 54.398437 30.042969 0 54.398437-24.355468 54.398437-54.398437-.03125-30.03125-24.367187-54.371094-54.398437-54.402344zm0 88.800781c-19 0-34.402344-15.402343-34.402344-34.398437 0-19 15.402344-34.402344 34.402344-34.402344 18.996093 0 34.398437 15.402344 34.398437 34.402344 0 18.996094-15.402344 34.398437-34.398437 34.398437zm0 0" />
                </svg>
                <div className="menu-row-text" style={{ paddingLeft: "10px" }}>
                  My Orders
                </div>
              </div>
            )}

            {this.props.customer && (
              <div
                className="menu-row seperator"
                onClick={() => {
                  this.closeMenu();
                  this.props.history.push(mainWebsitePath() + "favourites");
                }}
              >
                <svg viewBox="0 0 512 512" width="24px">
                  <g>
                    <g>
                      <path d="M378.667,21.333c-56.792,0-103.698,52.75-122.667,77.646c-18.969-24.896-65.875-77.646-122.667-77.646    C59.813,21.333,0,88.927,0,172c0,45.323,17.99,87.562,49.479,116.469c0.458,0.792,1.021,1.521,1.677,2.177l197.313,196.906    c2.083,2.073,4.802,3.115,7.531,3.115s5.458-1.042,7.542-3.125L467.417,283.74l2.104-2.042c1.667-1.573,3.313-3.167,5.156-5.208    c0.771-0.76,1.406-1.615,1.896-2.542C499.438,245.948,512,209.833,512,172C512,88.927,452.188,21.333,378.667,21.333z     M458.823,261.948c-0.292,0.344-0.563,0.708-0.802,1.083c-1,1.146-2.094,2.156-3.177,3.188L255.99,464.927L68.667,277.979    c-0.604-1.188-1.448-2.271-2.479-3.177C37.677,249.906,21.333,212.437,21.333,172c0-71.313,50.24-129.333,112-129.333    c61.063,0,113.177,79.646,113.698,80.448c3.938,6.083,14,6.083,17.938,0c0.521-0.802,52.635-80.448,113.698-80.448    c61.76,0,112,58.021,112,129.333C490.667,205.604,479.354,237.552,458.823,261.948z" />
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              </div>
            )}

            {this.props.customer &&
              this.props.config.CheckoutConfig &&
              this.props.config.CheckoutConfig.PaymentModes &&
              this.props.config.CheckoutConfig.PaymentModes.filter(
                (f) => f.Value.toLowerCase() === "wallet"
              ).length > 0 && (
                <div
                  className="menu-row seperator"
                  onClick={() => {
                    this.closeMenu();
                    this.props.history.push(mainWebsitePath() + "myWallet");
                  }}
                >
                  <svg viewBox="0 0 417.6 417.6" width="24px">
                    <g>
                      <g>
                        <g>
                          <path d="M409.6,154.6c-5.2-4.8-12-8-19.6-8c0.4,0,0.8,0,1.2,0v-41.2c0-16.8-6.8-32-18-43.2c-11.2-11.2-26.4-18-43.2-18H61.2     c-16.8,0-32,6.8-43.2,18C6.8,73.4,0,88.6,0,105.4v206.8c0,16.8,6.8,32,18,43.2s26.4,18,43.2,18H330c16.8,0,32-6.8,43.2-18     s18-26.4,18-43.2v-41.6c-0.4,0-0.8,0-1.2,0c7.6,0,14.4-3.2,19.6-8c4.8-4.8,8-12,8-19.6v-68.8     C417.6,166.6,414.4,159.8,409.6,154.6z M369.2,311.8c0,10.8-4.4,20.4-11.6,27.6S340.8,351,330,351H61.2     c-10.8,0-20.4-4.4-27.6-11.6c-7.2-7.2-11.6-16.8-11.6-27.6V105.4c0-10.8,4.4-20.4,11.6-27.6c7.2-7.2,16.8-11.6,27.6-11.6H330     c10.8,0,20.4,4.4,27.6,11.6s11.6,16.8,11.6,27.6v41.2h-124c-16.8,0-31.6,6.8-42.8,17.6c-10.8,10.8-17.6,26-17.6,42.8v2.8     c0,16.8,6.8,31.6,17.6,42.8c10.8,10.8,26,17.6,42.8,17.6h124V311.8z M395.6,243L395.6,243c0,1.6-0.8,3.2-1.6,4     c-1.2,1.2-2.4,1.6-4,1.6H245.2c-10.4,0-20.4-4.4-27.2-11.2s-11.2-16.8-11.2-27.2v-2.8c0-10.8,4.4-20.4,11.2-27.2     c7.2-7.2,16.8-11.2,27.2-11.2H390c1.6,0,3.2,0.8,4,1.6c1.2,1.2,1.6,2.4,1.6,4V243z" />
                          <path d="M248,190.6c-4.8,0-9.2,2-12.4,5.2c-3.2,3.2-5.2,7.6-5.2,12.4s2,9.2,5.2,12.4s7.6,5.2,12.4,5.2s9.2-2,12.8-5.2     c3.2-3.2,5.2-7.6,5.2-12.8c0-4.8-2-9.2-5.2-12.8C257.2,192.6,252.8,190.6,248,190.6z" />
                        </g>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <div
                    className="menu-row-text"
                    style={{ paddingLeft: "10px" }}
                  >
                    My Wallet
                  </div>
                </div>
              )}

            {this.props.customer &&
              this.props.config.CheckoutConfig &&
              this.props.config.CheckoutConfig.PaymentModes &&
              this.props.config.CheckoutConfig.PaymentModes.filter(
                (f) => f.Value.toLowerCase() === "loyalty"
              ).length > 0 && (
                <div
                  className="menu-row seperator"
                  onClick={() => {
                    this.closeMenu();
                    this.props.history.push(mainWebsitePath() + "loyalty");
                  }}
                >
                  <svg viewBox="0 0 417.6 417.6" width="24px">
                    <g>
                      <g>
                        <g>
                          <path d="M409.6,154.6c-5.2-4.8-12-8-19.6-8c0.4,0,0.8,0,1.2,0v-41.2c0-16.8-6.8-32-18-43.2c-11.2-11.2-26.4-18-43.2-18H61.2     c-16.8,0-32,6.8-43.2,18C6.8,73.4,0,88.6,0,105.4v206.8c0,16.8,6.8,32,18,43.2s26.4,18,43.2,18H330c16.8,0,32-6.8,43.2-18     s18-26.4,18-43.2v-41.6c-0.4,0-0.8,0-1.2,0c7.6,0,14.4-3.2,19.6-8c4.8-4.8,8-12,8-19.6v-68.8     C417.6,166.6,414.4,159.8,409.6,154.6z M369.2,311.8c0,10.8-4.4,20.4-11.6,27.6S340.8,351,330,351H61.2     c-10.8,0-20.4-4.4-27.6-11.6c-7.2-7.2-11.6-16.8-11.6-27.6V105.4c0-10.8,4.4-20.4,11.6-27.6c7.2-7.2,16.8-11.6,27.6-11.6H330     c10.8,0,20.4,4.4,27.6,11.6s11.6,16.8,11.6,27.6v41.2h-124c-16.8,0-31.6,6.8-42.8,17.6c-10.8,10.8-17.6,26-17.6,42.8v2.8     c0,16.8,6.8,31.6,17.6,42.8c10.8,10.8,26,17.6,42.8,17.6h124V311.8z M395.6,243L395.6,243c0,1.6-0.8,3.2-1.6,4     c-1.2,1.2-2.4,1.6-4,1.6H245.2c-10.4,0-20.4-4.4-27.2-11.2s-11.2-16.8-11.2-27.2v-2.8c0-10.8,4.4-20.4,11.2-27.2     c7.2-7.2,16.8-11.2,27.2-11.2H390c1.6,0,3.2,0.8,4,1.6c1.2,1.2,1.6,2.4,1.6,4V243z" />
                          <path d="M248,190.6c-4.8,0-9.2,2-12.4,5.2c-3.2,3.2-5.2,7.6-5.2,12.4s2,9.2,5.2,12.4s7.6,5.2,12.4,5.2s9.2-2,12.8-5.2     c3.2-3.2,5.2-7.6,5.2-12.8c0-4.8-2-9.2-5.2-12.8C257.2,192.6,252.8,190.6,248,190.6z" />
                        </g>
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                  <div
                    className="menu-row-text"
                    style={{ paddingLeft: "10px" }}
                  >
                    Loyalty Program
                  </div>
                </div>
              )}

            {!showCategoriesAsMenu && (
              <React.Fragment>
                {this.props.config.CheckoutConfig.Menus.map((m, index) => (
                  <div
                    key={"menuitem_" + index}
                    className="menu-row seperator"
                    onClick={() => this.onMenuSelected(m)}
                  >
                    <div className="menu-row-text">{m.Name}</div>
                  </div>
                ))}
              </React.Fragment>
            )}

            {showCategoriesAsMenu && (
              <React.Fragment>
                {this.props.menu
                  .filter((f) => f.ParentID === "")
                  .map((m) => this.renderMenuItem(m))}
              </React.Fragment>
            )}

            {this.props.config.CheckoutConfig &&
              this.props.config.CheckoutConfig.ShowContactDetailsInMenu && (
                <React.Fragment>
                  <div
                    className="menu-row seperator"
                    onClick={() => {
                      this.setState({
                        showContactUs: !this.state.showContactUs,
                      });
                    }}
                  >
                    <div className="menu-row-text">Contact us</div>
                    {!this.state.showContactUs && this.renderMenuCollapse()}
                    {this.state.showContactUs && this.renderMenuExpand()}
                  </div>
                  {this.state.showContactUs && (
                    <React.Fragment>
                      {this.props.config.CheckoutConfig.hasOwnProperty(
                        "EmailID"
                      ) &&
                        this.props.config.CheckoutConfig.EmailID.trim() !==
                          "" && (
                          <div
                            className="menu-row seperator"
                            onClick={() => {
                              this.props.history.push(
                                mainWebsitePath() + "Contact"
                              );
                            }}
                          >
                            <i
                              className="fa fa-envelope"
                              style={{ fontSize: "18px", color: "#101928" }}
                            ></i>{" "}
                            &nbsp;
                            <span className="menu-row-text">
                              {this.props.config.CheckoutConfig.EmailID}
                            </span>
                          </div>
                        )}
                      {this.props.config.CheckoutConfig.hasOwnProperty(
                        "ContactNo"
                      ) &&
                        this.props.config.CheckoutConfig.ContactNo.trim() !==
                          "" && (
                          <div className="menu-row seperator">
                            <a
                              href={
                                "tel:" +
                                this.props.config.CheckoutConfig.ContactNo.trim()
                              }
                            >
                              <i
                                className="fa fa-phone"
                                style={{ fontSize: "18px", color: "#101928" }}
                              ></i>{" "}
                              &nbsp;
                              <span className="menu-row-text">
                                {this.props.config.CheckoutConfig.ContactNo.trim()}
                              </span>
                            </a>
                          </div>
                        )}
                      {this.props.config.CheckoutConfig.hasOwnProperty(
                        "WhatsAppNo"
                      ) &&
                        this.props.config.CheckoutConfig.WhatsAppNo.trim() !==
                          "" && (
                          <div
                            className="menu-row seperator"
                            onClick={() => {
                              window.open(
                                "https://wa.me/91" +
                                  this.props.config.CheckoutConfig.WhatsAppNo.trim()
                              );
                            }}
                          >
                            <i
                              className="fa fa-whatsapp"
                              style={{ fontSize: "18px", color: "#101928" }}
                            ></i>
                            &nbsp;
                            <span className="menu-row-text">
                              {" "}
                              {this.props.config.CheckoutConfig.WhatsAppNo.trim()}
                            </span>
                          </div>
                        )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

            {this.props.config.CheckoutConfig &&
              this.props.config.CheckoutConfig.ShowSocialMediaDetailsInMenu && (
                <React.Fragment>
                  <div
                    className="menu-row seperator"
                    onClick={() => {
                      this.setState({ showFollowUs: !this.state.showFollowUs });
                    }}
                  >
                    <div className="menu-row-text">Follow us</div>
                    {!this.state.showFollowUs && this.renderMenuCollapse()}
                    {this.state.showFollowUs && this.renderMenuExpand()}
                  </div>
                  {this.state.showFollowUs && (
                    <React.Fragment>
                      {this.props.config.CheckoutConfig.hasOwnProperty(
                        "FacebookURL"
                      ) &&
                        this.props.config.CheckoutConfig.FacebookURL.trim() !==
                          "" && (
                          <div className="menu-row seperator">
                            <a
                              href={this.props.config.CheckoutConfig.FacebookURL.trim()}
                              target="_blank"
                            >
                              <i
                                className="fa fa-facebook"
                                style={{ fontSize: "18px", color: "#101928" }}
                              ></i>
                              &nbsp;
                              <span className="menu-row-text">Facebook</span>
                            </a>
                          </div>
                        )}

                      {this.props.config.CheckoutConfig.hasOwnProperty(
                        "InstagramURL"
                      ) &&
                        this.props.config.CheckoutConfig.InstagramURL.trim() !==
                          "" && (
                          <div className="menu-row seperator">
                            <a
                              href={this.props.config.CheckoutConfig.InstagramURL.trim()}
                              target="_blank"
                            >
                              <i
                                className="fa fa-instagram"
                                style={{ fontSize: "18px", color: "#101928" }}
                              ></i>
                              &nbsp;
                              <span className="menu-row-text">Instagram</span>
                            </a>
                          </div>
                        )}

                      {this.props.config.CheckoutConfig.hasOwnProperty(
                        "TwitterURL"
                      ) &&
                        this.props.config.CheckoutConfig.TwitterURL.trim() !==
                          "" && (
                          <div className="menu-row seperator">
                            <a
                              href={this.props.config.CheckoutConfig.TwitterURL.trim()}
                              target="_blank"
                            >
                              <i
                                className="fa fa-twitter"
                                style={{ fontSize: "18px", color: "#101928" }}
                              ></i>
                              &nbsp;
                              <span className="menu-row-text">Twitter</span>
                            </a>
                          </div>
                        )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

            {this.renderUsefullLinks()}
          </div>
          <br />
          <br />
        </div>
      </React.Fragment>
    );
  }

  renderUsefullLinks() {
    let menuLinks = this.props.pages.filter((f) => f.ShowInMenu);
    if (menuLinks.length > 0) {
      return (
        <React.Fragment>
          <div
            className="menu-row seperator"
            onClick={() => {
              this.setState({ showUsefulLinks: !this.state.showUsefulLinks });
            }}
          >
            <div className="menu-row-text">Useful links</div>
            {!this.state.showUsefulLinks && this.renderMenuCollapse()}
            {this.state.showUsefulLinks && this.renderMenuExpand()}
          </div>
          {this.state.showUsefulLinks && (
            <React.Fragment>
              {menuLinks.map((p, index) => (
                <div
                  key={"useful_links" + index}
                  className="menu-row seperator"
                >
                  <div
                    className="menu-row-text"
                    onClick={() => this.onPageClick(p)}
                  >
                    {p.Title}
                  </div>
                </div>
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
  }

  onPageClick(m) {
    this.closeMenu();
    const { history } = this.props;
    history.push(mainWebsitePath() + "pages/" + m.Title);
  }

  renderMenuCollapse() {
    return (
      <svg viewBox="0 0 24 24" height="18px">
        <path d="M15.4,9.879,10.811,5.293A1,1,0,0,0,9.4,6.707l4.586,4.586a1,1,0,0,1,0,1.414L9.4,17.293a1,1,0,0,0,1.415,1.414L15.4,14.121A3,3,0,0,0,15.4,9.879Z" />
      </svg>
    );
  }

  renderMenuExpand() {
    return (
      <svg viewBox="0 0 24 24" height="18px">
        <path d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,0,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586A1,1,0,0,0,18.707,8.207Z" />
      </svg>
    );
  }

  renderMobileHeader() {
    return (
      <React.Fragment>
        <div className="header-height"></div>
        <div className="header-mobile-new">
          {this.showMenu() && (
            <div
              className="header-mobile-new-menu-button pull-left"
              onClick={() => this.openMenu()}
            >
              {/* <svg viewBox="0 0 512 512">
                            <g><g><path d="M501.333,96H10.667C4.779,96,0,100.779,0,106.667s4.779,10.667,10.667,10.667h490.667c5.888,0,10.667-4.779,10.667-10.667    S507.221,96,501.333,96z" /></g></g><g><g><path d="M501.333,245.333H10.667C4.779,245.333,0,250.112,0,256s4.779,10.667,10.667,10.667h490.667    c5.888,0,10.667-4.779,10.667-10.667S507.221,245.333,501.333,245.333z" /></g></g><g><g><path d="M501.333,394.667H10.667C4.779,394.667,0,399.445,0,405.333C0,411.221,4.779,416,10.667,416h490.667    c5.888,0,10.667-4.779,10.667-10.667C512,399.445,507.221,394.667,501.333,394.667z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                        </svg> */}
              <Icon type="menubar" />
            </div>
          )}
          <div
            className="header-mobile-new-businessname"
            onClick={() => this.onHomeClick()}
          >
            {/* {this.props.config.BusinessName} */}
            {this.showLogo()}
          </div>
          {/* {this.showMenu() && <div className="header-mobile-search">
                        <Search displayMode="button" searchPlaceHolder="Search for products"/>
                    </div>} */}
        </div>
        <div className="header-search-bar-container">
          <div
            className="header-search-bar"
            onClick={() => this.setState({ showSearchPage: true })}
          >
            <Icon type="search" />
            <div onClick={() => this.setState({ showSearchPage: true })}>
              Search for items
            </div>
          </div>
        </div>
        {this.state.isOpen && (
          <div className="menu-overlay" onClick={() => this.closeMenu()} />
        )}

        {this.renderMobileMenu()}
        {this.state.showSearchPage && (
          <Search
            displayMode="button"
            show={this.state.showSearchPage}
            searchPlaceHolder="Search for products"
            onHide={() => this.setState({ showSearchPage: false })}
          />
        )}
      </React.Fragment>
    );
  }

  renderStoreSelection() {
    return (
      <React.Fragment>
        <div className="menu-overlay" />
        <Window
          onClose={() => {}}
          header={"Welcome to " + this.props.config.BusinessName}
          width={"500px"}
          height={"500px"}
          modal={false}
          processing={this.state.processing}
          hideClose={true}
        >
          <StoreLocator
            screen={this}
            onClose={() => {
              this.setState({ showStoreSelection: false });
            }}
          />
        </Window>
      </React.Fragment>
    );
  }

  renderDesktopMenu() {
    if (
      this.props.config.CheckoutConfig.hasOwnProperty("Menus") &&
      this.props.config.CheckoutConfig.Menus.length > 0
    ) {
      //render mew menus
      return (
        <React.Fragment>
          {this.props.config.CheckoutConfig.Menus.map((m, index) => (
            <div
              key={"menuitem_" + index}
              className="menu-item"
              onClick={() => this.onMenuSelected(m)}
            >
              {m.Name}
            </div>
          ))}
        </React.Fragment>
      );
    } else {
      //render online catgories that are in Menu
      return (
        <React.Fragment>
          <div
            key={"menuitem_home"}
            className="menu-item"
            onClick={() => this.props.history.push(mainWebsitePath())}
          >
            Home
          </div>
          {this.props.menu
            .filter((f) => f.ParentID === "")
            .map((m, index) => (
              <div
                key={"menuitem_" + index}
                className="menu-item"
                onClick={() =>
                  this.props.history.push(mainWebsitePath() + "l1/" + m.OMNITag)
                }
              >
                {m.Name}
              </div>
            ))}
        </React.Fragment>
      );
    }
  }

  showMenu() {
    let showMenu = true;
    if (
      this.props.config.CheckoutConfig.hasOwnProperty("PrivateCatalogue") &&
      this.props.config.CheckoutConfig.PrivateCatalogue
    ) {
      if (!this.props.customer) {
        showMenu = false;
      }
    }

    return showMenu;
  }

  renderDesktop() {
    let headerTemplate =
      this.props.config.CheckoutConfig.hasOwnProperty("HeaderTemplate") &&
      this.props.config.CheckoutConfig.HeaderTemplate !== ""
        ? this.props.config.CheckoutConfig.HeaderTemplate
        : "Template1";

    return (
      <React.Fragment>
        {headerTemplate === "Template1" && (
          <React.Fragment>
            {this.state.showLocation && (
              <Location
                onClose={() => this.setState({ showLocation: false })}
              />
            )}
            <div className="menubar" id="menubar">
              {this.renderContactInfo()}
              <div className="header">
                <div className="container">
                  <div className="search-cart-login-div">
                    {this.showLogo()}
                    <div className="searchbar">
                      <Search
                        displayMode="searchbar"
                        show={this.state.showSearchPage}
                        onHide={() => this.setState({ showSearchPage: false })}
                        onShow={() => this.setState({ showSearchPage: true })}
                        searchPlaceHolder="Search for products"
                      />
                    </div>
                    {this.showProfileButton()}
                    {this.showLoginButton()}
                    {this.showCartButton()}
                  </div>
                </div>
              </div>
              <div className="menu-items">
                <div className="container">{this.renderDesktopMenu()}</div>
              </div>
            </div>
          </React.Fragment>
        )}

        {headerTemplate === "Template2" && (
          <React.Fragment>
            <div className="menubar Template2" id="menubar">
              {this.renderContactInfo()}
              <div className="header">
                <div className="container">
                  <div className="search-cart-login-div">
                    {this.props.config.CheckoutConfig.HeaderTemplateConfig &&
                      this.props.config.CheckoutConfig.HeaderTemplateConfig
                        .LogoLeft && (
                        <div className="left-logo">
                          <img
                            src={
                              this.props.config.CheckoutConfig
                                .HeaderTemplateConfig.LogoLeft
                            }
                          ></img>
                        </div>
                      )}
                    <div>
                      {this.showProfileButton()}
                      {!this.props.config.CheckoutConfig.PrivateCatalogue &&
                        this.showLoginButton()}
                      {this.showMenu() && this.showCartButton()}
                    </div>
                    {this.props.config.CheckoutConfig.HeaderTemplateConfig &&
                      this.props.config.CheckoutConfig.HeaderTemplateConfig
                        .LogoRight && (
                        <div className="right-logo">
                          <img
                            src={
                              this.props.config.CheckoutConfig
                                .HeaderTemplateConfig.LogoRight
                            }
                          ></img>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {this.showMenu() && (
                <div className="menu-items">
                  <div className="container">{this.renderDesktopMenu()}</div>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderCart() {
    return (
      <React.Fragment>
        <div
          className="menu-overlay"
          onClick={() => this.setState({ showCart: false })}
        />
        <div className="menu-cart">
          <div
            onClick={() => {
              this.setState({ showCart: false });
            }}
            className="seperator"
          >
            <div className="menu-close-btn">
              <svg width="24px" viewBox="0 0 511.991 511.991">
                <g>
                  <g>
                    <path d="M153.433,255.991L381.037,18.033c4.063-4.26,3.917-11.01-0.333-15.083c-4.229-4.073-10.979-3.896-15.083,0.333    L130.954,248.616c-3.937,4.125-3.937,10.625,0,14.75L365.621,508.7c2.104,2.188,4.896,3.292,7.708,3.292    c2.646,0,5.313-0.979,7.375-2.958c4.25-4.073,4.396-10.823,0.333-15.083L153.433,255.991z" />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
              <div>CART</div>
            </div>
          </div>
          <div className="menu-cart-content">
            {this.props.lineItems.length === 0 && (
              <div className="menu-cart-checkout-empty">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <svg viewBox="0 0 450.391 450.391" width="100px">
                  <g>
                    <g>
                      <g>
                        <path d="M143.673,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02     c25.969,0,47.02-21.052,47.02-47.02C190.694,371.374,169.642,350.322,143.673,350.322z M143.673,423.465     c-14.427,0-26.122-11.695-26.122-26.122c0-14.427,11.695-26.122,26.122-26.122c14.427,0,26.122,11.695,26.122,26.122     C169.796,411.77,158.1,423.465,143.673,423.465z" />
                        <path d="M342.204,350.322c-25.969,0-47.02,21.052-47.02,47.02c0,25.969,21.052,47.02,47.02,47.02s47.02-21.052,47.02-47.02     C389.224,371.374,368.173,350.322,342.204,350.322z M342.204,423.465c-14.427,0-26.122-11.695-26.122-26.122     c0-14.427,11.695-26.122,26.122-26.122s26.122,11.695,26.122,26.122C368.327,411.77,356.631,423.465,342.204,423.465z" />
                        <path d="M448.261,76.037c-2.176-2.377-5.153-3.865-8.359-4.18L99.788,67.155L90.384,38.42     C83.759,19.211,65.771,6.243,45.453,6.028H10.449C4.678,6.028,0,10.706,0,16.477s4.678,10.449,10.449,10.449h35.004     c11.361,0.251,21.365,7.546,25.078,18.286l66.351,200.098l-5.224,12.016c-5.827,15.026-4.077,31.938,4.702,45.453     c8.695,13.274,23.323,21.466,39.184,21.943h203.233c5.771,0,10.449-4.678,10.449-10.449c0-5.771-4.678-10.449-10.449-10.449     H175.543c-8.957-0.224-17.202-4.936-21.943-12.539c-4.688-7.51-5.651-16.762-2.612-25.078l4.18-9.404l219.951-22.988     c24.16-2.661,44.034-20.233,49.633-43.886l25.078-105.012C450.96,81.893,450.36,78.492,448.261,76.037z M404.376,185.228     c-3.392,15.226-16.319,26.457-31.869,27.69l-217.339,22.465L106.58,88.053l320.261,4.702L404.376,185.228z" />
                      </g>
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
                <div className="content">Your cart is empty.</div>
              </div>
            )}

            {this.props.lineItems.length > 0 && (
              <div className="menu-cart-checkout-nonempty">
                {this.props.lineItems.map((m, index) => (
                  <Product
                    product={m.Product}
                    key={"checkout" + index}
                    displayMode="list"
                    checkOut={true}
                    index={index}
                  />
                ))}
                <br />
                <hr className="mb-4" />
                <div className="row">
                  <div className="col-6 pull-left">
                    <b>Total Item(s)</b>
                  </div>
                  <div className="col-6" style={{ textAlign: "right" }}>
                    <b>{this.props.lineItemTotal.billQty}</b>
                  </div>
                </div>
                <hr className="mb-4" />
                {this.props.config.hasOwnProperty("CheckoutConfig") &&
                  this.props.config.CheckoutConfig &&
                  this.props.config.CheckoutConfig.ShowProductPrice && (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-6 pull-left">
                          <b>Discount</b>
                        </div>
                        <div className="col-6" style={{ textAlign: "right" }}>
                          <b>₹ {this.props.lineItemTotal.billDiscount}</b>
                        </div>
                      </div>
                      <hr className="mb-4" />
                      <div className="row">
                        <div className="col-6 pull-left">
                          <b>To Pay</b>
                        </div>
                        <div className="col-6" style={{ textAlign: "right" }}>
                          <b>₹ {this.props.lineItemTotal.billSubTotal}</b>
                        </div>
                      </div>
                      <hr className="mb-4" />
                    </React.Fragment>
                  )}

                <div
                  className="menu-cart-footer"
                  onClick={() => {
                    this.props.history.push(mainWebsitePath() + "checkout");
                    this.setState({ showCart: false });
                  }}
                >
                  <div className="pull-left menu-cart-footer-cart-text">
                    {this.props.lineItemTotal.billQty}{" "}
                    {this.props.lineItemTotal.billQty > 1 ? "Items" : "Item"}
                    {this.props.config.hasOwnProperty("CheckoutConfig") &&
                    this.props.config.CheckoutConfig &&
                    this.props.config.CheckoutConfig.ShowProductPrice
                      ? " | ₹ " + this.props.lineItemTotal.billAmount
                      : ""}
                  </div>
                  <div className="pull-right menu-cart-footer-cart-text">
                    PROCEED TO CHECKOUT
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {/* <StoreLocator/> */}
        {this.state.showStoreSelection && this.renderStoreSelection()}
        {!isMobile() && this.renderDesktop()}
        {isMobile() && this.renderMobileHeader()}
        {this.state.showCart && this.renderCart()}
        {this.state.showLogin && (
          <Login
            onCustomerLogin={() => {
              this.setState({ showLogin: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.api.menu,
  config: state.api.config,
  customer: state.api.customer,
  lineItemTotal: state.cart.lineItemTotal,
  lineItems: state.cart.lineItems,
  pincode: state.api.pincode,
  pages: state.api.pages,
});

export default withRouter(
  connect(mapStateToProps, { setCustomer, setConfig })(Header)
);
