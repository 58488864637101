export const FETCH_CONFIG = 'FETCH_CONFIG';
export const FETCH_MENU = 'FETCH_CATEGORIES';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT_TAXES = 'FETCH_PRODUCT_TAXES';
export const FETCH_CUSTOM_FIELDS = 'FETCH_CUSTOM_FIELDS';
export const FETCH_PAGES = 'FETCH_PAGES';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE';
export const FETCH_CAROUSELS = 'FETCH_CAROUSELS';
export const FETCH_DELIVERY_PRODUCT = 'FETCH_DELIVERY_PRODUCT';
export const SET_PINCODE = 'SET_PINCODE';
export const SET_CUSTOMER_GEO_LOCATION = 'SET_CUSTOMER_GEO_LOCATION';
export const SET_STORE= 'SET_STORE';
export const FETCH_PACKAGING_PRODUCT = 'FETCH_PACKAGING_PRODUCT';