
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchMenu } from '../../services/api/actions';
import { fetchProducts, isMobile, getID , mainWebsitePath} from '../../services/util';
import Spinner from '../Spinner';
import ProductList from '../ProductList';
import PageFooter from '../PageFooter';
import Footer from '../Footer';
// import Search from '../Search';
import Header from "../Header";
import Icon from '../Common/Icon';
import './level2.css';

class Level2 extends Component {

    static propTypes = {
        menu: PropTypes.array.isRequired,
        config: PropTypes.object.isRequired,
        fetchMenu: PropTypes.func.isRequired
    };

    state = {
        products: [],
        isLoading: true,
        title:''
    }

    async componentDidMount() {    
        // console.log("componentDidMount", this.props.match.params.name.toLowerCase());
            this.loadData(this.props.match.params.name.toLowerCase());
            // console.log("type", this.props.type);
    }
    
    async componentWillReceiveProps(nextProps) 
    {
        this.loadData(nextProps.match.params.name.toLowerCase())
    }

    async loadData(selected)
    {
        // let searchResult = this.props.menu.filter(f => f.OMNITag.toLowerCase() === selected.toLowerCase()  && f.ParentID !=="")
        // let parentID, childID = ''
        // if (searchResult.length > 0) {
        //     childID = searchResult[0].ID
        //     parentID = searchResult[0].ParentID
        //         this.setState({ parentID: parentID, childID: childID, title:searchResult[0].Name })
        // }
        // //fetch products
        // let products = []
        // products = await fetchProducts(0, 1000, parentID, childID)
        // this.setState({ products: products, isLoading: false })

        let subcategory = await getID("subcategory",selected, this.props.config.key);
        console.log('subcategory',subcategory)
        //fetch products
        let products =[]
        // products= await fetchProducts(0, 1000, subcategory.ParentID, subcategory.id, "")
        products= await fetchProducts(0, 1000,subcategory.ParentID,subcategory.id ,"","",this.props.config.key, this.props.config.CheckoutConfig.StoreID)

        console.log('after fetchproducts',products)
        this.setState({products:products, title:subcategory.Name, isLoading:false})
    }

   
    render() {
      
        const { isLoading } = this.state;
        const { products } = this.state;

        return <React.Fragment>
                
                {!isMobile() && <Header/>}
                
                {isMobile() && <div className="level1-mobile-header">
                    <div className="level1-mobile-header-close-btn"  onClick={() =>this.props.history.push(mainWebsitePath())}>
                        <Icon type="back"/>
                    </div>
                    <div className='level1-mobile-header-title'>{this.state.title}</div>
                </div>}


                {isLoading && <Spinner />}

                {!isLoading && <div className="container">
                    
                    {isMobile() && <div className='level1-mobile-header-placeholder'></div>}
                    {!isMobile() && <div className="l1 page-title">{this.state.title}</div>}

                    {/* <div className="page-title">{this.state.title}</div> */}
                    
                    <ProductList products={products} displayMode={isMobile() ? "list" : "thumbnail" }/>
                
                </div>}

                <br />
                
                {!isMobile() &&<PageFooter config={this.props.config} />}
            
            <Footer config={this.props.config} show={isMobile()} width="100%"/>

        </React.Fragment>
    };
}


const mapStateToProps = state => ({
    menu: state.api.menu,
    config: state.api.config
});

export default connect(
    mapStateToProps,
    { fetchMenu }
)(Level2);
