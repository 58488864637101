import React, {Component} from "react";
import Icon from "../Common/Icon";
import './checkout.css';

class CheckOutHeader extends Component
{
    render(){
            return <React.Fragment>
            <div className="checkout-mobile-header">
                <div className="checkout-mobile-header-close-btn" onClick={() =>this.props.onClose()}>
                    <Icon type="back"/>
                </div>
                <div className='checkout-mobile-header-title'>{this.props.title}</div>
            </div>
            <div className="checkout-mobile-header-placeholder"></div>
        </React.Fragment>
    }
}

export default CheckOutHeader;