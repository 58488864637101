import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { fetchMenu } from "../../services/api/actions";
import {
  getAtomPaymentStatus,
  isMobile,
  mainWebsitePath,
  checkIfSaleExistsForAtomInitiatePaymentID,
  checkOut,
} from "../../services/util";
import { loadCart, loadCartTotal } from "../../services/cart/actions";
import Spinner from "../Spinner";
import PageFooter from "../PageFooter";
import Footer from "../Footer";
import Header from "../Header";
import Icon from "../Common/Icon";
import paymentSuccessImage from "./payment-success.png";
import paymentErrorImage from "./payment-error.png";
import "./checkout.css";

class CheckoutAtomResponse extends Component {
  static propTypes = {
    menu: PropTypes.array.isRequired,
    config: PropTypes.object.isRequired,
    // fetchMenu: PropTypes.func.isRequired,
  };

  state = {
    isLoading: true,
    screen: 0,
  };

  async componentDidMount() {
    // console.log('this.props.match.params.name.toLowerCase()', this.props.match.params.name.toLowerCase())
    let response = await getAtomPaymentStatus(this.props.match.params.id);
    // console.log("Atom response", JSON.stringify(response));
    if (response && response.data && response.data.data) {
      if (response.data.data.status === "success") {
        console.log("atom success");
        //save sale to
        //check if sale for this is already created or not
        let exists = await checkIfSaleExistsForAtomInitiatePaymentID(
          this.props.match.params.id
        );
        console.log("exists", exists);
        if (!exists) {
          let sale = response.data.data.Cart;
          sale.Payments.push({
            Amount: response.data.data.Amount,
            PaymentMode: "Online Payment",
            PaymentModeRemarks: "",
          });
          sale.BillPaidAmount = sale.BillAmount;
          sale.AtomInitiatePaymentID = this.props.match.params.id;
          sale.BalanceAmount = 0;
          console.log("sale", sale);
          //check
          response = await checkOut(sale);
          this.setState({ screen: 1, isLoading: false });
        } else {
          // alert("Order already placed");
          this.setState({ screen: 2, isLoading: false });
        }
      } else {
        //update card
        console.log("atom fail");
        const { loadCart, loadCartTotal } = this.props;
        let sale = response.data.data.Cart;
        loadCart(sale.LineItems);
        loadCartTotal({
          billQty: sale.BillQty,
          billSubTotal: sale.BillSubTotal,
          billAmount: sale.BillAmount,
          billTaxAmount: sale.BillTaxAmount,
          billDiscount: sale.BillDiscount,
          taxSummaryList: [],
          roundOff: 0,
        });
        this.setState({ screen: 3, isLoading: false });
        // loadCart(sale.LineItems);
      }
    } else {
      console.log("Atom response not received");
    }
  }

  renderCheckoutSuccess() {
    return (
      <React.Fragment>
        {/* <img style={{ width: "100px" }} src={require("./payment-success.png")}/> */}
        <img style={{ width: "100px" }} src={paymentSuccessImage} />
        <div className="text">Order placed successfully</div>
        <br />
        <br />
        Login to view order details
        {/* <div
          className="checkout-success-link"
          onClick={() => {
            this.setState({ successDisplay: false });
            this.props.history.push(mainWebsitePath() + "myorders");
          }}
        >
          Click here to view order details
        </div> */}
        <div
          className="checkout-success-link"
          onClick={() => {
            this.setState({ successDisplay: false });
            this.props.history.push(mainWebsitePath());
          }}
        >
          Continue shopping
        </div>
      </React.Fragment>
    );
  }

  renderCheckoutError() {
    return (
      <React.Fragment>
        <img style={{ width: "100px" }} src={paymentErrorImage} />
        <div className="text">Payment processing failed. Please try again.</div>
        <div
          className="checkout-success-link"
          onClick={() => {
            this.setState({ successDisplay: false });
            this.props.history.push(mainWebsitePath());
          }}
        >
          Continue shopping
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { isLoading, screen } = this.state;

    return (
      <React.Fragment>
        <React.Fragment>
          {!isMobile() && <Header />}

          {isMobile() && (
            <div className="level1-mobile-header">
              <div
                className="level1-mobile-header-close-btn"
                onClick={() => this.props.history.push(mainWebsitePath())}
              >
                <Icon type="back" />
              </div>
              <div className="level1-mobile-header-title">Payment response</div>
            </div>
          )}

          <div className="container checkoutatom">
            {isLoading && (
              <React.Fragment>
                <Spinner />
              </React.Fragment>
            )}
            {!isLoading && screen === 1 && (
              <React.Fragment>{this.renderCheckoutSuccess()}</React.Fragment>
            )}
            {!isLoading && screen === 3 && (
              <React.Fragment>{this.renderCheckoutError()}</React.Fragment>
            )}

            {!isLoading && screen === 2 && (
              <React.Fragment>
                <div className="text">
                  <br />
                  <br />
                  Order already placed for this transaction
                  <br />
                  <br />
                </div>
                <div
                  className="checkout-success-link"
                  onClick={() => {
                    this.setState({ successDisplay: false });
                    this.props.history.push(mainWebsitePath());
                  }}
                >
                  Continue shopping
                </div>
              </React.Fragment>
            )}

            <br />
          </div>

          {!isMobile() && <PageFooter config={this.props.config} />}
        </React.Fragment>

        <Footer config={this.props.config} show={isMobile()} width="100%" />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.api.menu,
  config: state.api.config,
});

export default connect(mapStateToProps, { loadCart, loadCartTotal })(
  CheckoutAtomResponse
);
