import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getWalletBalance,getWalletLedger, initiatePayment, isMobile , checkOut} from '../../services/util';
import Spinner from '../Spinner';
import Header from "../Header";
import PageFooter from "../PageFooter";
import Window from "../Common/Window";
import Input from "../Common/Input";

class MyWallet extends Component {

    constructor(props)
    {
        super(props)
        this.state = {
            walletLedger: [],
            walletBalance: 0,
            walletRechargeAmountTmp:0
        }
        this.loadWalletLedger = this.loadWalletLedger.bind(this);
        this.onWalletRechargeClicked = this.onWalletRechargeClicked.bind(this);
        this.onStartWalletRecharge = this.onStartWalletRecharge.bind(this);
        this.razorPayClick = this.razorPayClick.bind(this);
        this.razorPayHandler = this.razorPayHandler.bind(this);
    }
    
    async componentDidMount() {
        // console.log('this.props.config.CheckoutConfig.PaymentModes[index]',this.props.config.CheckoutConfig.PaymentModes)
        this.loadWalletLedger();
    }

    async loadWalletLedger(){
        let walletLedger = [];
        let walletBalance = 0;
        this.setState({isLoading:true})
        if (this.props.customer) {
            walletBalance = await getWalletBalance(this.props.customer.key)
            walletLedger = await getWalletLedger(this.props.customer.key, this.props.config.key);
        }
        // console.log('walletLedger',walletLedger)
        this.setState({ walletLedger: walletLedger, walletBalance:walletBalance, isLoading: false });
    }

    onWalletRechargeClicked(){
        this.setState({showWalletRecharge:true})
    }

    async onStartWalletRecharge(){
        //creates sales transaciton 
        
        if(this.props.config.CheckoutConfig.PaymentModes.filter(f=> f.Value === "razorpay").length ===0 ){
            alert('No payment gateway selected')
            return;
        }
        let selectedPaymentMode = this.props.config.CheckoutConfig.PaymentModes.filter(f=> f.Value === "razorpay")[0]
        if(this.state.walletRechargeAmountTmp ===0){
            alert('Please specify recharge amount')
            return;
        }
        if(!this.props.config.CheckoutConfig.hasOwnProperty('WalletRechargeTransactionTypeID')){
            alert('System is experiencing problem. Pls concta help line no.')
            return;
        }
        this.setState({processing:true})
        let walletRechargeAmountTmp = Number(this.state.walletRechargeAmountTmp);
        // console.log('this.state.walletRechargeAmountTmp',this.state.walletRechargeAmountTmp)
        let sale = {
            RegistrationID: this.props.config.key,
            StoreID: this.props.config.CheckoutConfig.StoreID,
            CashRegisterID: this.props.config.CheckoutConfig.CashRegisterID,
            TransactionTypeID: this.props.config.CheckoutConfig.WalletRechargeTransactionTypeID,
            RequestSource: "omni web",
            RequestSourceID: "omni web",
            Customer: this.props.customer,
            LineItems: [{RetailPrice: walletRechargeAmountTmp ,
                DiscountPer: 0,
                DiscountAmount: 0,
                Qty: 1,
                SubTotal: walletRechargeAmountTmp ,
                ChargeTaxOnProduct: false,
                PriceBasedTax: false,
                HSNSACCode: '',
                TaxInclusive: false,
                TaxGroup: '',
                TaxID: '',
                SubTotalWithDiscount: walletRechargeAmountTmp ,
                TaxableAmount: walletRechargeAmountTmp ,
                TotalTax: 0,
                Amount: walletRechargeAmountTmp ,
                ProductID: this.props.config.CheckoutConfig.WalletRechargeProductID,
                Product: "Wallet Recharge",
                BasePrice: walletRechargeAmountTmp ,
                TaxComponentAmount: [],
                Notes: ""}],
            BillAmount: walletRechargeAmountTmp,
            BalanceAmount: walletRechargeAmountTmp,
            BillChangeDue: 0,
            BillDiscount: 0,
            BillPaidAmount:walletRechargeAmountTmp ,
            BillQty: 1,
            BillSubTotal: walletRechargeAmountTmp,
            BillTaxAmount: 0,
            Payments:[]
        };
        this.setState({sale:sale})
        let response = await initiatePayment(
            sale,
            "razorpay",
            selectedPaymentMode
        );
        // console.log("initiatePayment response", response);
        // return;
        this.razorPayClick(
            response.data.data.paymentProviderReferenceID,
            sale,
            selectedPaymentMode.RazorPayKeyID
        );
        this.setState({processing:false})

    }

    razorPayClick(orderID, sale, key) {
        
        const options = {
            key: key,
            // amount: sale.BillAmount * 100,
            amount: this.state.billBalanceAmount * 100,
            currency: "INR",
            name: this.props.config.BusinessName,
            description: "Thank you for your purchase",
            // "image": "https://example.com/your_logo",
            order_id: orderID,
            handler: this.razorPayHandler,
            prefill: {
                name: sale.Customer.FirstName + " " + sale.Customer.LastName,
                email: sale.Customer.EmailID,
                contact: sale.Customer.PhoneNo,
            },
            // notes: {
            //     address: "Razorpay Corporate Office",
            // },
            // ,"theme": {
            //     "color": "#F37254"
            // }
        };
        // console.log(orderID, "razorpay_start", {})
        // console.log('options',options)
        // console.log('window.navigator',window.navigator)
        const rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response){
            // log(response.error.metadata.order_id, "razorpay_error", {OrderID:response.error.metadata.order_id, Response:response})
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
            alert('Payment processing failed. Please try again.')
    });
    rzp1.open();
    }

    async razorPayHandler(response) {
        // console.log('razorPayHandler response',response)
        
        let sale = this.state.sale;
        // console.log('sale, ',sale)
        sale.Payments.push( {
            Amount: sale.BalanceAmount,
            PaymentMode: "razorpay", //sale.PaymentProvider,
            PaymentModeRemarks: sale.PaymentProviderID,
        }) 
        sale.BalanceAmount = 0;
        sale.BillBalanceAmount = 0;
        sale.BillPaidAmount = sale.BillAmount;
        sale.PaymentProviderID = response.razorpay_payment_id;
        sale.PaymentProvider = "razorpay";
        // console.log('razorpay_payment_id',response)
        // if(sale.hasOwnProperty()
        // ];
        // alert(response.razorpay_payment_id);
        // log(response.razorpay_order_id ,"razorpay_success", {OrderID:sale.PaymentProviderID, Response:response})
        this.saveSale(sale);
    }

    async saveSale(sale) {
        // console.log("sale", sale);
        // return;
        let response = await checkOut(sale);
        if (response.status === 200 && response.data.status === "Success") {
            this.setState({
                showWalletRecharge: false,
                sale: null,
                walletRechargeAmountTmp:0,
                processing:false
            });
            
        } else {
            this.setState({
                showWalletRecharge: false,
                sale: null,
                walletRechargeAmountTmp:0,
                processing:false
            });
        }

        setTimeout(
            function () {
                this.setState({isLoading: true});
                this.loadWalletLedger();
            }.bind(this),
            2000
        );

        
    }


    renderWalletRecharge(){
        return <Window 
        onClose={()=>{this.setState({walletRechargeAmount:0, walletRechargeError:"",showWalletRecharge:false})}}
        header={"Wallet Recharge"}  
        width={"300px"}
        height={"200px"}
        modal={true}
        processing={this.state.processing}
        onButtonClick={this.onStartWalletRecharge}
        buttons={[{label:"Recharge"}]}>
            <Input  type="number" 
            label="Amount to recharge" 
            error={this.state.walletRechargeError}
            value={this.state.walletRechargeAmountTmp}
            onChange={(value)=>this.setState({walletRechargeAmountTmp:value, walletRechargeError:""})}/>
    </Window>}

    render() {

        const { walletLedger } = this.state;
        const { isLoading } = this.state;
        const {walletBalance} = this.state;

        return <React.Fragment>
            
            <Header/>
            
            {this.state.showWalletRecharge && this.renderWalletRecharge()}

             <div className="container">
                <div className="wallet-page"> 
                <div className="page-title">My Wallet</div>
                {isLoading && <Spinner />}
                {!isLoading &&<React.Fragment>
                        <div className="wallet-balance">{"₹ " + walletBalance}</div>
                        <div className="walletbalance-text">Wallet Balance</div>
                        {(this.props.config.CheckoutConfig.hasOwnProperty('EnableWalletRecharge') && this.props.config.CheckoutConfig.EnableWalletRecharge) &&  <div><button className="btn btn-flat wallet-recharge-btn" onClick={this.onWalletRechargeClicked}>Recharge Wallet</button></div>}
                        <div className="walletledger-transaction-detail-header">Transaction Details:</div>
                        {walletLedger.map((w,index)=> <div key={"walletledger" + index} className="walletledger">
                            <div className="walletledger-transaction-detail">
                                {/* {(new Date(w.TransactionDate).toISOString().split('T')[0])} */}
                                {(new Date(w.TransactionDate._seconds * 1000)).toString().substring(0, 16)}
                                <br/><span>{w.TransactionNo}</span>
                            </div>
                            {w.CreditAmount.toFixed(2) > 0 &&  <div className="walletledger-credit">
                                {"+ ₹ " +  Number(w.CreditAmount).toFixed(2)  }
                            </div>}
                            {w.DebitAmount.toFixed(2) > 0 &&  <div className="walletledger-debit">
                                 {"- ₹ " + Number(w.DebitAmount).toFixed(2) } 
                            </div>}
                        </div>)}
                    </React.Fragment>}       
                </div>
            </div>
            {!isMobile() && <PageFooter/>}
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    config: state.api.config,
    customer: state.api.customer
});

export default connect(
    mapStateToProps
)(MyWallet);