import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProduct, isMobile, fetchProducts } from '../../services/util';
import Spinner from '../Spinner';
import { addProduct, changeProductQuantity } from '../../services/cart/actions';
import PageFooter from '../PageFooter';
import {withRouter} from 'react-router-dom';
import Footer from '../Footer';
import Product from '../ProductList/Product';
import Header from "../Header";
import WriteReview from '../Common/ReviewRating/WriteReview';
import ReviewChart from '../Common/ReviewRating/ReviewChart';
import ReviewList from '../Common/ReviewRating/ReviewList';
import ProductList from '../ProductList/index';

class Level3 extends Component {

    static propTypes = {
        menu: PropTypes.array.isRequired,
        config: PropTypes.object.isRequired,
    };

    constructor(){
        super();
        this.state = {
            qty:0,
            product: null,
            isLoading: true,
            reviewRating:null,
            similarProducts:[],
            popularProducts:[],
            refreshReviewList:1
        }
        this.renderReviewSection = this.renderReviewSection.bind(this)
        this.renderSimilarProducts = this.renderSimilarProducts.bind(this)
    }

    async componentDidMount() {
        //fetch product
        this.loadProductDetails()
        // this.loadSimilarProducts(product)
        // this.loadPopularProducts()
    }

    async loadProductDetails()
    {
        let product = null
        product = await fetchProduct(this.props.config.key, this.props.match.params.name.toLowerCase())
        console.log('product',product)
        this.setState({ product: product, isLoading:false, reviewRating:product.hasOwnProperty('ReviewRating') ?  product.ReviewRating: null})

    }
    async loadSimilarProducts(product){
        console.log('product', product.CategoryID)
        // let result= await fetchProducts(0, 5, product.CategoryID,"")
        // let result= await fetchProducts(0, 5, product.CategoryID, "", "") ;
        let result= await fetchProducts(0, 5,product.CategoryID,"","","",this.props.config.key, this.props.config.CheckoutConfig.StoreID)

        let similarProducts = []
        for(let i=0;i<result.length;i++){
            if(product.key!==result.key && similarProducts.length <4 )
            {
                similarProducts.push(result[i])
            }
        }
        this.setState({similarProducts:similarProducts})
    }

    loadPopularProducts(){

    }

    renderSimilarProducts(){
        return <React.Fragment>
            {this.state.similarProducts.length > 0 && <div className="similar-products-section">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-title">Similar Products</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ProductList products={this.state.similarProducts} displayMode={"thumbnail" }/>
                    </div>
                </div>
        </div>}
        </React.Fragment>
    }

    renderReviewSection(product){
        return <div className="review-section">
            <div className="row">
                <div className="col-md-12">
                    <div className="page-title">Customer Review(s)</div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <ReviewChart reviewRating={this.state.reviewRating}/>
                </div>
                <div className="col-md-8">
                    <WriteReview productID={product.key} onSave={()=>{
                        this.loadProductDetails();
                        this.setState({refreshReviewList: (this.state.refreshReviewList + 1)})}}/>
                    <ReviewList productID={product.key} refresh={this.state.refreshReviewList}/>
                </div>
            </div>
        </div>
    }

    render() {
        const { isLoading } = this.state;
        const { product } = this.state;

        return <React.Fragment>
            
            <React.Fragment>
                <Header/>
                {isLoading && <Spinner />}
                {!isLoading && <div className="container">
                    <div className="level3">
                        <div className="product-detail">
                            {product && <Product product={this.state.product} displayMode={"detailpage"}/>}
                        </div>       
                    </div>
                    {/* {this.renderSimilarProducts()} */}
                    {/* {this.renderReviewSection(product)} */}
                </div>}
                <br />
                {!isMobile() && <PageFooter config={this.props.config} />}
            </React.Fragment>

            <Footer config={this.props.config} show={isMobile()} width="100%"/>

        </React.Fragment>
    };
}


const mapStateToProps = state => ({
    menu: state.api.menu,
    config: state.api.config,
    lineItems: state.cart.lineItems,
    customFields:state.api.customFields
});


export default withRouter(connect(
    mapStateToProps,
    { addProduct, changeProductQuantity }
)(Level3));
