import React, { Component } from 'react';
import Rating from './Rating';
import {getReviews} from '../../../services/util';

class ReviewList extends Component {

    constructor()
    {
        super()
        this.state={
            reviews:[]
        }
        this.loadReviews= this.loadReviews.bind(this)
    }

    async componentDidMount(){
        this.loadReviews()
    }

    async loadReviews(){
        let reviews = await getReviews(this.props.productID, 0 ,10)
        this.setState({reviews:reviews})
    }

    componentWillReceiveProps(nextProps) {
        // console.log('nextProps',nextProps);
       this.loadReviews()
      }

    render()
    {
        return <React.Fragment>

            {this.state.reviews.length > 0 && <React.Fragment>
                <div className="customer-reviews-title">Customer reviews</div>
                {this.state.reviews.map((review,index)=><div key={"review" + index} className="review">
                    <div>Rating {review.Rating}</div>
                    <Rating rating={review.Rating}/>
                    <div className="review-title">{review.Title}</div>
                    <div className="review-content">{review.Review}</div>
                    {review.CustomerName && <div className="review-by">Review by: {review.CustomerName}</div>}
                </div>)}
            </React.Fragment>}

        </React.Fragment>
    }
}

export default ReviewList;