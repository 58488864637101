import React, { Component } from "react";
import { connect } from "react-redux";
import { searchProducts, isMobile } from "../../services/util";
import Spinner from "../Spinner";
import ProductList from "../ProductList";
import Icon from "../Common/Icon";
import './search.css';

class Search extends Component {
    constructor() {
        super();
        this.state = {
            showSearchWindow: false,
            searchText: "",
            products: [],
            isLoading: false,
            from: 0,
            size: 100,
            // searchingOn:true
        };
    }

    async onSearch(searchText) {
        this.setState({ searchText: searchText });
        if (searchText.length > 3) {
            this.setState({ isLoading: true });
            let products = [];
            products = await searchProducts(
                this.props.config.key,
                this.state.from,
                this.state.size,
                searchText
            );
            this.setState({ products: products, isLoading: false });
        }
    }

    renderSearchWindow()
    {
        const { isLoading } = this.state;
        const { products } = this.state;

        return <div className="search-window">
        <div className="search-window-overlay" onClick={() => this.props.onHide()}></div>
        <div className="search-window-content">
            <div className="search-mobile-window-header">
                <div className="search-window-header-close-btn"  onClick={() =>this.props.onHide()}>
                    <Icon type="back"/>
                </div>
                <div className="search-bar-container">
                    <div className="search-bar">
                        <Icon type="search"/>
                        <input placeholder={this.props.searchPlaceHolder}
                        onChange={(evt) => {this.onSearch(evt.target.value);}}
                        value={this.state.searchText} />
                        {this.state.searchText.length > 0 && <div onClick={() =>
                                this.setState({
                                    searchText: "",
                                    products: [],
                                })
                            }> <Icon type="remove" /></div>}
                    </div>
                </div>
            </div>
            <div className="search-window-body">
                {isLoading && <Spinner />}
                {!isLoading && products.length > 0 && (
                    <ProductList
                        products={products}
                        displayMode="searchlist"
                        onEvent={() =>this.props.onHide()}
                    />
                )}
            </div>
        </div>
    </div>
    }

    renderSearchDropDown(){
        return <React.Fragment> 
            <div className="search-dropdown-overlay" 
                onClick={() =>
                    {
                        this.props.onHide();
                        this.setState({ 
                            // showSearchWindow: false, 
                            searchText:'' 
                    })
                    }
                   }></div>
            
            {this.state.isLoading && <div className="search-dropdown-loading" style={{width:document.getElementById('searchdiv').offsetWidth}}><i className="fa fa-spinner fa-spin"></i> Please wait</div>}
            {!this.state.isLoading && this.state.products.length > 0 && <div className="search-dropdown" style={{width:document.getElementById('searchdiv').offsetWidth}}><ProductList
                products={this.state.products}
                displayMode="searchlist"
                showSeperator={true}
                onEvent={() => this.props.onHide()
                    // this.setState({
                    //     showSearchWindow: false,
                    // })
                }
            />
            </div>}
            
        </React.Fragment>
    }

    render() {
        
        return (
            <React.Fragment>
                
                {this.props.displayMode &&
                    this.props.displayMode === "searchbar" && (
                        <div
                            className="search"
                            onClick={() =>
                                // this.setState({ showSearchWindow: true })
                                this.props.onShow()
                            }
                        >
                            <div className="search-div" id="searchdiv">
                                {isMobile() && <label>{this.props.searchPlaceHolder}</label>}
                                {!isMobile() && <input
                                    placeholder={
                                        this.props.searchPlaceHolder
                                    }
                                    onChange={(evt) => {
                                        this.onSearch(evt.target.value);
                                    }}
                                    value={this.state.searchText}
                                />}
                                <div className="searchbutton">
                                    <i className="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                    )}

                {/* {this.props.displayMode === "button" && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24px"
                        viewBox="0 0 512 512"
                        onClick={() =>this.props.onHide()
                            // this.setState({ showSearchWindow: true })
                        }
                    >
                        <g>
                            <g>
                                <path d="M508.875,493.792L353.089,338.005c32.358-35.927,52.245-83.296,52.245-135.339C405.333,90.917,314.417,0,202.667,0    S0,90.917,0,202.667s90.917,202.667,202.667,202.667c52.043,0,99.411-19.887,135.339-52.245l155.786,155.786    c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125C513.042,504.708,513.042,497.958,508.875,493.792z     M202.667,384c-99.979,0-181.333-81.344-181.333-181.333S102.688,21.333,202.667,21.333S384,102.677,384,202.667    S302.646,384,202.667,384z" />
                            </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                    </svg>
                )} */}

{/* this.state.showSearchWindow */}
                {(this.props.show  && isMobile()) &&  this.renderSearchWindow()}

                {(this.props.show && !isMobile()) &&  this.renderSearchDropDown()}


            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    menu: state.api.menu,
    config: state.api.config,
});

export default connect(mapStateToProps)(Search);
