import React, { Component } from 'react';
import GooglePlacesSearchInput from './GooglePlacesSearchInput';
import GoogleCurrentLocation from './GoogleCurrentLocation';
import GoogleMaps from './GoogleMap';
import Button from './Button';
import {getStores} from '../../services/util';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {setCustomerGeoLocation, setConfig, setStore} from '../../services/api/actions';
/* global google */

class StoreLocator extends Component {

    constructor()
    {
        super();
        this.state={
           place:{Address:"", Lat:"", Lng:""},
           stores:[],
           showStoreSelection:false,
           availableStores:[]
        }
        this.onContinueClick = this.onContinueClick.bind(this);
        this.getDistanceFromLatLonInKm = this.getDistanceFromLatLonInKm.bind(this);
    }

    async componentDidMount(){
        let stores = await getStores(this.props.config.key);
        // console.log('stores', stores)
        this.setState({stores:stores})
    }

    onContinueClick(){
        // console.log('onContinueClick')
        this.setState({processing:true});
        let availableStores = [];
        let stores  = this.state.stores;
        stores.map(s=>{
            if(s.hasOwnProperty('Location')){
                let storeDeliveryRadius = s.hasOwnProperty('DeliveryRadius') ? s.DeliveryRadius :  5;
                if(this.getDistanceFromLatLonInKm(this.state.place.Lat, this.state.place.Lng, Number(s.Location.lat), Number(s.Location.lon))<=storeDeliveryRadius){
                    availableStores.push(s)
                }
            }
        })
        this.setState({availableStores:availableStores, 
            showStoreSelection: availableStores.length> 0 ? true : false,
            processing:false,
            error:availableStores.length ===0 ? 'Sorry no stores found around you. Please try a different location' : ''})
            // console.log('availableStores',availableStores)
    }

    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
        var dLon = this.deg2rad(lon2 - lon1);
        var a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d;
      }
      
    deg2rad(deg) {
        return deg * (Math.PI / 180)
    }

    onChange(value){
        this.setState({place:value, error:""});
        // this.props.onAddressSelcted(value);
    }

    renderLocationSearchOptions()
    {
        return <React.Fragment>
            {this.state.error !=="" && <div className="error-msg">{this.state.error}</div>}
            <div>Set your delivery location</div>
            <GooglePlacesSearchInput value={this.state.place} onChange={(value)=>{this.onChange(value)}}/>
            <GoogleCurrentLocation onChange={(value)=>{this.onChange(value)}}/>
            <GoogleMaps 
            value={this.state.place} 
            onChange={(value)=>{this.onChange(value)}}
            draggable={true} height="300px"/>
            <div className="window-footer">
                <Button text="Continue" type="primary" onClick={this.onContinueClick}/>
            </div>
        </React.Fragment>
    }

    renderStoreList(){
        return <React.Fragment>
            {this.state.availableStores.length > 0 && <React.Fragment>
                <div className="store-locator-heading">Stores near you</div>
                <div className="store-locator-list">
                    {this.state.availableStores.map((s,index)=><div key={"store_locator_" + index} 
                    onClick={()=>this.onStoreSelected(s)}
                    className="store-locator-list-item">
                        <div className="store-locator-list-item-header">{s.StoreName}</div>
                        <div className="store-locator-list-item-text">{s.Address1}</div>
                        <div className="store-locator-list-item-text">{s.Address2}</div>
                    </div>)}
                </div>
            </React.Fragment>}
            {this.state.availableStores.length ===0 && <div className="store-locator-heading">No stores found near you</div>}
            <div className="window-footer">
                <Button text="Back" type="primary" onClick={()=>{this.setState({showStoreSelection:false})}}/>
            </div>
        </React.Fragment>
    }

    onStoreSelected(store){

        //calculate distance in KMS;
        if(store && store.Location 
            && store.Location.lat && store.Location.lat !=="" 
            && store.Location.lon &&  store.Location.lon !==""){
            
            //get if store has lat lng
            let lat1 = parseFloat(store.Location.lat);
            let lon1 = parseFloat(store.Location.lon);
            let lat2 = parseFloat(this.state.place.Lat);
            let lon2 =  parseFloat(this.state.place.Lng);
        
            let directionsService = new google.maps.DirectionsService();
            const route = {
                origin: {lat: lat1, lng:lon1},
                destination: {lat: lat2, lng:lon2},
                travelMode: 'DRIVING'
            }
            // console.log('route',route)
            directionsService.route(route,
            function(response, status){ // anonymous function to capture directions
                let kms = response.routes[0].legs[0].distance.value /1000;
                let customerLocaion = this.state.place;
                customerLocaion.DistanceFromStore = kms;
                this.props.setCustomerGeoLocation(customerLocaion);
                
            }.bind(this))
        }
        let storeID = store.key;
        let cashRegisterID = store.OmniCashRegisterID;
        let config = this.props.config;
        config.CheckoutConfig.StoreID = storeID;
        config.CheckoutConfig.CashRegisterID = cashRegisterID;
        this.props.setConfig(config);
        this.props.setStore(store);
        this.setState({showStoreSelection:false});
        this.props.onClose();
        // console.log('after set',this.props.config)
    }

    render(){
        return <React.Fragment>
            {!this.state.showStoreSelection && this.renderLocationSearchOptions()}
            {this.state.showStoreSelection && this.renderStoreList()}
        </React.Fragment>
    }
}

const mapStateToProps = (state) => ({
    config: state.api.config,
});

export default withRouter(connect(mapStateToProps, {setConfig, setCustomerGeoLocation, setStore})(StoreLocator));
    