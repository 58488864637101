import React from 'react';
import PropTypes from 'prop-types';

const ErrorMsg = ({ errorCode, errorMsg }) => {
  
  return (
    <div className="error">
        {errorCode ==="1" && <div><h1>Oops!</h1> <br/>
        <h3>Sorry, the page you are looking for doesn't exist or the username might have been changed.
           <br/> You can claim this page by downloading the posible app.</h3>
        </div>}
    </div>
  );
};

ErrorMsg.propTypes = {
    errorCode: PropTypes.string.isRequired,
    errorMsg: PropTypes.string
};

export default ErrorMsg;