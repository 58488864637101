import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "./index";
import Header from "../Header";
import PageFooter from "../PageFooter";
import {isMobile} from "../../services/util";
import "./login.css";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    
    getBackgroundStyle(){

        let style={}
        if(this.props.config.CheckoutConfig.Login){
            if(this.props.config.CheckoutConfig.Login.BackgroundImage){
                style = {backgroundImage:"url('" + this.props.config.CheckoutConfig.Login.BackgroundImage +"')"}
            }
        }
        console.log(style);
        return style;
    }

    render() {
        return (<React.Fragment>
            <Header/>
                <div className="login-page-background-image" style={this.getBackgroundStyle()}>
                    <div className="login-page-background-color">

                    
                    <div className="container">
                        <div className="row" >
                            <div className="col-md-4 order-md-2">
                                <div className="login-page-right-content">
                                    <Login page={true}/>
                                </div>
                                
                            </div>
                            <div className="col-md-8 order-md-1">
                                <div
                                        className="login-page-left-content"
                                        dangerouslySetInnerHTML={{ __html: this.props.config.CheckoutConfig.Login.BackgroundText}}
                                    />
                                </div>
                                
                            </div>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
            {!isMobile() && <PageFooter config={this.props.config} />}
    </React.Fragment>)}
}

const mapStateToProps = (state) => ({
    config: state.api.config
});

export default connect(mapStateToProps, { })(LoginPage);

