import React, { Component } from "react";
import { connect } from "react-redux";
import PageFooter from "../PageFooter";
import Header from "../Header";

class OmniPages extends Component {
    constructor() {
        super();
        this.state = {
            title: "",
            content: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData(this.props.match.params.name);
    }

    componentWillReceiveProps(nextProps) {
        //new parameters passed
        this.loadData(nextProps.match.params.name);
    }

    loadData(pageName) {
        let title = "Page not found";
        let content = "";
        let page = this.props.pages.filter((f) => f.Title === pageName);
        if (page.length > 0) {
            title = page[0].Title;
            content = page[0].Content;
        }
        this.setState({ title: title, content: content });
    }

    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="container">
                    <div className="pages-page"> 
                        <div className="page-title">{this.state.title}</div>
                        {/* <h1 className="pages">{this.state.title}</h1> */}
                        <div
                            className="page-content"
                            dangerouslySetInnerHTML={{ __html: this.state.content }}
                        />
                        <br />
                    </div>
                </div>
                <br />
                <PageFooter />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    pages: state.api.pages,
    config: state.api.config,
});

export default connect(mapStateToProps, {})(OmniPages);
