import React, { Component } from 'react';
import { connect } from 'react-redux';
import Rating from './Rating';
import Input from '../Input';
import {saveReview} from '../../../services/util';

class WriteReview extends Component {

    constructor()
    {
        super()
        this.state={
            rating:0,
            title:'',
            review:'',
            ratingError:'',
            titleError:'',
            reviewError:''
        }
        this.onSaveReview = this.onSaveReview.bind(this);
    }

    async onSaveReview()
    {
        let error = false
        if(this.state.rating ===0)
        {
            this.setState({ratingError:"Please provide rating"});
            error=true;
        }
        if(this.state.title.trim() ==="")
        {
            this.setState({ratingError:"Please provide title"});
            error=true;
        }
        if(this.state.review.trim() ==="")
        {
            this.setState({ratingError:"Please provide review"});
            error=true;
        }
        if(error)
        {
            return
        }
        let status = await saveReview( this.props.productID,{
            Title: this.state.title,
            Review: this.state.review,
            Rating: this.state.rating,
            ProductID: this.props.productID,
            RegistrationID: this.props.config.key,
            CustomerName:this.props.customer ? this.props.customer.FirstName + ' ' + this.props.customer.LastName :'',
            CustomerID:this.props.customer ? this.props.customer.key :''
        })
        if(status)
        {
            alert('Review saved')
            if(this.props.hasOwnProperty('onSave'))
            {this.props.onSave()}
        }
        else
        {
            alert('Unable to save review. Pls try after sometime.')
        }
    }

    render(){
        return <React.Fragment>
            <div className="submit-review-header">Write a review</div>
            <div>Your rating</div>
            <div className="row">
                <div className="col-md-12">
                <Rating 
                rating={this.state.rating}
                onChange={(rating)=>{
                this.setState({rating:rating})}}/><br/>
            <Input type="text" label="Title" onChange={(value)=>this.setState({title:value, titleError:""})} error={this.state.titleError}/>
                </div>
            </div>
            <Input type="textarea" label="Add a written review" onChange={(value)=>this.setState({review:value, reviewError:""})} error={this.state.reviewError}/>
            <button onClick={this.onSaveReview} className="btn btn-flat submit-review-btn">Submit Review</button>
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    customer: state.api.customer,
    config: state.api.config
});

export default connect(mapStateToProps,{})(WriteReview);
