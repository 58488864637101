import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLoyaltyDetails,getLoyaltyLedger, mainWebsitePath, isMobile } from '../../services/util';
import Spinner from '../Spinner';
import Header from "../Header";
import PageFooter from "../PageFooter";

class LoyaltyLedger extends Component {

    constructor(props)
    {
        super(props)
        this.state = {loyaltyLedger: [],loyaltyDetails:null}
        this.loadLoyaltyLedger = this.loadLoyaltyLedger.bind(this);
    }
    
    async componentDidMount() {
        this.loadLoyaltyLedger();
    }

    async loadLoyaltyLedger(){
        let loyaltyLedger = [];
        let loyaltyDetails = null
        this.setState({isLoading:true})
        if (this.props.customer) {
            loyaltyDetails = await getLoyaltyDetails(this.props.customer.key)
            loyaltyLedger = await getLoyaltyLedger(this.props.customer.key, this.props.config.key);
        }
        console.log('loyaltyDetails',loyaltyDetails)
        console.log('loyaltyLedger',loyaltyLedger)
        this.setState({ loyaltyLedger: loyaltyLedger, loyaltyDetails:loyaltyDetails, isLoading: false });
    }

    render() {

        const { loyaltyLedger } = this.state;
        const { isLoading } = this.state;
        const {loyaltyDetails} = this.state;

        return <React.Fragment>
            
            <Header/>
            
             <div className="container">
                <div className="wallet-page"> 
                <div className="page-title">Loyalty</div>
                {isLoading && <Spinner />}
                {!isLoading &&<React.Fragment>
                        {loyaltyDetails && <div className="loyalty-points">{"Loyalty Points: " + loyaltyDetails.LoyaltyPoints}</div>}
                        {loyaltyDetails && <div className="loyalty-amount">{"Amount: ₹ " + loyaltyDetails.LoyaltyAmount}</div>}
                        {loyaltyDetails && <div className="loyalty-type">{ loyaltyDetails.LoyaltyType}</div>}
                        
                        <div className="loyaltyledger-transaction-detail-header">Transaction Details:</div>
                        {loyaltyLedger.map((w,index)=> <div key={"loyaltyledger" + index} className="loyaltyledger">
                            <div className="loyaltyledger-transaction-detail">
                                {(new Date(w.TransactionDate._seconds * 1000)).toString().substring(0, 16)}
                                <br/><span>{w.TransactionNo}</span>
                            </div>
                            {w.Type==="earn" &&  <div className="loyaltyledger-credit">
                                {"+ " +  Number(w.LoyaltyPoints).toFixed(2)  }
                            </div>}
                            {w.Type!=="earn" &&  <div className="loyaltyledger-debit">
                                 {"- " + Number(w.LoyaltyPoints).toFixed(2) } 
                            </div>}
                        </div>)}
                    </React.Fragment>}       
                </div>
            </div>
            {!isMobile() && <PageFooter/>}
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    config: state.api.config,
    customer: state.api.customer
});

export default connect(
    mapStateToProps
)(LoyaltyLedger);