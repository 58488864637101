import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  mainWebsitePath,
  saveCustomerDetails,
  checkOut,
  updateSale,
  initiatePayment,
  initiatePaymentAtom,
  isMobile,
  getWalletBalance,
  getLoyaltyDetails,
  // removeCoupon,
  calculateBillTotal,
  onLineItemChange,
  log,
  updatePaymentForTransaction,
  getPaymentStatus,
  getStoresDetails,
} from "../../services/util";
import {
  loadCart,
  loadCartTotal,
  updateCoupon,
} from "../../services/cart/actions";
import { setCustomer } from "../../services/api/actions";
import Spinner from "../Spinner";
import CustomerAddress from "../Customer/CustomerAddresses";
import Login from "../Login";
import Cart from "./Cart";
import Header from "../Header";
import PageFooter from "../PageFooter";
import Window from "../Common/Window";
import Input from "../Common/Input";
import axios from "axios";
import paymentSuccessImage from "./payment-success.png";
import paymentErrorImage from "./payment-error.png";
import CheckOutHeader from "./CheckOutHeader";
import "./checkout.css";
/* global google */

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subDomain: "",
      key: "",
      isLoading: false,
      sale: null,
      showPaymentModeSelection: false,
      paymentModeSelectedIndex: -1,
      selectedAddress: null,
      addressRequired: false,
      showCustomerAddressSelection: false,
      showLogin: false,
      // showCustomerProfile: false,
      successDisplay: false,
      errorDisplay: false,
      // selectedDeliveryOption: null,
      deliveryCharge: 0,
      deliveryDate: "",
      deliverySlot: "",
      shippingOption: null,
      slots: [],
      billAmount: 0,
      billBalanceAmount: 0,
      minDate: "",
      addressSelectionError: "",
      paymentOptions1: [],
      paymentOptions2: [],
      walletBalance: 0,
      paymentOption1SelectedIndex: -1,
      coupon: null,
      walletBalance: 0,
      walletRedemptionAmount: "",
      walletRedemptionAmountTmp: "",
      loyaltyDetails: null,
      showLoyaltyRedemption: false,
      loyaltyRedemptionAmount: "",
      loyaltyRedemptionAmountTmp: "",
      packagingCharge: 0,
      showPackagingCharges: false,
      enableRemarks: false,
      remarksText: "Remarks",
      remarks: "",
      saleID: "",
      checkoutOption: "1",
      checkoutOptionStep: "1",
      emailIDEntry: false,
      emailIDMandatory: false,
      mobileNoEntry: false,
      mobileNoMandatory: false,
      mobileNo: "",
      emailID: "",
      showWalletReedemption: false,
      paymentModeSelectionError: "",
      showAppointmentOptions: false,
      employees: [],
      appointmentAvailableSlots: [],
      appointmentDate: new Date().toISOString().split("T")[0],
      appointmentDateError: "",
      store: null,
      appointmentTimeSlot: "",
      appointmentTimeSlotError: "",
      appointmentDateError: "",
      appointmentEmployee: "",
      // showPG:false,
      // pgURL:""
    };
    this.onCustomerAddressChange = this.onCustomerAddressChange.bind(this);
    this.updateAddress = this.updateAddress.bind(this);
    this.setCustomerAddress = this.setCustomerAddress.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.renderDesktop = this.renderDesktop.bind(this);
    this.renderDeliveryTime = this.renderDeliveryTime.bind(this);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.razorPayClick = this.razorPayClick.bind(this);
    this.razorPayHandler = this.razorPayHandler.bind(this);
    this.onPaymentModeSelected = this.onPaymentModeSelected.bind(this);
    this.saveSale = this.saveSale.bind(this);
    this.renderCheckoutSuccess = this.renderCheckoutSuccess.bind(this);
    this.renderMobileNew = this.renderMobileNew.bind(this);
    this.getCustomerWalletBalance = this.getCustomerWalletBalance.bind(this);
    this.getCustomerLoyaltyDetails = this.getCustomerLoyaltyDetails.bind(this);
    this.onWalletOptionSelected = this.onWalletOptionSelected.bind(this);
    this.onLoyaltyOptionSelected = this.onLoyaltyOptionSelected.bind(this);
    this.onWalletRedemptionClick = this.onWalletRedemptionClick.bind(this);
    this.onLoyaltyRedemptionClick = this.onLoyaltyRedemptionClick.bind(this);
    this.renderWalletRedemption = this.renderWalletRedemption.bind(this);
    this.renderLoyaltyRedemption = this.renderLoyaltyRedemption.bind(this);
    this.computeDeliveryCharges = this.computeDeliveryCharges.bind(this);
    this.onLineItemUpdate = this.onLineItemUpdate.bind(this);
    this.onApplyCoupon = this.onApplyCoupon.bind(this);
    this.onRemoveCoupon = this.onRemoveCoupon.bind(this);
    this.postOrderCompletion = this.postOrderCompletion.bind(this);
    this.renderCheckoutError = this.renderCheckoutError.bind(this);
    this.postPaymentProcessingError =
      this.postPaymentProcessingError.bind(this);
    this.computePackagingCharges = this.computePackagingCharges.bind(this);
    this.computeCharges = this.computeCharges.bind(this);
    this.onPayUClick = this.onPayUClick.bind(this);
    this.updateOnlinePaymentDetails =
      this.updateOnlinePaymentDetails.bind(this);
    this.renderCustomerDetails = this.renderCustomerDetails.bind(this);
    this.renderCustomerMobileNoAndEmailIDEntry =
      this.renderCustomerMobileNoAndEmailIDEntry.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.computeCharges();
    this.setCustomerAddress(this.props.customer);
    let paymentOptions1 = [];
    let paymentOptions2 = [];
    let enableRemarks = false;
    let remarksText = "Remarks";
    let checkoutOption = "1";
    let emailIDEntry = false;
    let emailIDMandatory = false;
    let mobileNoEntry = false;
    let mobileNoMandatory = false;

    if (
      this.props.config.CheckoutConfig.hasOwnProperty(
        "CheckoutDetailPageOptions"
      ) &&
      this.props.config.CheckoutConfig.CheckoutDetailPageOptions
    ) {
      if (
        this.props.config.CheckoutConfig.CheckoutDetailPageOptions.hasOwnProperty(
          "EmailIDEntry"
        )
      ) {
        emailIDEntry =
          this.props.config.CheckoutConfig.CheckoutDetailPageOptions
            .EmailIDEntry;
      }
      if (
        this.props.config.CheckoutConfig.CheckoutDetailPageOptions.hasOwnProperty(
          "EmailIDMandatory"
        )
      ) {
        emailIDMandatory =
          this.props.config.CheckoutConfig.CheckoutDetailPageOptions
            .EmailIDMandatory;
      }
      if (
        this.props.config.CheckoutConfig.CheckoutDetailPageOptions.hasOwnProperty(
          "MobileNoEntry"
        )
      ) {
        mobileNoEntry =
          this.props.config.CheckoutConfig.CheckoutDetailPageOptions
            .MobileNoEntry;
      }
      if (
        this.props.config.CheckoutConfig.CheckoutDetailPageOptions.hasOwnProperty(
          "MobileNoMandatory"
        )
      ) {
        mobileNoMandatory =
          this.props.config.CheckoutConfig.CheckoutDetailPageOptions
            .MobileNoMandatory;
      }
      if (
        this.props.config.CheckoutConfig.CheckoutDetailPageOptions.hasOwnProperty(
          "CheckoutOption"
        ) &&
        this.props.config.CheckoutConfig.CheckoutDetailPageOptions
          .CheckoutOption !== ""
      ) {
        checkoutOption =
          this.props.config.CheckoutConfig.CheckoutDetailPageOptions
            .CheckoutOption;
      }
    }
    if (this.props.config.CheckoutConfig.hasOwnProperty("EnableRemarks")) {
      enableRemarks = this.props.config.CheckoutConfig.EnableRemarks;
    }
    if (this.props.config.CheckoutConfig.hasOwnProperty("RemarksText")) {
      remarksText = this.props.config.CheckoutConfig.RemarksText;
    }
    if (this.props.config.CheckoutConfig.hasOwnProperty("PaymentModes")) {
      for (
        let i = 0;
        i < this.props.config.CheckoutConfig.PaymentModes.length;
        i++
      ) {
        let paymentMode = this.props.config.CheckoutConfig.PaymentModes[i];
        // console.log('paymentMode',paymentMode)
        if (paymentMode.Value.toLowerCase() === "wallet") {
          paymentMode.Text =
            "Pay using " + this.props.config.BusinessName + " wallet";
          paymentOptions1.push(paymentMode);
        } else if (paymentMode.Value.toLowerCase() === "loyalty") {
          paymentMode.Text = "Loyalty";
          paymentOptions1.push(paymentMode);
        } else {
          paymentOptions2.push(paymentMode);
        }
      }
    }
    // console.log("checkoutOption",checkoutOption);

    let showAppointmentOptions = false;
    let showEmployeeSelection = false;
    let store = null;
    let employees = [];

    if (
      this.props.config &&
      this.props.config.CheckoutConfig &&
      this.props.config.CheckoutConfig.EnableAppointmentForServices &&
      this.props.lineItems.filter((f) => f.Product.ProductTypeID === 3).length >
        0
    ) {
      showAppointmentOptions = true;
      //get store timimg
      //set time slots
      //based on store timimg set the min date
      //get store employees
      // console.log('showAppointmentOptions', showAppointmentOptions);
      // console.log("this.props.config.CheckoutConfig.StoreID",this.props.config.CheckoutConfig.StoreID);
      store = await getStoresDetails(this.props.config.CheckoutConfig.StoreID);
      if (store) {
        employees = store.Employees;
        // console.log("employees",employees)
        this.computeAppointmentSlots(
          new Date().toISOString().split("T")[0],
          store
        );
      }
      // console.log("store",store);
    }

    this.setState({
      paymentOptions1: paymentOptions1,
      paymentOptions2: paymentOptions2,
      enableRemarks: enableRemarks,
      remarksText: remarksText,
      checkoutOption: checkoutOption,
      emailIDEntry: emailIDEntry,
      emailIDMandatory: emailIDMandatory,
      mobileNoEntry: mobileNoEntry,
      mobileNoMandatory: mobileNoMandatory,
      showAppointmentOptions: showAppointmentOptions,
      showEmployeeSelection: showEmployeeSelection,
      employees: employees,
      store: store,
      isLoading: false,
    });

    if (this.props.config.CheckoutConfig.hasOwnProperty("ShippingOption")) {
      var weekDay = new Array(7);
      weekDay[0] = "Sunday";
      weekDay[1] = "Monday";
      weekDay[2] = "Tuesday";
      weekDay[3] = "Wednesday";
      weekDay[4] = "Thursday";
      weekDay[5] = "Friday";
      weekDay[6] = "Saturday";

      var date = new Date();
      var minDate;
      minDate = date.setDate(date.getDate());
      minDate = new Date(minDate).toISOString().split("T")[0];

      let shippingOption = this.props.config.CheckoutConfig.ShippingOption;
      let dayOfWeek = weekDay[new Date().getDay()];

      let deliveryOperatingHrs = shippingOption.DeliveryOperatingHrs.filter(
        (f) => f.Day === dayOfWeek
      )[0];

      let slots = [];

      if (
        deliveryOperatingHrs &&
        deliveryOperatingHrs.Slots &&
        deliveryOperatingHrs.Slots.length > 0
      )
        slots = deliveryOperatingHrs.Slots;

      for (let j = 0; j < slots.length; j++) {
        const slot = slots[j];
        let timeArray = slot.Slots.split("-");
        let currentDate = new Date();
        let slotDate = "";
        slot.AvailableForPickup = false;

        if (timeArray.length > 1) {
          slotDate = new Date(
            new Date().getFullYear() +
              "-" +
              (new Date().getMonth() + 1) +
              "-" +
              new Date().getDate() +
              " " +
              timeArray[0].trim()
          );
        } else {
          slotDate = new Date(
            new Date().getFullYear() +
              "-" +
              (new Date().getMonth() + 1) +
              "-" +
              new Date().getDate() +
              " " +
              slot.Slots.trim()
          );
        }

        if (currentDate < slotDate) {
          slot.AvailableForPickup = true;
        }
      }
      this.setState({
        shippingOption: shippingOption,
        slots: slots,
        minDate: minDate,
        deliveryDate: minDate,
      });
    }
  }

  computeAppointmentSlots(appointmentDate, store) {
    let slots = [
      { TimeInMins: 360, Display: "6:00 AM" },
      { TimeInMins: 390, Display: "6:30 AM" },
      { TimeInMins: 420, Display: "7:00 AM" },
      { TimeInMins: 450, Display: "7:30 AM" },
      { TimeInMins: 480, Display: "8:00 AM" },
      { TimeInMins: 510, Display: "8:30 AM" },
      { TimeInMins: 540, Display: "9:00 AM" },
      { TimeInMins: 570, Display: "9:30 AM" },
      { TimeInMins: 600, Display: "10:00 AM" },
      { TimeInMins: 630, Display: "10:30 AM" },
      { TimeInMins: 660, Display: "11:00 AM" },
      { TimeInMins: 690, Display: "11:30 AM" },
      { TimeInMins: 720, Display: "12:00 PM" },
      { TimeInMins: 750, Display: "12:30 PM" },
      { TimeInMins: 780, Display: "1:00 PM" },
      { TimeInMins: 810, Display: "1:30 PM" },
      { TimeInMins: 840, Display: "2:00 PM" },
      { TimeInMins: 870, Display: "2:30 PM" },
      { TimeInMins: 900, Display: "3:00 PM" },
      { TimeInMins: 930, Display: "3:30 PM" },
      { TimeInMins: 960, Display: "4:00 PM" },
      { TimeInMins: 990, Display: "4:30 PM" },
      { TimeInMins: 1020, Display: "5:00 PM" },
      { TimeInMins: 1050, Display: "5:30 PM" },
      { TimeInMins: 1080, Display: "6:00 PM" },
      { TimeInMins: 1110, Display: "6:30 PM" },
      { TimeInMins: 1140, Display: "7:00 PM" },
      { TimeInMins: 1170, Display: "7:30 PM" },
      { TimeInMins: 1200, Display: "8:00 PM" },
      { TimeInMins: 1230, Display: "8:30 PM" },
      { TimeInMins: 1260, Display: "9:00 PM" },
      { TimeInMins: 1290, Display: "9:30 PM" },
      { TimeInMins: 1320, Display: "10:00 PM" },
      { TimeInMins: 1350, Display: "10:30 PM" },
      { TimeInMins: 1380, Display: "11:00 PM" },
      { TimeInMins: 1410, Display: "11:30 PM" },
    ];
    let date = new Date(appointmentDate);
    let appointmentAvailableSlots = [];
    if (store.StoreTiming && store.StoreTiming.length > 0) {
      const weekday = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let currentDay = date.getDay();
      // console.log("currentDay",currentDay);
      let storeTimeForCurrentDay = store.StoreTiming[currentDay];
      // console.log("storeTimeForCurrentDay",storeTimeForCurrentDay);
      if (storeTimeForCurrentDay) {
        //curent day timing found

        if (storeTimeForCurrentDay.Open) {
          //store open for today
          let startSlotTime = 0;
          if (storeTimeForCurrentDay.StartTime.AMPM === "AM") {
            startSlotTime =
              Number(storeTimeForCurrentDay.StartTime.HH) * 60 +
              Number(storeTimeForCurrentDay.StartTime.MM);
          } else {
            startSlotTime =
              (Number(storeTimeForCurrentDay.StartTime.HH) + 12) * 60 +
              Number(storeTimeForCurrentDay.StartTime.MM);
          }
          let endSlotTime = 0;
          if (storeTimeForCurrentDay.EndTime.AMPM === "AM") {
            endSlotTime =
              Number(storeTimeForCurrentDay.EndTime.HH) * 60 +
              Number(storeTimeForCurrentDay.EndTime.MM);
          } else {
            endSlotTime =
              (Number(storeTimeForCurrentDay.EndTime.HH) + 12) * 60 +
              Number(storeTimeForCurrentDay.EndTime.MM);
          }
          console.log("startSlotTime", startSlotTime);
          console.log("endSlotTime", endSlotTime);

          if (appointmentDate === new Date().toISOString().split("T")[0]) {
            //todays date is selected

            const currentDate = new Date();
            const currentHour = currentDate.getHours();
            const currentMinute = currentDate.getMinutes();
            console.log("currentHour", currentHour);
            console.log("currentMinute", currentMinute);
            let todayStartTime = currentHour * 60 + currentMinute;
            console.log("todayStartTime", todayStartTime);
            if (todayStartTime > startSlotTime) {
              startSlotTime = todayStartTime;
              console.log("startSlotTime changed", startSlotTime);
            }
          }

          slots.map((s) => {
            if (s.TimeInMins >= startSlotTime && s.TimeInMins <= endSlotTime) {
              appointmentAvailableSlots.push(s);
            }
          });
        }
      }
    }
    this.setState({
      appointmentDate: appointmentDate,
      appointmentDateError: "",
      appointmentAvailableSlotsError: "",
      appointmentAvailableSlots: appointmentAvailableSlots,
    });
  }

  async computeCharges() {
    let deliveryCharge = await this.computeDeliveryCharges(
      this.props.lineItemTotal.billAmount
    );
    let packagingCharge = await this.computePackagingCharges(
      this.props.lineItemTotal.billAmount
    );
    // console.log('deliveryCharge', deliveryCharge);
    // console.log('packagingCharge', packagingCharge);
    let billAmount = Number(this.props.lineItemTotal.billAmount);
    billAmount =
      Number(billAmount) + Number(deliveryCharge) + Number(packagingCharge);
    this.setState({
      billAmount: billAmount,
      deliveryCharge: deliveryCharge,
      packagingCharge: packagingCharge,
      billBalanceAmount: billAmount,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.computeCharges();
    // this.computeDeliveryCharges(nextProps.lineItemTotal.billAmount)
  }

  computePackagingCharges() {
    // <option value={1}>Fixed amount</option>
    // <option value={2}>% of order value</option>
    // <option value={3}>Product level</option>

    let packagingCharge = 0;
    if (
      this.props.config.CheckoutConfig.hasOwnProperty("PackagingChargeType")
    ) {
      let packagingChargeType =
        this.props.config.CheckoutConfig.PackagingChargeType;
      // console.log('this.props.lineItems', this.props.lineItems);
      if (packagingChargeType === "1") {
        //fixed amount
        if (
          this.props.config.CheckoutConfig.hasOwnProperty("PackagingCharge")
        ) {
          packagingCharge = this.props.config.CheckoutConfig.PackagingCharge;
        }
      } else if (packagingChargeType === "2") {
        //% of order value

        let packagingChargePercentage =
          this.props.config.CheckoutConfig.PackagingCharge;
        packagingCharge =
          (Number(this.props.lineItemTotal.billAmount) *
            packagingChargePercentage) /
          100;
        packagingCharge = Number(packagingCharge).toFixed(2);
      } else if (packagingChargeType === "3") {
        // Product level
        let lineItems = this.props.lineItems;
        lineItems.map((l) => {
          if (l.Product.hasOwnProperty("PackagingCharges")) {
            packagingCharge =
              Number(packagingCharge) +
              Number(l.Product.PackagingCharges) * Number(l.Qty);
            // console.log('l.Qty)',l.Qty);
            // console.log('l.Product.PackagingCharges',l.Product.PackagingCharges)
          }
        });
      }
      // let billAmountTmp = billAmount+ packagingCharge;
      // console.log('packagingCharge',packagingCharge);
      // console.log('billAmountTmp', billAmountTmp)
      // this.setState({packagingCharge:packagingCharge, showPackagingCharges:true , billAmount:billAmountTmp})
    }
    return Number(packagingCharge);
  }

  computeDeliveryCharges(billAmount) {
    let deliveryCharge = 0;
    let shippingOption = null;
    if (this.props.config.CheckoutConfig.hasOwnProperty("ShippingOption")) {
      shippingOption = this.props.config.CheckoutConfig.ShippingOption;

      // <option value={1}>Flat rate</option>
      // <option value={2}>Order value</option>
      // <option value={3}>Pincode</option>
      // <option value={4}>KMS</option>

      if (shippingOption.ShippingType === "1") {
        //flat rate
        if (shippingOption.hasOwnProperty("FlatRate")) {
          deliveryCharge = shippingOption.FlatRate;
        }
      } else if (shippingOption.ShippingType === "2") {
        //order value
        if (
          shippingOption &&
          shippingOption.DeliveryRates &&
          shippingOption.DeliveryRates.length > 0
        ) {
          let deliveryRates = shippingOption.DeliveryRates;
          for (let index = 0; index < deliveryRates.length; index++) {
            const deliveryRate = deliveryRates[index];
            if (
              billAmount > deliveryRate.StartRange &&
              billAmount < deliveryRate.EndRange
            ) {
              deliveryCharge = deliveryRate.DeliveryCharge;
            }
          }
        }
      } else if (shippingOption.ShippingType === "3") {
        //pin code
      } else if (
        shippingOption.ShippingType === "4" &&
        shippingOption.KMCharges
      ) {
        //KMS
        let kms =
          this.props.customerGeoLocation &&
          this.props.customerGeoLocation.hasOwnProperty("DistanceFromStore")
            ? this.props.customerGeoLocation.DistanceFromStore
            : 0;
        for (let i = 0; i < shippingOption.KMCharges.length; i++) {
          let kmCharge = shippingOption.KMCharges[i];
          if (kms >= kmCharge.KMSFrom && kms <= kmCharge.KMSTo) {
            if (kmCharge.KMSRateType === "1") {
              //fixed rate
              deliveryCharge = kmCharge.KMSValue;
            } else if (kmCharge.KMSRateType === "2") {
              //per km
              deliveryCharge = kms * kmCharge.KMSValue;
            }
          }
        }
      }
    }
    this.setState({ shippingOption: shippingOption });
    return Number(deliveryCharge);
    // billAmount = billAmount+ deliveryCharge;
    // this.setState({billAmount:billAmount, deliveryCharge:deliveryCharge, shippingOption:shippingOption, billBalanceAmount:billAmount})
  }

  setCustomerAddress(customer) {
    if (
      this.props.config.hasOwnProperty("CheckoutConfig") &&
      this.props.config.CheckoutConfig &&
      this.props.config.CheckoutConfig.ShowBillingAddress
    ) {
      let selectedAddress = null;
      if (customer && customer.Addresses.length > 0) {
        selectedAddress = customer.Addresses[0];
      }
      // console.log('selectedAddress',selectedAddress)
      this.setState({
        selectedAddress: selectedAddress,
        addressRequired: true,
      });
    }
    this.getCustomerWalletBalance();
    this.getCustomerLoyaltyDetails();
  }

  async onSubmitClick() {
    this.setState({ isLoading: true });
    let addressSelectionError = "";
    let customerLoginError = "";
    let configError = "";
    let mobileNoError = "";
    let emailIDError = "";
    let appointmentTimeSlotError = "";

    if (!this.props.customer) {
      customerLoginError = "Please login or signup";
    }
    if (
      this.props.config.hasOwnProperty("CheckoutConfig") &&
      this.props.config.CheckoutConfig &&
      this.props.config.CheckoutConfig.ShowBillingAddress
    ) {
      if (!this.state.selectedAddress) {
        addressSelectionError = "Please specify your address";
      }
    }
    if (!this.props.config.CheckoutConfig) {
      configError =
        "Required checkout settings missing. Please contact support@posible.in";
    }
    if (this.props.customer) {
      if (this.state.mobileNoMandatory && this.state.mobileNo.trim() === "") {
        mobileNoError = "Please provide mobile no.";
      }
      if (this.state.emailIDMandatory && this.state.emailID.trim() === "") {
        emailIDError = "Please provide email id.";
      }
    }
    if (this.state.showAppointmentOptions) {
      if (this.state.appointmentTimeSlot === "") {
        appointmentTimeSlotError = "Please select appointment slot";
      }
    }
    console.log("addressSelectionError", addressSelectionError);
    if (
      customerLoginError !== "" ||
      addressSelectionError !== "" ||
      configError !== "" ||
      mobileNoError !== "" ||
      appointmentTimeSlotError !== ""
    ) {
      this.setState({
        customerLoginError: customerLoginError,
        addressSelectionError: addressSelectionError,
        configError: configError,
        mobileNoError: mobileNoError,
        emailIDError: emailIDError,
        appointmentTimeSlotError: appointmentTimeSlotError,
        isLoading: false,
      });
      return;
    }
    //continue with saving
    let subDomain = this.state.subDomain;
    let config = this.props.config;

    let deliveryDate = new Date().toISOString().split("T")[0];
    let deliveryTime = null;

    if (this.state.shippingOption) {
      if (this.state.deliveryDate !== "")
        deliveryDate = this.state.deliveryDate;

      if (this.state.deliverySlot != "") {
        var res = this.state.deliverySlot.split(":");
        var hh = res[0];
        var res1 = res[1].split(" ");
        var mm = res1[0];
        var ampm = res1[1];

        deliveryTime = { HH: hh, MM: mm, AMPM: ampm };
      }
    }
    console.log("this.props.lineItemTotal", this.props.lineItemTotal);

    let customer = this.props.customer;
    if (this.state.mobileNo !== "") {
      customer.PhoneNo = this.state.mobileNo;
    }
    if (this.state.emailID.trim() !== "") {
      customer.EmailID = this.state.emailID;
    }
    let saleID = this.props.lineItemTotal.hasOwnProperty("saleID")
      ? this.props.lineItemTotal.saleID
      : "";

    let sale = {
      SaleID: saleID,
      RegistrationID: config.key,
      StoreID: config.CheckoutConfig.StoreID,
      CashRegisterID: config.CheckoutConfig.CashRegisterID,
      TransactionTypeID: config.CheckoutConfig.TransactionTypeID,
      RequestSource: "omni web",
      RequestSourceID: "omni web",
      Customer: customer,
      DeliveryDate: deliveryDate,
      DeliveryTime: deliveryTime,
      LineItems: [],
      BillAmount: this.state.billAmount,
      BalanceAmount: this.state.billBalanceAmount,
      BillChangeDue: 0,
      BillDiscount: this.props.lineItemTotal.billDiscount,
      BillPaidAmount: this.state.billAmount - this.state.billBalanceAmount,
      BillQty: this.props.lineItemTotal.billQty,
      BillSubTotal:
        Number(this.props.lineItemTotal.billSubTotal) +
        Number(this.state.deliveryCharge) +
        Number(this.state.packagingCharge),
      BillTaxAmount: this.props.lineItemTotal.billTaxAmount,
      Payments: [],
      Notes: this.state.remarks,
    };

    if (
      this.props.config.hasOwnProperty("CheckoutConfig") &&
      this.props.config.CheckoutConfig &&
      this.props.config.CheckoutConfig.ShowBillingAddress
    ) {
      sale.DeliveryCustomer = customer;
      sale.DeliveryCustomerAddress = this.state.selectedAddress;
    }

    let lineItems = this.props.lineItems;
    lineItems.forEach((m) => {
      let lineItem = {
        RetailPrice: m.RetailPrice,
        DiscountPer: m.DiscountPer,
        DiscountAmount: m.DiscountAmount,
        Qty: m.Qty,
        SubTotal: m.SubTotal,
        ChargeTaxOnProduct: m.ChargeTaxOnProduct,
        PriceBasedTax: m.PriceBasedTax,
        HSNSACCode: m.HSNSACCode,
        TaxInclusive: m.TaxInclusive,
        TaxGroup: m.TaxGroup,
        TaxID: m.TaxID,
        SubTotalWithDiscount: m.SubTotalWithDiscount,
        TaxableAmount: m.TaxableAmount,
        TotalTax: m.TotalTax,
        Amount: m.Amount,
        ProductID: m.Product.key,
        Product: m.Product,
        BasePrice: m.TaxableAmount,
        TaxComponentAmount: m.TaxComponentAmount,
        Notes: m.Notes,
      };
      if (this.props.employee) {
        lineItem.Employee = this.props.employee;
      }
      sale.LineItems.push(lineItem);
    });

    if (Number(this.state.deliveryCharge) > 0) {
      let deliveryProduct = this.props.deliveryProduct;
      console.log("deliveryProduct", deliveryProduct);
      let lineItem = {
        RetailPrice: Number(this.state.deliveryCharge),
        DiscountPer: 0,
        DiscountAmount: 0,
        Qty: 1,
        SubTotal: Number(this.state.deliveryCharge),
        ChargeTaxOnProduct: deliveryProduct.hasOwnProperty("ChargeTaxOnProduct")
          ? deliveryProduct.ChargeTaxOnProduct
          : false,
        PriceBasedTax: deliveryProduct.hasOwnProperty("PriceBasedTax")
          ? deliveryProduct.PriceBasedTax
          : false,
        HSNSACCode: deliveryProduct.HSNSACCode,
        TaxInclusive: deliveryProduct.TaxInclusive,
        TaxGroup: deliveryProduct.TaxGroup,
        TaxID: deliveryProduct.TaxID,
        SubTotalWithDiscount: Number(this.state.deliveryCharge),
        TaxableAmount: 0,
        TotalTax: 0,
        Amount: Number(this.state.deliveryCharge),
        ProductID: deliveryProduct.key,
        Product: deliveryProduct,
        BasePrice: Number(this.state.deliveryCharge),
        TaxComponentAmount: [],
        Notes: "",
      };
      sale.LineItems.push(lineItem);
    }
    if (Number(this.state.packagingCharge) > 0 && this.props.packagingProduct) {
      let packagingProduct = this.props.packagingProduct;
      let lineItem = {
        RetailPrice: Number(this.state.packagingCharge),
        DiscountPer: 0,
        DiscountAmount: 0,
        Qty: 1,
        SubTotal: Number(this.state.packagingCharge),
        ChargeTaxOnProduct: packagingProduct.ChargeTaxOnProduct,
        PriceBasedTax: packagingProduct.PriceBasedTax,
        HSNSACCode: packagingProduct.HSNSACCode,
        TaxInclusive: packagingProduct.TaxInclusive,
        TaxGroup: packagingProduct.TaxGroup,
        TaxID: packagingProduct.TaxID,
        SubTotalWithDiscount: Number(this.state.packagingCharge),
        TaxableAmount: 0,
        TotalTax: 0,
        Amount: Number(this.state.packagingCharge),
        ProductID: packagingProduct.key,
        Product: packagingProduct,
        BasePrice: Number(this.state.packagingCharge),
        TaxComponentAmount: [],
        Notes: "",
      };
      sale.LineItems.push(lineItem);
    }
    if (this.state.walletSelected && this.state.walletRedemptionAmount > 0) {
      sale.Payments.push({
        Amount: this.state.walletRedemptionAmount,
        PaymentMode: "wallet",
        PaymentModeRemarks: "",
      });
    }
    if (this.state.loyaltySelected && this.state.loyaltyRedemptionAmount > 0) {
      sale.Payments.push({
        Amount: this.state.loyaltyRedemptionAmount,
        PaymentMode: "loyalty",
        PaymentModeRemarks: "",
      });
    }
    this.setState({ sale: sale, saleID: saleID });

    let response = null;
    if (subDomain.toLowerCase() === "phonepe") {
      response = await initiatePayment(sale, "phonepe");
      window.open(response.data.data.redirectUrl);
      return;
    } else {
      if (
        this.props.config.CheckoutConfig.hasOwnProperty("PaymentModes") &&
        this.state.billBalanceAmount > 0
      ) {
        if (this.props.config.CheckoutConfig.PaymentModes.length > 1) {
          //more than one payment mode..so show the option to select payment pages
          if (isMobile()) {
            this.setState({
              showPaymentModeSelection: true,
              isLoading: false,
              billBalanceAmount: this.state.billAmount,
            });
          } else {
            if (this.state.paymentModeSelectedIndex === -1) {
              //payment mode not selected
              this.setState({
                paymentModeSelectionError: "Please select payment mode.",
                isLoading: false,
              });
              return;
            } else {
              //payment mode selected
              this.onPaymentModeSelected(
                this.state.paymentModeSelectedIndex,
                sale
              );
            }
          }
        } else {
          this.onPaymentModeSelected(0, sale);
        }
      } else {
        //no payment mode..save data
        this.saveSale(sale, false);
      }
    }
  }

  async onPayUClick(selectedPaymentMode, sale) {
    sale.PayUProductInfo = "onlineorder";
    console.log("sale", sale);
    console.log("selectedPaymentMode", selectedPaymentMode);
    let response = await initiatePayment(sale, "payu", selectedPaymentMode);
    console.log("response", response);
    let orderID = "";
    let key = "";
    let hash = "";
    const { URLSearchParams } = require("url");
    const fetch = require("node-fetch");
    const encodedParams = new URLSearchParams();
    encodedParams.set("key", key);
    encodedParams.set("amount", this.state.billBalanceAmount * 100);
    encodedParams.set("txnid", orderID);
    encodedParams.set("firstname", sale.Customer.FirstName);
    encodedParams.set("email", sale.Customer.EmailID);
    encodedParams.set("phone", sale.Customer.PhoneNo);
    encodedParams.set("productinfo", "onlineorder");
    encodedParams.set("surl", "https://apiplayground-response.herokuapp.com/");
    encodedParams.set("furl", "https://apiplayground-response.herokuapp.com/");
    encodedParams.set("pg", "");
    encodedParams.set("bankcode", "");
    encodedParams.set("ccnum", "");
    encodedParams.set("ccexpmon", "");
    encodedParams.set("ccexpyr", "");
    encodedParams.set("ccvv", "");
    encodedParams.set("ccname", "");
    encodedParams.set("txn_s2s_flow", "");
    encodedParams.set("hash", hash);
    const url = "https://secure.payu.in/_payment";
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encodedParams,
    };
    return axios
      .post(url, options)
      .then((res) => {
        console.log("res", res);
        // return uniqueName;
      })
      .catch((err) => {
        console.log(err);
        // console.log('Could not get businessname for provided domain name. Try again later.');
      });

    // fetch(url, options).then(res => res.json()).then(json => console.log(json)).catch(err => console.error('error:' + err));
  }

  razorPayClick(orderID, sale, key) {
    const options = {
      key: key,
      // amount: sale.BillAmount * 100,
      amount: this.state.billBalanceAmount * 100,
      currency: "INR",
      name: this.props.config.BusinessName,
      description: "Thank you for your purchase",
      // "image": "https://example.com/your_logo",
      order_id: orderID,
      handler: this.razorPayHandler,
      prefill: {
        name: sale.Customer.FirstName + " " + sale.Customer.LastName,
        email: sale.Customer.EmailID,
        contact: sale.Customer.PhoneNo,
      },
      // notes: {
      //     address: "Razorpay Corporate Office",
      // },
      // ,"theme": {
      //     "color": "#F37254"
      // }
    };
    log(orderID, "razorpay_start", { OrderID: orderID, Sale: sale });
    // console.log('options',options)
    // console.log('window.navigator',window.navigator)
    const rzp1 = new window.Razorpay(options);
    rzp1.on(
      "payment.failed",
      function (response) {
        log(response.error.metadata.order_id, "razorpay_error", {
          OrderID: response.error.metadata.order_id,
          Response: response,
        });
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        // alert('Payment processing failed. Please try again.')
        //    console.log('payment failed')
        this.postPaymentProcessingError();
      }.bind(this)
    );
    rzp1.open();
  }

  async razorPayHandler(response) {
    //1-mar-2023
    // this.updateOnlinePaymentDetails("razorpay", response.razorpay_payment_id,"razorpay",)
    let sale = this.state.sale;
    sale.Payments.push({
      Amount: this.state.billBalanceAmount,
      PaymentMode: "Online Payment",
      PaymentModeRemarks: "",
    });
    sale.BillPaidAmount = sale.BillAmount;
    sale.BalanceAmount = 0;
    this.saveSale(sale, false);
  }

  async updateOnlinePaymentDetails(
    paymentMode,
    paymentProviderID,
    paymentProvider
  ) {
    let sale = this.state.sale;

    // log(response.razorpay_order_id ,"razorpay_success", {OrderID:sale.PaymentProviderID, Response:response})

    let transactionID = this.state.transactionID;
    let paidAmount = sale.BalanceAmount;
    // let paymentMode = "razorpay";
    let paymentModeRemarks = ""; //sale.PaymentProviderID;
    // let paymentProviderID = response.razorpay_payment_id;
    // let paymentProvider = "razorpay";
    // console.log('razorPayHandler > transactionID', transactionID);
    let status = await updatePaymentForTransaction(
      transactionID,
      paidAmount,
      paymentMode,
      paymentModeRemarks,
      paymentProviderID,
      paymentProvider
    );
    if (status) {
      //success
      // console.log('Payment success')
      this.postOrderCompletion();
    } else {
      //error
      this.postPaymentProcessingError();
    }
  }

  postPaymentProcessingError() {
    // console.log('Payment error')
    // this.props.loadCart([]);
    // this.props.loadCartTotal({
    //     billQty: 0,
    //     billSubTotal: 0,
    //     billAmount: 0,
    //     billTaxAmount: 0,
    //     billDiscount: 0,
    //     taxSummaryList: [],
    //     roundOff: 0,
    // });
    this.setState({
      sale: null,
      billAmount: 0,
      deliveryCharge: 0,
      deliverySlot: "",
      deliveryDate: "",
      isLoading: false,
      showPaymentModeSelection: false,
      successDisplay: false,
      errorDisplay: true,
    });
  }

  async onPaymentModeSelected(index, sale) {
    // alert(this.state.paymentModeSelectedIndex)
    this.setState({ isLoading: true });
    if (this.state.billBalanceAmount > 0 && index < 0) {
      this.setState({
        paymentModeError: "Please select payment mode",
        isLoading: false,
      });
      return;
    }
    // let selectedPaymentMode = this.props.config.CheckoutConfig.PaymentModes[index];
    let selectedPaymentMode = this.state.paymentOptions2[index];

    if (
      isMobile() &&
      (this.state.walletSelected || this.state.loyaltySelected)
    ) {
      sale.BillAmount = this.state.billAmount;
      sale.BalanceAmount = this.state.billBalanceAmount;
      sale.BillPaidAmount =
        this.state.billAmount - this.state.billBalanceAmount;

      if (this.state.walletSelected && this.state.walletRedemptionAmount > 0) {
        sale.Payments.push({
          Amount: this.state.walletRedemptionAmount,
          PaymentMode: "wallet",
          PaymentModeRemarks: "",
        });
      }

      if (
        this.state.loyaltySelected &&
        this.state.loyaltyRedemptionAmount > 0
      ) {
        sale.Payments.push({
          Amount: this.state.loyaltyRedemptionAmount,
          PaymentMode: "loyalty",
          PaymentModeRemarks: "",
        });
      }
    }
    if (selectedPaymentMode.Value === "cashondelivery") {
      //keep balance and save data
      this.saveSale(sale, false);
    } else if (selectedPaymentMode.Value === "razorpay") {
      let response = await initiatePayment(
        sale,
        "razorpay",
        selectedPaymentMode
      );
      this.razorPayClick(
        response.data.data.paymentProviderReferenceID,
        sale,
        selectedPaymentMode.RazorPayKeyID
      );
    } else if (selectedPaymentMode.Value === "atom") {
      console.log("11 Aug 2024");
      // console.log("this.props.config.key", this.props.config.key);
      const fullURL = window.location.href;

      // Parse the URL to get the hostname (domain name)
      const parser = new URL(fullURL);
      const domainName = parser.hostname;

      // console.log(domainName);
      let returnURL = "https://" + domainName;
      // console.log("returnURL", returnURL);
      let email =
        sale.Customer && sale.Customer.EmailID && sale.Customer.EmailID !== ""
          ? sale.Customer.EmailID.trim()
          : "";
      // : "nocustomeremail@worldretailexchange.com";
      // Remove all blank spaces using regular expression
      let cleanEmail = email.replace(/\s+/g, "");

      let customerPhoneNo =
        sale.Customer && sale.Customer.PhoneNo
          ? sale.Customer.PhoneNo.trim()
          : this.props.config.ContactNo.trim();

      if (cleanEmail === "") {
        alert("Customer email id is mandatory. Please update your profile.");
        this.setState({ isLoading: false });
        return;
      }

      if (customerPhoneNo === "") {
        alert("Customer mobile no is mandatory. Please update your profile.");
        return;
      }

      //check id valid mobile no format
      const mobileNoRegex = /^[6-9]\d{9}$/;
      if (!mobileNoRegex.test(customerPhoneNo)) {
        alert(
          "Invalid mobile no. Please update your profile with correct mobile no."
        );
        this.setState({ isLoading: false });
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(cleanEmail)) {
        alert(
          "Invalid email id. Please update your profile with correct email id."
        );
        this.setState({ isLoading: false });
        return;
      }

      let response = await initiatePaymentAtom(
        this.props.config.key,
        this.state.billBalanceAmount.toString(),
        cleanEmail,
        customerPhoneNo,
        sale,
        returnURL
      );

      if (response) {
        console.log("response", JSON.stringify(response));
        if (
          response &&
          response.data &&
          response.data.token &&
          response.data.token !== ""
        ) {
          // console.log(cleanEmail); // Output: "pawan@posible.in"¸
          const options = {
            atomTokenId: response.data.token,
            merchId: response.data.merchId,
            custEmail: cleanEmail,
            custMobile: customerPhoneNo,
            returnUrl: "https://tmpposiblepg.el.r.appspot.com/Response",
          };
          console.log("atom options", options);
          //  return false
          if (window.AtomPaynetz) {
            this.setState({ isLoading: false });
            let atom = new window.AtomPaynetz(options, "");
          } else {
            console.error(
              "AtomPaynetz is not defined. Make sure the script is loaded."
            );
          }
        } else {
          alert("Error connecting atom");
        }
      }
    } else if (selectedPaymentMode.Value === "atomold") {
      let response = await initiatePayment(
        sale,
        "atom",
        selectedPaymentMode,
        window.location.href
      );
      if (response.data.status) {
        var closeWindow = window.open(response.data.data.URL, "_blank"); //,"_blank"
        let timer = setInterval(async () => {
          let payment = await getPaymentStatus(
            response.data.data.TransactionID
          );
          if (payment.data.data.Status.toLowerCase() === "payment initiated") {
            console.log("waiting for pg response");
          } else if (payment.data.data.Status.toLowerCase() === "ok") {
            clearInterval(timer);
            closeWindow.close();
            sale.Payments.push({
              Amount: this.state.billBalanceAmount,
              PaymentMode: "Online Payment",
              PaymentModeRemarks: "",
            });
            sale.BillPaidAmount = sale.BillAmount;
            sale.BalanceAmount = 0;
            this.saveSale(sale, false);
          } else {
            console.log("payment un successful");
            clearInterval(timer);
            closeWindow.close();
            alert("Payment processing failed. Please try again");
            this.setState({ isLoading: false });
            return;
          }
        }, 10000);
      } else {
        alert("Unable to connect ATOM payment gateway. Please try again");
        return;
      }
      // this.saveSale(sale, true);
      // this.onPayUClick(selectedPaymentMode,sale);
      // let response = await initiatePayment(sale,"payu", payUKey, payUSalt, payUProductInfo)
    }
    this.setState({ selectedPaymentMode: -1 });
  }

  async saveSale(sale, waitForPaymentAfterSave = false) {
    if (this.state.showAppointmentOptions) {
      if (this.state.appointmentDate !== "") {
        let appointmentEmployeeName = "";
        if (this.state.appointmentEmployee !== "") {
          if (
            this.state.employees.filter(
              (f) => f.key === this.state.appointmentEmployee
            ).length > 0
          ) {
            let employee = this.state.employees.filter(
              (f) => f.key === this.state.appointmentEmployee
            )[0];

            appointmentEmployeeName =
              employee.EmployeeFirstName + " " + employee.EmployeeLastName;
          }
        }
        console.log("appointmentEmployeeName", appointmentEmployeeName);
        sale.Appointment = {
          AppointmentDate: this.state.appointmentDate,
          AppointmentSlot: this.state.appointmentTimeSlot,
          AppointmentEmployee: this.state.appointmentEmployee,
          AppointmentEmployeeName: appointmentEmployeeName,
        };
      }
    }
    console.log("sale", sale);
    // return;
    let saleID = sale.hasOwnProperty("SaleID") ? sale.SaleID : "";
    console.log("saveSale sale id", saleID);

    let response = null;
    if (saleID === "") {
      //new sale
      response = await checkOut(sale);
    } else {
      response = await updateSale(sale);
    }
    // console.log('save response', response)
    if (response.status === 200 && response.data.status === "Success") {
      if (!waitForPaymentAfterSave) {
        console.log("waitForPaymentAfterSave", waitForPaymentAfterSave);
        this.postOrderCompletion();
      } else {
        let transactionID = response.data.message;
        this.setState({ transactionID: transactionID });
        console.log("transactionID", transactionID);
        return true;
      }
    } else {
      this.setState({
        isLoading: false,
        showPaymentModeSelection: false,
        errorDisplay: true,
      });
      return false;
    }
  }

  postOrderCompletion() {
    this.setState({
      isLoading: false,
      sale: null,
      billAmount: 0,
      deliveryCharge: 0,
      deliverySlot: "",
      deliveryDate: "",
    });
    this.props.loadCart([]);
    this.props.loadCartTotal({
      billQty: 0,
      billSubTotal: 0,
      billAmount: 0,
      billTaxAmount: 0,
      billDiscount: 0,
      taxSummaryList: [],
      roundOff: 0,
    });
    this.setState({
      isLoading: false,
      showPaymentModeSelection: false,
      successDisplay: true,
    });
  }

  onCustomerAddressChange() {
    this.setState({ showCustomerAddressSelection: true });
  }

  async getCustomerWalletBalance() {
    if (
      this.props.customer &&
      this.props.config.CheckoutConfig.hasOwnProperty("PaymentModes") &&
      this.props.config.CheckoutConfig.PaymentModes.filter(
        (f) => f.Value.toLowerCase() === "wallet"
      ).length > 0
    ) {
      let walletBalance = await getWalletBalance(this.props.customer.key);
      this.setState({ walletBalance: walletBalance });
    }
  }

  async getCustomerLoyaltyDetails() {
    if (
      this.props.customer &&
      this.props.config.CheckoutConfig.hasOwnProperty("PaymentModes") &&
      this.props.config.CheckoutConfig.PaymentModes.filter(
        (f) => f.Value.toLowerCase() === "loyalty"
      ).length > 0
    ) {
      let loyaltyDetails = await getLoyaltyDetails(this.props.customer.key);
      this.setState({ loyaltyDetails: loyaltyDetails });
    }
  }

  async updateAddress(addresses) {
    let customer = this.props.customer;
    customer.Addresses = addresses;
    let res = await saveCustomerDetails(customer.key, customer);
    if (res) {
      this.props.setCustomer(
        this.props.config.CheckoutConfig.StoreID,
        customer
      );
      this.setCustomerAddress(customer);
      this.setState({ showCustomerAddressSelection: false });
    } else {
      alert("unable to update customer");
    }
  }

  onDateChange(e) {
    if (!this.props.config.CheckoutConfig.hasOwnProperty("ShippingOption")) {
      return;
    }
    let shippingOption = this.props.config.CheckoutConfig.ShippingOption;

    var weekDay = new Array(7);
    weekDay[0] = "Sunday";
    weekDay[1] = "Monday";
    weekDay[2] = "Tuesday";
    weekDay[3] = "Wednesday";
    weekDay[4] = "Thursday";
    weekDay[5] = "Friday";
    weekDay[6] = "Saturday";

    let minDate = new Date();
    minDate = new Date(minDate).toISOString().split("T")[0];

    let dayOfWeek = weekDay[new Date(e.target.value).getDay()];

    let deliveryDate = e.target.value;

    let deliveryOperatingHrs = shippingOption.DeliveryOperatingHrs.filter(
      (f) => f.Day === dayOfWeek
    )[0];

    let slots = [];

    if (
      deliveryOperatingHrs &&
      deliveryOperatingHrs.Slots &&
      deliveryOperatingHrs.Slots.length > 0
    )
      slots = deliveryOperatingHrs.Slots;

    for (let j = 0; j < slots.length; j++) {
      const slot = slots[j];
      let timeArray = slot.Slots.split("-");
      let currentDate = new Date();
      let slotDate = "";
      slot.AvailableForPickup = false;

      if (timeArray.length > 1) {
        slotDate = new Date(
          new Date(e.target.value).getFullYear() +
            "-" +
            (new Date(e.target.value).getMonth() + 1) +
            "-" +
            new Date(e.target.value).getDate() +
            " " +
            timeArray[0].trim()
        );
      } else {
        slotDate = new Date(
          new Date(e.target.value).getFullYear() +
            "-" +
            (new Date(e.target.value).getMonth() + 1) +
            "-" +
            new Date(e.target.value).getDate() +
            " " +
            slot.Slots.trim()
        );
      }

      if (currentDate < slotDate) {
        slot.AvailableForPickup = true;
      }
    }

    this.setState({
      shippingOption: shippingOption,
      slots: slots,
      deliveryDate: deliveryDate,
      minDate: minDate,
    });
  }

  renderDeliveryTime() {
    return (
      <React.Fragment>
        {this.state.shippingOption &&
          this.state.shippingOption.SpecifyPickupDate && (
            <div
              className={
                isMobile() ? "col-6 checkout-delivery-date-time" : "col-6"
              }
            >
              <label>Select Date</label>
              <input
                type="date"
                className="form-control"
                min={this.state.minDate}
                value={this.state.deliveryDate}
                onChange={(e) => this.onDateChange(e)}
              />
            </div>
          )}
        {this.state.shippingOption &&
          this.state.shippingOption.SpecifyPickupSlot && (
            <div
              className={
                isMobile() ? "col-6 checkout-delivery-date-time" : "col-6"
              }
            >
              <label>Select Slot</label>
              <select
                className="form-control"
                value={this.state.deliverySlot}
                onChange={(evt) =>
                  this.setState({ deliverySlot: evt.target.value })
                }
              >
                <option value="">Select Slot</option>
                {this.state.slots
                  .filter((f) => f.AvailableForPickup)
                  .map((slot) => (
                    <option value={slot.Slot}>{slot.Slots}</option>
                  ))}
              </select>
            </div>
          )}
      </React.Fragment>
    );
  }

  renderPaymentOptions() {
    return (
      <React.Fragment>
        {this.state.paymentOptions1
          .filter((f) => f.Value.toLowerCase() === "wallet")
          .map((m) => (
            <React.Fragment>
              {this.state.walletBalance > 0 && (
                <div className="row" key="wallet">
                  <div className="col-md-12">
                    <div className="checkout-paymentmode">
                      <input
                        type="checkbox"
                        name="paymentmode"
                        checked={this.state.walletSelected}
                        onChange={this.onWalletOptionSelected}
                        disabled={this.state.walletBalance === 0 ? true : false}
                      />
                      &nbsp;
                      <label onClick={this.onWalletOptionSelected}>
                        {m.Text}
                      </label>
                      <br></br>
                      <div>Wallet Balance: ₹ {this.state.walletBalance}</div>
                      {this.state.walletRedemptionAmount > 0 && (
                        <div>Amount: ₹ {this.state.walletRedemptionAmount}</div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        {this.state.paymentOptions1
          .filter((f) => f.Value.toLowerCase() === "loyalty")
          .map((m) => (
            <React.Fragment>
              {this.state.loyaltyDetails &&
                this.state.loyaltyDetails.LoyaltyAmount > 0 && (
                  <div key="loyalty" className="row">
                    <div className="col-md-12">
                      <div className="checkout-paymentmode">
                        <input
                          type="checkbox"
                          name="paymentmode"
                          checked={this.state.loyaltySelected}
                          onChange={this.onLoyaltyOptionSelected}
                          disabled={
                            this.state.loyaltyDetails.LoyaltyAmount === 0
                              ? true
                              : false
                          }
                        />
                        &nbsp;
                        <label onClick={this.onLoyaltyOptionSelected}>
                          {m.Text}
                        </label>
                        <br></br>
                        <div>
                          Loyalty Points:{" "}
                          {this.state.loyaltyDetails.LoyaltyPoints}
                        </div>
                        <div>
                          Loyalty Amount: ₹{" "}
                          {this.state.loyaltyDetails.LoyaltyAmount}
                        </div>
                        {this.state.loyaltyRedemptionAmount > 0 && (
                          <div>
                            Amount: ₹ {this.state.loyaltyRedemptionAmount}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
            </React.Fragment>
          ))}
        {this.state.paymentOptions2.map((m, index) => (
          <div className="row">
            <div className="col-md-12">
              <div className="checkout-paymentmode">
                <input
                  type="radio"
                  // id={m.Value}
                  name="paymentmode"
                  // value={m.Value}
                  checked={
                    this.state.paymentModeSelectedIndex === index ? true : false
                  }
                  onChange={(evt) =>
                    this.setState({ paymentModeSelectedIndex: index })
                  }
                />
                &nbsp;
                <label
                  onClick={(evt) =>
                    this.setState({ paymentModeSelectedIndex: index })
                  }
                >
                  {" "}
                  {m.Value.toLowerCase() === "razorpay" ||
                  m.Value.toLowerCase() === "atom"
                    ? "Online payment"
                    : m.Value.toLowerCase() === "cashondelivery"
                    ? "Cash On Delivery (COD)"
                    : m.Text}
                </label>
                <br></br>
                {m.Value.toLowerCase() === "razorpay" ||
                  (m.Value.toLowerCase() === "atom" && (
                    <div>
                      <b>
                        <i>
                          (Pay using Credit Card, Debit Card, UPI, NetBbanking
                          and more)
                        </i>
                      </b>{" "}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }

  renderCheckoutSuccess() {
    return (
      <React.Fragment>
        {/* <img style={{ width: "100px" }} src={require("./payment-success.png")}/> */}
        <img style={{ width: "100px" }} src={paymentSuccessImage} />
        <div className="text">Order placed successfully</div>
        <div
          className="checkout-success-link"
          onClick={() => {
            this.setState({ successDisplay: false });
            this.props.history.push(mainWebsitePath() + "myorders");
          }}
        >
          Click here to view order details
        </div>
        <div
          className="checkout-success-link"
          onClick={() => {
            this.setState({ successDisplay: false });
            this.props.history.push(mainWebsitePath());
          }}
        >
          Continue shopping
        </div>
      </React.Fragment>
    );
  }

  renderCheckoutError() {
    return (
      <React.Fragment>
        <img style={{ width: "100px" }} src={paymentErrorImage} />
        <div className="text">Payment processing failed. Please try again.</div>
        {/* <div className="text">Please pay at the time of delivery. {(this.props.config.CheckoutConfig.hasOwnProperty('ContactNo')  && this.props.config.CheckoutConfig.ContactNo !=="") && <React.Fragment> If payment has been deducted at your end then please contact {this.props.config.CheckoutConfig.ContactNo.trim()} </React.Fragment>}</div> */}
        {/* <div className="checkout-success-link" onClick={() => {
                    this.setState({successDisplay: false,});
                    this.props.history.push(mainWebsitePath() + "myorders");
                }}>
                Click here to view order details
            </div> */}
        <div
          className="checkout-success-link"
          onClick={() => {
            this.setState({ successDisplay: false });
            this.props.history.push(mainWebsitePath());
          }}
        >
          Continue shopping
        </div>
      </React.Fragment>
    );
  }

  async onLineItemUpdate() {
    this.onRemoveCoupon();
  }

  async onApplyCoupon(offer) {
    let discountPercentage = 0;
    let discountAmount = 0;
    let overRide = true;
    let cartTotal = {};
    if (offer.Discount.toLowerCase() === "amount") {
      discountAmount = Number(offer.DiscountValue);
      // console.log('amount', discountAmount)
    } else {
      discountPercentage = Number(offer.DiscountValue);
      // console.log('amount', discountPercentage)
    }
    // const {loadCart, loadCartTotal } = this.props;
    if (discountAmount > 0) {
      let billSubTotal = 0;
      this.props.lineItems.map((m) => {
        let allowDiscount = true;
        if (m.Product.hasOwnProperty("AllowDiscount")) {
          allowDiscount = m.Product.AllowDiscount;
        }
        if (allowDiscount) {
          billSubTotal = billSubTotal + Number(m.SubTotal);
        }
      });
      discountPercentage = Number(
        ((discountAmount * 100) / Number(billSubTotal)).toFixed(3)
      );
    }
    let lineItems = [];
    this.props.lineItems.map((lineItem) => {
      lineItem.DiscountPer = overRide
        ? Number(discountPercentage)
        : Number(lineItem.DiscountPer) + Number(discountPercentage);
      lineItem.DiscountAmount = 0;
      lineItem = onLineItemChange(
        lineItem,
        this.props.productTaxes,
        offer.hasOwnProperty("Type") ? offer.Type : "Offer",
        offer.key,
        offer.Name,
        true
      );
      lineItems.push(lineItem);
    });
    cartTotal = calculateBillTotal(lineItems);
    this.props.loadCart(lineItems);
    this.props.loadCartTotal(cartTotal);
    // this.computeDeliveryCharges(this.props.lineItemTotal.billAmount);
    this.computeCharges();
    this.setState({ coupon: offer });
  }

  onRemoveCoupon() {
    let cartTotal = this.props.lineItemTotal;
    if (this.state.coupon) {
      let updatedLineItems = [];
      for (let i = 0; i < this.props.lineItems.length; i++) {
        let lineItem = this.props.lineItems[i];
        //   if (lineItem.hasOwnProperty('Discounts')) {
        //       if (lineItem.Discounts.filter(f => f.DiscountID === offer.key).length > 0) {
        lineItem.DiscountPer = 0;
        lineItem.DiscountAmount = 0;
        lineItem.Discounts = [];
        lineItem = onLineItemChange(lineItem, this.props.productTaxes);
        //       }
        //   }
        updatedLineItems.push(lineItem);
      }
      cartTotal = calculateBillTotal(updatedLineItems);
      // console.log('cartTotal',cartTotal)
      loadCart(updatedLineItems);
      loadCartTotal(cartTotal);
    }
    // this.computeDeliveryCharges(cartTotal.billAmount);
    // this.computePackagingCharges(cartTotal.billAmount);
    this.computeCharges();
    this.setState({ coupon: null });
  }

  onWalletOptionSelected() {
    this.setState({
      walletSelected: !this.state.walletSelected,
      showWalletReedemption: !this.state.walletSelected,
      walletRedemptionAmount: 0,
      walletRedemptionAmountTmp: "",
      billBalanceAmount:
        this.state.billAmount - this.state.loyaltyRedemptionAmount,
    });
  }

  onLoyaltyOptionSelected() {
    this.setState({
      loyaltySelected: !this.state.loyaltySelected,
      showLoyaltyRedemption: !this.state.loyaltySelected,
      loyaltyRedemptionAmount: 0,
      loyaltyRedemptionAmountTmp: "",
      billBalanceAmount:
        this.state.billAmount - this.state.walletRedemptionAmount,
    });
  }

  onLoyaltyRedemptionClick() {
    if (Number(this.state.loyaltyRedemptionAmountTmp) <= 0) {
      this.setState({ loyaltyRedemptionAmountError: "Please specify amount" });
      return;
    }
    if (
      Number(this.state.loyaltyRedemptionAmountTmp) >
      Number(this.state.loyaltyDetails.LoyaltyAmount)
    ) {
      this.setState({
        loyaltyRedemptionAmountError: "Not enough loyalty amount",
      });
      return;
    }
    if (
      Number(this.state.loyaltyRedemptionAmountTmp) >
      Number(this.state.billBalanceAmount)
    ) {
      this.setState({
        loyaltyRedemptionAmountError:
          "Amount can not be more than order amount",
      });
      return;
    }
    //add payment
    let billBalanceAmount =
      this.state.billAmount -
      (Number(this.state.loyaltyRedemptionAmountTmp) +
        Number(this.state.walletRedemptionAmount));
    // console.log('billBalanceAmount',billBalanceAmount)
    this.setState({
      showLoyaltyRedemption: false,
      billBalanceAmount: billBalanceAmount,
      loyaltySelected: true,
      loyaltyRedemptionAmount: this.state.loyaltyRedemptionAmountTmp,
      loyaltyRedemptionAmountTmp: 0,
    });
  }

  onWalletRedemptionClick() {
    if (Number(this.state.walletRedemptionAmountTmp) <= 0) {
      this.setState({ walletRedemptionAmountError: "Please specify amount" });
      return;
    }
    if (
      Number(this.state.walletRedemptionAmountTmp) >
      Number(this.state.walletBalance)
    ) {
      this.setState({
        walletRedemptionAmountError: "Not enough wallet balance",
      });
      return;
    }
    if (
      Number(this.state.walletRedemptionAmountTmp) >
      Number(this.state.billBalanceAmount)
    ) {
      this.setState({
        walletRedemptionAmountError: "Amount can not be more than order amount",
      });
      return;
    }
    //add payment
    let billBalanceAmount =
      this.state.billAmount -
      (Number(this.state.walletRedemptionAmountTmp) +
        Number(this.state.loyaltyRedemptionAmount));
    console.log("billBalanceAmount", billBalanceAmount);
    this.setState({
      showWalletReedemption: false,
      billBalanceAmount: billBalanceAmount,
      walletSelected: true,
      walletRedemptionAmount: this.state.walletRedemptionAmountTmp,
      walletRedemptionAmountTmp: 0,
    });
  }

  renderLoyaltyRedemption() {
    return (
      <Window
        onClose={() => {
          this.setState({
            loyaltyRedemptionAmount: 0,
            loyaltyRedemptionAmountError: "",
            showLoyaltyRedemption: false,
            loyaltySelected: false,
          });
        }}
        header={"Loyalty Redemption"}
        width={"300px"}
        height={"200px"}
        modal={true}
        processing={this.state.processing}
        onButtonClick={this.onLoyaltyRedemptionClick}
        buttons={[{ label: "OK" }]}
      >
        <Input
          type="number"
          label="Amount to redeem"
          error={this.state.loyaltyRedemptionAmountError}
          value={this.state.loyaltyRedemptionAmountTmp}
          onChange={(value) =>
            this.setState({
              loyaltyRedemptionAmountTmp: value,
              loyaltyRedemptionAmountError: "",
            })
          }
        />
      </Window>
    );
  }

  renderWalletRedemption() {
    return (
      <Window
        onClose={() => {
          this.setState({
            walletRedemptionAmount: 0,
            walletRedemptionAmountError: "",
            showWalletReedemption: false,
            walletSelected: false,
          });
        }}
        header={"Wallet Redemption"}
        width={"300px"}
        height={"200px"}
        modal={true}
        processing={this.state.processing}
        onButtonClick={this.onWalletRedemptionClick}
        buttons={[{ label: "OK" }]}
      >
        <Input
          type="number"
          label="Amount to redeem"
          error={this.state.walletRedemptionAmountError}
          value={this.state.walletRedemptionAmountTmp}
          onChange={(value) =>
            this.setState({
              walletRedemptionAmountTmp: value,
              walletRedemptionAmountError: "",
            })
          }
        />
      </Window>
    );
  }

  renderAppointmentOptions() {
    // console.log("renderAppointmentOptions");
    // console.log(this.props.lineItems)

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <Input
              label="Preferred Date"
              type="date"
              value={this.state.appointmentDate}
              min={new Date().toISOString().split("T")[0]}
              onChange={(value) =>
                this.computeAppointmentSlots(value, this.state.store)
              }
              error={this.state.appointmentDateError}
            />
          </div>
          <div className="col-md-12">
            <Input
              label="Preferred Time"
              type="select"
              value={this.state.appointmentTimeSlot}
              onChange={(value) =>
                this.setState({
                  appointmentTimeSlot: value,
                  appointmentTimeSlotError: "",
                })
              }
              error={this.state.appointmentTimeSlotError}
            >
              <option value="">Please select time slot</option>
              {this.state.appointmentAvailableSlots.map((t) => (
                <option value={t.TimeInMins}>{t.Display}</option>
              ))}
            </Input>
          </div>
          <div className="col-md-12">
            <Input
              label="Staff"
              type="select"
              value={this.state.appointmentEmployee}
              onChange={(value) =>
                this.setState({
                  appointmentEmployee: value,
                  appointmentEmployeeError: "",
                })
              }
              error={this.state.appointmentEmployeeError}
            >
              <option value="">Please select</option>
              {this.state.employees.map((e) => (
                <option value={e.key}>{e.EmployeeFirstName}</option>
              ))}
            </Input>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderMobileNew() {
    return (
      <React.Fragment>
        {this.state.isLoading && (
          <div className="container ">
            <div className="checkout-payment-processing">Please wait!.</div>
            <Spinner />
          </div>
        )}

        {!this.state.showPaymentModeSelection && (
          <div className="checkout">
            {/* <div className="checkout-header">
                    <div className="menu-close-btn"  onClick={() =>this.props.history.push(mainWebsitePath())}>
                        <svg width="24px" viewBox="0 0 511.991 511.991"
                        ><g><g><path d="M153.433,255.991L381.037,18.033c4.063-4.26,3.917-11.01-0.333-15.083c-4.229-4.073-10.979-3.896-15.083,0.333    L130.954,248.616c-3.937,4.125-3.937,10.625,0,14.75L365.621,508.7c2.104,2.188,4.896,3.292,7.708,3.292    c2.646,0,5.313-0.979,7.375-2.958c4.25-4.073,4.396-10.823,0.333-15.083L153.433,255.991z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                        <span>CHECKOUT</span>
                    </div>
                </div> */}
            <CheckOutHeader
              title="CHECKOUT"
              onClose={() => this.props.history.push(mainWebsitePath())}
            />
            <div className="container ">
              {this.state.addressSelectionError !== "" && (
                <div className="error-msg">
                  {this.state.addressSelectionError}
                </div>
              )}

              {this.state.customerLoginError !== "" && (
                <div className="error-msg">{this.state.customerLoginError}</div>
              )}

              {this.state.configError !== "" && (
                <div className="error-msg">{this.state.configError}</div>
              )}

              {this.renderCustomerMobileNoAndEmailIDEntry()}

              <Cart
                shippingOption={this.state.shippingOption}
                deliveryCharge={this.state.deliveryCharge}
                packagingCharge={this.state.packagingCharge}
                billAmount={this.state.billAmount}
                coupon={this.state.coupon}
                onLineItemUpdate={this.onLineItemUpdate}
                onApplyCoupon={this.onApplyCoupon}
                onRemoveCoupon={this.onRemoveCoupon}
                // onDeliveryChargeChange={(deliveryCharge)=>{console.log('deliveryCharge',deliveryCharge)}}
              />

              {this.state.showAppointmentOptions &&
                this.renderAppointmentOptions()}
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>

            {this.props.lineItems.length > 0 && (
              <React.Fragment>
                {this.props.customer && !this.state.selectedAddress && (
                  <div
                    type="submit"
                    className="checkout-continue"
                    onClick={() => {
                      let mobileNoError = "";
                      let emailIDError = "";
                      if (this.props.customer) {
                        if (
                          this.state.mobileNoMandatory &&
                          this.state.mobileNo.trim() === ""
                        ) {
                          mobileNoError = "Please provide mobile no.";
                        }
                        if (
                          this.state.emailIDMandatory &&
                          this.state.emailID.trim() === ""
                        ) {
                          emailIDError = "Please provide email id.";
                        }
                      }
                      if (mobileNoError !== "" || emailIDError !== "") {
                        this.setState({
                          mobileNoError: mobileNoError,
                          emailIDError: emailIDError,
                        });
                        return;
                      }
                      this.setState({ showCustomerAddressSelection: true });
                    }}
                  >
                    CONTINUE
                  </div>
                )}

                {!this.props.customer && (
                  <div
                    type="submit"
                    className="checkout-continue"
                    onClick={() => {
                      this.setState({ showLogin: true });
                    }}
                  >
                    CONTINUE
                  </div>
                )}

                {this.props.customer && this.state.selectedAddress && (
                  <div
                    className="checkout-address"
                    style={{
                      height:
                        this.state.shippingOption &&
                        this.state.shippingOption.SpecifyPickupDate
                          ? "160px"
                          : "100px",
                    }}
                  >
                    <div className="checkout-address-icon">
                      <svg viewBox="0 0 368.16 368.16" width="24px">
                        <g>
                          <g>
                            <g>
                              <path d="M184.08,0c-74.992,0-136,61.008-136,136c0,24.688,11.072,51.24,11.536,52.36c3.576,8.488,10.632,21.672,15.72,29.4     l93.248,141.288c3.816,5.792,9.464,9.112,15.496,9.112s11.68-3.32,15.496-9.104l93.256-141.296     c5.096-7.728,12.144-20.912,15.72-29.4c0.464-1.112,11.528-27.664,11.528-52.36C320.08,61.008,259.072,0,184.08,0z      M293.8,182.152c-3.192,7.608-9.76,19.872-14.328,26.8l-93.256,141.296c-1.84,2.792-2.424,2.792-4.264,0L88.696,208.952     c-4.568-6.928-11.136-19.2-14.328-26.808C74.232,181.816,64.08,157.376,64.08,136c0-66.168,53.832-120,120-120     c66.168,0,120,53.832,120,120C304.08,157.408,293.904,181.912,293.8,182.152z" />
                              <path d="M184.08,64.008c-39.704,0-72,32.304-72,72c0,39.696,32.296,72,72,72c39.704,0,72-32.304,72-72     C256.08,96.312,223.784,64.008,184.08,64.008z M184.08,192.008c-30.872,0-56-25.12-56-56s25.128-56,56-56s56,25.12,56,56     S214.952,192.008,184.08,192.008z" />
                            </g>
                          </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                    </div>
                    <div className="checkout-address-content">
                      <div>
                        <b>{this.state.selectedAddress.AddressType}</b>
                      </div>
                      <div>{this.state.selectedAddress.Address1}</div>
                      <div>{this.state.selectedAddress.Address2}</div>
                    </div>
                    <div
                      className="checkout-address-change"
                      onClick={this.onCustomerAddressChange}
                    >
                      CHANGE
                    </div>
                    <div className="row checkout-delivery-section-mobile">
                      {this.renderDeliveryTime()}
                    </div>
                  </div>
                )}

                {this.props.customer && this.state.selectedAddress && (
                  <div className="checkout-footer">
                    <div className="section1">₹ {this.state.billAmount}</div>
                    <div
                      className="section2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (!this.state.isLoading) {
                          this.onSubmitClick();
                        }
                      }}
                    >
                      {this.state.isLoading ? "...." : "PROCEED TO PAY"}
                    </div>
                  </div>
                )}

                {this.props.customer && !this.state.addressRequired && (
                  <div className="checkout-footer">
                    <div className="section1">₹ {this.state.billAmount}</div>
                    <div
                      className="section2"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (!this.state.isLoading) {
                          this.onSubmitClick();
                        }
                      }}
                    >
                      {this.state.isLoading ? "...." : "PROCEED TO PAY"}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        )}

        {this.state.showLogin && (
          <Login onCustomerLogin={() => this.setState({ showLogin: false })} />
        )}

        {this.state.showCustomerAddressSelection && (
          <React.Fragment>
            {/* <div className="checkout-header">
                    <div className="menu-close-btn"  onClick={() =>this.setState({showCustomerAddressSelection:false})}>
                        <svg width="24px" viewBox="0 0 511.991 511.991"
                        ><g><g><path d="M153.433,255.991L381.037,18.033c4.063-4.26,3.917-11.01-0.333-15.083c-4.229-4.073-10.979-3.896-15.083,0.333    L130.954,248.616c-3.937,4.125-3.937,10.625,0,14.75L365.621,508.7c2.104,2.188,4.896,3.292,7.708,3.292    c2.646,0,5.313-0.979,7.375-2.958c4.25-4.073,4.396-10.823,0.333-15.083L153.433,255.991z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                        <span>ADDRESS SELECTION</span>
                    </div>
                </div> */}
            <CheckOutHeader
              title="ADDRESS SELECTION"
              onClose={() =>
                this.setState({ showCustomerAddressSelection: false })
              }
            />

            <div className="checkout-address-selection">
              <CustomerAddress
                selection={true}
                addresses={this.props.customer.Addresses}
                onAddressUpdated={(addresses) => this.updateAddress(addresses)}
                onAddressSelection={(selectedAddress, selectedAddressindex) => {
                  this.setState({
                    showCustomerAddressSelection: false,
                    selectedAddress: selectedAddress,
                  });
                }}
              />
            </div>
          </React.Fragment>
        )}

        {!this.state.isLoading && this.state.showPaymentModeSelection && (
          <div className="checkout">
            {/* <div className="checkout-header">
                    <div className="menu-close-btn"  
                        onClick={() =>this.setState({showPaymentModeSelection:false, 
                            loyaltySelected:false,
                            showLoyaltyRedemption:false,
                            loyaltyRedemptionAmount:0,
                            loyaltyRedemptionAmountTmp:0,
                            walletSelected:false,
                            showWalletReedemption:false, 
                            walletRedemptionAmount:0,
                            billBalanceAmount:this.state.billAmount})}>
                        <svg width="24px" viewBox="0 0 511.991 511.991"
                        ><g><g><path d="M153.433,255.991L381.037,18.033c4.063-4.26,3.917-11.01-0.333-15.083c-4.229-4.073-10.979-3.896-15.083,0.333    L130.954,248.616c-3.937,4.125-3.937,10.625,0,14.75L365.621,508.7c2.104,2.188,4.896,3.292,7.708,3.292    c2.646,0,5.313-0.979,7.375-2.958c4.25-4.073,4.396-10.823,0.333-15.083L153.433,255.991z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                        <span>PAYMENT SELECTION</span>
                    </div>
                </div> */}
            <CheckOutHeader
              title="PAYMENT SELECTION"
              onClose={() =>
                this.setState({
                  showPaymentModeSelection: false,
                  loyaltySelected: false,
                  showLoyaltyRedemption: false,
                  loyaltyRedemptionAmount: 0,
                  loyaltyRedemptionAmountTmp: 0,
                  walletSelected: false,
                  showWalletReedemption: false,
                  walletRedemptionAmount: 0,
                  billBalanceAmount: this.state.billAmount,
                })
              }
            />

            <div className="container ">
              {this.state.paymentModeError !== "" && (
                <div className="error-msg">{this.state.paymentModeError}</div>
              )}
              {this.renderPaymentOptions()}
            </div>
            <div
              type="submit"
              className="checkout-continue"
              onClick={() => {
                this.onPaymentModeSelected(
                  this.state.paymentModeSelectedIndex,
                  this.state.sale
                );
              }}
            >
              {this.state.billBalanceAmount > 0
                ? "PROCEED TO PAY ₹" + this.state.billBalanceAmount
                : "PLACE ORDER"}
            </div>
          </div>
        )}

        {this.state.isLoading && this.state.paymentModeSelectedIndex !== -1 && (
          <div className="checkout">
            {/* <div className="checkout-header">
                    <div className="menu-close-btn"  onClick={() =>{this.setState({showPaymentModeSelection:false,paymentModeSelectedIndex:-1,loading:false})}}>
                        <svg width="24px" viewBox="0 0 511.991 511.991"><g><g><path d="M153.433,255.991L381.037,18.033c4.063-4.26,3.917-11.01-0.333-15.083c-4.229-4.073-10.979-3.896-15.083,0.333    L130.954,248.616c-3.937,4.125-3.937,10.625,0,14.75L365.621,508.7c2.104,2.188,4.896,3.292,7.708,3.292    c2.646,0,5.313-0.979,7.375-2.958c4.25-4.073,4.396-10.823,0.333-15.083L153.433,255.991z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                        <span>PROCESSING PAYMENT</span>
                    </div>
                </div> */}
            <CheckOutHeader
              title="PROCESSING SELECTION"
              onClose={() => {
                this.setState({
                  showPaymentModeSelection: false,
                  paymentModeSelectedIndex: -1,
                  loading: false,
                });
              }}
            />
            <div className="container ">
              <div className="checkout-payment-processing">Please wait!.</div>
              <Spinner />
            </div>
          </div>
        )}
        {this.state.successDisplay && (
          <div className="checkout">
            {/* <div className="checkout-header">
                    <div className="menu-close-btn"  onClick={() =>this.props.history.push(mainWebsitePath())}>
                        <svg width="24px" viewBox="0 0 511.991 511.991"
                        ><g><g><path d="M153.433,255.991L381.037,18.033c4.063-4.26,3.917-11.01-0.333-15.083c-4.229-4.073-10.979-3.896-15.083,0.333    L130.954,248.616c-3.937,4.125-3.937,10.625,0,14.75L365.621,508.7c2.104,2.188,4.896,3.292,7.708,3.292    c2.646,0,5.313-0.979,7.375-2.958c4.25-4.073,4.396-10.823,0.333-15.083L153.433,255.991z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                        <span>ORDER PLACED</span>
                    </div>
                </div> */}
            <CheckOutHeader
              title="ORDER PLACED"
              onClose={() => this.props.history.push(mainWebsitePath())}
            />

            <div className="container ">
              <div className="checkout-mobile-success-container">
                {this.renderCheckoutSuccess()}
              </div>
            </div>
          </div>
        )}
        {this.state.errorDisplay && (
          <div className="checkout">
            {/* <div className="checkout-header">
                    <div className="menu-close-btn"  onClick={() =>this.props.history.push(mainWebsitePath())}>
                        <svg width="24px" viewBox="0 0 511.991 511.991"
                        ><g><g><path d="M153.433,255.991L381.037,18.033c4.063-4.26,3.917-11.01-0.333-15.083c-4.229-4.073-10.979-3.896-15.083,0.333    L130.954,248.616c-3.937,4.125-3.937,10.625,0,14.75L365.621,508.7c2.104,2.188,4.896,3.292,7.708,3.292    c2.646,0,5.313-0.979,7.375-2.958c4.25-4.073,4.396-10.823,0.333-15.083L153.433,255.991z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                        <span>ORDER FAILED</span>
                    </div>
                </div> */}
            <CheckOutHeader
              title="ORDER FAILED"
              onClose={() => this.props.history.push(mainWebsitePath())}
            />

            <div className="container ">
              <div className="checkout-mobile-success-container">
                {this.renderCheckoutError()}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderCustomerMobileNoAndEmailIDEntry() {
    return (
      <React.Fragment>
        {(this.state.emailIDEntry || this.state.mobileNoEntry) && (
          <React.Fragment>
            <Input
              type="label"
              label="Name"
              value={this.props.customer.FirstName}
            />
            <Input
              label="Mobile No"
              type={this.state.mobileNoEntry ? "mobileno" : "label"}
              value={this.state.mobileNo}
              onChange={(value) =>
                this.setState({ mobileNo: value, mobileNoError: "" })
              }
              error={this.state.mobileNoError}
            />
            <Input
              label="Email Id"
              type={this.state.emailIDEntry ? "text" : "label"}
              value={this.state.emailID}
              onChange={(value) =>
                this.setState({ emailID: value, emailIDError: "" })
              }
              error={this.state.emailIDError}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderCustomerDetails() {
    return (
      <div className="checkout-mycart">
        {!this.props.customer && (
          <div className="">
            <div className="page-sub-title">Login / Signup</div>
            <button
              className="btn btn-flat checkout-login-signup-btn"
              onClick={() => {
                this.setState({ showLogin: true });
              }}
            >
              Login or Signup
            </button>
            {this.state.customerLoginError !== "" && (
              <div className="error-msg">{this.state.customerLoginError}</div>
            )}
            {this.state.showLogin && (
              <Login
                returnLoginStatus={true}
                onCustomerLogin={() => {
                  this.setState({ showLogin: false });
                }}
              />
            )}
          </div>
        )}

        {this.props.customer && (
          <div className="">
            <div className="page-sub-title">Customer Details</div>
            {!this.state.emailIDEntry && !this.state.mobileNoEntry && (
              <React.Fragment>
                <div>
                  Name: {this.props.customer.FirstName}{" "}
                  {this.props.customer.LastName}
                </div>
                <div>Mobile No: {this.props.customer.PhoneNo}</div>
                <div>Email Id: {this.props.customer.EmailID}</div>
              </React.Fragment>
            )}
            {this.renderCustomerMobileNoAndEmailIDEntry()}
          </div>
        )}

        {this.state.showAppointmentOptions && (
          <div className="">
            <div className="page-sub-title">
              Please select appointment options
            </div>
            {this.renderAppointmentOptions()}
          </div>
        )}

        {this.props.config &&
          this.props.config.CheckoutConfig &&
          this.props.config.CheckoutConfig.ShowBillingAddress && (
            <div className="">
              <div className="page-sub-title">Delivery Address</div>
              {this.props.customer && (
                <CustomerAddress
                  selection={true}
                  addresses={this.props.customer.Addresses}
                  onAddressSelection={(address, index) => {
                    this.setState({
                      selectedAddress: address,
                      addressSelectionError: "",
                    });
                  }}
                  onAddressUpdated={(addresses) =>
                    this.updateAddress(addresses)
                  }
                />
              )}
              {this.state.addressSelectionError !== "" && (
                <div className="error-msg">
                  {this.state.addressSelectionError}
                </div>
              )}
            </div>
          )}

        {this.state.shippingOption &&
          this.state.shippingOption.SpecifyPickupDate && (
            <div className="">
              <div className="page-sub-title">Delivery Time</div>
              <div className="row">{this.renderDeliveryTime()}</div>
            </div>
          )}

        {this.state.enableRemarks && (
          <React.Fragment>
            <div className="cart-seperator" />
            <Input
              type="textarea"
              label={this.state.remarksText}
              value={this.state.remarks}
              onChange={(value) => this.setState({ remarks: value })}
            />
          </React.Fragment>
        )}

        {this.props.config.CheckoutConfig.hasOwnProperty("PaymentModes") &&
          this.props.config.CheckoutConfig.PaymentModes.length > 1 && (
            <div className="">
              <div className="page-sub-title">Payment Options</div>
              {this.state.paymentModeSelectionError !== "" && (
                <div className="error-msg">
                  {this.state.paymentModeSelectionError}
                </div>
              )}
              {this.renderPaymentOptions()}
            </div>
          )}
        <div>
          <div className="row">
            {this.state.checkoutOptionStep === "2" && (
              <React.Fragment>
                <div className="col-md-6">
                  <button
                    onClick={() => {
                      this.setState({ checkoutOptionStep: "1" });
                    }}
                    className="btn btn-flat back-to-cart"
                  >
                    Back to cart
                  </button>
                </div>
                <div className="col-md-6">
                  <button
                    disabled={this.state.isLoading}
                    onClick={() => {
                      if (!this.state.isLoading) {
                        this.onSubmitClick();
                      }
                    }}
                    className="btn btn-flat place-order"
                  >
                    {this.state.isLoading && (
                      <React.Fragment>
                        <i className="fa fa-spinner fa-spin"></i> Please wait
                      </React.Fragment>
                    )}
                    {!this.state.isLoading && (
                      <React.Fragment>Place order</React.Fragment>
                    )}
                  </button>
                </div>
              </React.Fragment>
            )}
            {this.state.checkoutOptionStep === "1" && (
              <React.Fragment>
                <div className="col-md-12">
                  <button
                    disabled={this.state.isLoading}
                    onClick={() => {
                      if (!this.state.isLoading) {
                        this.onSubmitClick();
                      }
                    }}
                    className="btn btn-flat place-order"
                  >
                    {this.state.isLoading && (
                      <React.Fragment>
                        <i className="fa fa-spinner fa-spin"></i> Please wait
                      </React.Fragment>
                    )}
                    {!this.state.isLoading && (
                      <React.Fragment>Place order</React.Fragment>
                    )}
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderDesktop() {
    return (
      <React.Fragment>
        <Header />

        <div className="container">
          {this.state.successDisplay && (
            <div className="col-md-12">
              <div className="checkout-mycart">
                {/* <div className="page-sub-title">Oeder placed successfully</div> */}
                <div className="checkout-success-msg-desktop">
                  {this.renderCheckoutSuccess()}
                </div>
              </div>
            </div>
          )}

          {this.state.errorDisplay && (
            <div className="col-md-12">
              <div className="checkout-mycart">
                <div className="checkout-success-msg-desktop">
                  {this.renderCheckoutError()}
                </div>
              </div>
            </div>
          )}

          {this.props.lineItems.length === 0 && (
            <div className="row">
              {!this.state.successDisplay && !this.state.errorDisplay && (
                <div className="col-md-12">
                  <div className="checkout-mycart">
                    <div className="page-sub-title">My Cart</div>
                    <Cart
                      shippingOption={this.state.shippingOption}
                      deliveryCharge={this.state.deliveryCharge}
                      packagingCharge={this.state.packagingCharge}
                      billAmount={this.state.billAmount}
                      coupon={this.state.coupon}
                      onLineItemUpdate={this.onLineItemUpdate}
                      onApplyCoupon={this.onApplyCoupon}
                      onRemoveCoupon={this.onRemoveCoupon}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {this.props.lineItems.length > 0 &&
            !this.state.successDisplay &&
            !this.state.errorDisplay && (
              <div className="row">
                {this.state.checkoutOption === "1" && (
                  <React.Fragment>
                    <div className="col-md-8">
                      {this.renderCustomerDetails()}
                    </div>
                    <div className="col-md-4">
                      <div className="checkout-mycart">
                        <div className="page-sub-title">My Cart</div>
                        <Cart
                          shippingOption={this.state.shippingOption}
                          deliveryCharge={this.state.deliveryCharge}
                          packagingCharge={this.state.packagingCharge}
                          billAmount={this.state.billAmount}
                          coupon={this.state.coupon}
                          onLineItemUpdate={this.onLineItemUpdate}
                          onApplyCoupon={this.onApplyCoupon}
                          onRemoveCoupon={this.onRemoveCoupon}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
                {this.state.checkoutOption === "2" && (
                  <React.Fragment>
                    <div className="col-md-12">
                      {this.state.checkoutOptionStep === "1" && (
                        <div className="checkout-mycart">
                          <div className="page-sub-title">My Cart</div>
                          <Cart
                            shippingOption={this.state.shippingOption}
                            deliveryCharge={this.state.deliveryCharge}
                            packagingCharge={this.state.packagingCharge}
                            billAmount={this.state.billAmount}
                            coupon={this.state.coupon}
                            onLineItemUpdate={this.onLineItemUpdate}
                            onApplyCoupon={this.onApplyCoupon}
                            onRemoveCoupon={this.onRemoveCoupon}
                          />
                          <div className="row">
                            <div className="col-md-6">
                              <button
                                onClick={() => {
                                  this.props.history.push(mainWebsitePath());
                                }}
                                className="btn btn-flat continue-shopping"
                              >
                                Continue shopping
                              </button>
                            </div>
                            <div className="col-md-6">
                              <button
                                onClick={() => {
                                  this.setState({ checkoutOptionStep: "2" });
                                }}
                                className="btn btn-flat place-order"
                              >
                                Proceed to checkout
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.checkoutOptionStep === "2" && (
                        <React.Fragment>
                          {this.renderCustomerDetails()}

                          {this.renderAppointmentOptions()}
                        </React.Fragment>
                      )}

                      {this.renderAppointmentOptions()}
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
        </div>
        <PageFooter />
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showWalletReedemption && this.renderWalletRedemption()}

        {this.state.showLoyaltyRedemption && this.renderLoyaltyRedemption()}

        {isMobile() && this.renderMobileNew()}

        {!isMobile() && this.renderDesktop()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  lineItems: state.cart.lineItems,
  lineItemTotal: state.cart.lineItemTotal,
  config: state.api.config,
  // coupon: state.cart.coupon,
  productTaxes: state.api.productTaxes,
  customer: state.api.customer,
  employee: state.api.employee,
  deliveryProduct: state.api.deliveryProduct,
  packagingProduct: state.api.packagingProduct,
  customerGeoLocation: state.api.customerGeoLocation,
  store: state.api.store,
});

export default connect(mapStateToProps, {
  loadCart,
  loadCartTotal,
  updateCoupon,
  setCustomer,
})(withRouter(Checkout));
