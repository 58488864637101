import React,{Component} from "react";
import Product from "./Product";
import { isMobile } from "../../services/util";

class ProductList  extends Component {

    returnProductClass(){
        if(this.props.displayMode==="" || this.props.displayMode==="listthumbnail")
        {
            // listthumbnail
            if(isMobile()){
                return "col-12";
            }
            else
            {
                return "col-3";
            }
        }
        else if(this.props.displayMode==="list" || this.props.displayMode==="searchlist" || this.props.displayMode==="detailpage")
        {
            return "col-12";
        }
        else if(this.props.displayMode==="thumbnail" || this.props.displayMode==="thumbnailscroll")
        {
            if(isMobile()){
                return "col-6";
            }
            else
            {
                return "col-3";
            }
        }
        // else if(this.props.displayMode==="detailpage")
        // {
        //     return "col-12";
        // }
    }

    renderStyle(){
        if(this.props.displayMode==="thumbnailscroll")
        {
            return {
                flexDirection: "row",
                overflowX: "auto",
                display: "flex"
            }
        }
    }
    render(){
        
        let products = [];
        products = this.props.products.filter((f) =>!(f.hasOwnProperty("ParentProductID") && f.ParentProductID !== ""))
        products.sort(function(a,b){
            let first =1 ;
            let second = 1;
            if(a.hasOwnProperty("SequenceNo") && a.SequenceNo !==""){
                first = a.SequenceNo;
                console.log('first',first)
            }
            if(b.hasOwnProperty("SequenceNo") && b.SequenceNo !==""){
                second = b.SequenceNo;
                console.log('second',second)
            }
            return first - second;
        })
        console.log('products',products);

        return (
            <div className={this.props.displayMode==="thumbnailscroll" ? "" :"row"} style={this.renderStyle()}>
                {products.map((p) => {
                        return (
                            <div className={this.returnProductClass()} 
                                key={"col_product_" + p.key}>
                                <Product
                                    product={p}
                                    key={p.key}
                                    displayMode={this.props.displayMode ==="" ? "listthumbnail" : this.props.displayMode}
                                    onEvent={this.props.onEvent}
                                />
                            </div>
                        );
                    })}
            </div>
        );
    }
}
// const ProductList = ({ products, displayMode, onEvent }) => {

// };

export default ProductList;

// listthumbnail
// list
// thumbnail
// thumbnailscroll
//searchlist
//detailpage
//list-no-image


// style={{
//     display: "flex",
//     flexFlow: "row wrap",
//     justifyContent: "space-around"
// }}

// return (
//     <div 
//     className="products"
//     // className="row"
    
//     >
//         {products.filter((f) =>!(f.hasOwnProperty("ParentProductID") && f.ParentProductID !== "")).map((p) => {
//                 return (
//                     <div
//                         // className={(displayMode === "list" ||displayMode === "searchlist")? "col-md-12": "col-md-3 product-display"}
//                         className="product-container"
//                         key={"div" + p.key}
//                     >
//                         <Product
//                             product={p}
//                             key={p.key}
//                             // displayMode={displayMode}
//                             displayMode={"thumbnail"}

//                             onEvent={onEvent}
//                         />
//                     </div>
//                 );
//             })}
//     </div>
// );