
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchProducts } from '../../services/util';
import Spinner from '../Spinner';
import ProductList from '../ProductList';
import PageFooter from '../PageFooter';

class Level2 extends Component {

    static propTypes = {
        menu: PropTypes.array.isRequired,
        config: PropTypes.object.isRequired,
        fetchMenu: PropTypes.func.isRequired
    };

    state = {
        products: [],
        parentID: '',
        childID: '',
        isLoading: true,
        from: 0,
        size: 50,
        searchText: ''
    }

    async componentDidMount() {
        if (this.props.match.params.name.toLowerCase() !== "search") {
            let products = []
            products = await searchProducts(this.props.config.key, this.state.from, this.state.size, this.props.match.params.name.toLowerCase())
            // console.log(products);
            this.setState({ products: products, isLoading: false, searchText: this.props.match.params.name.toLowerCase() })
        }
        else
        {
            this.setState({  isLoading: false, products: [], searchText: '' })
        }
    }


    async componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.name.toLowerCase() !== "search") {
            let products = []
            products = await searchProducts(this.props.config.key, this.state.from, this.state.size, nextProps.match.params.name.toLowerCase())
            this.setState({ products: products, isLoading: false, searchText: nextProps.match.params.name.toLowerCase() })
        }
        else
        {
            this.setState({  isLoading: false, products: [], searchText: '' })
        }
    }

    async onSearch()
    {
        let products = []
        products = await searchProducts(this.props.config.key, this.state.from, this.state.size, this.state.searchText)
        this.setState({ products: products, isLoading: false })
    }

    render() {
        const { isLoading } = this.state;
        const { products } = this.state;

        return <React.Fragment>
            {isLoading && <Spinner />}
            {!isLoading && <React.Fragment>
                <div className="container">
                    <div className="section-title">Search Product(s)</div>
                    <br />
                    <div className="col-md-12">
                        <div className="btn-group" style={{width: "100%"}}>
                            <input placeholder="search" value={this.state.searchText} onChange={(e) => this.setState({ searchText: e.target.value })} class="form-control" style={{ marginRight: "5px" }} />
                            <svg onClick={() => { this.onSearch()

                            }} height="20px" role="img" viewBox="0 0 512 512" width="20px" xmlns="http://www.w3.org/2000/svg" class="m-auto"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" fill="currentColor"></path></svg>
                        </div>
                    </div>
                    <br />
                    <br />
                    <ProductList products={products} />
                </div>
                <br />
                <PageFooter config={this.props.config} />
            </React.Fragment>}
        </React.Fragment>
    };
}


const mapStateToProps = state => ({
    menu: state.api.menu,
    config: state.api.config
});

export default connect(
    mapStateToProps,
)(Level2);
