import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";

import Main from "../Main";
import ErrorMsg from "../ErrorMsg";
import Spinner from "../Spinner";
import {
  fetchLatestCarousels,
  fetchConfig,
  fetchMenu,
  fetchProductTaxes,
  fetchCustomFields,
  fetchPages,
  fetchDeliveryProduct,
  fetchPackagingProduct,
} from "../../services/api/actions";
import {
  fetchBusinessNameByDomainName,
  mainWebsitePath,
  getCustomerFromLocalStorage,
  isMobile,
} from "../../services/util";
import SplashScreen from "../SplashScreen";
// import ReactGA from 'react-ga'

class App extends Component {
  static propTypes = {
    fetchConfig: PropTypes.func.isRequired,
    fetchMenu: PropTypes.func.isRequired,
    fetchProductTaxes: PropTypes.func.isRequired,
    fetchPages: PropTypes.func.isRequired,
    fetchDeliveryProduct: PropTypes.func.isRequired,
    config: PropTypes.object,
  };

  state = {
    merchantExists: false,
    isLoading: false,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    let hostName = window.location.hostname;
    let businessName = "";
    let mainWebsitePath1 = mainWebsitePath();
    if (mainWebsitePath1 === "/") {
      //brand domain provided pls check with that
      businessName = await fetchBusinessNameByDomainName(hostName);
    } else if (mainWebsitePath1 === "/") {
      //brand domain provided pls check with that
      businessName = await fetchBusinessNameByDomainName(hostName);
    } else {
      let path = window.location.pathname;
      let pathParams = path.split("/");
      if (pathParams.length > 1 && pathParams[1] !== "") {
        businessName = pathParams[1];
      }
    }
    // console.log('businessName', businessName)
    if (businessName === "") {
      // console.log('bsuiness name not found')
      this.setState({ isLoading: false, merchantExists: false });
      return;
    }
    await this.props.fetchConfig(businessName);
    console.log("this.props.config", this.props.config);
    if (!this.props.config) {
      this.setState({ isLoading: false, merchantExists: false });
      return;
    }
    document.title = this.props.config.CheckoutConfig.AppTitle;
    if (
      this.props.config.CheckoutConfig.hasOwnProperty("PrimaryColor") &&
      this.props.config.CheckoutConfig.PrimaryColor
    ) {
      var r = document.querySelector(":root");
      r.style.setProperty(
        "--clr",
        this.props.config.CheckoutConfig.PrimaryColor
      );
    }
    let shippingOption = this.props.config.CheckoutConfig.hasOwnProperty(
      "ShippingOption"
    )
      ? this.props.config.CheckoutConfig.ShippingOption
      : null;
    console.log("shippingOption", shippingOption);
    if (shippingOption) {
      if (shippingOption.ShippingChargesServiceID !== "") {
        this.props.fetchDeliveryProduct(
          shippingOption.ShippingChargesServiceID
        );
      }
    }
    if (
      this.props.config.CheckoutConfig.hasOwnProperty(
        "PackagingChargesServiceID"
      ) &&
      this.props.config.CheckoutConfig.PackagingChargesServiceID !== ""
    ) {
      this.props.fetchPackagingProduct(
        this.props.config.CheckoutConfig.PackagingChargesServiceID
      );
    }
    if (this.props.config.CheckoutConfig.hasOwnProperty("CustomCSS")) {
      const style = document.createElement("style");
      style.textContent = this.props.config.CheckoutConfig.CustomCSS;
      document.head.appendChild(style);
    }
    let styles = document.createElement("link");
    styles.rel = "stylesheet";
    styles.type = "text/css";
    styles.media = "screen";
    // styles.href = 'https://firebasestorage.googleapis.com/v0/b/posible.in/o/1OMNICSS%2F' + (this.props.config.CheckoutConfig.hasOwnProperty('CSS')  && this.props.config.CheckoutConfig.CSS !=="" ? this.props.config.CheckoutConfig.CSS : "omni.css")+ '?alt=media';
    styles.href =
      this.props.config.CheckoutConfig.hasOwnProperty("CSS") &&
      this.props.config.CheckoutConfig.CSS !== ""
        ? this.props.config.CheckoutConfig.CSS
        : "https://firebasestorage.googleapis.com/v0/b/posible.in/o/1OMNICSS%2Fomni.css?alt=media&token=c80487e9-2074-4450-a6d6-48740e602935";
    document.getElementsByTagName("head")[0].appendChild(styles);

    // property="og:locale"
    // property="og:type"

    // <meta name="description" content="Here is a precise description of my awesome webpage.">
    if (this.props.config.CheckoutConfig.MetaDescription) {
      var metaDescription = document.createElement("meta");
      metaDescription.setAttribute("name", "description");
      metaDescription.content =
        this.props.config.CheckoutConfig.MetaDescription;
      document.getElementsByTagName("head")[0].appendChild(metaDescription);
    }

    if (this.props.config.CheckoutConfig.MetaKeywords) {
      var metaKeywords = document.createElement("meta");
      metaKeywords.setAttribute("name", "keywords");
      metaKeywords.content = this.props.config.CheckoutConfig.MetaKeywords;
      document.getElementsByTagName("head")[0].appendChild(metaKeywords);
    }

    var metalocale = document.createElement("meta");
    metalocale.setAttribute("property", "og:locale");
    metalocale.content = "en_US";
    document.getElementsByTagName("head")[0].appendChild(metalocale);

    var metatype = document.createElement("meta");
    metatype.setAttribute("property", "og:type");
    metatype.content = "website";
    document.getElementsByTagName("head")[0].appendChild(metatype);

    var metaTitle = document.createElement("meta");
    metaTitle.setAttribute("property", "og:title");
    metaTitle.content = this.props.config.CheckoutConfig.AppTitle;
    document.getElementsByTagName("head")[0].appendChild(metaTitle);

    var metaDescription = document.createElement("meta");
    metaDescription.setAttribute("property", "og:description");
    metaDescription.content = this.props.config.CheckoutConfig.AppTitle;
    document.getElementsByTagName("head")[0].appendChild(metaDescription);

    var metaURL = document.createElement("meta");
    metaURL.setAttribute("property", "og:url");
    metaURL.content = "https://" + window.location.hostname;
    document.getElementsByTagName("head")[0].appendChild(metaURL);

    //icon
    if (isMobile()) {
      if (
        this.props.config.CheckoutConfig &&
        this.props.config.CheckoutConfig.AppIcon &&
        this.props.config.CheckoutConfig.AppIcon.Preview !== ""
      ) {
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = this.props.config.CheckoutConfig.AppIcon.Preview;

        var metaImage = document.createElement("meta");
        metaImage.setAttribute("property", "og:image");
        metaImage.content = this.props.config.CheckoutConfig.AppIcon.Preview;
        document.getElementsByTagName("head")[0].appendChild(metaImage);
      }
    } else {
      if (
        this.props.config.CheckoutConfig &&
        this.props.config.CheckoutConfig.WebsiteImageIcon &&
        this.props.config.CheckoutConfig.WebsiteImageIcon.Preview !== ""
      ) {
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = this.props.config.CheckoutConfig.WebsiteImageIcon.Preview;

        var metaImage = document.createElement("meta");
        metaImage.setAttribute("property", "og:image");
        metaImage.content =
          this.props.config.CheckoutConfig.WebsiteImageIcon.Preview;
        document.getElementsByTagName("head")[0].appendChild(metaImage);
      }
    }
    // if (this.props.config.CheckoutConfig &&
    //     this.props.config.CheckoutConfig.AppLogo &&
    //     this.props.config.CheckoutConfig.AppLogo.Preview !== "") {
    //     var link = document.querySelector("link[rel~='icon']");
    // if (!link) {
    //     link = document.createElement("link");
    //     link.rel = "icon";
    //     document.getElementsByTagName("head")[0].appendChild(link);
    // }
    // link.href = this.props.config.CheckoutConfig.AppLogo.Preview;

    // var metaImage = document.createElement("meta");
    // metaImage.setAttribute("property", "og:image");
    // metaImage.content = this.props.config.CheckoutConfig.AppLogo.Preview;
    // document.getElementsByTagName("head")[0].appendChild(metaImage);
    // console.log('this.props.config.CheckoutConfig.AppLogo',this.props.config.CheckoutConfig.AppLogo)
    // }
    //meta for sharing
    // <meta property="og:title" content="European Travel Destinations">
    // <meta property="og:description" content="Offering tour packages for individuals or groups.">
    // <meta property="og:image" content="http://euro-travel-example.com/thumbnail.jpg">
    // <meta property="og:url" content="http://euro-travel-example.com/index.htm">

    if (
      this.props.config.CheckoutConfig.GA &&
      this.props.config.CheckoutConfig.GA.trim() !== ""
    ) {
      //  console.log('google analytics',this.props.config.CheckoutConfig.GA);
      //  ReactGA.initialize(this.props.config.CheckoutConfig.GA)
      //  ReactGA.pageview(window.location.pathname + window.location.search);

      const script = document.createElement("script");
      script.src =
        "https://www.googletagmanager.com/gtag/js?id=" +
        this.props.config.CheckoutConfig.GA;
      script.async = true;
      document.body.appendChild(script);

      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.innerHTML =
        "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '" +
        this.props.config.CheckoutConfig.GA +
        "');";
      document.body.appendChild(s);
    }
    await this.props.fetchMenu(this.props.config.BusinessUniqueName);
    await this.props.fetchLatestCarousels(this.props.config.key);
    this.props.fetchProductTaxes(this.props.config.key);
    this.props.fetchCustomFields(this.props.config.key);
    this.props.fetchPages(this.props.config.key);
    setTimeout(
      function () {
        this.setState({
          isLoading: false,
          merchantExists: true,
        });
      }.bind(this),
      1000
    );
  }

  render() {
    const { isLoading } = this.state;
    const { merchantExists } = this.state;
    return (
      <Router>
        <React.Fragment>
          {/* {isLoading && <Spinner />} */}
          {isLoading && <SplashScreen />}
          {!isLoading && !merchantExists && <ErrorMsg errorCode="1" />}
          {merchantExists && <Main />}
        </React.Fragment>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.api.config,
});

export default connect(mapStateToProps, {
  fetchLatestCarousels,
  fetchConfig,
  fetchMenu,
  fetchProductTaxes,
  fetchCustomFields,
  fetchPages,
  fetchDeliveryProduct,
  fetchPackagingProduct,
})(App);

// export default withRouter(connect(mapStateToProps,{ })(Footer));
