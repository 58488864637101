import {
  SET_STORE,
  FETCH_PACKAGING_PRODUCT,
  SET_CUSTOMER_GEO_LOCATION,
  SET_PINCODE,
  FETCH_EMPLOYEE,
  FETCH_MENU,
  FETCH_CONFIG,
  FETCH_PRODUCT_TAXES,
  FETCH_CUSTOM_FIELDS,
  FETCH_PAGES,
  SET_CUSTOMER,
  FETCH_CAROUSELS,
  FETCH_DELIVERY_PRODUCT,
} from "./actionTypes";
import axios from "axios";
import {
  employeeAPI,
  menuAPI,
  configAPI,
  productTaxesAPI,
  customFieldsAPI,
  pagesAPI,
  carouselAPI,
  deliveryServiceAPI,
} from "../util";

export const setStore = (store) => (dispatch) => {
  return dispatch({
    type: SET_STORE,
    payload: store,
  });
};

export const setPincode = (pincode) => (dispatch) => {
  return dispatch({
    type: SET_PINCODE,
    payload: pincode,
  });
};

export const setCustomerGeoLocation = (customerGeoLocation) => (dispatch) => {
  return dispatch({
    type: SET_CUSTOMER_GEO_LOCATION,
    payload: customerGeoLocation,
  });
};

export const fetchLatestCarousels = (key) => (dispatch) => {
  return axios
    .post(carouselAPI, { key: key })
    .then((res) => {
      let { carousels } = res.data;
      // console.log('carousels',carousels)
      return dispatch({
        type: FETCH_CAROUSELS,
        payload: carousels,
      });
    })
    .catch((err) => {
      console.log("Could not fetch carousels. Try again later.");
    });
};

export const fetchMenu = (businessUniqueName) => (dispatch) => {
  return axios
    .post(menuAPI, { from: 0, size: 1000, uniqueName: businessUniqueName })
    .then((res) => {
      let { menu } = res.data;
      // console.log('fetchCategories', menu)
      return dispatch({
        type: FETCH_MENU,
        payload: menu,
      });
    })
    .catch((err) => {
      console.log("Could not fetch categories. Try again later.");
    });
};

export const fetchConfig = (businessUniqueName) => (dispatch) => {
  return axios
    .post(configAPI, { uniqueName: businessUniqueName, apiVersion: "1.2" })
    .then((res) => {
      let { config } = res.data;
      // console.log(res.data);
      return dispatch({
        type: FETCH_CONFIG,
        payload: config,
      });
    })
    .catch((err) => {
      console.log("Could not fetch config. Try again later.");
    });
};

export const setConfig = (config) => (dispatch) => {
  return dispatch({
    type: FETCH_CONFIG,
    payload: config,
  });
};

export const fetchProductTaxes = (key) => (dispatch) => {
  return axios
    .post(productTaxesAPI, { RegistrationID: key })
    .then((res) => {
      let { productTaxes } = res.data;
      // console.log('fetchProductTaxes productTaxes',productTaxes)
      return dispatch({
        type: FETCH_PRODUCT_TAXES,
        payload: productTaxes,
      });
    })
    .catch((err) => {
      console.log("Could not fetch product taxes. Try again later.");
    });
};

export const fetchCustomFields = (key) => (dispatch) => {
  return axios
    .post(customFieldsAPI, { RegistrationID: key })
    .then((res) => {
      let { customFields } = res.data;
      // console.log('customFields', customFields)
      return dispatch({
        type: FETCH_CUSTOM_FIELDS,
        payload: customFields,
      });
    })
    .catch((err) => {
      console.log("Could not fetch custom fields. Try again later.");
    });
};

export const fetchPages = (key) => (dispatch) => {
  return axios
    .post(pagesAPI, { RegistrationID: key })
    .then((res) => {
      let { omniPages } = res.data;
      // console.log('pages', omniPages)
      return dispatch({
        type: FETCH_PAGES,
        payload: omniPages,
      });
    })
    .catch((err) => {
      console.log("Could not fetch pages. Try again later.");
    });
};

export const setCustomer = (registrationID, customer) => (dispatch) => {
  // console.log(customer.key)
  // window.localStorage.setItem(registrationID + "customerkey1", customer ? customer.key : null);
  return dispatch({
    type: SET_CUSTOMER,
    payload: customer,
  });
};

export const fetchEmployee = (employeeCode, registrationID) => (dispatch) => {
  console.log("employeeCode", employeeCode);
  console.log("registrationID", registrationID);
  return axios
    .post(employeeAPI, {
      EmployeeCode: employeeCode,
      RegistrationID: registrationID,
    })
    .then((res) => {
      console.log("res.data", res.data);
      let { employee } = res.data;
      console.log("employee", employee);
      return dispatch({
        type: FETCH_EMPLOYEE,
        payload: employee,
      });
    })
    .catch((err) => {
      console.log("Could not fetch employee. Try again later.");
    });
};

export const fetchDeliveryProduct = (productID) => (dispatch) => {
  return axios
    .post(deliveryServiceAPI, { productID: productID })
    .then((res) => {
      console.log("res.data.product", res.data);
      let deliveryProduct = res.data.product;
      return dispatch({
        type: FETCH_DELIVERY_PRODUCT,
        payload: deliveryProduct,
      });
    })
    .catch((err) => {
      console.log("Could not fetch delivery product. Try again later.");
    });
};

export const fetchPackagingProduct = (productID) => (dispatch) => {
  return axios
    .post(deliveryServiceAPI, { productID: productID })
    .then((res) => {
      let packagingProduct = res.data.product;
      return dispatch({
        type: FETCH_PACKAGING_PRODUCT,
        payload: packagingProduct,
      });
    })
    .catch((err) => {
      console.log("Could not fetch delivery product. Try again later.");
    });
};
