import React, { Component } from 'react';
import Window from '../Common/Window';
import { connect } from 'react-redux';
import Input from '../Common/Input';
import {cancelOrder} from '../../services/util';

class CancelOrder extends Component {
    
    constructor()
    {
        super();
        this.state={
            reason:'',
            remarks:'',
            reasonError:'',
            processing:false
        }
        this.onCancelOrder= this.onCancelOrder.bind(this);
    }

    async onCancelOrder()
    {
        this.setState({processing:true})
        if(this.props.config.CheckoutConfig.OrderCancellationReasons.length >0)
        {
            if(this.state.reason  ==="")
            {
                this.setState({reasonError:'Please select reason',processing:false});
                return;
            }
        }
        let cancellationStatus = await cancelOrder(this.props.orderKey, this.state.reason, this.state.remarks )
        // console.log('cancellationStatus', cancellationStatus)
        if(cancellationStatus)
        {
            this.props.onClose(true)
        }
        else
        {
            this.props.onClose(false)
        }
        this.setState({processing:false})
    }

    render()
    {
        return <React.Fragment>
            <Window onClose={()=>this.props.onClose(false)} 
                header={"Cancel order"}  
                processing={this.state.processing}
                onButtonClick={this.onCancelOrder}
                buttons={[{label:"Cancel Order"}]}>

                {this.props.config.CheckoutConfig.OrderCancellationReasons.length ===0 &&  <div>Are you sure you wish to cancel this order</div>}
                
                {this.props.config.CheckoutConfig.OrderCancellationReasons.length >0 &&  <Input 
                    name="cancellationreason"
                    label="Please select reason for cancellation"
                    error = {this.state.reasonError}
                    onChange={(value)=> this.setState({reason:value, reasonError:''}) }
                    type={"radiolist"}>
                    {this.props.config.CheckoutConfig.OrderCancellationReasons.map((reason,index)=><option text={reason.Reason}/>)}
                </Input>}


                {this.props.config.CheckoutConfig.OrderCancellationCustomerRemarks &&  <Input 
                    label="Remarks"
                    onChange={(value)=> this.setState({remarks:value})}
                    type={"textarea"}>
                </Input>}
                <br/>
                <br/>

            </Window>
        </React.Fragment>
    }
}

const mapStateToProps = state => ({
    config: state.api.config,
});

export default connect(
    mapStateToProps
)(CancelOrder);