import React, { Component } from 'react';
import { isMobile } from '../../services/util';
import './common.css';
import Icon from './Icon';
import  './Window.css';

class Window extends Component {

    width(){
        if(isMobile() && !this.props.modal)
        {
            return "100%";
        }
        else if(this.props.width){
            return this.props.width;
        }
        else
        {
            return "400px";
        }
    }

    height(){
        if(isMobile() && !this.props.modal)
        {
            return "100%";
        }
        else if(this.props.height){
            return this.props.height;
        }
        else
        {
            return "auto";
        }
    }

    contentHeight(){
        if(isMobile())
        {
            return "100%";
        }
        else{
            return "auto";
        }
        // else if(this.props.height){
        //     return this.props.height;
        // }
        // else
        // {
        //     return "400px";
        // }
    }

    renderCloseButton()
    {
        if(this.props.hasOwnProperty('hideClose') && this.props.hideClose){
            return ""
        }
        if(isMobile() && !this.props.modal)
        {
            return <div className={this.returnClassName("window-close-btn")} onClick={()=>{this.props.onClose();}}>
                    <Icon type="back"/>
                    {/* <svg 
                    onClick={()=>{this.props.onClose();}}
                    xmlns="http://www.w3.org/2000/svg"  
                    width="24px" viewBox="0 0 511.991 511.991" ><g><g><path d="M153.433,255.991L381.037,18.033c4.063-4.26,3.917-11.01-0.333-15.083c-4.229-4.073-10.979-3.896-15.083,0.333    L130.954,248.616c-3.937,4.125-3.937,10.625,0,14.75L365.621,508.7c2.104,2.188,4.896,3.292,7.708,3.292    c2.646,0,5.313-0.979,7.375-2.958c4.25-4.073,4.396-10.823,0.333-15.083L153.433,255.991z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>   */}
            </div>
        }
        else
        {
            return <div className={this.returnClassName("window-close-btn")}
            onClick={()=>{this.props.onClose();}}>
                <Icon type="back"/>
                {/* <svg viewBox="0 0 496.096 496.096" ><g><g><path d="M259.41,247.998L493.754,13.654c3.123-3.124,3.123-8.188,0-11.312c-3.124-3.123-8.188-3.123-11.312,0L248.098,236.686
                L13.754,2.342C10.576-0.727,5.512-0.639,2.442,2.539c-2.994,3.1-2.994,8.015,0,11.115l234.344,234.344L2.442,482.342
                c-3.178,3.07-3.266,8.134-0.196,11.312s8.134,3.266,11.312,0.196c0.067-0.064,0.132-0.13,0.196-0.196L248.098,259.31
                l234.344,234.344c3.178,3.07,8.242,2.982,11.312-0.196c2.995-3.1,2.995-8.016,0-11.116L259.41,247.998z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg> */}
            </div>
        }

    }

    returnClassName(className)
    {
        if(this.props.modal)
        {
            return className;
        }
        else if(isMobile())
        {
            return className + "-mobile";
        }
        else
        {
            return className;
        }
    }

     // className="window-header"
            // className={this.props.header ? this.returnClassName("window-header") : ""}
           

    render(){
        return <React.Fragment>
        <div className="window-overlay" onClick={()=>{this.props.onClose();}} 
        // style={{zIndex:"10"}}
        ></div>
        <div className={this.returnClassName("window")} style={{width:this.width(),minHeight:this.height()}}>
        {/* , zIndex:"10" */}
            <div className={this.returnClassName(this.props.header ? "window-header": "window-header-borderless")}>
                {this.renderCloseButton()}
                {this.props.header}
            </div>
            <div className={this.returnClassName(this.props.header ? "window-content" : "window-content-borderless")}>
                {this.props.children}
            </div>
            {this.props.buttons && <div className={this.returnClassName("window-footer")}>
                {this.props.buttons.map((button,index)=><button key={"btn_" + button.label} 
                className="btn btn-flat btn-success" 
                disabled={this.props.processing}
                onClick={()=>{
                    if(!this.props.processing)
                    {
                        this.props.onButtonClick(button, index)
                    }
                }}>
                {this.props.processing && <React.Fragment><i className="fa fa-spinner fa-spin"></i> Please wait</React.Fragment>}
                {!this.props.processing && <React.Fragment> {button.label}</React.Fragment>}
                </button>)}
            </div>}
        </div>
        </React.Fragment>
    }
}

export default Window;


