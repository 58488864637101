import React, { Component } from 'react';
import Icon from './Icon';
/* global google */

class Input extends Component {

    constructor()
    {
        super();
        this.state={
            options:[]
        }
        this.autocompleteInput = React.createRef();
        this.autocomplete = null;
        this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    }

    componentDidMount(){
        
        if(this.props.type==="checkboxlist")
        {
            let options=[]
            this.props.children.map((option)=>{
                let tmp = {
                    value:option.props.value ? option.props.value : "",
                    text:option.props.text ? option.props.text : "",
                    selected:false                    
                }
                options.push(tmp);
            })
            this.setState({options:options})
        }
        if(this.props.type==="googleplaces"){
            this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
                {componentRestrictions: { country: "in" },
                fields: ["formatted_address", "geometry", "name"],});
        
            this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
        
        }
    }

    handlePlaceChanged(){
        const place = this.autocomplete.getPlace();
        this.props.onChange(place);
    }

    renderGooglePlacesSearch(){
        return <React.Fragment>
            {this.props.label && <label>{this.props.label}</label>}
            <input ref={this.autocompleteInput}  id="autocomplete" placeholder={this.props.placeholder}
            type="text" className="form-control" ></input>
             {this.renderError()}
        </React.Fragment>
    }

    renderError(){
        return <React.Fragment>{this.props.error && <div className="error-msg">{this.props.error}</div>}</React.Fragment>
    }

    renderLabel(){
        return <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <input type="text" 
        className="form-control" 
        value={this.props.value}
        // onChange={(e) => { this.props.onChange(e.target.value)}}
        />
        {this.renderError()}
    </React.Fragment>
    }

    renderText()
    {
        return <React.Fragment>
            {this.props.label && <label>{this.props.label}</label>}
            <input type="text" 
            className="form-control" 
            value={this.props.value}
            onChange={(e) => { this.props.onChange(e.target.value)}}
            />
            {this.renderError()}
        </React.Fragment>
    }

    renderTextArea()
    {
        return <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <textarea type="text" 
        rows="4" cols="50"
        className="form-control" 
        value={this.props.value}
        onChange={(e) => { this.props.onChange(e.target.value)}}
        />
         {this.renderError()}
     </React.Fragment>
    }

    renderNumber()
    {
        return <React.Fragment>
            {this.props.label && <label>{this.props.label}</label>}
            <input type="number" 
            className="form-control" 
            value={this.props.value}
            onChange={(e) => { this.props.onChange(e.target.value)}}
            />
             {this.renderError()}
        </React.Fragment>
    }

    renderSelect()
    {
        return <React.Fragment>
            {this.props.label && <label>{this.props.label}</label>}
            <select
            className="form-control" 
            value={this.props.value}
            onChange={(e) => { this.props.onChange(e.target.value)}}
            >
                {this.props.children}
            </select>
            {this.renderError()}
        </React.Fragment>
    }
    
    renderCheckBoxList(){
        return <React.Fragment>
            {this.props.label && <label>{this.props.label}</label>}  
            {this.renderError()} 
            {this.state.options.map((o,index)=> <div id={"ctrl_"+ index}>
                <input type="checkbox" 
                checked={o.selected}
                onChange={()=>{
                    let options = this.state.options;
                    options[index].selected= !options[index].selected
                    this.setState({options:options});
                    this.props.onChange(options);
                }}/> {o.text}
                </div>)}
        </React.Fragment>
    }

    renderRadioList(){
        return <React.Fragment>
            {this.props.label && <label>{this.props.label}</label>}
            {this.renderError()}
            {this.props.children.map((o,index)=> <div id={"ctrl_"+ index}>
                <input type="radio" 
                onChange={()=>{this.props.onChange(o.props.text)}}
                name={this.props.name} /> {o.props.text}
                </div>)}
        </React.Fragment>
    }

    renderMobileNo(){
        return <React.Fragment>
            {this.props.label && <label>{this.props.label}</label>}
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">+91</span>
                </div>
                <input type="number" 
                className="form-control" 
                value={this.props.value}
                onChange={(e) => {if(e.target.value.length <=10) {
                    this.props.onChange(e.target.value);
                }}}
                disabled={this.props.disabled}
               
                />
            </div>
            {this.renderError()}
        </React.Fragment>
    }

    renderEmailID(){
        return <React.Fragment>
            {this.props.label && <label>{this.props.label}</label>}
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text"><i className="fa fa-envelope"/></span>
                </div>
                <input type="text" 
                className="form-control" 
                value={this.props.value}
                onChange={(e) => { this.props.onChange(e.target.value)}}
                disabled={this.props.disabled}
                />
            </div>
            {this.renderError()}
        </React.Fragment>
    }

    // renderLoginID(){
    //     return <React.Fragment>
    //         {this.props.label && <label>{this.props.label}</label>}
    //         <div className="form-group  has-feedback">
    //             <input type="text" className="form-control input-lg login-border-radius" placeholder={this.props.placeholder}
    //                 value={this.props.value}
    //                 onChange={e => this.props.onChange(e.target.value)} />
    //             <span className="fa fa-envelope form-control-feedback"></span>
    //         </div>
    //         {this.renderError()}
    //     </React.Fragment>
    // }

    renderLoginID(){
        return <React.Fragment>
            {this.props.label && <label>{this.props.label}</label>}
            <div className="input-rounded">
                <input type="text" placeholder={this.props.placeholder} value={this.props.value}  onChange={e => this.props.onChange(e.target.value)} />
                {/* <span><Icon type="login"/></span> */}
            </div>
            {this.renderError()}
        </React.Fragment>
    }

    renderPassword(){
        return <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
        <div className="input-rounded">
            <input type="password" placeholder={this.props.placeholder} value={this.props.value}  onChange={e => this.props.onChange(e.target.value)} />
            {/* <span><Icon type="password"/></span> */}
        </div>
        {this.renderError()}
    </React.Fragment>
    }

    renderSearch(){
        return <div className="input-rounded">
                <input type="text" placeholder={this.props.placeholder} value={this.props.value}  onChange={e => this.props.onChange(e.target.value)} />
                <span><Icon type="search"/></span>
            </div>
    }

    renderSwitch(){
        return <React.Fragment>
        {this.props.label && <label>{this.props.label}</label>}
            <label className="switch">
                <input type="checkbox" value={this.props.value} onClick={()=>this.props.onChange(!this.props.value)}/>
                <span className="slider round"></span>
            </label>
      {this.renderError()}
      </React.Fragment>
    }

    renderDate()
    {
        return <React.Fragment>
            {this.props.label && <label>{this.props.label}</label>}
            <input type="date" 
            className="form-control" 
            value={this.props.value}
            onChange={(e) => { this.props.onChange(e.target.value)}}
            min={this.props.min}
            />
            {this.renderError()}
        </React.Fragment>
    }

    renderErrorText(){
        return <React.Fragment>{this.renderError()}</React.Fragment>
    }

        renderCheckBox(){
        return <React.Fragment>
            
            <input type="checkbox" 
            checked={this.props.value}
            onChange={() => { this.props.onChange(!this.props.value)}}
            /> 
            {this.props.label && <span onClick={() => { this.props.onChange(!this.props.value)}}>&nbsp;{this.props.label}</span>}  
            {this.renderError()} 
        </React.Fragment>
    }

    render(){
        return <div className="input-control">
            {this.props.type==="error" && this.renderErrorText()}
            {this.props.type==="date" && this.renderDate()}
            {this.props.type==="text" && this.renderText()}
            {this.props.type==="number" && this.renderNumber()}
            {this.props.type==="select" && this.renderSelect()}
            {this.props.type==="checkboxlist" && this.renderCheckBoxList()}
            {this.props.type==="radiolist" && this.renderRadioList()}
            {this.props.type==="textarea" && this.renderTextArea()}
            {this.props.type==="mobileno" && this.renderMobileNo()}
            {this.props.type==="emailid" && this.renderEmailID()}
            {this.props.type==="loginid" && this.renderLoginID()}
            {this.props.type==="password" && this.renderPassword()}
            {this.props.type==="search" && this.renderSearch()}
            {this.props.type==="switch" && this.renderSwitch()}
            {this.props.type==="googleplaces" && this.renderGooglePlacesSearch()}
            {this.props.type==="checkbox" && this.renderCheckBox()}
            {this.props.type==="label" && this.renderLabel()}
    </div>
    }
}

export default Input;