import { SET_PINCODE, 
  SET_STORE,
  SET_CUSTOMER_GEO_LOCATION,
  FETCH_EMPLOYEE,
  SET_CUSTOMER, 
  FETCH_CONFIG, 
  FETCH_MENU , 
  FETCH_PRODUCTS, 
  FETCH_PRODUCT_TAXES, 
  FETCH_CUSTOM_FIELDS,
  FETCH_PAGES, 
  FETCH_CAROUSELS, 
  FETCH_DELIVERY_PRODUCT,
  FETCH_PACKAGING_PRODUCT
} from './actionTypes';

const initialState = {
  config:null,
  menu: [],
  products:[],
  productTaxes:[],
  customFields:[],
  pages:[],
  customer:null,
  employee:null,
  carousels: [],
  deliveryProduct: null,
  pincode:'',
  customerGeoLocation:null,
  store:null,
  packagingProduct:null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_STORE:
      return{
        ...state,
        store:action.payload
      }
    case SET_CUSTOMER_GEO_LOCATION:
      return {
        ...state,
        customerGeoLocation:action.payload
      }
    case SET_PINCODE:
    return {
      ...state,
      pincode: action.payload
      };
    case FETCH_CAROUSELS:
        return {
        ...state,
        carousels: action.payload
        };
    case FETCH_EMPLOYEE:
        return {
        ...state,
        employee: action.payload
        };
    case FETCH_CONFIG:
        return {
        ...state,
        config: action.payload
        };
    case FETCH_MENU:
      return {
        ...state,
        menu: action.payload
      };
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case FETCH_PRODUCT_TAXES:
      return{
        ...state,
        productTaxes:action.payload
      }
    case FETCH_CUSTOM_FIELDS:
      // console.log('action.payload',action.payload)
      return{
        ...state,
        customFields:action.payload
      }
    case FETCH_PAGES:
      return{
        ...state,
        pages:action.payload
      }
    case SET_CUSTOMER:
      return{
        ...state,
        customer:action.payload
      }
      case FETCH_DELIVERY_PRODUCT:
      return{
        ...state,
        deliveryProduct:action.payload
      }
      case FETCH_PACKAGING_PRODUCT:
      return{
        ...state,
        packagingProduct:action.payload
      }
      
    default:
      return state;
  }
}
