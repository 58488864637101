import React, { Component } from "react";
import { mainWebsitePath } from "../../services/util";
import Spinner from "../Spinner";

import "./Style.css";

class SplashScreen extends Component {
    constructor() {
        super();
        this.state = {
            backgroundColor: "#F23D2E", //"white",
            imagePath:
                "https://firebasestorage.googleapis.com/v0/b/posible.in/o/omni_logo%2Fkrumbs.png?alt=media&token=464f00af-71d0-436e-8f00-be2765eaf92e",
        };
    }

    componentDidMount() {
        // console.log('mainWebsitePath',mainWebsitePath())
        let website = mainWebsitePath();
        if (website === "/krumbs/" || window.location.hostname === "krumbsfood.com") {
            this.setState({
                backgroundColor: "#F23D2E",
                imagePath:"https://firebasestorage.googleapis.com/v0/b/posible.in/o/omni_logo%2Fkrumbs.png?alt=media&token=464f00af-71d0-436e-8f00-be2765eaf92e",
            });
        } else if (website === "/sabzeefresh/" || window.location.hostname === "sabzeefresh.com") {
            this.setState({
                backgroundColor: "#5C9C08",
                imagePath:"https://storage.googleapis.com/posible.in/sabzeefresh/omni/1607579468653Sabzee_Fresh-Logo_TK02_V014.png",
            });
        } else if(website === "/beloenterprises/" || window.location.hostname === "belopets.com"){
            this.setState({
                backgroundColor:"white",
                imagePath:"https://firebasestorage.googleapis.com/v0/b/posible.in/o/1OMNICSS%2FbeloPets_logo.jpeg?alt=media&token=4f333612-d42e-4b8e-9dc4-11a7f1f24178"
            })
        } else if(website === "/m10uniforms/" || window.location.hostname === "lwsuniforms.com"){
            this.setState({
                backgroundColor:"#263657",
                imagePath:"https://storage.googleapis.com/posible.in/m10uniforms/omni/1677939479581LWS-OG-logo (1).png"
            })

        } else {
            this.setState({
                backgroundColor: "white",
                imagePath: "",
            });
        }
    }

    renderSplashScreen() {
        return (
            <div
                className="splash-screen"
                style={{ backgroundColor: this.state.backgroundColor }}
            >
                <div className="fade-in">
                    {this.state.imagePath !== "" && (
                        <img src={this.state.imagePath} />
                    )}
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.state.imagePath !== "" && this.renderSplashScreen()}
                {this.state.imagePath === "" && <Spinner />}
            </React.Fragment>
        );
    }
}

export default SplashScreen;
