import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {fetchMenu} from '../../services/api/actions';
import { fetchProducts, isMobile , getID, mainWebsitePath} from '../../services/util';
import Spinner from '../Spinner';
import MenuThumbNails from '../MenuThumbNails';
import ProductList from '../ProductList';
import PageFooter from '../PageFooter';
import Footer from '../Footer';
import Header from "../Header";
import Icon from '../Common/Icon';
import './level1.css';

class Level1 extends Component {

    static propTypes = {
        menu: PropTypes.array.isRequired,
        config: PropTypes.object.isRequired,
        fetchMenu: PropTypes.func.isRequired
      };

      state={
        categoryID:'',
          products:[],
          isLoading:true,
          title:''
      }

      async loadData(selected)
      {
        let hideProductLisitng = false;
        let products =[];
        let category = await getID("category",selected, this.props.config.key);

        if(this.props.config.CheckoutConfig.hasOwnProperty("Level1Settings")
            && this.props.config.CheckoutConfig.Level1Settings.hasOwnProperty("HideProductListing")
        )
        {
            hideProductLisitng= this.props.config.CheckoutConfig.Level1Settings.HideProductListing;
        }
        if(!hideProductLisitng){
            //fetch products
            products= await fetchProducts(0, 1000,category.id,"","","",this.props.config.key, this.props.config.CheckoutConfig.StoreID)
            console.log('after fetchproducts',products)
            // console.log(this.props.menu.filter(f=> f.ParentID ===category.id))
        }
        
        this.setState({
            hideProductLisitng:hideProductLisitng,
            products:products, 
            title:category.Name,  
            categoryID: category.id, 
            isLoading:false
        })

      }

    async componentDidMount(){
            // console.log('this.props.match.params.name.toLowerCase()', this.props.match.params.name.toLowerCase())
           this.loadData(this.props.match.params.name.toLowerCase())
    }

    componentWillReceiveProps(nextProps) {
        //new parameters passed
        // console.log('componentWillReceiveProps')
        // console.log(nextProps.match.params.name)
        this.loadData(nextProps.match.params.name.toLowerCase())
    }

    render()
    {
        const {categoryID} = this.state; 
        const {isLoading} = this.state;
        const {products} = this.state;
        
        return <React.Fragment>
            
             <React.Fragment> 
                
                {!isMobile() && <Header/>}
                
                {isMobile() && <div className="level1-mobile-header">
                    <div className="level1-mobile-header-close-btn"  onClick={() =>this.props.history.push(mainWebsitePath())}>
                        <Icon type="back"/>
                    </div>
                    <div className='level1-mobile-header-title'>{this.state.title}</div>
                </div>}

                {isLoading && <React.Fragment>
                    <Spinner/> 
                </React.Fragment>}

                {!isLoading &&<div className="container l1">
        
                    {isMobile() && <div className='level1-mobile-header-placeholder'></div>}
                    {!isMobile() && <div className="l1 page-title">{this.state.title}</div>}
        
                    {this.props.menu && this.props.menu.filter(f=> f.ParentID ===categoryID).length > 0 &&  <div className='l1-subcategory'>
                        
                        <MenuThumbNails 
                            displayMode="rounded-image" 
                            menu={this.props.menu.filter(f=> f.ParentID ===categoryID)} 
                            businessUniqueName={this.props.config.BusinessUniqueName} 
                            type="subcategory"
                        />

                        <br/>
                    </div>}
        
                    {products.length> 0 && <div className='l1-products'><ProductList products={products} displayMode={isMobile() ? "list" : "thumbnail" }/></div>}
        
                </div>}
                    
                <br/>
                    
                {!isMobile() &&<PageFooter config={this.props.config}/>}
            
            </React.Fragment>
            
            <Footer config={this.props.config} show={isMobile()} width="100%"/>
        
        </React.Fragment>
    };
}


const mapStateToProps = state => ({
    menu: state.api.menu,
    config:state.api.config
});

export default connect(
    mapStateToProps,
    {fetchMenu}
  )(Level1);
  