import React, { Component } from "react";
import { connect } from "react-redux";
import { myOrders, mainWebsitePath, isMobile } from "../../services/util";
import Spinner from "../Spinner";
import Header from "../Header";
import PageFooter from "../PageFooter";
import CancelOrder from "./CancelOrder";

class MyOrders extends Component {
  state = {
    sales: [],
    isLoading: true,
    user: null,
    orderKey: "",
    showCancelWindow: false,
  };

  async componentDidMount() {
    this.loadOrders();
  }

  async loadOrders() {
    let sales = [];
    if (this.props.customer) {
      sales = await myOrders(
        0,
        10,
        this.props.customer.key,
        this.props.config.CheckoutConfig.StoreID
      );
      console.log("sales", sales);
    }
    this.setState({ sales: sales, isLoading: false });
  }

  checkCancelIsAllowed(order) {
    if (
      order.hasOwnProperty("CancellationReason") &&
      order.CancellationReason.trim() !== ""
    ) {
      return false;
    }
    if (
      this.props.config.CheckoutConfig.hasOwnProperty(
        "AllowOrderCancellation"
      ) &&
      this.props.config.CheckoutConfig.AllowOrderCancellation
    ) {
      if (!order.hasOwnProperty("RequestSource")) {
        return false;
      }
      if (order.RequestSource && order.RequestSource !== "omni web") {
        return false;
      }
      // order
      if (
        this.props.config.CheckoutConfig.OrderCancellationAllowedUptil === "1"
      ) {
        //time based
      } else {
        //status based
        if (
          order.hasOwnProperty("DeliveryStatus") &&
          order.DeliveryStatus !== ""
        ) {
          let deliveryStatuses = [
            { Id: "1", Status: "Pending" },
            { Id: "2", Status: "Confirmed" },
            { Id: "3", Status: "Out For Delivery" },
            { Id: "4", Status: "Delivered" },
          ];
          if (
            deliveryStatuses.filter(
              (f) =>
                f.Status.toLowerCase() === order.DeliveryStatus.toLowerCase()
            ).length > 0
          ) {
            let currentStatus = deliveryStatuses.filter(
              (f) =>
                f.Status.toLowerCase() === order.DeliveryStatus.toLowerCase()
            )[0].Id;
            if (
              Number(currentStatus) <=
              Number(this.props.config.CheckoutConfig.OrderCancellationStatus)
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  getThumbNailImage(m) {
    let url = "";
    if (m.Images && m.Images.length > 0) {
      if (m.Images[0]) {
        if (m.Images[0].ImagePath !== "") {
          url = m.Images[0].ImagePath;
        }
      }
    }

    if (url !== "") {
      return <img src={url} alt="" />;
    } else {
      return (
        <svg
          onClick={() =>
            this.props.history.push(
              "/" + this.props.config.BusinessUniqueName + "/p/" + m.Name
            )
          }
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect width="100%" height="100%" fill="#777"></rect>
          <text x="35%" y="66%" fill="#eceeef" style={{ fontSize: "50px" }}>
            {m.Name && m.Name.substring(0, 1)}
          </text>
        </svg>
      );
    }
  }

  renderOrderDetail(s) {
    let productsPurchased = [];
    // console.log('this.props.deliveryProduct',this.props.deliveryProduct)

    for (let i = 0; i < s.LineItems.length; i++) {
      // console.log('s.LineItems[i]',s.LineItems[i])
      if (
        this.props.deliveryProduct &&
        this.props.deliveryProduct.key === s.LineItems[i].Product.key
      ) {
        continue;
      }
      productsPurchased.push(s.LineItems[i]);
    }
    return (
      <React.Fragment>
        {productsPurchased.map((m, lineIndex) => (
          <div key={"lineItem" + lineIndex} className="order-lineitem">
            <div className="order-lineitem-image">
              {m.Product && this.getThumbNailImage(m.Product)}
            </div>
            <div className="order-lineitem-content">
              <div className="order-lineitem-content-product-name">
                {m.Product.Name}
              </div>
              <div className="order-lineitem-content-amount">
                {m.Qty + " X ₹ " + m.RetailPrice + " = ₹ " + m.Amount}
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }

  orderStatus(order) {
    if (
      order.hasOwnProperty("CancellationReason") &&
      order.CancellationReason.trim() !== ""
    ) {
      return "Cancelled";
    } else if (
      order.hasOwnProperty("DeliveryStatus") &&
      order.DeliveryStatus !== ""
    ) {
      return order.DeliveryStatus;
    } else {
      return "Completed";
    }
  }

  renderOrderContent(s, index) {
    if (isMobile()) {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-md-12">
              <div className="order-delivery-address">
                <div
                  onClick={() =>
                    this.setState({
                      selecteAddressIndex:
                        this.state.selecteAddressIndex === index ? -1 : index,
                    })
                  }
                  className="order-delivery-address-link"
                >
                  Delivery Address &nbsp;
                  {this.state.selecteAddressIndex === index && (
                    <i className="fa fa-caret-up"></i>
                  )}
                  {this.state.selecteAddressIndex !== index && (
                    <i className="fa fa-caret-down"></i>
                  )}
                </div>
                {this.state.selecteAddressIndex === index && (
                  <React.Fragment>
                    {/* <div><b>{s.BillingAddress.AddressType}</b></div>
                                        <div>{s.BillingAddress.Address1}</div>
                                        <div>{s.BillingAddress.Address2}</div>
                                        <div>{s.BillingAddress.City}, {s.BillingAddress.State}</div>
                                        <div>{s.BillingAddress.Country} - {s.BillingAddress.PostalCode}</div> */}
                    {this.renderDeliveryAddress(s)}
                  </React.Fragment>
                )}
              </div>
            </div>
            {/* <div className="col-md-12">
              <div>Delivery Date:&nbsp;{s.DeliveryDate}</div>
            </div>
            <div className="col-md-12">{this.renderDeliveryTime(s)}</div> */}
          </div>
          <div className="row">
            <div className="col-md-12">{this.renderOrderDetail(s)}</div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div>Total Qty: {s.BillQty}</div>
              <div>Total Amount: ₹ {s.BillAmount}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">{this.renderOrderTimeLine(s)}</div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-md-8">
              {/* {s.LineItems.map((m, lineIndex) => <span key={"lineItem" + lineIndex}>
                                {m.Product.Name} x {m.Qty},&nbsp;
                                </span>)} */}
              {this.renderOrderDetail(s)}
            </div>
            <div className="col-md-4">
              {/* {s.BillingAddress && <div>
                                    <div>Billing Address</div>
                                    <div><b>{s.BillingAddress.AddressType}</b></div>
                                    <div>{s.BillingAddress.Address1}</div>
                                    <div>{s.BillingAddress.Address2}</div>
                                    <div>{s.BillingAddress.City}, {s.BillingAddress.State}</div>
                                    <div>{s.BillingAddress.Country} - {s.BillingAddress.PostalCode}</div>
                                </div>}
                                {s.DeliveryCustomerAddress && <div>
                                    <div>Delivery Address</div>
                                    <div><b>{s.DeliveryCustomerAddress.AddressType}</b></div>
                                    <div>{s.DeliveryCustomerAddress.Address1}</div>
                                    <div>{s.DeliveryCustomerAddress.Address2}</div>
                                    <div>{s.DeliveryCustomerAddress.City}, {s.DeliveryCustomerAddress.State}</div>
                                    <div>{s.DeliveryCustomerAddress.Country} - {s.DeliveryCustomerAddress.PostalCode}</div>
                                </div>} */}
              {this.renderDeliveryAddress(s)}
              {/* <br/> */}
              <div>Delivery Date:&nbsp;{s.DeliveryDate}</div>
              {/* <br/> */}
              {this.renderDeliveryTime(s)}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div>Total Qty: {s.BillQty}</div>
              <div>Total amount: ₹ {s.BillAmount}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">{this.renderOrderTimeLine(s)}</div>
          </div>
        </React.Fragment>
      );
    }
  }

  renderDeliveryAddress(order) {
    if (order.DeliveryCustomerAddress) {
      let cityState = "";
      let countryPostalCode = "";
      if (order.DeliveryCustomerAddress.City !== "") {
        cityState = order.DeliveryCustomerAddress.City;
      }
      if (order.DeliveryCustomerAddress.State !== "") {
        cityState =
          cityState +
          (cityState !== "" ? " , " : " ") +
          order.DeliveryCustomerAddress.City;
      }
      if (order.DeliveryCustomerAddress.Country.trim() !== "") {
        countryPostalCode = order.DeliveryCustomerAddress.Country;
      }
      if (order.DeliveryCustomerAddress.PostalCode !== "") {
        countryPostalCode =
          countryPostalCode +
          (countryPostalCode !== "" ? " - " : " ") +
          order.DeliveryCustomerAddress.PostalCode;
      }

      return (
        <div>
          {!isMobile() && <div>Delivery Address:</div>}
          <div>{order.DeliveryCustomerAddress.AddressType}</div>
          <div>{order.DeliveryCustomerAddress.Address1}</div>
          <div>{order.DeliveryCustomerAddress.Address2}</div>
          {/* <div>{order.DeliveryCustomerAddress.City}, {s.DeliveryCustomerAddress.State}</div> */}
          <div>{cityState}</div>
          <div>{countryPostalCode}</div>

          {/* <div>{s.DeliveryCustomerAddress.Country} - {s.DeliveryCustomerAddress.PostalCode}</div> */}
        </div>
      );
    }
  }

  renderDeliveryTime(order) {
    let deliverTime = "Not specified";
    // if(order.DeliveryTime)
    // {
    //     if(order.DeliveryTime.HH !=="")
    //     {
    //         deliverTime = order.DeliveryTime.HH
    //         if(order.DeliveryTime.MM!==""){deliverTime = deliverTime + " : " + order.DeliveryTime.MM }
    //     }
    //     if(order.DeliveryTime.AMPM !==""){
    //         deliverTime = deliverTime + " : " + order.DeliveryTime.AMPM
    //     }
    // }
    return (
      <React.Fragment>
        <div>Delivery Time:&nbsp;{deliverTime}</div>
      </React.Fragment>
    );
  }

  renderOrderTimeLine(order) {
    // if(isMobile())
    // {
    //     return <div class="timeline">
    //         <div class="container right">
    //             <div class="content">
    //             <h2>2017</h2>
    //             <p>Lorem ipsum..</p>
    //             </div>
    //         </div>
    //         <div class="container right">
    //             <div class="content">
    //             <h2>2016</h2>
    //             <p>Lorem ipsum..</p>
    //             </div>
    //         </div>
    //     </div>
    // }
    // else{
    return (
      <ul className="timeline">
        <li className="li complete">
          <div className="status">Order Placed</div>
        </li>
        <li
          className={
            order.DeliveryStatus &&
            order.DeliveryStatus.toLowerCase() === "pending"
              ? "li"
              : "li complete"
          }
        >
          <div className="status">Order Confirmed</div>
        </li>
        <li
          className={
            order.DeliveryStatus &&
            (order.DeliveryStatus.toLowerCase() === "pending" ||
              order.DeliveryStatus.toLowerCase() === "confirmed")
              ? "li"
              : "li complete"
          }
        >
          <div className="status">Out For Delivery</div>
        </li>
        <li
          className={
            order.DeliveryStatus &&
            order.DeliveryStatus.toLowerCase() === "delivered"
              ? "li complete"
              : "li"
          }
        >
          <div className="status">Delivered</div>
        </li>
      </ul>
    );
    // }
  }

  render() {
    const { sales } = this.state;
    const { isLoading } = this.state;

    return (
      <React.Fragment>
        {this.state.showCancelWindow && (
          <CancelOrder
            orderKey={this.state.orderKey}
            onClose={(status) => {
              if (status) {
                setTimeout(
                  function () {
                    this.loadOrders();
                    this.setState({ showCancelWindow: false });
                  }.bind(this),
                  3000
                );
                console.log("laod orders");
              } else {
                this.setState({ showCancelWindow: false });
              }
            }}
          />
        )}

        <Header />

        <div className="container">
          <div className="orders-page">
            <div className="page-title">My Orders</div>
            {isLoading && <Spinner />}
            {!isLoading && (
              <React.Fragment>
                {sales.map((s, index) => (
                  <div key={"order" + index} className="order">
                    <div className="order-header">
                      {!isMobile() && (
                        <React.Fragment>
                          <span>
                            Ordered On{" "}
                            {
                              new Date(s.TransactionDate)
                                .toISOString()
                                .split("T")[0]
                            }
                          </span>
                          <span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Order #{" "}
                            {s.TransactionNo}
                          </span>
                          <span
                            className={
                              "pull-right order-status-" +
                              this.orderStatus(s).toLowerCase()
                            }
                          >
                            Order Status {this.orderStatus(s)}
                          </span>
                        </React.Fragment>
                      )}
                      {isMobile() && (
                        <React.Fragment>
                          <div>
                            Ordered On{" "}
                            {
                              new Date(s.TransactionDate)
                                .toISOString()
                                .split("T")[0]
                            }
                          </div>
                          <div>Order # {s.TransactionNo}</div>
                          <div>
                            <span
                              className={
                                "order-status-" +
                                this.orderStatus(s).toLowerCase()
                              }
                            >
                              Order Status {this.orderStatus(s)}
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    <div className="order-content">
                      {this.renderOrderContent(s, index)}
                    </div>
                    <div className="order-footer">
                      {this.checkCancelIsAllowed(s) && (
                        <button
                          className="btn btn-flat order-cancel-btn"
                          onClick={() =>
                            this.setState({
                              orderKey: s.key,
                              showCancelWindow: true,
                            })
                          }
                        >
                          Cancel Order
                        </button>
                      )}
                    </div>
                  </div>
                ))}
                <br />
                <br />
                <br />
                <br />
                {sales.length === 0 && (
                  <div className="record-not-found">
                    <svg
                      viewBox="0 0 56 60"
                      width="68px"
                      height="68px"
                      fill="#67768c"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill-rule="evenodd">
                        <path
                          d="M1 14c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1h42c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1H1zM15 22c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h22c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1H15zM17 6c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1h10c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1H17zM13 10c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1h18c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1H13zM36 8h7c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-8c-.5522847 0-1-.44771525-1-1V1c0-.55228475.4477153-1 1-1s1 .44771525 1 1v7zM7 24c-.55228475 0-1-.4477153-1-1v-4c0-.5522847.44771525-1 1-1h4c.5522847 0 1 .4477153 1 1v4c0 .5522847-.4477153 1-1 1H7zm1-2h2v-2H8v2zM15 30c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h6.052c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1H15zM7 32c-.55228475 0-1-.4477153-1-1v-4c0-.5522847.44771525-1 1-1h4c.5522847 0 1 .4477153 1 1v4c0 .5522847-.4477153 1-1 1H7zm1-2h2v-2H8v2zM15 38c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h4c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-4zM7 40c-.55228475 0-1-.4477153-1-1v-4c0-.5522847.44771525-1 1-1h4c.5522847 0 1 .4477153 1 1v4c0 .5522847-.4477153 1-1 1H7zm1-2h2v-2H8v2zM15 46c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h7.624c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1H15zM7 48c-.55228475 0-1-.4477153-1-1v-4c0-.5522847.44771525-1 1-1h4c.5522847 0 1 .4477153 1 1v4c0 .5522847-.4477153 1-1 1H7zm1-2h2v-2H8v2zM50.4161068 57.3851932c.8194757.8194757 2.1493107.8194757 2.9690786-.000292.8200861-.819409.8200861-2.1487934-.0003531-2.9685554l-5.8009391-5.801939c-.8194757-.8194757-2.1493107-.8194757-2.9687864 0-.8204757.8204757-.8204757 2.1493107 0 2.9697864l5.801 5.801zm-1.4142136 1.4142136l-5.801-5.801c-1.6015243-1.6015243-1.6015243-4.1966893 0-5.7982136 1.6005243-1.6005243 4.1966893-1.6005243 5.7972745.000061l5.8006469 5.801647c1.6019139 1.600591 1.6019139 4.1972066.0002922 5.7975056-1.6005243 1.6005243-4.1966893 1.6005243-5.7972136 0z"
                          fill-rule="nonzero"
                        ></path>
                        <path
                          d="M44.2767682 49.0854427c-.0796855.1431637-.1409432.2915959-.1839798.4449137-.2066214.7360886-1.129285.9774606-1.6698952.4368504l-3.071-3.071c-.4227588-.4227589-.3825419-1.1195578.0860482-1.4908709.7296849-.5782061 1.3890884-1.2376096 1.9672945-1.9672945.3713131-.4685901 1.068112-.508807 1.4908709-.0860482l3.071 3.071c.5409662.5409663.298863 1.4642816-.4379449 1.6702017-.1524408.0426036-.299632.1034181-.4698447.1976596-.0184888.0094983-.0184888.0094983-.0310432.015818-.1740347.1024444-.3053389.2007059-.4131672.3085343-.1052752.1052752-.2029509.2352593-.2975553.3920191-.0189673.0378655-.0189673.0378655-.0407833.0782168zm.7492923-.7780213c-.0150164.0082337-.0150277.0082399-.0041919.0024769a3.21566785 3.21566785 0 0 1 .0041919-.0024769zm-3.4977824-2.0632569l1.3399831 1.3399832c.1030122-.1362829.2127271-.2632496.332632-.3831545.1205479-.1205479.2483304-.2309889.3829023-.3328841l-1.339731-1.3397311c-.2299487.2471101-.4686764.4858378-.7157864.7157865zm.9945169 1.8804997l.0060477-.0112071a4.15519983 4.15519983 0 0 0-.004591.0082705l-.0014567.0029366z"
                          fill-rule="nonzero"
                        ></path>
                        <path
                          d="M2 54.0002h39c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1H1c-.55228475 0-1-.4477153-1-1v-54c0-.55228475.44771525-1 1-1h34c.2652165 0 .5195704.10535684.7071068.29289322l8 8C43.8946432 8.4806296 44 8.73498351 44 9.0002v14.094c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1V9.41441356L34.5857864 2.0002H2v52z"
                          fill-rule="nonzero"
                        ></path>
                        <path
                          d="M44 36.0005c0-6.6277153-5.3722847-12-12-12s-12 5.3722847-12 12 5.3722847 12 12 12 12-5.3722847 12-12zm2 0c0 7.7322847-6.2677153 14-14 14s-14-6.2677153-14-14 6.2677153-14 14-14 14 6.2677153 14 14zM50.4161068 57.3851932c.8194757.8194757 2.1493107.8194757 2.9690786-.000292.8200861-.819409.8200861-2.1487934-.0003531-2.9685554l-5.8009391-5.801939c-.8194757-.8194757-2.1493107-.8194757-2.9687864 0-.8204757.8204757-.8204757 2.1493107 0 2.9697864l5.801 5.801zm-1.4142136 1.4142136l-5.801-5.801c-1.6015243-1.6015243-1.6015243-4.1966893 0-5.7982136 1.6005243-1.6005243 4.1966893-1.6005243 5.7972745.000061l5.8006469 5.801647c1.6019139 1.600591 1.6019139 4.1972066.0002922 5.7975056-1.6005243 1.6005243-4.1966893 1.6005243-5.7972136 0z"
                          fill-rule="nonzero"
                        ></path>
                        <path
                          d="M40 36.0005c0-4.4184153-3.5815847-8-8-8-4.4184153 0-8 3.5815847-8 8 0 4.4184153 3.5815847 8 8 8 4.4184153 0 8-3.5815847 8-8zm2 0c0 5.5229847-4.4770153 10-10 10s-10-4.4770153-10-10 4.4770153-10 10-10 10 4.4770153 10 10z"
                          fill-rule="nonzero"
                        ></path>
                        <path d="M33.41421356 36l1.41421356 1.41421356c.39052426.39052426.39052426 1.0236893 0 1.41421356-.39052425.39052426-1.0236893.39052426-1.41421356 0L32 37.41421356l-1.41421356 1.41421356c-.39052426.39052426-1.0236893.39052426-1.41421356 0-.39052426-.39052425-.39052426-1.0236893 0-1.41421356L30.58578644 36l-1.41421356-1.41421356c-.39052426-.39052426-.39052426-1.0236893 0-1.41421356.39052425-.39052426 1.0236893-.39052426 1.41421356 0L32 34.58578644l1.41421356-1.41421356c.39052426-.39052426 1.0236893-.39052426 1.41421356 0 .39052426.39052425.39052426 1.0236893 0 1.41421356L33.41421356 36z"></path>
                      </g>
                    </svg>
                    <div className="record-not-found-title">
                      No Orders Found
                    </div>
                    <a
                      onClick={() => {
                        this.props.history.push(mainWebsitePath());
                      }}
                    >
                      Continue Shopping
                    </a>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        {!isMobile() && <PageFooter />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.api.config,
  customer: state.api.customer,
  deliveryProduct: state.api.deliveryProduct,
});

export default connect(mapStateToProps)(MyOrders);
