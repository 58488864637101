import { LOAD_CART, ADD_PRODUCT, REMOVE_PRODUCT, CHANGE_PRODUCT_QUANTITY, UPDATE_TOTAL, SHOW_CART,
  COUPON_UPDATE } from './actionTypes';

const initialState = {
  lineItems: [],
  lineItemTotal:{},
  cartIsOpen:false,
  coupon:null
};

export default function(state = initialState, action) {
  // alert(action);
  switch (action.type) {
    case COUPON_UPDATE:
      return {
        ...state,
        coupon:action.payload
      }
    case UPDATE_TOTAL:
      return {
        ...state,
        lineItemTotal:action.payload
      };
    case LOAD_CART:
      return {
        ...state,
        lineItems: action.payload
      };
    case ADD_PRODUCT:
      return {
        ...state,
        productToAdd: Object.assign({}, action.payload)
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        productToRemove: Object.assign({}, action.payload)
      };
    case CHANGE_PRODUCT_QUANTITY:
      return {
        ...state,
        productToChange: Object.assign({}, action.payload)
      };
    case SHOW_CART:
      return {
        ...state,
        cartIsOpen: action.payload
      };
    default:
      return state;
  }
}
