import React from 'react';
import './style.scss';

export default () => (
  <React.Fragment>
    <div className="spinner lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
  </React.Fragment>
  
);
