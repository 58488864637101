import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { isMobile } from "../../services/util";
import Home from "../Home";
import Level1 from "../Level1";
import Level2 from "../Level2";
import Level3 from "../Level3";
import ProductSearch from "../ProductSearch";
import Spinner from "../Spinner";
import Contact from "../Contact";
import Checkout from "../Checkout";
import MyOrders from "../MyOrders";
import MyFavourites from "../MyFavourites";
import OmniPages from "../OmniPages";
import {
  mainWebsitePath,
  getCustomerFromLocalStorage,
  omniGetCustomerByKey,
} from "../../services/util";
import { setCustomer, fetchEmployee } from "../../services/api/actions";
import CustomerProfile from "../Customer/CustomerProfile";
import MyWallet from "../Wallet/MyWallet";
import LoyaltyLedger from "../Loyalty/LoyaltyLedger";
import LevelProductGroup from "../LevelProductGroup";
import LevelBrand from "../LevelBrand";
import LoginPage from "../Login/LoginPage";
import CheckoutAtomResponse from "../Checkout/CheckoutAtomResponse";
import UnderMaintenance from "../Common/UnderMaintenance";

class Main extends Component {
  static propTypes = { config: PropTypes.object };

  state = {
    isLoading: false,
    topMargin: "0px",
  };

  async componentDidMount() {
    this.setState({ topMargin: isMobile() ? "45px" : "0px" });
    let customerKey = getCustomerFromLocalStorage(this.props.config.key);
    if (customerKey && customerKey !== "" && customerKey !== "null") {
      let cust = await omniGetCustomerByKey(customerKey);

      if (cust) {
        if (cust.hasOwnProperty("AffiliateCode")) {
          this.props.fetchEmployee(cust.AffiliateCode, this.props.config.key);
        }
        this.props.setCustomer(this.props.config.key, cust);
      }
    }
    this.extractReferralCode();
  }

  extractReferralCode() {
    let search = window.location.search;
    if (search.length > 0 && search.substring(1, 3) === "r=") {
      let referralCode = search.substring(3, search.lenght);
      if (referralCode.length > 0) {
        this.props.fetchEmployee(referralCode, this.props.config.key);
      }
    }
  }

  showLoginPage() {
    if (
      this.props.config.CheckoutConfig.PrivateCatalogue &&
      !this.props.customer
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { isLoading } = this.state;
    return (
      <Router>
        <React.Fragment>
          {isLoading && <Spinner />}
          {!isLoading && (
            <React.Fragment>
              <Route
                exact
                path={mainWebsitePath() + "undermaintenance"}
                component={UnderMaintenance}
              />
              {this.showLoginPage() && <LoginPage />}
              {!this.showLoginPage() && (
                <React.Fragment>
                  <Route
                    exact
                    path={mainWebsitePath() === "/" ? "/" : "/:business"}
                    component={Home}
                  />
                  <Route
                    exact
                    path={mainWebsitePath() + "contact"}
                    component={Contact}
                  />
                  <Route
                    path={mainWebsitePath() + "checkoutatomresponse/:id"}
                    component={CheckoutAtomResponse}
                  />
                  <Route
                    path={mainWebsitePath() + "l1/:name"}
                    component={Level1}
                  />
                  <Route
                    path={mainWebsitePath() + "l2/:name"}
                    component={Level2}
                  />
                  <Route
                    path={mainWebsitePath() + "pg/:name"}
                    component={LevelProductGroup}
                  />
                  <Route
                    path={mainWebsitePath() + "b/:name"}
                    component={LevelBrand}
                  />
                  <Route
                    path={mainWebsitePath() + "p/:name"}
                    component={Level3}
                  />
                  <Route
                    exact
                    path={mainWebsitePath() + "myorders"}
                    component={MyOrders}
                  />
                  <Route
                    exact
                    path={mainWebsitePath() + "favourites"}
                    component={MyFavourites}
                  />
                  <Route
                    exact
                    path={mainWebsitePath() + "loyalty"}
                    component={LoyaltyLedger}
                  />
                  <Route
                    exact
                    path={mainWebsitePath() + "pages/:name"}
                    component={OmniPages}
                  />
                  <Route
                    path={mainWebsitePath() + "search/:name"}
                    component={ProductSearch}
                  />
                  <Route
                    exact
                    path={mainWebsitePath() + "checkout"}
                    component={Checkout}
                  />
                  <Route
                    exact
                    path={mainWebsitePath() + "myWallet"}
                    component={MyWallet}
                  />
                  <Route
                    exact
                    path={mainWebsitePath() + "myprofile"}
                    component={CustomerProfile}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.api.config,
  customer: state.api.customer,
});

export default connect(mapStateToProps, { setCustomer, fetchEmployee })(Main);
