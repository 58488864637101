import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { addProduct, changeProductQuantity } from '../../../services/cart/actions';
import {
  loadCart,
  loadCartTotal,
  updateCoupon,
} from "../../../services/cart/actions";
import Alert from "../../Alert";
import { withRouter } from "react-router-dom";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  mainWebsitePath,
  onLineItemChange,
  calculateBillTotal,
  removeCoupon,
  isMobile,
  isLocationCheckerEnabled,
  fetchProduct,
} from "../../../services/util";
import { SideBySideMagnifier } from "react-image-magnifiers";
import Location from "../../Common/Location";
import noimage from "./no-image.png";
import Input from "../../Common/Input";
import "./product.css";

class Product extends Component {
  static propTypes = {
    product: PropTypes.object,
    displayMode: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      qty: 0,
      retailPrice: 0,
      mrp: 0,
      showVariantSelection: false,
      attributes: [],
      variants: [],
      selectedVariantIndex: -1,
      showVariantRemoval: false,
      currentImgeURL: "",
      discountPercentage: 0,
      outOfStock: false,
      attributeLabel: "",
      attributes1: [],
      attributes2: [],
      attributes3: [],
      selectedAttribute1: "",
      selectedAttribute2: "",
      selectedAttribute3: "",
      showAttribute1: true,
      showAttribute2: false,
      showAttribute3: false,
      variantProduct: null,
      notes: "",
      variantLabel: "",
      showVariantDropDown: false,
      product: null,
      OMNITag: "",
      attributeLabel1: "",
      attributeLabel2: "",
      attributeLabel3: "",
      attribute1Index: "",
      attribute2Index: "",
      attribute3Index: "",
    };
    this.onAddItemClicked = this.onAddItemClicked.bind(this);
    this.redirectToDetailsPage = this.redirectToDetailsPage.bind(this);
    this.increaseQty = this.increaseQty.bind(this);
    this.addQty = this.addQty.bind(this);
    this.decreaseQty = this.decreaseQty.bind(this);
    this.minusQty = this.minusQty.bind(this);
    this.renderList = this.renderList.bind(this);
    this.renderAddButton = this.renderAddButton.bind(this);
    this.renderThumbnail = this.renderThumbnail.bind(this);
    this.renderDetailPage = this.renderDetailPage.bind(this);
    this.onLocaitonSelected = this.onLocaitonSelected.bind(this);
    this.getVariantProduct = this.getVariantProduct.bind(this);
    this.renderVariant = this.renderVariant.bind(this);
    this.renderSearchList = this.renderSearchList.bind(this);
    this.showNextLevel = this.showNextLevel.bind(this);
    this.renderVariantSelectionForDetailPage =
      this.renderVariantSelectionForDetailPage.bind(this);
    this.onVariantSelected = this.onVariantSelected.bind(this);
    this.calculateDiscountPercentage =
      this.calculateDiscountPercentage.bind(this);
    this.renderVariantSelection = this.renderVariantSelection.bind(this);

    this.onAttribute1Selection = this.onAttribute1Selection.bind(this);
    this.onAttribute2Selection = this.onAttribute2Selection.bind(this);
    this.onAttribute3Selection = this.onAttribute3Selection.bind(this);
  }

  async componentDidMount() {
    // console.log('componentDidMount', this.props.product);
    // console.log("this.props.displayMode",this.props.displayMode);
    // let showVariantDropDown= true;
    let retailPrice = 0;
    let mrp = 0;
    let product = this.props.product;
    let currentImgeURL = "";
    let customFieldName1 = "";
    let customFieldName2 = "";
    let customFieldName3 = "";
    let customFieldName4 = "";
    let customFieldName5 = "";
    let customFieldName6 = "";
    let qty = 0;
    let notes = "";
    let variants = [];
    let attributes = [];
    let OMNITag = "";
    let outOfStock = false;
    let attributeLabel1 = "";
    let attributeLabel2 = "";
    let attributeLabel3 = "";
    let parentProductID = product.key;
    let attributes1 = [];
    let attribute1 = "";

    // let parentProductName = "";

    // if(this.props.checkOut){
    //     showVariantDropDown=true;
    // }

    if (product.hasOwnProperty("OMNITag")) {
      OMNITag = product.OMNITag;
    }

    if (product.hasOwnProperty("OutOfStock")) {
      outOfStock = product.OutOfStock;
    }

    if (product.Images && product.Images.length > 0) {
      if (product.Images[0]) {
        if (product.Images[0].ImagePath !== "") {
          currentImgeURL = product.Images[0].ImagePath;
        }
      }
    }

    if (
      this.props.customFields.filter(
        (f) => f.ModuleName.toLowerCase() === "product"
      ).length > 0
    ) {
      let productCustomField = this.props.customFields.filter(
        (f) => f.ModuleName.toLowerCase() === "product"
      )[0];
      customFieldName1 = productCustomField.CustomField1Name;
      customFieldName2 = productCustomField.CustomField2Name;
      customFieldName3 = productCustomField.CustomField3Name;
      customFieldName4 = productCustomField.CustomField4Name;
      customFieldName5 = productCustomField.CustomField5Name;
      customFieldName6 = productCustomField.CustomField6Name;
    }

    if (
      this.props.lineItems.filter((f) => f.Product.key === product.key).length >
      0
    ) {
      let lineItem = this.props.lineItems.filter(
        (f) => f.Product.key === product.key
      )[0];
      qty = lineItem.Qty;
      notes = lineItem.Notes;
    }

    if (
      product.RetailPrice === 0 &&
      product.hasOwnProperty("StoreSpecificPrices") &&
      product.StoreSpecificPrices.length > 0
    ) {
      retailPrice = product.StoreSpecificPrices[0].RetailPrice;
      mrp = product.StoreSpecificPrices[0].MRP;
    } else {
      retailPrice = product.RetailPrice;
      mrp = product.MRP;
    }

    if (
      product.InventoryType === "Variants" &&
      product.hasOwnProperty("Variants") &&
      product.Variants.length > 0
    ) {
      variants = product.Variants;
      attributes = product.Attributes;
      if (attributes.length === 3) {
        attributeLabel1 = attributes[0].Attribute;
        attributeLabel2 = attributes[1].Attribute;
        attributeLabel3 = attributes[2].Attribute;
      }
      if (attributes.length === 2) {
        attributeLabel1 = attributes[0].Attribute;
        attributeLabel2 = attributes[1].Attribute;
        attributeLabel3 = "";
      }
      if (attributes.length === 1) {
        attributeLabel1 = attributes[0].Attribute;
        attributeLabel2 = "";
        attributeLabel3 = "";
      }

      variants.map((v) => {
        if (
          v.Attribute1.trim() !== "" &&
          attributes1.filter((f) => f.attribute.trim() === v.Attribute1.trim())
            .length === 0
        ) {
          attributes1.push({ attribute: v.Attribute1.trim() });
        }
      });

      if (attributes1.length > 0) {
        attribute1 = attributes1[0].attribute;
      }
    }
    // else{
    //     if( product.hasOwnProperty("ParentProductID")
    //         && product.ParentProductID !=="" )
    //     {
    //         let parentProduct = await fetchProduct(this.props.config.key, "", this.props.product.ParentProductID);
    //         // console.log('parentProduct',parentProduct);
    //         OMNITag =parentProduct.OMNITag;
    //         variants = parentProduct.Variants;
    //         attributes= parentProduct.Attributes;
    //     }
    // }
    //
    // console.log("attributes",attributes)
    // attributes.map(a=>{
    //     if(a.Attribute!==""){
    //         if(variantLabel ==="")
    //         {
    //             variantLabel=a.Attribute;
    //         }
    //         else
    //         {
    //             variantLabel= variantLabel + " > " + a.Attribute;
    //         }
    //     }
    // })

    this.calculateDiscountPercentage(mrp, retailPrice);

    let showAppointmentOption = false;
    if (
      this.props.config &&
      this.props.config.hasOwnProperty("CheckoutConfig") &&
      this.props.config.CheckoutConfig &&
      this.props.config.CheckoutConfig.hasOwnProperty(
        "EnableAppointmentForServices"
      ) &&
      this.props.config.CheckoutConfig.EnableAppointmentForServices &&
      product.ProductTypeID === 3
    ) {
      showAppointmentOption = true;
    }

    this.setState({
      showAppointmentOption: showAppointmentOption,
      outOfStock: outOfStock,
      // showVariantDropDown:showVariantDropDown,
      retailPrice: retailPrice,
      mrp: mrp,
      product: product,
      currentImgeURL: currentImgeURL,
      customFieldName1: customFieldName1,
      customFieldName2: customFieldName2,
      customFieldName3: customFieldName3,
      customFieldName4: customFieldName4,
      customFieldName5: customFieldName5,
      customFieldName6: customFieldName6,
      qty: qty,
      notes: notes,
      variants: variants,
      attributes: attributes,
      OMNITag: OMNITag,
      attributes1: attributes1,
      attribute1: attribute1,
      attributeLabel1: attributeLabel1,
      attributeLabel2: attributeLabel2,
      attributeLabel3: attributeLabel3,
    });

    if (
      !this.props.checkOut &&
      product.Variants &&
      product.Variants.length > 0
    ) {
      this.onAttribute1Selection(product, attribute1);
    } else {
      this.setState({
        attribute1: product.hasOwnProperty("Attribute1")
          ? product.Attribute1
          : "",
        attribute2: product.hasOwnProperty("Attribute2")
          ? product.Attribute2
          : "",
        attribute3: product.hasOwnProperty("Attribute3")
          ? product.Attribute3
          : "",
      });
    }
    // console.log(this.props.config)
    console.log("this.props.displayMode", this.props.displayMode);
  }

  onAttribute1Selection(product, attribute1) {
    let attributes2 = [];
    let attribute2 = "";

    let retailPrice = this.state.retailPrice;
    let mrp = this.state.mrp;

    product.Variants.map((v) => {
      if (
        v.Attribute2.trim() !== "" &&
        v.Attribute1 === attribute1 &&
        attributes2.filter((f) => f.attribute.trim() === v.Attribute2.trim())
          .length === 0
      ) {
        attributes2.push({ attribute: v.Attribute2.trim() });
      }
    });
    // console.log('attributes2',attributes2)
    if (attributes2.length > 0) {
      attribute2 = attributes2[0].attribute;
    }

    let attributes3 = [];
    let attribute3 = "";

    product.Variants.map((v) => {
      if (
        v.Attribute3.trim() !== "" &&
        v.Attribute1 === attribute1 &&
        v.Attribute2 === attribute2 &&
        attributes3.filter((f) => f.attribute.trim() === v.Attribute3.trim())
          .length === 0
      ) {
        attributes3.push({ attribute: v.Attribute3.trim() });
      }
    });

    if (attributes3.length > 0) {
      attribute3 = attributes3[0].attribute;
    }

    let tmp = product.Variants.filter(
      (f) =>
        f.Attribute1 === attribute1 &&
        f.Attribute2 === attribute2 &&
        f.Attribute3 === attribute3
    );
    if (tmp.length > 0) {
      let variant = tmp[0];
      // console.log('variant.ProductID',variant.ProductID);

      product.key = variant.ProductID;
      product.MRP = variant.MRP;
      product.RetailPrice = variant.RetailPrice;
      product.SKU = variant.SKU;
      product.DiscountAmount = variant.DiscountAmount;
      product.DiscountPercentage = variant.DiscountPercentage;
      retailPrice = variant.RetailPrice;
      mrp = variant.MRP;
      product.Attribute1 = attribute1;
      product.Attribute2 = attribute2;
      product.Attribute3 = attribute3;
    } else {
      // alert("Product not found. Please select different product")
    }
    // console.log("product.key",product.key);
    // console.log('this.props.lineItems',this.props.lineItems)
    let qty = 0;
    if (
      this.props.lineItems.filter((f) => f.Product.key === product.key).length >
      0
    ) {
      let lineItem = this.props.lineItems.filter(
        (f) => f.Product.key === product.key
      )[0];
      // console.log('lineItem',lineItem )
      qty = lineItem.Qty;
    } else {
      qty = 0;
    }
    // console.log('qty',qty);
    this.setState({
      attribute1: attribute1,
      attributes2: attributes2,
      attribute2: attribute2,
      attributes3: attributes3,
      attribute3: attribute3,
      product: product,
      retailPrice: retailPrice,
      mrp: mrp,
      qty: qty,
    });
  }

  onAttribute2Selection(product, attribute1, attribute2) {
    // console.log('attribute1',attribute1);
    // console.log('attribute2',attribute2);

    let attributes3 = [];
    let attribute3 = "";
    let retailPrice = this.state.retailPrice;
    let mrp = this.state.mrp;

    product.Variants.map((v) => {
      if (
        v.Attribute3.trim() !== "" &&
        v.Attribute1 === attribute1 &&
        v.Attribute2 === attribute2 &&
        attributes3.filter((f) => f.attribute.trim() === v.Attribute3.trim())
          .length === 0
      ) {
        attributes3.push({ attribute: v.Attribute3.trim() });
      }
    });

    if (attributes3.length > 0) {
      attribute3 = attributes3[0].Attribute;
    }

    let tmp = product.Variants.filter(
      (f) =>
        f.Attribute1 === attribute1 &&
        f.Attribute2 === attribute2 &&
        f.Attribute3 === attribute3
    );
    if (tmp.length > 0) {
      let variant = tmp[0];
      // console.log('variant',variant);
      product.key = variant.ProductID;
      product.MRP = variant.MRP;
      product.RetailPrice = variant.RetailPrice;
      product.SKU = variant.SKU;
      product.DiscountAmount = variant.DiscountAmount;
      product.DiscountPercentage = variant.DiscountPercentage;
      retailPrice = variant.RetailPrice;
      mrp = variant.MRP;
      product.Attribute1 = attribute1;
      product.Attribute2 = attribute2;
      product.Attribute3 = attribute3;
    } else {
      alert("Product not found. Please select different product");
    }

    let qty = 0;
    if (
      this.props.lineItems.filter((f) => f.Product.key === product.key).length >
      0
    ) {
      let lineItem = this.props.lineItems.filter(
        (f) => f.Product.key === product.key
      )[0];
      console.log("lineItem", lineItem);
      qty = lineItem.Qty;
    } else {
      qty = 0;
    }

    this.setState({
      attribute2: attribute2,
      attributes3: attributes3,
      attribute3: attribute3,
      product: product,
      retailPrice: retailPrice,
      mrp: mrp,
      qty: qty,
    });
  }

  onAttribute3Selection(product, attribute1, attribute2, attribute3) {
    let retailPrice = this.state.retailPrice;
    let mrp = this.state.mrp;

    let tmp = product.Variants.filter(
      (f) =>
        f.Attribute1 === attribute1 &&
        f.Attribute2 === attribute2 &&
        f.Attribute3 === attribute3
    );
    if (tmp.length > 0) {
      let variant = tmp[0];
      product.key = variant.ProductID;
      product.MRP = variant.MRP;
      product.RetailPrice = variant.RetailPrice;
      product.SKU = variant.SKU;
      product.DiscountAmount = variant.DiscountAmount;
      product.DiscountPercentage = variant.DiscountPercentage;
      retailPrice = variant.RetailPrice;
      mrp = variant.MRP;
      product.Attribute1 = attribute1;
      product.Attribute2 = attribute2;
      product.Attribute3 = attribute3;
    } else {
      alert("Product not found. Please select different product");
    }

    let qty = 0;
    if (
      this.props.lineItems.filter((f) => f.Product.key === product.key).length >
      0
    ) {
      let lineItem = this.props.lineItems.filter(
        (f) => f.Product.key === product.key
      )[0];
      console.log("lineItem", lineItem);
      qty = lineItem.Qty;
    } else {
      qty = 0;
    }
    this.setState({
      attribute3: attribute3,
      product: product,
      retailPrice: retailPrice,
      mrp: mrp,
      qty: qty,
    });
  }

  calculateDiscountPercentage(mrp, retailPrice) {
    if (mrp !== retailPrice && mrp !== "") {
      //calculate profit percetage
      let disAmt = Number(mrp) - Number(retailPrice);
      let discPer = (disAmt * 100) / Number(mrp);
      this.setState({ discountPercentage: discPer.toFixed(0) });
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log('nextProps',nextProps);
    // if (nextProps.product.hasOwnProperty("InventoryType")
    //     && nextProps.product.InventoryType === "Variants"
    //     && !nextProps.checkOut
    // ) {
    //     //for variants find child
    //     // console.log('0. if')
    //     let qty = 0;
    //     for (let i = 0; i < nextProps.lineItems.length; i++) {
    //         let lineItem = nextProps.lineItems[i];
    //         if (lineItem.Product.hasOwnProperty("ParentProductID")
    //             && lineItem.Product.ParentProductID !== ""
    //             && lineItem.Product.ParentProductID === nextProps.product.key
    //         ) {
    //             qty = Number(qty) + Number(lineItem.Qty);
    //         }
    //     }
    //     this.setState({ qty: qty });
    // } else {
    // console.log('1. else block')
    if (
      nextProps.lineItems.filter((f) => f.Product.key === nextProps.product.key)
        .length > 0
    ) {
      let lineItem = nextProps.lineItems.filter(
        (f) => f.Product.key === nextProps.product.key
      )[0];
      this.setState({ qty: lineItem.Qty });
      // console.log('1.1 lineItem.Qty', lineItem.Qty)
    } else {
      this.setState({ qty: 0 });
    }
    // }
  }

  redirectToDetailsPage(name) {
    const { history } = this.props;
    history.push(mainWebsitePath() + "p/" + name);
    if (this.props.onEvent) {
      this.props.onEvent("close");
    }
  }

  onLocaitonSelected() {
    this.setState({ showLocation: false });
    if (this.props.pincode) {
      //add item
      this.increaseQty();
    }
  }

  getVariantProduct(variantIndex) {
    let product = { ...this.props.product };
    let variant = this.props.product.Variants[variantIndex];
    let key = product.key;
    product.ParentProductID = key;
    product.key = variant.ProductID;
    product.MRP = variant.MRP;
    product.RetailPrice = variant.RetailPrice;
    product.SKU = variant.SKU;
    product.DiscountAmount = variant.DiscountAmount;
    product.DiscountPercentage = variant.DiscountPercentage;
    product.Name = product.Name + " / " + variant.Name;
    console.log("product", product);
    return product;
  }

  onAddItemClicked() {
    if (this.state.selectedVariantIndex > -1) {
      // alert(this.state.selectedVariantIndex)
      let product = this.getVariantProduct(this.state.selectedVariantIndex);
      this.addQty(product);
      this.setState({ showVariantSelection: false });
    }
  }

  async increaseQty() {
    // console.log(' this.props.product', this.props.product)
    // console.log('increaseQty')
    let product = null;
    if (
      isLocationCheckerEnabled(this.props.config) &&
      this.props.pincode === ""
    ) {
      //prompt for location
      this.setState({ showLocation: true });
      return;
    }
    if (
      this.props.config.CheckoutConfig.ShowLineItemNotes &&
      this.props.displayMode !== "detailpage" &&
      !this.props.checkOut
    ) {
      this.redirectToDetailsPage(this.state.OMNITag);
      return;
    }
    if (
      this.props.config.CheckoutConfig.ShowLineItemNotes &&
      this.props.displayMode === "detailpage" &&
      this.props.config.CheckoutConfig.LineItemNotesMandatory
    ) {
      //check if notes has been entered
      if (this.state.notes.trim() === "") {
        this.setState({ notesError: "Please provide value" });
        return;
      }
    }
    // if (this.state.product.Variants.length > 0 ) {
    //     // && !this.props.checkOut
    //     //check if more than one varaint
    //     if(this.props.displayMode==="detailpage"){
    //         if(! this.state.variantProduct){
    //             alert('Variant not selected. Please try again.');
    //             return;
    //         }
    //         product = { ...this.props.product };
    //         let variant = this.state.variantProduct;
    //         let key = product.key;
    //         product.ParentProductID = key;
    //         product.key = variant.ProductID;
    //         product.MRP = variant.MRP;
    //         product.RetailPrice = variant.RetailPrice;
    //         product.SKU = variant.SKU;
    //         product.DiscountAmount = variant.DiscountAmount;
    //         product.DiscountPercentage = variant.DiscountPercentage;
    //         product.Name = product.Name + " / " + variant.Name;
    //         product.ParentProductName = product.Name;
    //     }
    //     else if(this.state.showVariantDropDown){

    //     }
    //     if(this.state.product.Variants.length > 1){

    //     }
    //     else
    //     {
    //         product = this.getVariantProduct(0)
    //     }
    // }
    // else{
    //     product = this.state.product;
    // }
    product = this.state.product;
    // console.log('product',product)
    await this.addQty({ ...product });
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange();
    }
  }

  addQty(p) {
    let qty = Number(this.state.qty) + 1;
    let product = p;
    const { lineItems, loadCart, loadCartTotal, lineItemTotal } = this.props;
    let lineItemIndex = -1;
    let lineItem = {};
    let cartTotal = {};
    for (let i = 0; i < lineItems.length; i++) {
      if (lineItems[i].Product.key === product.key) {
        lineItemIndex = i;
        lineItem = lineItems[i];
      }
    }
    if (lineItemIndex === -1) {
      lineItem.RetailPrice = product.RetailPrice;
      lineItem.DiscountPer = product.hasOwnProperty("DiscountPercentage")
        ? product.DiscountPercentage
        : 0;
      lineItem.DiscountAmount = 0;
      lineItem.Qty = 1;
      lineItem.SubTotal = 0;
      lineItem.ChargeTaxOnProduct = product.ChargeTaxOnProduct;
      lineItem.PriceBasedTax = product.ChargeTaxOnProduct
        ? product.PriceBasedTax
        : false;
      lineItem.HSNSACCode = product.HSNSACCode;
      lineItem.TaxInclusive = product.ChargeTaxOnProduct
        ? product.TaxInclusive
        : false;
      lineItem.TaxGroup = product.TaxGroup;
      lineItem.TaxID = product.TaxID;
      lineItem.SubTotalWithDiscount = 0;
      lineItem.TaxableAmount = 0;
      lineItem.TotalTax = 0;
      lineItem.Amount = 0;
      lineItem.Product = product;
      lineItem.Employee = { key: "" };
      lineItem.Notes = this.state.notes;
    } else {
      lineItem.Qty = Number(lineItem.Qty) + 1;
    }
    lineItem = onLineItemChange(lineItem, this.props.productTaxes);
    if (lineItemIndex >= 0) {
      lineItems[lineItemIndex] = lineItem;
    } else {
      lineItems.unshift(lineItem);
    }
    cartTotal = calculateBillTotal(lineItems, lineItemTotal.shippingOption);
    loadCart(lineItems);
    loadCartTotal(cartTotal);
    this.setState({ qty: qty });
  }

  async decreaseQty() {
    if (this.props.product.hasOwnProperty("MinQty")) {
      // console.log("MinQty", this.props.product.MinQty);
      if (Number(this.state.qty) <= Number(this.props.product.MinQty)) {
        return false;
      }
    }
    let lineItemIndex = -1;
    if (this.state.variants.length > 0 && !this.props.checkOut) {
      let lineCount = 0;

      for (let i = 0; i < this.props.lineItems.length; i++) {
        let lineItem = this.props.lineItems[i];
        if (
          lineItem.Product.hasOwnProperty("ParentProductID") &&
          lineItem.Product.ParentProductID !== "" &&
          lineItem.Product.ParentProductID === this.props.product.key
        ) {
          lineCount += 1;
          lineItemIndex = i;
        }
      }
      if (lineCount > 1) {
        this.setState({ showVariantRemoval: true });
        return;
      }
    } else {
      lineItemIndex = this.props.lineItems.findIndex(
        (p) => p.Product.key === this.props.product.key
      );
    }
    await this.minusQty(lineItemIndex);
    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange();
    }
  }

  minusQty(lineItemIndex) {
    console.log("minusQty");
    let qty = Number(this.state.qty) - 1;
    const { lineItems, loadCart, loadCartTotal, lineItemTotal } = this.props;

    if (this.props.hasOwnProperty("onChange")) {
      this.props.onChange();
    }

    let cartTotal = {};
    if (lineItemIndex >= 0) {
      let lineItem = lineItems[lineItemIndex];
      let qty = lineItem.Qty - 1;
      if (qty <= 0) {
        lineItems.splice(lineItemIndex, 1);
        cartTotal = calculateBillTotal(lineItems, lineItemTotal.shippingOption);
      } else {
        lineItem.Qty = qty;
        lineItem = onLineItemChange(lineItem, this.props.productTaxes);
        lineItems[lineItemIndex] = lineItem;
        cartTotal = calculateBillTotal(lineItems, lineItemTotal.shippingOption);
      }
      loadCart(lineItems);
      loadCartTotal(cartTotal);
    }
    this.setState({ qty: qty });
  }

  getThumbNailImage = (m, className) => {
    let url = "";
    if (!m) {
      return;
    }
    if (m.Images && m.Images.length > 0) {
      if (m.Images[0]) {
        if (m.Images[0].ImagePath !== "") {
          url = m.Images[0].ImagePath;
        }
      }
    }

    if (url !== "") {
      return (
        <img
          className={className}
          onClick={() => this.redirectToDetailsPage(m.OMNITag)}
          src={url}
          alt=""
        />
      );
    } else {
      // console.log('url',url)
      return (
        <img
          className={className}
          onClick={() => this.redirectToDetailsPage(m.OMNITag)}
          // src={require("./no-image.png")}
          src={noimage}
          alt=""
        />
      );
    }
  };

  renderDiscountBadge() {
    return (
      <React.Fragment>
        {this.state.discountPercentage > 0 && (
          <div className="discount-badge">
            {this.state.discountPercentage}% off
          </div>
        )}
      </React.Fragment>
    );
  }

  renderAddButton() {
    //check if it is service and appointment is enabled

    return (
      <React.Fragment>
        {this.state.showAppointmentOption && (
          <React.Fragment>
            {this.state.qty === 0 && (
              <div
                className={
                  this.props.displayMode === "detailpage"
                    ? "product-add-btn"
                    : "product-add-btn pull-right"
                }
                onClick={() => this.increaseQty()}
              >
                Add
              </div>
            )}
            {this.state.qty !== 0 && (
              <div
                className={
                  this.props.displayMode === "detailpage"
                    ? "product-add-btn"
                    : "product-add-btn pull-right"
                }
                onClick={() => this.decreaseQty()}
              >
                Remove
              </div>
            )}
          </React.Fragment>
        )}

        {!this.state.showAppointmentOption && !this.state.outOfStock && (
          <React.Fragment>
            {this.state.qty === 0 && (
              <div
                className={
                  this.props.displayMode === "detailpage"
                    ? "product-add-btn"
                    : "product-add-btn pull-right"
                }
                onClick={() => this.increaseQty()}
              >
                Add
              </div>
            )}
            {this.state.qty !== 0 && (
              <div
                className={
                  this.props.displayMode === "detailpage"
                    ? "product-add-btn"
                    : "product-add-btn pull-right"
                }
              >
                <div onClick={() => this.decreaseQty()}>-</div>
                <div>{this.state.qty}</div>
                <div onClick={() => this.increaseQty()}>+</div>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  showNextLevel(attribute1, attribute2 = "", attribute3 = "") {
    let showAttribute1 = this.state.showAttribute1;
    let showAttribute2 = false;
    let showAttribute3 = false;
    let variantProduct = this.props.selectedVariantProduct;

    if (
      variantProduct.Variants.filter(
        (f) =>
          f.Attribute1 === attribute1 &&
          f.Attribute2 === attribute2 &&
          f.Attribute3 === attribute3
      ).length > 0
    ) {
      let variantFound = variantProduct.Variants.filter(
        (f) =>
          f.Attribute1 === attribute1 &&
          f.Attribute2 === attribute2 &&
          f.Attribute3 === attribute3
      )[0];
      // console.log("variantFound", variantFound);
      if (variantFound) {
        // console.log(this.props.config.Products);

        if (
          this.props.config.Products.filter(
            (f) => f.key === variantFound.ProductID
          ).length > 0
        ) {
          let product = this.props.config.Products.filter(
            (f) => f.key === variantFound.ProductID
          )[0];
          // console.log("product", product);
          if (product) {
            // this.props.onOK(product);
          }
        }
      }
    } else {
      if (attribute2 === "") {
        showAttribute2 = true;
        showAttribute1 = false;
      }
      if (attribute2 !== "" && attribute3 === "") {
        showAttribute1 = false;
        showAttribute3 = true;
      }
    }
    this.setState({
      selectedAttribute1: attribute1,
      selectedAttribute2: attribute2,
      selectedAttribute3: attribute3,
      showAttribute1: showAttribute1,
      showAttribute2: showAttribute2,
      showAttribute3: showAttribute3,
    });
  }

  //12 dec 22
  renderVariant() {
    return (
      <React.Fragment>
        <div
          className="product-variant-overlay"
          onClick={() => {
            this.setState({ showVariantSelection: false });
          }}
        ></div>
        <div
          className={
            isMobile() ? "product-variant mobile" : "product-variant desktop"
          }
        >
          <div className="main">{this.props.product.Name}</div>
          {this.state.variants.map((m, index) => (
            <div
              key={"prouctvariant_" + index}
              onClick={() => {
                document.getElementById(index).checked = true;
                this.setState({
                  selectedVariantIndex: index,
                });
              }}
              className="variant"
              style={{ cursor: "pointer" }}
            >
              <input type="radio" id={index} name="paymentmode" value={index} />
              <label>
                {m.Attribute1}
                {this.props.config.hasOwnProperty("CheckoutConfig") &&
                  this.props.config.CheckoutConfig &&
                  this.props.config.CheckoutConfig.ShowProductPrice && (
                    <React.Fragment>
                      &nbsp;&nbsp;&nbsp;&nbsp;₹
                      {m.RetailPrice}
                    </React.Fragment>
                  )}
              </label>
            </div>
          ))}
          <div
            className="button"
            onClick={this.onAddItemClicked}
            style={{ cursor: "pointer" }}
          >
            ADD ITEM
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderMRP() {
    return (
      <React.Fragment>
        {this.props.config.hasOwnProperty("CheckoutConfig") &&
          this.props.config.CheckoutConfig.hasOwnProperty(
            "ProductDetailPageOptions"
          ) &&
          this.props.config.CheckoutConfig.ProductDetailPageOptions
            .ShowProductMRP &&
          this.state.product &&
          this.state.product.MRP !== this.state.product.RetailPrice && (
            <React.Fragment>
              <span className="mrp">
                <strike>
                  {/* ₹ {this.props.product.MRP} */}₹ {this.state.mrp}
                </strike>
              </span>
            </React.Fragment>
          )}
      </React.Fragment>
    );
  }

  renderList() {
    return (
      <React.Fragment>
        {this.state.product && (
          <React.Fragment>
            {this.state.outOfStock && (
              <div className="out-of-stock-list">
                <span className="">Out Of Stock</span>
              </div>
            )}

            <div
              className={
                isMobile()
                  ? " product product-list-new mobile-product-list-new"
                  : " product product-list"
              }
            >
              <div>
                {this.renderDiscountBadge()}
                {this.getThumbNailImage(this.state.product, "")}
              </div>
              <div style={{ width: "70%" }}>
                <div className="title">{this.state.product.Name}</div>
                <div className="variant-dropdown">
                  {!this.props.checkOut && this.renderVariantSelection()}
                  {this.props.checkOut && (
                    <React.Fragment>
                      {this.state.product.hasOwnProperty("Attribute1") &&
                        this.state.product.Attribute1 !== "" && (
                          <div>
                            {this.state.attributeLabel1} :{" "}
                            {this.state.product.Attribute1}
                          </div>
                        )}
                      {this.state.product.hasOwnProperty("Attribute2") &&
                        this.state.product.Attribute2 !== "" && (
                          <div>
                            {this.state.attributeLabel2} :{" "}
                            {this.state.product.Attribute2}
                          </div>
                        )}
                      {this.state.product.hasOwnProperty("Attribute3") &&
                        this.state.product.Attribute3 !== "" && (
                          <div>
                            {this.state.attributeLabel3} :{" "}
                            {this.state.product.Attribute3}
                          </div>
                        )}
                    </React.Fragment>
                  )}
                </div>
                <div className="price-row">
                  {this.props.config.hasOwnProperty("CheckoutConfig") &&
                    this.props.config.CheckoutConfig &&
                    this.props.config.CheckoutConfig.ShowProductPrice && (
                      <span className="price">
                        ₹{this.props.product.RetailPrice}
                      </span>
                    )}
                  {this.renderMRP()}
                  {this.renderAddButton()}
                </div>
                <div className="title">{this.state.notes}</div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderListWithNoImage() {
    return (
      <React.Fragment>
        <div className="row product product-list border-bottom">
          {/* <div className="col-4 col-xs-4">
                        {this.getThumbNailImage(this.props.product, "")}
                    </div> */}
          <div className="col-12 col-xs-12" style={{ paddingLeft: "0px" }}>
            <div className="title">{this.props.product.Name}</div>
            <div className="price-row">
              {this.props.config.hasOwnProperty("CheckoutConfig") &&
                this.props.config.CheckoutConfig &&
                this.props.config.CheckoutConfig.ShowProductPrice && (
                  <span className="price">
                    ₹{this.props.product.RetailPrice}
                  </span>
                )}
              {this.renderMRP()}
              {this.renderAddButton()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderThumbnail() {
    // return <div style={{border:"solid 1px black"}}>
    // {this.props.product.Name}
    // </div>
    return (
      <React.Fragment>
        {this.state.outOfStock && (
          <div className="out-of-stock-thumbnail">
            <span className="">Out Of Stock</span>
          </div>
        )}
        <div className="product product-thumbnail-new">
          {this.renderDiscountBadge()}
          {this.getThumbNailImage(this.props.product, "")}
          <div className="title-thumbnail">{this.props.product.Name}</div>
          <div className="variant-dropdown">
            {this.renderVariantSelection()}
          </div>
          <div className="price-row">
            {this.props.config.hasOwnProperty("CheckoutConfig") &&
              this.props.config.CheckoutConfig &&
              this.props.config.CheckoutConfig.ShowProductPrice && (
                <span className="price">
                  ₹{this.state.retailPrice}
                  {this.renderMRP()}
                </span>
              )}
            {this.renderAddButton()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  onVariantSelected(attribute1Index, attribute2Index, attribute3Index) {
    // console.log('this.props.product',this.props.product)
    let attributes1 = this.props.product.Attributes[0].AttributeValues;
    let attributes2 =
      this.props.product.Attributes.length > 1
        ? this.props.product.Attributes[1].AttributeValues
        : [];
    let attributes3 =
      this.props.product.Attributes.length > 2
        ? this.props.product.Attributes[2].AttributeValues
        : [];
    let selectedAttribute1 = "";
    let selectedAttribute2 = "";
    let selectedAttribute3 = "";
    let retailPrice = 0;
    let mrp = 0;
    let qty = 0;
    let attributeLabel1 = this.props.product.Attributes[0].Attribute;
    let attributeLabel2 =
      this.props.product.Attributes.length > 1
        ? this.props.product.Attributes[1].Attribute
        : "";
    let attributeLabel3 =
      this.props.product.Attributes.length > 2
        ? this.props.product.Attributes[2].Attribute
        : "";

    attributes1.map((m, index) => {
      if (index === attribute1Index) {
        m.Selected = true;
        selectedAttribute1 = m.label;
      } else {
        m.Selected = false;
      }
    });

    if (attributes2.length > 0) {
      attributes2.map((m, index) => {
        if (index === attribute2Index) {
          m.Selected = true;
          selectedAttribute2 = m.label;
        } else {
          m.Selected = false;
        }
      });
    }

    if (attributes3.length > 0) {
      attributes3.map((m, index) => {
        if (index === attribute3Index) {
          m.Selected = true;
          selectedAttribute3 = m.label;
        } else {
          m.Selected = false;
        }
      });
    }

    let variantProduct = null;
    this.props.product.Variants.map((m) => {
      if (
        m.Attribute1.trim() === selectedAttribute1 &&
        m.Attribute2.trim() === selectedAttribute2 &&
        m.Attribute3.trim() === selectedAttribute3
      ) {
        variantProduct = m;
      }
    });
    // console.log('variantProduct',variantProduct)
    if (variantProduct) {
      // console.log('this.props.lineItems',this.props.lineItems)
      retailPrice = variantProduct.RetailPrice;
      mrp = variantProduct.MRP;
      for (let i = 0; i < this.props.lineItems.length; i++) {
        let lineItem = this.props.lineItems[i];
        if (lineItem.Product.key === variantProduct.ProductID) {
          qty = Number(qty) + Number(lineItem.Qty);
        }
      }
    }

    // if (this.props.product.hasOwnProperty("InventoryType") && this.props.product.InventoryType === "Variants" && !this.props.checkOut) {
    //for variants find child

    // this.setState({ qty: qty });
    // 12 dec 22
    // }

    this.setState({
      attributes1: attributes1,
      attributes2: attributes2,
      attributes3: attributes3,
      attribute1Index: attribute1Index,
      attribute2Index: attribute2Index,
      attribute3Index: attribute3Index,
      retailPrice: retailPrice,
      mrp: mrp,
      variantProduct: variantProduct,
      attributeLabel1: attributeLabel1,
      attributeLabel2: attributeLabel2,
      attributeLabel3: attributeLabel3,
      qty: qty,
    });
  }

  renderVariantSelection() {
    return (
      <React.Fragment>
        {this.state.product && (
          <React.Fragment>
            {this.state.attributes1.length > 0 && (
              <Input
                type="select"
                label={this.state.attributeLabel1}
                value={this.state.attribute1}
                onChange={(value) => {
                  this.onAttribute1Selection(this.state.product, value);
                }}
              >
                {this.state.attributes1.map((a) => (
                  <option value={a.attribute}>{a.attribute}</option>
                ))}
              </Input>
            )}
            {this.state.attributes2.length > 0 && (
              <Input
                type="select"
                label={this.state.attributeLabel2}
                value={this.state.attribute2}
                onChange={(value) => {
                  this.onAttribute2Selection(
                    this.state.product,
                    this.state.attribute1,
                    value
                  );
                }}
              >
                {this.state.attributes2.map((a) => (
                  <option value={a.attribute}>{a.attribute}</option>
                ))}
              </Input>
            )}
            {this.state.attributes3.length > 0 && (
              <Input
                type="select"
                label={this.state.attributeLabel3}
                value={this.state.attribute3}
                onChange={(value) => {
                  this.onAttribute3Selection(
                    this.state.product,
                    this.state.attribute1,
                    this.state.attribute2,
                    value
                  );
                }}
              >
                {this.state.attributes3.map((a) => (
                  <option value={a.attribute}>{a.attribute}</option>
                ))}
              </Input>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderVariantSelectionForDetailPage() {
    return (
      <div className="col-md-12 col-12">
        {this.props.product && (
          <React.Fragment>
            <div className="variant-attribute">
              {this.state.attributeLabel1}
            </div>
            <div className="variant-attribute-values">
              {this.state.attributes1.map((attributeValue, index) => (
                <div
                  key={"attr_value_" + index}
                  className={
                    attributeValue.Selected
                      ? "varaint-attribute-value-selected"
                      : "varaint-attribute-value"
                  }
                  onClick={() => this.onVariantSelected(index, 0, 0)}
                >
                  {attributeValue.label}
                </div>
              ))}
            </div>
            <div className="variant-attribute">
              {this.state.attributeLabel2}
            </div>
            <div className="variant-attribute-values">
              {this.state.attributes2.map((attributeValue, index) => (
                <div
                  key={"attr_value_" + index}
                  className={
                    attributeValue.Selected
                      ? "varaint-attribute-value-selected"
                      : "varaint-attribute-value"
                  }
                  onClick={() =>
                    this.onVariantSelected(this.state.attribute1Index, index, 0)
                  }
                >
                  {attributeValue.label}
                </div>
              ))}
            </div>
            <div className="variant-attribute">
              {this.state.attributeLabel3}
            </div>
            <div className="variant-attribute-values">
              {this.state.attributes3.map((attributeValue, index) => (
                <div
                  key={"attr_value_" + index}
                  className={
                    attributeValue.Selected
                      ? "varaint-attribute-value-selected"
                      : "varaint-attribute-value"
                  }
                  onClick={() =>
                    this.onVariantSelected(
                      this.state.attribute1Index,
                      this.state.attribute2Index,
                      index
                    )
                  }
                >
                  {attributeValue.label}
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderDetailPage() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6 col-12" data-sku={this.props.product.SKU}>
            <SideBySideMagnifier
              imageSrc={this.state.currentImgeURL}
              imageAlt={this.props.product.Name}
            />
            {this.props.product.Images.length > 1 && (
              <div className="col-12" style={{ textAlign: "center" }}>
                {this.props.product.Images.map((img, index) => (
                  <div
                    key={"img" + index}
                    className="product-list-image-div"
                    style={{
                      display: "inline-block",
                      margin: "10px 5px 0px 0px",
                    }}
                    onClick={() =>
                      this.setState({ currentImgeURL: img.ImagePath })
                    }
                  >
                    <img
                      src={img.ImagePath}
                      className="product-list-image"
                      alt="..."
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="col-md-6 col-12">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="product-detail-title">
                  {this.props.product.Name}
                </div>
              </div>
              {/* {this.renderVariantSelectionForDetailPage()} */}
              <div className="col-md-12 col-12">
                {this.renderVariantSelection()}
              </div>
              {this.props.config.hasOwnProperty("CheckoutConfig") &&
                this.props.config.CheckoutConfig.hasOwnProperty(
                  "ProductDetailPageOptions"
                ) &&
                this.props.config.CheckoutConfig.ProductDetailPageOptions
                  .ShowProductMRP &&
                this.state.mrp !== this.state.retailPrice && (
                  <div className="col-md-12 col-12">
                    <div className="product-detail-mrp">
                      <strike>₹ {this.state.mrp}</strike>
                    </div>
                  </div>
                )}
              {this.props.config.hasOwnProperty("CheckoutConfig") &&
                this.props.config.CheckoutConfig &&
                this.props.config.CheckoutConfig.ShowProductPrice && (
                  <div className="col-md-12 col-12">
                    <div className="product-detail-retail-price">
                      ₹ {this.state.retailPrice}
                    </div>
                  </div>
                )}
              {this.props.config.hasOwnProperty("CheckoutConfig") &&
                this.props.config.CheckoutConfig &&
                this.props.config.CheckoutConfig.ShowLineItemNotes && (
                  <React.Fragment>
                    <div className="col-md-12 col-12">
                      {/* <div className="product-detail-retail-price"> */}
                      <Input
                        type="text"
                        label={
                          this.props.config.CheckoutConfig.LineItemNotesText
                        }
                        disabled={this.state.processing}
                        error={this.state.notesError}
                        value={this.state.notes}
                        onChange={(value) =>
                          this.setState({ notes: value, notesError: "" })
                        }
                      />
                      <br />
                      {/* </div> */}
                    </div>
                  </React.Fragment>
                )}
              <div className="col-md-12 col-12">
                {this.renderAddButton()}
                {this.state.outOfStock && (
                  <div className="product-detail-product-outofstock">
                    Out of stock
                  </div>
                )}
              </div>
              {this.props.config.CheckoutConfig.ProductDetailPageOptions
                .ShowProductSKU && (
                <div className="col-md-12">
                  <div className="product-detail-sku-lable">SKU:</div>
                  <div className="product-detail-sku">
                    {this.props.product.SKU}
                  </div>
                </div>
              )}
              {this.props.config.CheckoutConfig.ProductDetailPageOptions
                .ShowProductBarCode && (
                <div className="col-md-12">
                  <div className="product-detail-barcode-lable">Barcode:</div>
                  <div className="product-detail-barcode">
                    {this.props.product.BarCode}
                  </div>
                </div>
              )}
              {this.props.config.hasOwnProperty("CheckoutConfig") &&
                this.props.config.CheckoutConfig.hasOwnProperty(
                  "ProductDetailPageOptions"
                ) && (
                  <React.Fragment>
                    {/* {this.props.config.CheckoutConfig.ProductDetailPageOptions.ShowProductDescription && (
                                <div className="col-md-12">
                                    <div className="product-detail-description-label">Product Details:</div>
                                    <div className="product-detail-description">
                                        {this.props.product.Description}
                                    </div>
                                    <div className="product-detail-long-description">
                                        <div dangerouslySetInnerHTML={{ __html: this.props.product.LongDescription }} />
                                    </div>
                            </div>)} */}
                    {this.props.config.CheckoutConfig.ProductDetailPageOptions
                      .ShowProductCustomField1 &&
                      this.props.product.CustomField1 !== "" && (
                        <div className="col-md-12">
                          <div className="product-detail-customfield1-lable">
                            {this.state.customFieldName1}:
                          </div>
                          <div className="product-detail-customfield1">
                            {this.props.product.CustomField1}
                          </div>
                        </div>
                      )}
                    {this.props.config.CheckoutConfig.ProductDetailPageOptions
                      .ShowProductCustomField2 &&
                      this.props.product.CustomField2 !== "" && (
                        <div className="col-md-12">
                          <div className="product-detail-customfield2-lable">
                            {this.state.customFieldName2}:
                          </div>
                          <div className="product-detail-customfield2">
                            {this.props.product.CustomField2}
                          </div>
                        </div>
                      )}
                    {this.props.config.CheckoutConfig.ProductDetailPageOptions
                      .ShowProductCustomField3 &&
                      this.props.product.CustomField3 !== "" && (
                        <div className="col-md-12">
                          <div className="product-detail-customfield3-lable">
                            {this.state.customFieldName3}:
                          </div>
                          <div className="product-detail-customfield3">
                            {this.props.product.CustomField3}
                          </div>
                        </div>
                      )}
                    {this.props.config.CheckoutConfig.ProductDetailPageOptions
                      .ShowProductCustomField4 &&
                      this.props.product.CustomField4 !== "" && (
                        <div className="col-md-12">
                          <div className="product-detail-customfield4-lable">
                            {this.state.customFieldName4}:
                          </div>
                          <div className="product-detail-customfield4">
                            {this.props.product.CustomField4}
                          </div>
                        </div>
                      )}
                    {this.props.config.CheckoutConfig.ProductDetailPageOptions
                      .ShowProductCustomField5 &&
                      this.props.product.CustomField5 !== "" && (
                        <div className="col-md-12">
                          <div className="product-detail-customfield5-lable">
                            {this.state.customFieldName5}:
                          </div>
                          <div className="product-detail-customfield5">
                            {this.props.product.CustomField5}
                          </div>
                        </div>
                      )}
                    {this.props.config.CheckoutConfig.ProductDetailPageOptions
                      .ShowProductCustomField6 &&
                      this.props.product.CustomField6 !== "" && (
                        <div className="col-md-12">
                          <div className="product-detail-customfield6-lable">
                            {this.state.customFieldName6}:
                          </div>
                          <div className="product-detail-customfield6">
                            {this.props.product.CustomField6}
                          </div>
                        </div>
                      )}
                  </React.Fragment>
                )}
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-12"> */}
          {this.props.config.hasOwnProperty("CheckoutConfig") &&
            this.props.config.CheckoutConfig.hasOwnProperty(
              "ProductDetailPageOptions"
            ) && (
              <React.Fragment>
                {this.props.config.CheckoutConfig.ProductDetailPageOptions
                  .ShowProductDescription && (
                  <div className="col-md-12">
                    <div className="product-detail-description-label">
                      Product Details:
                    </div>
                    <div className="product-detail-description">
                      {this.props.product.Description}
                    </div>
                    <div className="product-detail-long-description">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.product.LongDescription,
                        }}
                      />
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          {/* </div> */}
        </div>
      </React.Fragment>
    );
  }

  renderSearchList() {
    return (
      <div className="search-list-product">
        <div className="search-list-img">
          {this.getThumbNailImage(this.props.product, "search-list-img")}
        </div>
        <div className="search-list-productname">
          {this.props.product.Name}
          {isMobile() && (
            <div>
              ₹ {this.props.product.RetailPrice} &nbsp;{this.renderMRP()}
            </div>
          )}
        </div>
        {!isMobile() && (
          <div className="search-list-price">
            <div>₹ {this.props.product.RetailPrice} </div>
            <div>{this.renderMRP()}</div>
          </div>
        )}
        <div className="search-list-add-btn">
          {this.renderAddButton()}
          {this.state.outOfStock && (
            <div className="product-outofstock">Out of stock</div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showLocation && (
          <Location onClose={this.onLocaitonSelected} />
        )}

        {this.props.displayMode === "listthumbnail" &&
          isMobile() &&
          this.renderList()}

        {this.props.displayMode === "listthumbnail" &&
          !isMobile() &&
          this.renderThumbnail()}

        {this.props.displayMode === "list" && this.renderList()}

        {this.props.displayMode === "searchlist" && this.renderSearchList()}

        {this.props.displayMode === "list-no-image" &&
          this.renderListWithNoImage()}

        {(this.props.displayMode === "thumbnail" ||
          this.props.displayMode === "thumbnailscroll") &&
          this.renderThumbnail()}

        {this.props.displayMode === "detailpage" && this.renderDetailPage()}

        {this.state.showVariantSelection && this.renderVariant()}

        {this.state.showVariantRemoval && (
          <Alert
            title="Remove item from cart"
            content="This item has multiple customizations added. Proced to cart to remove item?"
            noClicked={() => {
              this.setState({ showVariantRemoval: false });
            }}
            yesClicked={() => {
              this.setState({ showVariantRemoval: false });
              if (isMobile()) {
                this.props.history.push(mainWebsitePath() + "checkout");
              }
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  config: state.api.config,
  pincode: state.api.pincode,
  lineItems: state.cart.lineItems,
  lineItemTotal: state.cart.lineItemTotal,
  coupon: state.cart.coupon,
  productTaxes: state.api.productTaxes,
  customFields: state.api.customFields,
});

export default connect(mapStateToProps, {
  loadCart,
  loadCartTotal,
  updateCoupon,
})(withRouter(Product));
