import React, { Component } from 'react';
import { connect } from "react-redux";
import {isMobile} from '../../services/util';


class Logo extends Component {
   
    //size sm,md,lg
    logoExists() {
        if (
            this.props.config.CheckoutConfig &&
            this.props.config.CheckoutConfig.AppLogo &&
            this.props.config.CheckoutConfig.AppLogo.Preview !== ""
        )
            return true;
        else return false;
    }

    applyLogoClass()
    {
        if(!this.props.size)
        {
            return "logo-sm"
        }
        else
        {
            return "logo-" + this.props.size
        }
    }

    renderLogo(){

        let img = ""
        if(this.props.showWebsiteLogo){
            if(this.props.config.CheckoutConfig.WebsiteImageLogo){
                img = this.props.config.CheckoutConfig.WebsiteImageLogo.Preview;
            }else if (this.props.config.CheckoutConfig.AppLogo){
                img = this.props.config.CheckoutConfig.AppLogo.Preview;
            }
        }else
        {
            if(isMobile()){
                if (this.props.config.CheckoutConfig.AppLogo){
                    img = this.props.config.CheckoutConfig.AppLogo.Preview;
                }
            }else
            {
                if(this.props.config.CheckoutConfig.WebsiteImageLogo){
                    img = this.props.config.CheckoutConfig.WebsiteImageLogo.Preview;
                }
            }
        }
        if(img!==""){
            return <img className={this.applyLogoClass()} src={img}/>
        }
    }
    render(){

        return <React.Fragment>
            {/* {this.props.config.CheckoutConfig.AppLogo && <img className={this.applyLogoClass()} src={this.props.config.CheckoutConfig.AppLogo.Preview}/>} */}
            {this.renderLogo()}
        </React.Fragment>
    }
}


const mapStateToProps = (state) => ({
    config: state.api.config,
  });

export default connect(mapStateToProps, {})(Logo);


// import React, { Component } from 'react';

// class Logo extends Component {
//     render (){
//         return <div></div>
//     }
// }
// export default Logo;
