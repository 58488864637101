import React, { Component } from 'react';

class Rating extends Component {

    
    onChange(rating)
    {
        if(this.props.hasOwnProperty('onChange'))
        {
            this.props.onChange(rating)
        }
    }
    render(){
        return <React.Fragment>
            <span class={this.props.rating >= 1 ?"fa fa-star review-rating-checked" :  "fa fa-star review-rating " } onClick={()=> this.onChange(1)}></span>
            <span class={this.props.rating >= 2  ?"fa fa-star review-rating-checked" :  "fa fa-star review-rating " } onClick={()=>this.onChange(2)}></span>
            <span class={this.props.rating >= 3  ?"fa fa-star review-rating-checked" :  "fa fa-star review-rating " } onClick={()=>this.onChange(3)}></span>
            <span class={this.props.rating >= 4  ?"fa fa-star review-rating-checked" :  "fa fa-star review-rating " } onClick={()=>this.onChange(4)}></span>
            <span class={this.props.rating === 5 ? "fa fa-star review-rating-checked" :  "fa fa-star review-rating "} onClick={()=>this.onChange(5)}></span>
        </React.Fragment>
    }
}

export default Rating;
