import React, { Component } from "react";
import { connect } from "react-redux";
import {
    generateOTP,
    validateOTPV1,
    getCustomerDetails,
    mainWebsitePath,
    myOrders,
    calculateBillTotal
    // errorMsg
} from "../../services/util";
import { 
    setCustomer, 
    fetchEmployee
} from "../../services/api/actions";
import {
    loadCart,
    loadCartTotal,
} from "../../services/cart/actions";
import CustomerProfile from "../Customer/CustomerProfile";
import Window from "../Common/Window";
import Input from "../Common/Input";
import Logo from "../Common/Logo";
import { withRouter } from "react-router-dom";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            otpGenerated: false,
            otpText: "CONTINUE",
            validateText: "ENTER OTP",
            phoneNo: "",
            otp: "",
            showCustomerProfile: false,
            showLogin: true,
            loginSelected:true,
            signUpSelected:false,
            loginErrorMsg:'',
            EnableMobileOTPLogin:false,
            otpError:"",
            ageConfirmation:false,
            ageConfirmationError:"",
            customerCodeLogin: false,
            customerCode:"",
            customerCodeLable:"GRN No.",
            disableSignUpOption:false,
            autoPopulateCartOnLogin:false,
            loginLabelText:""
            // showOTPSection:false,
        };
        this.onGenerateOTP = this.onGenerateOTP.bind(this);
        this.getCustomerDetail = this.getCustomerDetail.bind(this);
        this.renderLogin = this.renderLogin.bind(this);
        this.renderSignUP = this.renderSignUP.bind(this);
        this.login = this.login.bind(this);
        this.vaidateLogin  = this.vaidateLogin.bind(this);
        this.loginCustomer= this.loginCustomer.bind(this);
        this.renderControl = this.renderControl.bind(this);
    }

    componentDidMount(){

        // console.log('config', this.props.config);
        let EnableMobileOTPLogin = false;
        let customerCodeLogin = false;
        let disableSignUpOption = false;
        let autoPopulateCartOnLogin = false;
        let loginLabelText="";

        if(this.props.config.CheckoutConfig.hasOwnProperty('EnableMobileOTPLogin')){
            EnableMobileOTPLogin = this.props.config.CheckoutConfig.EnableMobileOTPLogin
        }
        if(this.props.config.CheckoutConfig.hasOwnProperty('CustomerCodeLogin')){
            customerCodeLogin = this.props.config.CheckoutConfig.CustomerCodeLogin
        }
        if(this.props.config.CheckoutConfig.hasOwnProperty('DisableSignUpOption')){
            disableSignUpOption = this.props.config.CheckoutConfig.DisableSignUpOption
        }
        if(this.props.config.CheckoutConfig.hasOwnProperty('AutoPopulateCartOnLogin')){
            autoPopulateCartOnLogin = this.props.config.CheckoutConfig.AutoPopulateCartOnLogin
        }

        if(this.props.config.CheckoutConfig.Login && this.props.config.CheckoutConfig.Login.Label){
            loginLabelText =this.props.config.CheckoutConfig.Login.Label;
        }
        // console.log('EnableMobileOTPLogin',EnableMobileOTPLogin);
        this.setState({
            EnableMobileOTPLogin:EnableMobileOTPLogin, 
            customerCodeLogin:customerCodeLogin,
            disableSignUpOption:disableSignUpOption,
            autoPopulateCartOnLogin:autoPopulateCartOnLogin,
            loginLabelText:loginLabelText
        });
    }

    vaidateLogin(){

        if (!this.state.customerCodeLogin ){

            if(this.state.phoneNo.trim() === "") {
                this.setState({loginErrorMsg:"Please enter valid mobile no."});
                return false;
            }

            if (this.state.phoneNo.trim().length  !== 10) {
                this.setState({loginErrorMsg:"Please enter valid mobile no."});
                return false;
            }

        }else{
            if (this.state.customerCodeLogin &&  this.state.customerCode.trim() === "") {
                this.setState({loginErrorMsg:"Please enter valid " + this.state.customerCodeLable});
                return false;
            }
        }

        if(this.props.config && this.props.config.CheckoutConfig && this.props.config.CheckoutConfig.AgeConfirmation){
            if(!this.state.ageConfirmation){
                this.setState({ageConfirmationError:"Please confirm your age"});
                alert("Please confirm your age");
                return;
            }
        } 

    }

    async login(){
        // console.log('login');

        this.setState({processing:true});

        this.vaidateLogin();
        
        let customer = await this.getCustomerDetail();
        // console.log("customer",customer)
        this.loginCustomer(customer);

        this.setState({processing:false});

    }

    async onGenerateOTP() {
        // console.log('onGenerateOTP')
        let phoneNo = this.state.phoneNo;

        this.setState({processing:true});

        this.vaidateLogin();

        if(this.state.customerCodeLogin){
            let customer = await this.getCustomerDetail();
            if(!customer){
                this.setState({ 
                    processing: false, 
                    loginErrorMsg: "Invalid details. Please try again"
                });
                return;
            }
            phoneNo= customer.PhoneNo;
            this.setState({phoneNo:phoneNo});
        }

        console.log("phoneNo",phoneNo);

        let response = await generateOTP(
            phoneNo,
            this.props.config.key,
            this.props.config.CheckoutConfig.StoreID,
            "omniloginotp"
        );

        if (response.status === 200 && response.data.status === "Success") {
            this.setState({ otpGenerated: true, processing:false });
        } else {
            this.setState({ processing: false, loginErrorMsg: "OTP generation failed. Please contact support@posible.in"});
        }

    }

    async onValidateOTP() {

        this.setState({ processing: true });

        if (this.state.phoneNo.trim() === "") {
            this.setState({otpError:"Please enter valid OTP", processing:false});
            return false;
        }

        let OTPValidationSuccess = await validateOTPV1(
            this.state.phoneNo,
            this.state.otp,
            this.props.config.key,
            this.props.config.CheckoutConfig.StoreID,
            "omniloginotp"
        );

        // console.log('page - checkout response', response);
        if (OTPValidationSuccess) {
            //check if user exits with this mobile no
            this.setState({ processing: false});
            let customer = await this.getCustomerDetail();
            this.loginCustomer(customer);
        } else {
            this.setState({ processing: false, otpError:"Invalid OTP. Please try again." });
            // alert();
        }

    }

    async getCustomerDetail()
    {
        let customer = await getCustomerDetails(
            this.state.phoneNo,
            this.props.config.key,
            this.state.customerCode
        );
        // console.log("getCustomerDetail phoneNo",  this.state.phoneNo);
        // console.log("getCustomerDetail config.key", this.props.config.key);
        // console.log("getCustomerDetail customerCode",  this.state.customerCode);
        // console.log("getCustomerDetail customer",customer);
        return customer;
    }

    async loginCustomer(customer){

        if (customer) {
            // console.log('loginCustomer customer',customer)
             //customer found
            //set in localstorage //and redux and take to home page
            
            if (customer.hasOwnProperty("AffiliateCode")) {
                this.props.fetchEmployee(
                    customer.AffiliateCode,
                    this.props.config.key
                );
            }
            if(this.state.autoPopulateCartOnLogin){
                let result = await myOrders(0, 10, customer.key, this.props.config.CheckoutConfig.StoreID);
                let filter = result.filter(f=> f.hasOwnProperty("Payments") && f.Payments.length ===0)
                if(filter.length> 0)
                {
                    let sale = filter[0];
                    // console.log("sale", sale);
                    sale.LineItems.map(m=> m.Product.MinQty = m.Qty);
                    let cartTotal = calculateBillTotal(sale.LineItems);
                    cartTotal.saleID = sale.key;
                    // console.log('cartTotal',cartTotal)
                    this.props.loadCart(sale.LineItems);
                    this.props.loadCartTotal(cartTotal);   
                    this.props.history.push(mainWebsitePath() + "checkout/" );
                }
            }
            this.props.setCustomer(this.props.config.key, customer);
            if(this.props.hasOwnProperty("onCustomerLogin")){
                this.props.onCustomerLogin(true, "");
            }
            
        } else {
            
            let errorMsg="";

            if(this.state.customerCodeLogin){
                errorMsg=this.state.customerCodeLable +  " no not found.";
            }
            else
            {
                errorMsg="Mobile no not found.";
            }

            if(!this.state.disableSignUpOption){
                errorMsg = errorMsg + "Kindly sign up.";
            }

            this.setState({loginErrorMsg:errorMsg, otpError:errorMsg});

        }

    }

    renderSignUP(){
        return <React.Fragment>
                <CustomerProfile
                    returnStatus={true}
                    new={true}
                    phoneNo={this.state.phoneNo}
                    onSave={() => {
                        // this.props.history.push(mainWebsitePath());
                        this.props.onCustomerLogin(true, "");
                    }}
                    showAsComponent={true}
                />
        </React.Fragment>
    }

    renderLogin() {
        return (<React.Fragment>
            {!this.state.otpGenerated && <React.Fragment>
                <div className="row">
                    {!this.state.customerCodeLogin && <div className="col-md-12">
                        <Input type="mobileno" 
                        label="Please enter mobile no" 
                        disabled={this.state.processing} 
                        error={this.state.loginErrorMsg}
                        value={this.state.phoneNo}
                        onChange={(value) =>
                            this.setState({phoneNo:value.length > 10 ? this.state.phoneNo: value,loginErrorMsg:""})}
                        />
                    </div>}
                    {this.state.customerCodeLogin && <div className="col-md-12">
                        <Input type="text" 
                        label={this.state.loginLabelText} 
                        disabled={this.state.processing} 
                        error={this.state.loginErrorMsg}
                        value={this.state.customerCode}
                        onChange={(value) =>
                            this.setState({customerCode:value,loginErrorMsg:""})}
                        />
                    </div>}
                </div>
                {this.props.config && this.props.config.CheckoutConfig && this.props.config.CheckoutConfig.AgeConfirmation && <div className="row">
                    <div className="col-md-12">
                            <br/>
                            <Input 
                            error={this.state.ageConfirmationError}
                            value={this.state.ageConfirmation}
                            onChange={(value)=>{this.setState({ageConfirmation:value, ageConfirmationError:""})}}
                            type="checkbox" 
                            label={"I am over " + this.props.config.CheckoutConfig.AgeConfirmation + " yrs"}/>
                    </div>
                </div>}
                <div className="row">
                    <div className="col-md-12">
                        {!this.state.EnableMobileOTPLogin  && <button className="btn btn-flat generate-otp" onClick={() => this.login()}>
                            {this.state.processing ? "Please wait..." : "Login"}
                        </button>}
                        {this.state.EnableMobileOTPLogin && <button 
                            className="btn btn-flat generate-otp" 
                            disabled={this.state.processing}
                            onClick={() => this.onGenerateOTP()}>
                            {this.state.processing ? "Please wait..." : "Send OTP"}
                        </button>}
                    </div>
                </div>
            </React.Fragment>}

            {this.state.otpGenerated && <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <Input type="number" 
                        label="Please enter otp" 
                        disabled={this.state.processing} 
                        error={this.state.otpError}
                        value={this.state.otp}
                        onChange={(value) =>
                            this.setState({otp:value.length > 5 ? this.state.otp: value, otpError:""})}
                        />
                        </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button 
                            className="btn btn-flat generate-otp" 
                            disabled={this.state.processing}
                            onClick={() => this.onValidateOTP()}>
                            {this.state.processing ? "Please wait..." : "Validate OTP"}
                        </button>
                    </div>
                </div>
            </React.Fragment>}
        </React.Fragment>);
    }

    renderControl(){

        return <div className="login-content">
            <div className="login-logo">
                <Logo size={"lg"} showWebsiteLogo={true}/>
            </div>
            <div className="login-option">
                <span className={this.state.loginSelected ? "selected" : ""} onClick={()=> this.setState({loginSelected:true,signUpSelected:false})}>Login</span>
                {!this.state.disableSignUpOption &&<span className={this.state.signUpSelected ? "selected" : ""} onClick={()=> this.setState({loginSelected:false,signUpSelected:true})}>Sign Up</span>}
            </div>
            {this.state.loginSelected && <div>
                {this.renderLogin()}
            </div>}               
            {this.state.signUpSelected && <div>
                {this.renderSignUP()}
            </div>}
        </div>
    }

    render(){
        return <React.Fragment>
           {!this.props.page &&<Window onClose={()=>this.props.onCustomerLogin(false)} width={"300px"}>
                {this.renderControl()}
           </Window>}
           {this.props.page && this.renderControl()}
        </React.Fragment>
    }
}

const mapStateToProps = (state) => ({
    config: state.api.config,
});

export default withRouter(connect(mapStateToProps, { setCustomer, fetchEmployee, loadCart, loadCartTotal })(Login));
