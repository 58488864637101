import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import Root from "./Root";
import "./control.css";
// import { mainWebsitePath } from "./services/util";

// var r = document.querySelector(":root"); 
// function myFunction_get() {
//     var rs = getComputedStyle(r);
//     alert("The value of --blue is: " + rs.getPropertyValue("--clr"));
// }

// Create a function for setting a variable value
function myFunction_set() {
    // Set the value of variable --blue to another value (in this case "lightblue")
    // let website = mainWebsitePath();

    // if (
    //     website === "/krumbs/" ||
    //     window.location.hostname === "krumbsfood.com"
    // ) {
    //     r.style.setProperty("--clr", "#F23D2E");
    //     r.style.setProperty("--top-header", "#F23D2E");
    // } else if (
    //     website === "/sabzeefresh/" ||
    //     window.location.hostname === "sabzeefresh.com"
    // ) {
    //     r.style.setProperty("--clr", "#5C9C08");
    //     r.style.setProperty("--top-header", "#2C6100");
    // } else {
    //     r.style.setProperty("--clr", "#09AAFB");
    //     r.style.setProperty("--top-header", "#09AAFB");
    // }
}

ReactDOM.render(
    <Root>
        <App />
    </Root>
    ,
    document.getElementById("root", myFunction_set())
);
