import React, {Component } from 'react';
import {submitInquiry,isMobile} from '../../services/util';
import {connect} from 'react-redux';
import Header from "../Header";
import PageFooter from "../PageFooter";

class Contact extends Component
{
    state={
        name:'',
        contactNo:'',
        emailID:'',
        message:'',
        validationclassName:'needs-validation'
    }

    async componentDidMount() {

        let user = window.localStorage.getItem(this.props.config.CheckoutConfig.StoreID + 'user')
        if(user)
        {
            user = JSON.parse(window.localStorage.getItem(this.props.config.CheckoutConfig.StoreID + 'user'));
            this.setState({ name: user.firstName.trim() + ' ' + user.lastName.trim(), contactNo: user.phoneNo})
        }
    }

    onSubmitClick = async ()=>{
        let validationSuccess = true 
        if(this.state.name.trim()==="")
        {
            validationSuccess= false
        }
        if(this.state.contactNo.trim()==="")
        {
            validationSuccess= false
        }
        if(this.state.emailID.trim()==="")
        {
            validationSuccess= false
        }
        if(this.state.message.trim()==="")
        {
            validationSuccess= false
        }
        if(!validationSuccess)
        {
            this.setState({validationclassName:"needs-validation was-validated"})
            return false;
        }
        else
        {
            // console.log('config',this.props.config)
            this.setState({validationclassName:"needs-validation"})
            let response = await submitInquiry({
                    Name: this.state.name,
                    ContactNo: this.state.contactNo,
                    EmailID: this.state.emailID,
                    Message: this.state.message,
                    RegistrationID: this.props.config.key})
            if(response.status ===200)
            {
                //saved succesfull 
                this.setState({name:'', contactNo:'', emailID:'',message:'' })
                alert('Thank you for contacting. Your inquiry has been submitted.');
            }
            else{
                alert('Error submitting inquiry. Please try after some time.');
            }
            // console.log('response', response)
        }
    }


    render(){
        return <React.Fragment>
         <Header/>
        <div className="container">
            <br/>
            <h5>Get in touch</h5>
            <div>Please fill out the below form and we will get in touch with you.</div>
            <div className={this.state.validationclassName} noValidate="">
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <label ></label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fa fa-user"/></span>
                                </div>
                                <input 
                                    onChange={(e)=> this.setState({name:e.target.value})} 
                                    value={this.state.name}
                                    type="text" className="form-control" id="name" placeholder="Name" required={true}/>
                                <div className="invalid-feedback" style={{width: "100%"}}>
                                Please provide name.
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <label></label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fa fa-phone"/></span>
                                </div>
                                <input 
                                 onChange={(e)=> this.setState({contactNo:e.target.value})} 
                                 value={this.state.contactNo}
                                type="text" className="form-control" id="contactNo" placeholder="Your contact no" required={true}/>
                                <div className="invalid-feedback" style={{width: "100%"}}>
                                Please provide your contact no.
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <label></label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fa fa-envelope"/></span>
                                </div>
                                <input 
                                onChange={(e)=> this.setState({emailID:e.target.value})} 
                                value={this.state.emailID}
                                type="text" className="form-control" id="emailid" placeholder="Your email id" required={true}/>
                                <div className="invalid-feedback" style={{width: "100%"}}>
                                Please provide your email id.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="col-12" style={{padding:"0px"}}>
                        <label></label>
                        <div className="input-group">
                            <textarea 
                             onChange={(e)=> this.setState({message:e.target.value})} 
                             value={this.state.message}
                            type="text" className="form-control" placeholder="Message" required={true} style={{height:"160px"}}/>
                            <div className="invalid-feedback" style={{width: "100%"}}>
                                Please provide message.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary btn-lg pull-right" style={{marginTop:"15px"}} onClick={this.onSubmitClick}>Submit</button>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <br/>
        </div>
        {!isMobile() && <PageFooter/>}
    </React.Fragment>}
};


const mapStateToProps = state => ({
    config:state.api.config
  });
  
  export default connect(
    mapStateToProps,
    { }
  )(Contact);
  