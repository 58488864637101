import React,{Component} from 'react';
import { connect } from 'react-redux';
import { isMobile, isLocationCheckerEnabled , validatePincode} from '../../services/util';
import Window from '../Common/Window';
import Input from '../Common/Input';
import Location from '../Common/Location';
// import './Style.css';

class CustomerAddresses extends Component {

    constructor(props)
    {
        super(props)
        this.state={
            showAddress:false,
            addressType:'Home',
            address1:'',
            address2:'',
            city:'',
            state:'',
            postalCode:'',
            country:'',
            countryCode:'',
            stateCode: '',
            selectedIndex:-1,
            showAddress1:true,
            showAddress2:true,
            showCity:false,
            showState:false,
            showPostalCode:false,
            showCountry:false,
            cityOptions:[]
        }
        this.addNewAddress = this.addNewAddress.bind(this)
        this.onOKClicked = this.onOKClicked.bind(this)
        this.onDeleteClicked= this.onDeleteClicked.bind(this)
        this.onEditClicked = this.onEditClicked.bind(this)
        this.renderAddEditAddress = this.renderAddEditAddress.bind(this)
        this.onLocaitonSelected = this.onLocaitonSelected.bind(this);
    }
    
    componentDidMount()
    {
        if(this.props.config.CheckoutConfig.hasOwnProperty('CheckoutDetailPageOptions') )
        {
            let showAddress1 = this.props.config.CheckoutConfig.CheckoutDetailPageOptions.ShowCustomerLineAddress1
            let showAddress2 = this.props.config.CheckoutConfig.CheckoutDetailPageOptions.ShowCustomerLineAddress2
            let showCity = this.props.config.CheckoutConfig.CheckoutDetailPageOptions.ShowCustomerCity
            let showState = this.props.config.CheckoutConfig.CheckoutDetailPageOptions.ShowCustomerState
            let showPostalCode = this.props.config.CheckoutConfig.CheckoutDetailPageOptions.ShowCustomerPinCode
            let showCountry = this.props.config.CheckoutConfig.CheckoutDetailPageOptions.ShowCustomerCountry
            this.setState({showAddress1:showAddress1,showAddress2:showAddress2,showCity:showCity,showState:showState,showPostalCode:showPostalCode,showCountry:showCountry})
        }
        else
        {
            this.setState({showAddress1:true,showAddress2:true})
        }
        
       if(this.props.selection && this.props.addresses.length===0)
       {
           //show new address immediatley 
            this.addNewAddress();
       }
       else if(this.props.selection && this.props.addresses.length>0)
       {
        this.setState({selectedIndex:0})
       }
       if(this.props.config.CheckoutConfig.hasOwnProperty('CityOptions') 
       && this.props.config.CheckoutConfig.CityOptions.length>0)
        {
            this.setState({cityOptions:this.props.config.CheckoutConfig.CityOptions})
        }
    }

    onLocaitonSelected()
    {
        this.setState({showLocation:false});
        if(this.props.pincode){
            //add item 
            this.setState({postalCode:this.props.pincode})
        }
    }

    addNewAddress()
    {
        this.setState({showAddress:true, addressType:'Home',
        address1:'',
        address2:'',
        city:'',
        state:'',
        postalCode:this.props.pincode ? this.props.pincode : "",
        country:'',
        countryCode:'',
        stateCode: '',
        selectedIndex:-1,})
    }

    async onOKClicked()
    {
        //check if required details are entered
        // console.log('this.state.postalCode',this.state.postalCode)
        let error= false
        if(this.state.showAddress1 && this.state.address1.trim()==="" )
        {
            this.setState({address1Error :'Please provide House / Flat / Block No'});
            error=true;
        }
            
        if(this.state.showAddress2 && this.state.address2.trim()==="" )
        {
            this.setState({address2Error:'Please provide landmark'});
            error=true;
        }
            
        if(this.state.showCity && this.state.city.trim()==="" )
        {
            this.setState({cityError:'Please provide city'});
            error=true;
        }
            
        if(this.state.showState && this.state.state.trim()==="" )
        {
            this.setState({stateError:'Please provide state'});
            error=true;
        }
            
        if(this.state.showCountry && this.state.country.trim()==="" )
        {
            this.setState({countryError:'Please provide country'});
            error=true;
        }
            
        if(this.state.showPostalCode && this.state.postalCode==="" )
        {
            this.setState({pincodeError:'Please provide postal code'});
            error=true;
        }
        if(isLocationCheckerEnabled(this.props.config) && this.state.postalCode!==this.props.pincode)
        {
            console.log('validate pincode')
            let validPinCode = await validatePincode(this.state.postalCode,this.props.config.key) 
            if(!validPinCode){
                this.setState({pincodeError:'We currently do not deliver at the specified pincode'});
                error = true
            }
        }
        if(error){return;}
         
        let addresses = this.props.addresses
        let address = {
            AddressType:this.state.addressType,
            Address1: this.state.address1,
            Address2:this.state.address2,
            City:this.state.city,
            State:this.state.state,
            StateCode: this.state.stateCode,
            PostalCode:this.state.postalCode,
            Country:this.state.country,
            CountryCode: this.state.countryCode
        }
    
        if(this.state.selectedIndex >-1)
        {
            ///edit 
            addresses[this.state.selectedIndex] = address
        }
        else{
            ///new address   
            addresses.push(address)
        }
        this.props.onAddressUpdated(addresses)
        this.setState({
        showAddress:false,
        addressType:'Home',
        lineAddress1:'',
        lineAddress2:'',
        city:'',
        state:'',
        postalCode:'',
        country:'',
        countryCode:'',
        stateCode: '',
        selectedIndex:-1})
    }

    onDeleteClicked(index){
        let addresses = this.props.addresses
        addresses.splice(index,1)
        this.props.onAddressUpdated(addresses)
    }

    onEditClicked(index){
        let address = this.props.addresses[index]
        this.setState({
            showAddress:true,
            addressType:address.AddressType,
            address1:address.Address1,
            address2:address.Address2,
            city:address.City,
            state:address.State,
            postalCode:address.PostalCode,
            country:address.Country,
            countryCode:address.CountryCode,
            stateCode: address.StateCode,
            selectedIndex:index
        })
    }

    renderAddEditAddress(){
        return <React.Fragment>
            <Window 
                header={this.state.selectedIndex === -1 ? "ADD NEW ADDRESS" : "EDIT ADDRESS"}
                onClose={() =>this.setState({showAddress: false})}
                onButtonClick={this.onOKClicked}
                buttons={[{label:"OK"}]}
                height={"600px"}
                >
                <div className="mb-3">
                    <Input type="select" 
                    label="Type"
                    value={this.state.addressType}
                    onChange={(value) => { this.setState({ addressType:value}) }}
                    >
                        <option >Home</option>
                        <option >Work</option>
                        <option >Other</option>
                    </Input>
                    
                </div>
                {this.state.showAddress1 && <div className="mb-3">
                    <Input type="text" 
                    label="House / Flat / Block No"
                    value={this.state.address1}
                    onChange={(value) => { this.setState({ address1:value,address1Error:''}) }}
                    error={this.state.address1Error}
                    />
                </div>}
                {this.state.showAddress2 && <div className="mb-3">
                    <Input type="text" 
                    label="Landmark"
                    value={this.state.address2}
                    onChange={(value) => { this.setState({ address2:value,address2Error:''}) }}
                    error={this.state.address2Error}
                    />
                </div>}
                {this.state.showCity && <div className="mb-3">
                    {this.state.cityOptions.length>0 && <Input type="select" 
                    label="City"
                    value={this.state.city}
                    onChange={(value) => { this.setState({ city:value,cityError:''}) }}
                    error={this.state.cityError}
                    >
                        <option value="">Please select</option>
                        {this.state.cityOptions.map(m=><option value={m.Value}>{m.Value}</option>)}
                    </Input>}
                    {this.state.cityOptions.length===0 && <Input type="text" 
                    label="City"
                    value={this.state.city}
                    onChange={(value) => { this.setState({ city:value,cityError:''}) }}
                    error={this.state.cityError}
                    />}
                </div>}
                {this.state.showState && <div className="mb-3">
                    <Input type="text" 
                    label="State"
                    value={this.state.state}
                    onChange={(value) => { this.setState({ state:value,stateError:''}) }}
                    error={this.state.stateError}
                    />
                </div>}
                {this.state.showCountry &&<div className="mb-3">
                    <Input type="text" 
                    label="Country"
                    value={this.state.country}
                    onChange={(value) => { this.setState({ country:value,countryError:''}) }}
                    error={this.state.countryError}
                    />
                </div>}
                {this.state.showPostalCode && <div className="mb-3">
                    {/* {!isLocationCheckerEnabled(this.props.config) && */}
                    <Input type="number" 
                    label="Pincode"
                    value={this.state.postalCode}
                    onChange={(value) => { if(value.length<7){
                        this.setState({ postalCode:value,pincodeError:''});
                        }  
                    }}
                    error={this.state.pincodeError}
                    />
                    {/* } */}

                    {/* {isLocationCheckerEnabled(this.props.config) && <React.Fragment>
                        <label>Pincode</label>
                        <div className="input-group">
                        <input type="number" className="form-control"
                        onChange={(e) =>
                            this.setState({postalCode: e.target.value,pincodeError:''})}
                        value={this.state.postalCode}
                        />
                        <div className="input-group-append">
                            <button className="form-control" onClick={() =>{this.setState({showLocation:true})}}>
                               Verify
                            </button>
                        </div>
                    </div>
                    </React.Fragment>} */}
                </div>}
            </Window>
        </React.Fragment>
    }

    renderAddress(m,index)
    {
        return <React.Fragment>
            <div className="address-type"><b>{m.AddressType}</b></div>
            <div className="address-text">{m.Address1}</div>
            <div className="address-text">{m.Address2}</div>
            <div className="address-text">{m.City}</div>
            <div className="address-text">{m.State}</div>
            <div className="address-text">{m.Country}</div>
            <div className="address-text">{m.PostalCode}</div>
            <div className="address-action-button">
                <a href="#" onClick={()=>this.onEditClicked(index)}>EDIT</a>
                <a href="#" onClick={()=>this.onDeleteClicked(index)}>DELETE</a>
            </div>
        </React.Fragment>
    }

    render()
    {
        return <div>
            
            {this.state.showLocation && <Location onClose={this.onLocaitonSelected}/>}

            {this.state.showAddress && this.renderAddEditAddress()}
            <div className="mb-3">
                <div>
                    <button className="btn btn-light btn-flat" style={{width:"100%"}} onClick={this.addNewAddress}>+ Add new address</button>      
                </div>
                {/* <br/> */}
                {/* {this.props.addresses.length ===0 && <div>
                    <i>No address added</i>
                </div>} */}
                {this.props.addresses.length> 0 && <div className={!isMobile()? this.props.selection ? "addresses-desktop-container": "" :""}>
                    {this.props.selection && <React.Fragment>
                        {this.props.addresses.map((m,index)=><div key={"address_" + index} 
                            className={isMobile() ? this.state.selectedIndex===index ?  "address-mobile address-selected" : "address-mobile": this.state.selectedIndex===index ? "address-desktop address-selected" : "address-desktop"}
                            onClick={()=>{
                                this.setState({selectedIndex:index})
                                this.props.onAddressSelection(m,index)
                                }}>
                            {this.renderAddress(m,index)}
                        </div>)}
                    </React.Fragment>}
                    {!this.props.selection && <React.Fragment>
                        {this.props.addresses.map((m,index)=><div key={"address_" + index}>
                            {this.renderAddress(m,index)}  
                        <hr/>
                        </div>)}
                    </React.Fragment>}
                </div>}
            </div>
        </div>
    }

}

const mapStateToProps = state => ({
config: state.api.config,
pincode: state.api.pincode});
  
export default connect(mapStateToProps,{})(CustomerAddresses);
