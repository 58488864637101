import React, { Component } from 'react';


class Icon extends Component {

    renderIcon() {
        if(this.props.type==="home"){
            return <svg viewBox="0 0 476.912 476.912" width="24px">
            <g><g><path d="M461.776,209.408L249.568,4.52c-6.182-6.026-16.042-6.026-22.224,0L15.144,209.4c-3.124,3.015-4.888,7.17-4.888,11.512    c0,8.837,7.164,16,16,16h28.2v224c0,8.837,7.163,16,16,16h112c8.837,0,16-7.163,16-16v-128h80v128c0,8.837,7.163,16,16,16h112    c8.837,0,16-7.163,16-16v-224h28.2c4.338,0,8.489-1.761,11.504-4.88C468.301,225.678,468.129,215.549,461.776,209.408z     M422.456,220.912c-8.837,0-16,7.163-16,16v224h-112v-128c0-8.837-7.163-16-16-16h-80c-8.837,0-16,7.163-16,16v128h-112v-224    c0-8.837-7.163-16-16-16h-28.2l212.2-204.88l212.28,204.88H422.456z" /></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
            </svg>
        }
        if (this.props.type === "login") {
            return <svg viewBox="0 0 490.667 490.667"  width="24px">
                <g><g><path d="M437.333,0H202.667c-29.397,0-53.333,23.936-53.333,53.333v128c0,5.888,4.779,10.667,10.667,10.667    s10.667-4.779,10.667-10.667v-128c0-17.643,14.357-32,32-32h234.667c17.643,0,32,14.357,32,32v384c0,17.643-14.357,32-32,32    H202.667c-17.643,0-32-14.357-32-32v-128c0-5.888-4.779-10.667-10.667-10.667s-10.667,4.779-10.667,10.667v128    c0,29.397,23.936,53.333,53.333,53.333h234.667c29.397,0,53.333-23.936,53.333-53.333v-384C490.667,23.936,466.731,0,437.333,0z" /></g></g>
                <g><g><path d="M352,234.667H10.667C4.779,234.667,0,239.445,0,245.333S4.779,256,10.667,256H352c5.888,0,10.667-4.779,10.667-10.667    S357.888,234.667,352,234.667z" /></g></g>
                <g><g><path d="M359.531,237.824l-64-64c-4.16-4.16-10.923-4.16-15.083,0s-4.16,10.923,0,15.083l56.448,56.448l-56.448,56.448    c-4.16,4.16-4.16,10.923,0,15.083c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.531-3.093l64-64    C363.691,248.747,363.691,241.984,359.531,237.824z" /></g>
                </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                <g></g><g></g><g></g><g></g><g></g><g></g>
            </svg>
        }
        if (this.props.type === "customer") {
            return <svg viewBox="0 0 24 24"><path d="M9,12A6,6,0,1,0,3,6,6.006,6.006,0,0,0,9,12ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z" /><path d="M9,14a9.011,9.011,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.011,9.011,0,0,0,9,14Z" /><path d="M22,7.875a2.107,2.107,0,0,0-2,2.2,2.107,2.107,0,0,0-2-2.2,2.107,2.107,0,0,0-2,2.2c0,1.73,2.256,3.757,3.38,4.659a.992.992,0,0,0,1.24,0c1.124-.9,3.38-2.929,3.38-4.659A2.107,2.107,0,0,0,22,7.875Z" /></svg>
        }
        if (this.props.type === "catalogue") {
            return <svg viewBox="0 0 24 24"><path d="M4.5,17.5H2a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2V19.5A2,2,0,0,0,4.5,17.5Zm0,4.5H2V19.5H4.5Z" /><path d="M22,17.5H19.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V19.5A2,2,0,0,0,22,17.5ZM22,22H19.5V19.5H22Z" /><path d="M4.5,8.75H2a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,4.5,8.75Zm0,4.5H2v-2.5H4.5Z" /><path d="M22,8.75H19.5a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,22,8.75Zm0,4.5H19.5v-2.5H22Z" /><path d="M4.5,0H2A2,2,0,0,0,0,2V4.5a2,2,0,0,0,2,2H4.5a2,2,0,0,0,2-2V2A2,2,0,0,0,4.5,0Zm0,4.5H2V2H4.5Z" /><path d="M13.25,17.5h-2.5a2,2,0,0,0-2,2V22a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2V19.5A2,2,0,0,0,13.25,17.5Zm0,4.5h-2.5V19.5h2.5Z" /><path d="M13.25,8.75h-2.5a2,2,0,0,0-2,2v2.5a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2v-2.5A2,2,0,0,0,13.25,8.75Zm0,4.5h-2.5v-2.5h2.5Z" /><path d="M13.25,0h-2.5a2,2,0,0,0-2,2V4.5a2,2,0,0,0,2,2h2.5a2,2,0,0,0,2-2V2A2,2,0,0,0,13.25,0Zm0,4.5h-2.5V2h2.5Z" /><path d="M22,0H19.5a2,2,0,0,0-2,2V4.5a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,4.5H19.5V2H22Z" /></svg>
        }
        if (this.props.type === "sales") {
            return <svg viewBox="0 0 24 24"><path d="M23,19H21V17a1,1,0,0,0-2,0v2H17a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V21h2a1,1,0,0,0,0-2Z" /><path d="M21,6H18A6,6,0,0,0,6,6H3A3,3,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5h9a1,1,0,0,0,0-2H5a3,3,0,0,1-3-3V9A1,1,0,0,1,3,8H6v2a1,1,0,0,0,2,0V8h8v2a1,1,0,0,0,2,0V8h3a1,1,0,0,1,1,1v5a1,1,0,0,0,2,0V9A3,3,0,0,0,21,6ZM8,6a4,4,0,0,1,8,0Z" /></svg>
        }
        if (this.props.type === "inventory") {
            return <svg viewBox="0 0 24 24"><path d="M7,0H4A4,4,0,0,0,0,4V7a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V4A4,4,0,0,0,7,0ZM9,7A2,2,0,0,1,7,9H4A2,2,0,0,1,2,7V4A2,2,0,0,1,4,2H7A2,2,0,0,1,9,4Z" /><path d="M7,13H4a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4H7a4,4,0,0,0,4-4V17A4,4,0,0,0,7,13Zm2,7a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2H7a2,2,0,0,1,2,2Z" /><path d="M20,13H17a4,4,0,0,0-4,4v3a4,4,0,0,0,4,4h3a4,4,0,0,0,4-4V17A4,4,0,0,0,20,13Zm2,7a2,2,0,0,1-2,2H17a2,2,0,0,1-2-2V17a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2Z" /><path d="M14,7h3v3a1,1,0,0,0,2,0V7h3a1,1,0,0,0,0-2H19V2a1,1,0,0,0-2,0V5H14a1,1,0,0,0,0,2Z" /></svg>
        }
        if (this.props.type === "staff") {
            return <svg viewBox="0 0 24 24"><path d="M7.5,13A4.5,4.5,0,1,1,12,8.5,4.505,4.505,0,0,1,7.5,13Zm0-7A2.5,2.5,0,1,0,10,8.5,2.5,2.5,0,0,0,7.5,6ZM15,23v-.5a7.5,7.5,0,0,0-15,0V23a1,1,0,0,0,2,0v-.5a5.5,5.5,0,0,1,11,0V23a1,1,0,0,0,2,0Zm9-5a7,7,0,0,0-11.667-5.217,1,1,0,1,0,1.334,1.49A5,5,0,0,1,22,18a1,1,0,0,0,2,0ZM17.5,9A4.5,4.5,0,1,1,22,4.5,4.505,4.505,0,0,1,17.5,9Zm0-7A2.5,2.5,0,1,0,20,4.5,2.5,2.5,0,0,0,17.5,2Z" /></svg>
        }
        if (this.props.type === "reports") {
            return <svg viewBox="0 0 24 24"><path d="M13,20a5.006,5.006,0,0,0,5-5V6.243a3.972,3.972,0,0,0-1.172-2.829L14.586,1.172A3.972,3.972,0,0,0,11.757,0H7A5.006,5.006,0,0,0,2,5V15a5.006,5.006,0,0,0,5,5ZM4,15V5A3,3,0,0,1,7,2s4.919.014,5,.024V4a2,2,0,0,0,2,2h1.976c.01.081.024,9,.024,9a3,3,0,0,1-3,3H7A3,3,0,0,1,4,15ZM22,8V19a5.006,5.006,0,0,1-5,5H8a1,1,0,0,1,0-2h9a3,3,0,0,0,3-3V8a1,1,0,0,1,2,0Z" /></svg>

        }
        if (this.props.type === "onlinestore") {
            return <svg viewBox="0 0 24 24"><path d="M22.713,4.077A2.993,2.993,0,0,0,20.41,3H4.242L4.2,2.649A3,3,0,0,0,1.222,0H1A1,1,0,0,0,1,2h.222a1,1,0,0,1,.993.883l1.376,11.7A5,5,0,0,0,8.557,19H19a1,1,0,0,0,0-2H8.557a3,3,0,0,1-2.82-2h11.92a5,5,0,0,0,4.921-4.113l.785-4.354A2.994,2.994,0,0,0,22.713,4.077ZM21.4,6.178l-.786,4.354A3,3,0,0,1,17.657,13H5.419L4.478,5H20.41A1,1,0,0,1,21.4,6.178Z" /><circle cx="7" cy="22" r="2" /><circle cx="17" cy="22" r="2" /></svg>

        }
        if (this.props.type === "crm") {
            return <svg viewBox="0 0 24 24"><path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z" /></svg>

        }
        if (this.props.type === "loyalty") {
            return <svg viewBox="0 0 24 24"><path d="M22.766,4.566A1.994,1.994,0,0,0,20.586,5L18,7.586,13.414,3a2,2,0,0,0-2.828,0L6,7.586,3.414,5A2,2,0,0,0,0,6.414V17a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V6.414A2,2,0,0,0,22.766,4.566ZM22,17a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V6.414L5.293,9.707a1,1,0,0,0,1.414,0L12,4.414l5.293,5.293a1,1,0,0,0,1.414,0L22,6.414Z" /></svg>

        }
        if (this.props.type === "expense") {
            return <svg viewBox="0 0 24 24" >
                <circle cx="5.5" cy="15.5" r="1.5" />
                <path d="M19,3H5C2.2,3,0,5.2,0,8v8c0,2.8,2.2,5,5,5h14c2.8,0,5-2.2,5-5V8C24,5.2,21.8,3,19,3z M5,5h14c1.7,0,3,1.3,3,3H2
                C2,6.3,3.3,5,5,5z M19,19H5c-1.7,0-3-1.3-3-3v-6h20v6C22,17.7,20.7,19,19,19z"/>
            </svg>
        }
        if (this.props.type === "setup") {
            return <svg viewBox="0 0 24 24"><path d="M12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z" /><path d="M21.294,13.9l-.444-.256a9.1,9.1,0,0,0,0-3.29l.444-.256a3,3,0,1,0-3-5.2l-.445.257A8.977,8.977,0,0,0,15,3.513V3A3,3,0,0,0,9,3v.513A8.977,8.977,0,0,0,6.152,5.159L5.705,4.9a3,3,0,0,0-3,5.2l.444.256a9.1,9.1,0,0,0,0,3.29l-.444.256a3,3,0,1,0,3,5.2l.445-.257A8.977,8.977,0,0,0,9,20.487V21a3,3,0,0,0,6,0v-.513a8.977,8.977,0,0,0,2.848-1.646l.447.258a3,3,0,0,0,3-5.2Zm-2.548-3.776a7.048,7.048,0,0,1,0,3.75,1,1,0,0,0,.464,1.133l1.084.626a1,1,0,0,1-1,1.733l-1.086-.628a1,1,0,0,0-1.215.165,6.984,6.984,0,0,1-3.243,1.875,1,1,0,0,0-.751.969V21a1,1,0,0,1-2,0V19.748a1,1,0,0,0-.751-.969A6.984,6.984,0,0,1,7.006,16.9a1,1,0,0,0-1.215-.165l-1.084.627a1,1,0,1,1-1-1.732l1.084-.626a1,1,0,0,0,.464-1.133,7.048,7.048,0,0,1,0-3.75A1,1,0,0,0,4.79,8.992L3.706,8.366a1,1,0,0,1,1-1.733l1.086.628A1,1,0,0,0,7.006,7.1a6.984,6.984,0,0,1,3.243-1.875A1,1,0,0,0,11,4.252V3a1,1,0,0,1,2,0V4.252a1,1,0,0,0,.751.969A6.984,6.984,0,0,1,16.994,7.1a1,1,0,0,0,1.215.165l1.084-.627a1,1,0,1,1,1,1.732l-1.084.626A1,1,0,0,0,18.746,10.125Z" /></svg>
        }
        if (this.props.type === "rightarrow") {
            return <svg viewBox="0 0 24 24"><path d="M15.4,9.879,10.811,5.293A1,1,0,0,0,9.4,6.707l4.586,4.586a1,1,0,0,1,0,1.414L9.4,17.293a1,1,0,0,0,1.415,1.414L15.4,14.121A3,3,0,0,0,15.4,9.879Z" /></svg>
        }
        if (this.props.type === "search") {
            return <svg viewBox="0 0 24 24"><path d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z" /></svg>
        }
        if (this.props.type === "downarrow") {
            return <svg viewBox="0 0 24 24"><path d="M18.707,8.207a1,1,0,0,0-1.414,0l-4.586,4.586a1.021,1.021,0,0,1-1.414,0L6.707,8.207A1,1,0,0,0,5.293,9.621l4.585,4.586a3,3,0,0,0,4.243,0l4.586-4.586A1,1,0,0,0,18.707,8.207Z" /></svg>
        }
        if (this.props.type === "new") {
            return <svg viewBox="0 0 24 24"><path d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z" /></svg>
        }
        if (this.props.type === "list") {
            return <svg viewBox="0 0 24 24"><path d="M7,6H23a1,1,0,0,0,0-2H7A1,1,0,0,0,7,6Z" /><path d="M23,11H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><path d="M23,18H7a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /><circle cx="2" cy="5" r="2" /><circle cx="2" cy="12" r="2" /><circle cx="2" cy="19" r="2" /></svg>
        }
        // if(this.props.type==="cashregister"){
        //     return <svg  viewBox="0 0 24 24"><path d="M19,1H5A5.006,5.006,0,0,0,0,6v8a5.006,5.006,0,0,0,5,5h6v2H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2H13V19h6a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1Zm3,13a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V6A3,3,0,0,1,5,3H19a3,3,0,0,1,3,3Zm-2-4a1,1,0,0,1-1,1H16.035l-1.7,2.555A1,1,0,0,1,13.5,14c-.021,0-.042,0-.062,0a1,1,0,0,1-.833-.551L10.376,8.988,9.332,10.555A1,1,0,0,1,8.5,11H5A1,1,0,0,1,5,9H7.965l1.7-2.555A.987.987,0,0,1,10.562,6a1,1,0,0,1,.833.551l2.229,4.459,1.044-1.567A1,1,0,0,1,15.5,9H19A1,1,0,0,1,20,10Z"/></svg>
        // }
        if (this.props.type === "campaign") {
            // return <svg  viewBox="0 0 24 24"><path d="M23.119.882a2.966,2.966,0,0,0-2.8-.8l-16,3.37a4.995,4.995,0,0,0-2.853,8.481L3.184,13.65a1,1,0,0,1,.293.708v3.168a2.965,2.965,0,0,0,.3,1.285l-.008.007.026.026A3,3,0,0,0,5.157,20.2l.026.026.007-.008a2.965,2.965,0,0,0,1.285.3H9.643a1,1,0,0,1,.707.292l1.717,1.717A4.963,4.963,0,0,0,15.587,24a5.049,5.049,0,0,0,1.605-.264,4.933,4.933,0,0,0,3.344-3.986L23.911,3.715A2.975,2.975,0,0,0,23.119.882ZM4.6,12.238,2.881,10.521a2.94,2.94,0,0,1-.722-3.074,2.978,2.978,0,0,1,2.5-2.026L20.5,2.086,5.475,17.113V14.358A2.978,2.978,0,0,0,4.6,12.238Zm13.971,7.17a3,3,0,0,1-5.089,1.712L11.762,19.4a2.978,2.978,0,0,0-2.119-.878H6.888L21.915,3.5Z"/></svg>
            return <svg viewBox="0 0 24 24"><path d="M17,0a1,1,0,0,0-1,1c0,2.949-2.583,4-5,4H4A4,4,0,0,0,0,9v2a3.979,3.979,0,0,0,1.514,3.109l3.572,7.972A3.233,3.233,0,0,0,8.039,24a2.982,2.982,0,0,0,2.72-4.2L8.559,15H11c2.417,0,5,1.051,5,4a1,1,0,0,0,2,0V1A1,1,0,0,0,17,0ZM8.937,20.619A.983.983,0,0,1,8.039,22a1.232,1.232,0,0,1-1.126-.734L4.105,15H6.359ZM16,14.6A7.723,7.723,0,0,0,11,13H4a2,2,0,0,1-2-2V9A2,2,0,0,1,4,7h7a7.723,7.723,0,0,0,5-1.595Zm7.9.852a1,1,0,0,1-1.342.448l-2-1a1,1,0,0,1,.894-1.79l2,1A1,1,0,0,1,23.9,15.447Zm-3.79-9a1,1,0,0,1,.448-1.342l2-1a1,1,0,1,1,.894,1.79l-2,1a1,1,0,0,1-1.342-.448ZM20,10a1,1,0,0,1,1-1h2a1,1,0,0,1,0,2H21A1,1,0,0,1,20,10Z" /></svg>
        }
        if (this.props.type === "membership") {
            return <svg viewBox="0 0 24 24"><path d="M23.836,8.794a3.179,3.179,0,0,0-3.067-2.226H16.4L15.073,2.432a3.227,3.227,0,0,0-6.146,0L7.6,6.568H3.231a3.227,3.227,0,0,0-1.9,5.832L4.887,15,3.535,19.187A3.178,3.178,0,0,0,4.719,22.8a3.177,3.177,0,0,0,3.8-.019L12,20.219l3.482,2.559a3.227,3.227,0,0,0,4.983-3.591L19.113,15l3.56-2.6A3.177,3.177,0,0,0,23.836,8.794Zm-2.343,1.991-4.144,3.029a1,1,0,0,0-.362,1.116L18.562,19.8a1.227,1.227,0,0,1-1.895,1.365l-4.075-3a1,1,0,0,0-1.184,0l-4.075,3a1.227,1.227,0,0,1-1.9-1.365L7.013,14.93a1,1,0,0,0-.362-1.116L2.507,10.785a1.227,1.227,0,0,1,.724-2.217h5.1a1,1,0,0,0,.952-.694l1.55-4.831a1.227,1.227,0,0,1,2.336,0l1.55,4.831a1,1,0,0,0,.952.694h5.1a1.227,1.227,0,0,1,.724,2.217Z" /></svg>
        }
        if (this.props.type === "coupon") {
            return <svg viewBox="0 0 24 24"><path d="M19,22H5a5.006,5.006,0,0,1-5-5V7A5.006,5.006,0,0,1,5,2H19a5.006,5.006,0,0,1,5,5V17A5.006,5.006,0,0,1,19,22ZM5,4A3,3,0,0,0,2,7V17a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7a3,3,0,0,0-3-3Z" /><path d="M19,13H11a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z" /><path d="M7,13H5a1,1,0,0,1,0-2H7a1,1,0,0,1,0,2Z" /><path d="M13,18H5a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z" /><path d="M19,18H17a1,1,0,0,1,0-2h2a1,1,0,0,1,0,2Z" /></svg>
        }
        if (this.props.type === "category") {
            return <svg viewBox="0 0 24 24"><path d="M10.007,3,8,2.986A3,3,0,0,0,5,0H3A3,3,0,0,0,0,3V5A3,3,0,0,0,2.993,8L2.979,9.993A1,1,0,0,0,3.973,11h.006a1,1,0,0,0,1-.993L4.993,8H5A3,3,0,0,0,8,5V4.986L9.993,5H10a1,1,0,0,0,.007-2ZM6,5A1,1,0,0,1,5,6H3A1,1,0,0,1,2,5V3A1,1,0,0,1,3,2H5A1,1,0,0,1,6,3Z" /><path d="M21,0H19a3,3,0,0,0-3,2.993l-1.994-.014H14a1,1,0,0,0-.007,2L16,4.993V5a3,3,0,0,0,3,3h.014L19,9.993A1,1,0,0,0,19.993,11H20a1,1,0,0,0,1-.993L21.014,8A3,3,0,0,0,24,5V3A3,3,0,0,0,21,0Zm1,5a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V3a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Z" /><path d="M21.007,16l.014-1.994A1,1,0,0,0,20.027,13h-.006a1,1,0,0,0-1,.993L19.007,16H19a3,3,0,0,0-3,3v.014L14.007,19H14a1,1,0,0,0-.007,2L16,21.014A3,3,0,0,0,19,24h2a3,3,0,0,0,3-3V19A3,3,0,0,0,21.007,16ZM22,21a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Z" /><path d="M10.007,19.021,8,19.007V19a3,3,0,0,0-3-3H4.986L5,14.007A1,1,0,0,0,4.007,13H4a1,1,0,0,0-1,.993L2.986,16A3,3,0,0,0,0,19v2a3,3,0,0,0,3,3H5a3,3,0,0,0,3-2.993l1.994.014H10a1,1,0,0,0,.007-2ZM6,21a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1H5a1,1,0,0,1,1,1Z" /></svg>
        }
        if (this.props.type === "subcategory") {
            return <svg viewBox="0 0 24 24"><path d="M21,16V12a8.983,8.983,0,0,0-3.356-7h2.633a1.991,1.991,0,1,0-.009-2h-4.41A3.981,3.981,0,0,0,8.142,3H3.723a1.991,1.991,0,1,0,.009,2H6.356A8.983,8.983,0,0,0,3,12v4a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3H5a3,3,0,0,0,3-3V19a3,3,0,0,0-3-3V12A7,7,0,0,1,8.521,5.935a3.963,3.963,0,0,0,6.958,0A7,7,0,0,1,19,12v4a3,3,0,0,0-3,3v2a3,3,0,0,0,3,3h2a3,3,0,0,0,3-3V19A3,3,0,0,0,21,16ZM6,19v2a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1H5A1,1,0,0,1,6,19ZM12,6a2,2,0,1,1,2-2A2,2,0,0,1,12,6ZM22,21a1,1,0,0,1-1,1H19a1,1,0,0,1-1-1V19a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Z" /></svg>
        }
        if (this.props.type == "offers") {
            return <svg viewBox="0 0 24 24"><path d="M7.54,13H2.732L7.865,4.109l3.982,6.9c.051,0,.1-.008.153-.008a5.953,5.953,0,0,1,2.46.534L9.6,3.108a2,2,0,0,0-3.464,0h0L.134,13.5A1,1,0,0,0,1,15H6.35A6.009,6.009,0,0,1,7.54,13Z" /><path d="M23,3H13a1,1,0,0,0-1,1v7a5.976,5.976,0,0,1,2,.35V5h8v8H16.46a6.009,6.009,0,0,1,1.19,2H23a1,1,0,0,0,1-1V4A1,1,0,0,0,23,3Z" /><path d="M12,12a5,5,0,1,1-5,5,5.006,5.006,0,0,1,5-5m0-2a7,7,0,1,0,7,7,7,7,0,0,0-7-7Z" /></svg>
        }
        if (this.props.type === "kds") {
            return <svg viewBox="0 0 24 24"><path d="M19,1H5A5.006,5.006,0,0,0,0,6v8a5.006,5.006,0,0,0,5,5h6v2H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2H13V19h6a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1ZM5,3H19a3,3,0,0,1,3,3v7H2V6A3,3,0,0,1,5,3ZM19,17H5a3,3,0,0,1-2.816-2H21.816A3,3,0,0,1,19,17Z" /></svg>
        }
        if (this.props.type === "taxes") {
            return <svg viewBox="0 0 24 24"><path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0Zm3,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2H19a3,3,0,0,1,3,3ZM18,9a1,1,0,0,1-2,0,1,1,0,0,0-1-1H13v8h1a1,1,0,0,1,0,2H10a1,1,0,0,1,0-2h1V8H9A1,1,0,0,0,8,9,1,1,0,0,1,6,9,3,3,0,0,1,9,6h6A3,3,0,0,1,18,9Z" /></svg>
        }
        if (this.props.type === "product") {
            return <svg viewBox="0 0 24 24"><path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0ZM16,11H13V8h3Zm2-3h4v3H18ZM13,6V2h3V6ZM11,6H8V2h3Zm0,2v3H8V8ZM6,11H2V8H6ZM2,13H6v3H2Zm6,0h3v3H8Zm3,5v4H8V18Zm2,0h3v4H13Zm0-2V13h3v3Zm5-3h4v3H18Zm4-8V6H18V2h1A3,3,0,0,1,22,5ZM5,2H6V6H2V5A3,3,0,0,1,5,2ZM2,19V18H6v4H5A3,3,0,0,1,2,19Zm17,3H18V18h4v1A3,3,0,0,1,19,22Z" /></svg>
        }
        if (this.props.type === "tags") {
            return <svg viewBox="0 0 24 24"><path d="M16,0h-.13a2.02,2.02,0,0,0-1.941,1.532,2,2,0,0,1-3.858,0A2.02,2.02,0,0,0,8.13,0H8A5.006,5.006,0,0,0,3,5V21a3,3,0,0,0,3,3H8.13a2.02,2.02,0,0,0,1.941-1.532,2,2,0,0,1,3.858,0A2.02,2.02,0,0,0,15.87,24H18a3,3,0,0,0,3-3V5A5.006,5.006,0,0,0,16,0Zm2,22-2.143-.063A4,4,0,0,0,8.13,22H6a1,1,0,0,1-1-1V17H7a1,1,0,0,0,0-2H5V5A3,3,0,0,1,8,2l.143.063A4.01,4.01,0,0,0,12,5a4.071,4.071,0,0,0,3.893-3H16a3,3,0,0,1,3,3V15H17a1,1,0,0,0,0,2h2v4A1,1,0,0,1,18,22Z" /><path d="M13,15H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" /></svg>
        }
        if (this.props.type === "brand") {
            return <svg viewBox="0 0 24 24"><path d="M2,11H13a2,2,0,0,0,2-2V2a2,2,0,0,0-2-2H2A2,2,0,0,0,0,2V9A2,2,0,0,0,2,11ZM2,2H13V9H2Z" /><path d="M22,0H19a2,2,0,0,0-2,2V9a2,2,0,0,0,2,2h3a2,2,0,0,0,2-2V2A2,2,0,0,0,22,0Zm0,9H19V2h3Z" /><path d="M5,13H2a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H5a2,2,0,0,0,2-2V15A2,2,0,0,0,5,13Zm0,9H2V15H5Z" /><path d="M22,13H11a2,2,0,0,0-2,2v7a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V15A2,2,0,0,0,22,13Zm0,9H11V15H22Z" /></svg>
        }
        if (this.props.type === "bom") {
            return <svg viewBox="0 0 24 24"><path d="M9,9a1,1,0,0,1,1-1h1V7a1,1,0,0,1,2,0V8h1a1,1,0,0,1,0,2H13v1a1,1,0,0,1-2,0V10H10A1,1,0,0,1,9,9Zm7,5H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Zm-4,4H8a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2ZM21,7V19a5.006,5.006,0,0,1-5,5H8a5.006,5.006,0,0,1-5-5V7A5.006,5.006,0,0,1,8,2h.171A3.006,3.006,0,0,1,11,0h2a3.006,3.006,0,0,1,2.829,2H16A5.006,5.006,0,0,1,21,7ZM19,7a3,3,0,0,0-3-3H15a1,1,0,0,1-1-1,1,1,0,0,0-1-1H11a1,1,0,0,0-1,1A1,1,0,0,1,9,4H8A3,3,0,0,0,5,7V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3Z" /></svg>
        }
        if (this.props.type === "modifiers") {
            return <svg viewBox="0 0 24 24"><path d="M24,11.5A4.476,4.476,0,0,0,22.294,8,4.481,4.481,0,0,0,19.5,0H4.5A4.481,4.481,0,0,0,1.706,8a4.443,4.443,0,0,0,0,7A4.481,4.481,0,0,0,4.5,23h15a4.481,4.481,0,0,0,2.794-8A4.476,4.476,0,0,0,24,11.5ZM2,4.5A2.5,2.5,0,0,1,4.5,2H5V3A1,1,0,0,0,7,3V2H9V3a1,1,0,0,0,2,0V2h8.5a2.5,2.5,0,0,1,0,5H4.5A2.5,2.5,0,0,1,2,4.5Zm20,14A2.5,2.5,0,0,1,19.5,21H4.5a2.5,2.5,0,0,1,0-5H5v1a1,1,0,0,0,2,0V16H9v1a1,1,0,0,0,2,0V16h8.5A2.5,2.5,0,0,1,22,18.5ZM4.5,14a2.5,2.5,0,0,1,0-5H5v1a1,1,0,0,0,2,0V9H9v1a1,1,0,0,0,2,0V9h8.5a2.5,2.5,0,0,1,0,5Z" /></svg>
        }
        if (this.props.type === "attendance") {
            // return <svg  viewBox="0 0 24 24"><path d="M17,24H7.005a4.014,4.014,0,0,1-3.044-1.4,3.94,3.94,0,0,1-.917-3.158A12.522,12.522,0,0,1,7.445,12a12.522,12.522,0,0,1-4.4-7.444A3.94,3.94,0,0,1,3.961,1.4,4.014,4.014,0,0,1,7.005,0H17a4.017,4.017,0,0,1,3.044,1.4,3.943,3.943,0,0,1,.918,3.155A12.556,12.556,0,0,1,16.551,12a12.557,12.557,0,0,1,4.406,7.448,3.944,3.944,0,0,1-.918,3.156A4.017,4.017,0,0,1,17,24ZM17,2H7.005a2.015,2.015,0,0,0-1.528.7,1.921,1.921,0,0,0-.456,1.556c.376,2.5,1.924,4.84,4.6,6.957a1,1,0,0,1,0,1.568C6.945,14.9,5.4,17.242,5.021,19.741A1.921,1.921,0,0,0,5.477,21.3a2.015,2.015,0,0,0,1.528.7H17a2.014,2.014,0,0,0,1.528-.7,1.917,1.917,0,0,0,.456-1.554c-.373-2.487-1.92-4.829-4.6-6.962a1,1,0,0,1,0-1.564c2.681-2.133,4.228-4.475,4.6-6.963A1.916,1.916,0,0,0,18.523,2.7,2.014,2.014,0,0,0,17,2ZM15.681,20H8.318a1,1,0,0,1-.927-1.374,11.185,11.185,0,0,1,3.471-4.272l.518-.412a1,1,0,0,1,1.245,0l.509.406a11.3,11.3,0,0,1,3.473,4.276A1,1,0,0,1,15.681,20Zm-5.647-2h3.928A11.57,11.57,0,0,0,12,16,11.3,11.3,0,0,0,10.034,18Z"/></svg>
            return <svg viewBox="0 0 24 24"><path d="M23,11a1,1,0,0,0-1,1,10.034,10.034,0,1,1-2.9-7.021A.862.862,0,0,1,19,5H16a1,1,0,0,0,0,2h3a3,3,0,0,0,3-3V1a1,1,0,0,0-2,0V3.065A11.994,11.994,0,1,0,24,12,1,1,0,0,0,23,11Z" /><path d="M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z" /></svg>
        }
        if (this.props.type === "staff") {
            return <svg viewBox="0 0 24 24"><path d="M7.5,13A4.5,4.5,0,1,1,12,8.5,4.505,4.505,0,0,1,7.5,13Zm0-7A2.5,2.5,0,1,0,10,8.5,2.5,2.5,0,0,0,7.5,6ZM15,23v-.5a7.5,7.5,0,0,0-15,0V23a1,1,0,0,0,2,0v-.5a5.5,5.5,0,0,1,11,0V23a1,1,0,0,0,2,0Zm9-5a7,7,0,0,0-11.667-5.217,1,1,0,1,0,1.334,1.49A5,5,0,0,1,22,18a1,1,0,0,0,2,0ZM17.5,9A4.5,4.5,0,1,1,22,4.5,4.505,4.505,0,0,1,17.5,9Zm0-7A2.5,2.5,0,1,0,20,4.5,2.5,2.5,0,0,0,17.5,2Z" /></svg>
        }
        if (this.props.type === "stores") {
            return <svg viewBox="0 0 24 24"><path d="M24,10a.988.988,0,0,0-.024-.217l-1.3-5.868A4.968,4.968,0,0,0,17.792,0H6.208a4.968,4.968,0,0,0-4.88,3.915L.024,9.783A.988.988,0,0,0,0,10v1a3.984,3.984,0,0,0,1,2.643V19a5.006,5.006,0,0,0,5,5H18a5.006,5.006,0,0,0,5-5V13.643A3.984,3.984,0,0,0,24,11ZM2,10.109l1.28-5.76A2.982,2.982,0,0,1,6.208,2H7V5A1,1,0,0,0,9,5V2h6V5a1,1,0,0,0,2,0V2h.792A2.982,2.982,0,0,1,20.72,4.349L22,10.109V11a2,2,0,0,1-2,2H19a2,2,0,0,1-2-2,1,1,0,0,0-2,0,2,2,0,0,1-2,2H11a2,2,0,0,1-2-2,1,1,0,0,0-2,0,2,2,0,0,1-2,2H4a2,2,0,0,1-2-2ZM18,22H6a3,3,0,0,1-3-3V14.873A3.978,3.978,0,0,0,4,15H5a3.99,3.99,0,0,0,3-1.357A3.99,3.99,0,0,0,11,15h2a3.99,3.99,0,0,0,3-1.357A3.99,3.99,0,0,0,19,15h1a3.978,3.978,0,0,0,1-.127V19A3,3,0,0,1,18,22Z" /></svg>
        }
        if (this.props.type === "dashboard") {
            return <svg viewBox="0 0 24 24"><path d="M23,22H3a1,1,0,0,1-1-1V1A1,1,0,0,0,0,1V21a3,3,0,0,0,3,3H23a1,1,0,0,0,0-2Z" /><path d="M15,20a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v7A1,1,0,0,0,15,20Z" /><path d="M7,20a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v7A1,1,0,0,0,7,20Z" /><path d="M19,20a1,1,0,0,0,1-1V7a1,1,0,0,0-2,0V19A1,1,0,0,0,19,20Z" /><path d="M11,20a1,1,0,0,0,1-1V7a1,1,0,0,0-2,0V19A1,1,0,0,0,11,20Z" /></svg>
        }
        if (this.props.type === "registration") {
            return <svg viewBox="0 0 24 24"><path d="M24,23a1,1,0,0,1-1,1H1a1,1,0,0,1,0-2H23A1,1,0,0,1,24,23ZM.291,8.552A2.443,2.443,0,0,1,.444,5.986a4.716,4.716,0,0,1,1.668-1.5L9.613.582a5.174,5.174,0,0,1,4.774,0l7.5,3.907h0a4.716,4.716,0,0,1,1.668,1.5,2.443,2.443,0,0,1,.153,2.566A2.713,2.713,0,0,1,21.292,10H21v8h1a1,1,0,0,1,0,2H2a1,1,0,0,1,0-2H3V10H2.708A2.713,2.713,0,0,1,.291,8.552ZM5,18H8V10H5Zm5-8v8h4V10Zm9,0H16v8h3ZM2.063,7.625A.717.717,0,0,0,2.708,8H21.292a.717.717,0,0,0,.645-.375.452.452,0,0,0-.024-.5,2.7,2.7,0,0,0-.949-.864h0l-7.5-3.907a3.176,3.176,0,0,0-2.926,0l-7.5,3.907a2.712,2.712,0,0,0-.949.865A.452.452,0,0,0,2.063,7.625Z" /></svg>
        }
        if (this.props.type === "adduser") {
            return <svg id="Outline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23,11H21V9a1,1,0,0,0-2,0v2H17a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V13h2a1,1,0,0,0,0-2Z" /><path d="M9,12A6,6,0,1,0,3,6,6.006,6.006,0,0,0,9,12ZM9,2A4,4,0,1,1,5,6,4,4,0,0,1,9,2Z" /><path d="M9,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,9,14Z" /></svg>
        }
        if (this.props.type === "paymenttypes") {
            return <svg viewBox="0 0 24 24"><path d="M22.485,10.975,12,17.267,1.515,10.975A1,1,0,1,0,.486,12.69l11,6.6a1,1,0,0,0,1.03,0l11-6.6a1,1,0,1,0-1.029-1.715Z" /><path d="M22.485,15.543,12,21.834,1.515,15.543A1,1,0,1,0,.486,17.258l11,6.6a1,1,0,0,0,1.03,0l11-6.6a1,1,0,1,0-1.029-1.715Z" /><path d="M12,14.773a2.976,2.976,0,0,1-1.531-.425L.485,8.357a1,1,0,0,1,0-1.714L10.469.652a2.973,2.973,0,0,1,3.062,0l9.984,5.991a1,1,0,0,1,0,1.714l-9.984,5.991A2.976,2.976,0,0,1,12,14.773ZM2.944,7.5,11.5,12.633a.974.974,0,0,0,1,0L21.056,7.5,12.5,2.367a.974.974,0,0,0-1,0h0Z" /></svg>
        }
        if (this.props.type === "menubar") {
            return <svg viewBox="0 0 24 24"><path d="M1,6H23a1,1,0,0,0,0-2H1A1,1,0,0,0,1,6Z" /><path d="M1,11H15a1,1,0,0,0,0-2H1a1,1,0,0,0,0,2Z" /><path d="M15,19H1a1,1,0,0,0,0,2H15a1,1,0,0,0,0-2Z" /><path d="M23,14H1a1,1,0,0,0,0,2H23a1,1,0,0,0,0-2Z" /></svg>
        }
        if (this.props.type === "store") {
            return <svg viewBox="0 0 24 24"><path d="M23.121,9.069,15.536,1.483a5.008,5.008,0,0,0-7.072,0L.879,9.069A2.978,2.978,0,0,0,0,11.19v9.817a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V11.19A2.978,2.978,0,0,0,23.121,9.069ZM15,22.007H9V18.073a3,3,0,0,1,6,0Zm7-1a1,1,0,0,1-1,1H17V18.073a5,5,0,0,0-10,0v3.934H3a1,1,0,0,1-1-1V11.19a1.008,1.008,0,0,1,.293-.707L9.878,2.9a3.008,3.008,0,0,1,4.244,0l7.585,7.586A1.008,1.008,0,0,1,22,11.19Z" /></svg>
        }
        if (this.props.type === "cashregister") {
            return <svg viewBox="0 0 24 24"><path d="M19,1H5A5.006,5.006,0,0,0,0,6v8a5.006,5.006,0,0,0,5,5h6v2H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2H13V19h6a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1Zm3,13a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V6A3,3,0,0,1,5,3H19a3,3,0,0,1,3,3Zm-2-4a1,1,0,0,1-1,1H16.035l-1.7,2.555A1,1,0,0,1,13.5,14c-.021,0-.042,0-.062,0a1,1,0,0,1-.833-.551L10.376,8.988,9.332,10.555A1,1,0,0,1,8.5,11H5A1,1,0,0,1,5,9H7.965l1.7-2.555A.987.987,0,0,1,10.562,6a1,1,0,0,1,.833.551l2.229,4.459,1.044-1.567A1,1,0,0,1,15.5,9H19A1,1,0,0,1,20,10Z" /></svg>
        }
        if (this.props.type === "user") {
            return <svg viewBox="0 0 24 24"><path d="M12,12A6,6,0,1,0,6,6,6.006,6.006,0,0,0,12,12ZM12,2A4,4,0,1,1,8,6,4,4,0,0,1,12,2Z" /><path d="M12,14a9.01,9.01,0,0,0-9,9,1,1,0,0,0,2,0,7,7,0,0,1,14,0,1,1,0,0,0,2,0A9.01,9.01,0,0,0,12,14Z" /></svg>
        }
        if (this.props.type === "add") {
            // return <svg viewBox="0 0 24 24"><path d="M23,11H13V1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H11V23a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13H23a1,1,0,0,0,1-1h0A1,1,0,0,0,23,11Z"/></svg>
            return <svg viewBox="0 0 24 24"><path d="M17,11H13V7a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v4H7a1,1,0,0,0-1,1H6a1,1,0,0,0,1,1h4v4a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13h4a1,1,0,0,0,1-1h0A1,1,0,0,0,17,11Z" /></svg>
        }
        if (this.props.type === "help") {
            return <svg viewBox="0 0 24 24"><path d="M12,15a1,1,0,0,1-1-1,3.953,3.953,0,0,1,1.963-3.195,1.994,1.994,0,0,0,1-2.124,2.024,2.024,0,0,0-1.6-1.6A2,2,0,0,0,10,9.052a1,1,0,0,1-2,0A4,4,0,0,1,9.429,5.987a4,4,0,1,1,4.5,6.57A1.987,1.987,0,0,0,13,14,1,1,0,0,1,12,15Zm.793,8.76C14.953,22.892,22,19.479,22,12.043V6.872a4.993,4.993,0,0,0-3.426-4.746L12.315.051a.985.985,0,0,0-.63,0L5.426,2.126A4.993,4.993,0,0,0,2,6.872v5.171c0,6.562,7.005,10.576,9.153,11.65A2.331,2.331,0,0,0,12,24,2.242,2.242,0,0,0,12.793,23.76ZM17.944,4.024A3,3,0,0,1,20,6.872v5.171c0,6.183-6.087,9.111-7.953,9.861h0C10.159,20.96,4,17.459,4,12.043V6.872A3,3,0,0,1,6.056,4.024L12,2.054ZM12,17a1,1,0,1,0,1,1A1,1,0,0,0,12,17Z" /></svg>
        }
        if (this.props.type === "notification") {
            return <svg viewBox="0 0 24 24"><path d="M22.555,13.662l-1.9-6.836A9.321,9.321,0,0,0,2.576,7.3L1.105,13.915A5,5,0,0,0,5.986,20H7.1a5,5,0,0,0,9.8,0h.838a5,5,0,0,0,4.818-6.338ZM12,22a3,3,0,0,1-2.816-2h5.632A3,3,0,0,1,12,22Zm8.126-5.185A2.977,2.977,0,0,1,17.737,18H5.986a3,3,0,0,1-2.928-3.651l1.47-6.616a7.321,7.321,0,0,1,14.2-.372l1.9,6.836A2.977,2.977,0,0,1,20.126,16.815Z" /></svg>
        }
        if (this.props.type == "park") {
            // return <svg viewBox="0 0 24 24"><path d="M6.5,0A3.5,3.5,0,0,0,3,3.5v17a3.5,3.5,0,0,0,7,0V3.5A3.5,3.5,0,0,0,6.5,0ZM8,20.5a1.5,1.5,0,0,1-3,0V3.5a1.5,1.5,0,0,1,3,0Z"/><path d="M17.5,0A3.5,3.5,0,0,0,14,3.5v17a3.5,3.5,0,0,0,7,0V3.5A3.5,3.5,0,0,0,17.5,0ZM19,20.5a1.5,1.5,0,0,1-3,0V3.5a1.5,1.5,0,0,1,3,0Z"/></svg>
            return <svg viewBox="0 0 24 24"><path d="M23,16h0a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17A1,1,0,0,0,23,16Z" /><path d="M5.293,13.538l4.586,4.587a3.007,3.007,0,0,0,4.242,0l4.586-4.586a1,1,0,1,0-1.414-1.414L13,16.416V1a1,1,0,0,0-2,0V16.417L6.707,12.124a1,1,0,1,0-1.414,1.414Z" /></svg>
        }
        if (this.props.type === "retrieve") {
            // return <svg  viewBox="0 0 24 24"><path d="M0,23V16A9.01,9.01,0,0,1,9,7h4.83V5.414A2,2,0,0,1,17.244,4l5.88,5.879a3,3,0,0,1,0,4.242L17.244,20a2,2,0,0,1-3.414-1.414V17H8a6.006,6.006,0,0,0-6,6,1,1,0,0,1-2,0ZM15.83,8a1,1,0,0,1-1,1H9a7.008,7.008,0,0,0-7,7v1.714A7.984,7.984,0,0,1,8,15h6.83a1,1,0,0,1,1,1v2.586l5.879-5.879a1,1,0,0,0,0-1.414L15.83,5.414Z"/></svg>
            return <svg viewBox="0 0 24 24"><path d="M22,17v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17a1,1,0,0,0-1-1h0A1,1,0,0,0,22,17Z" /><path d="M18.707,5.462,14.121.875a3.007,3.007,0,0,0-4.242,0L5.293,5.462A1,1,0,1,0,6.707,6.876L11,2.584V18a1,1,0,0,0,2,0V2.583l4.293,4.293a1,1,0,1,0,1.414-1.414Z" /></svg>
        }
        if (this.props.type === "discount") {
            return <svg viewBox="0 0 24 24"><path d="M19,0H14a1,1,0,0,0,0,2h5a2.951,2.951,0,0,1,1.285.3L.293,22.293a1,1,0,1,0,1.414,1.414L21.7,3.715A2.951,2.951,0,0,1,22,5v5a1,1,0,0,0,2,0V5A5.006,5.006,0,0,0,19,0Z" /><path d="M6,10A4,4,0,1,0,2,6,4,4,0,0,0,6,10ZM6,4A2,2,0,1,1,4,6,2,2,0,0,1,6,4Z" /><path d="M18,14a4,4,0,1,0,4,4A4,4,0,0,0,18,14Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,18,20Z" /></svg>
        }
        if (this.props.type === "notes") {
            return <svg viewBox="0 0 24 24"><path d="M18.656.93,6.464,13.122A4.966,4.966,0,0,0,5,16.657V18a1,1,0,0,0,1,1H7.343a4.966,4.966,0,0,0,3.535-1.464L23.07,5.344a3.125,3.125,0,0,0,0-4.414A3.194,3.194,0,0,0,18.656.93Zm3,3L9.464,16.122A3.02,3.02,0,0,1,7.343,17H7v-.343a3.02,3.02,0,0,1,.878-2.121L20.07,2.344a1.148,1.148,0,0,1,1.586,0A1.123,1.123,0,0,1,21.656,3.93Z" /><path d="M23,8.979a1,1,0,0,0-1,1V15H18a3,3,0,0,0-3,3v4H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2h9.042a1,1,0,0,0,0-2H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H16.343a4.968,4.968,0,0,0,3.536-1.464l2.656-2.658A4.968,4.968,0,0,0,24,16.343V9.979A1,1,0,0,0,23,8.979ZM18.465,21.122a2.975,2.975,0,0,1-1.465.8V18a1,1,0,0,1,1-1h3.925a3.016,3.016,0,0,1-.8,1.464Z" /></svg>
        }
        if (this.props.type === "delete") {
            return <svg viewBox="0 0 24 24"><path d="M21,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H3A1,1,0,0,0,3,6H4V19a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5V6h1a1,1,0,0,0,0-2ZM11,2h2a3.006,3.006,0,0,1,2.829,2H8.171A3.006,3.006,0,0,1,11,2Zm7,17a3,3,0,0,1-3,3H9a3,3,0,0,1-3-3V6H18Z" /><path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18Z" /><path d="M14,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z" /></svg>
        }
        if (this.props.type === "delivery") {
            return <svg viewBox="0 0 24 24"><path d="M20,11H19V5a4,4,0,0,0-4-4H4A4,4,0,0,0,0,5V16a2.994,2.994,0,0,0,2.071,2.838A3.4,3.4,0,0,0,2,19.5a3.5,3.5,0,0,0,7,0,3.465,3.465,0,0,0-.041-.5h6.082a3.465,3.465,0,0,0-.041.5,3.5,3.5,0,0,0,7,0,3.4,3.4,0,0,0-.071-.662A2.994,2.994,0,0,0,24,16V15A4,4,0,0,0,20,11ZM2,11V7H4v4ZM6,7H8v4H6Zm4,0h3V17H10Zm5,0h2v4H15ZM4,3H15a2,2,0,0,1,2,2H2A2,2,0,0,1,4,3ZM2,13H8v4H3a1,1,0,0,1-1-1Zm5,6.5a1.5,1.5,0,0,1-3,0,1.41,1.41,0,0,1,.093-.5H6.907A1.41,1.41,0,0,1,7,19.5ZM18.5,21A1.5,1.5,0,0,1,17,19.5a1.41,1.41,0,0,1,.093-.5h2.814a1.41,1.41,0,0,1,.093.5A1.5,1.5,0,0,1,18.5,21ZM22,16a1,1,0,0,1-1,1H15V13h5a2,2,0,0,1,2,2Z" /></svg>
        }
        if (this.props.type === "info") {
            return <svg viewBox="0 0 24 24"><path d="M4,6a2.982,2.982,0,0,1-2.122-.879L.334,3.747A1,1,0,0,1,1.666,2.253L3.251,3.667a1,1,0,0,0,1.456.04L8.311.276A1,1,0,0,1,9.689,1.724L6.1,5.138A2.964,2.964,0,0,1,4,6ZM24,4a1,1,0,0,0-1-1H13a1,1,0,0,0,0,2H23A1,1,0,0,0,24,4ZM6.1,13.138,9.689,9.724A1,1,0,1,0,8.311,8.276l-3.6,3.431a1.023,1.023,0,0,1-1.414,0L1.707,10.122A1,1,0,0,0,.293,11.536l1.585,1.585a3,3,0,0,0,4.226.017ZM24,12a1,1,0,0,0-1-1H13a1,1,0,0,0,0,2H23A1,1,0,0,0,24,12ZM6.1,21.138l3.585-3.414a1,1,0,1,0-1.378-1.448l-3.6,3.431a1,1,0,0,1-1.456-.04L1.666,18.253A1,1,0,0,0,.334,19.747l1.544,1.374a3,3,0,0,0,4.226.017ZM24,20a1,1,0,0,0-1-1H13a1,1,0,0,0,0,2H23A1,1,0,0,0,24,20Z" /></svg>
        }
        if (this.props.type === "") {
            return <svg viewBox="0 0 24 24"><path d="M18,24H6a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,6,0H18a5.006,5.006,0,0,1,5,5V19A5.006,5.006,0,0,1,18,24ZM6,2A3,3,0,0,0,3,5V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5a3,3,0,0,0-3-3Zm10,8H8A3,3,0,0,1,8,4h8a3,3,0,0,1,0,6ZM8,6A1,1,0,0,0,8,8h8a1,1,0,0,0,0-2ZM6,13a1,1,0,1,0,1,1A1,1,0,0,0,6,13Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,10,13Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,14,13ZM6,17a1,1,0,1,0,1,1A1,1,0,0,0,6,17Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,10,17Zm8-4a1,1,0,1,0,1,1A1,1,0,0,0,18,13Zm1,5a1,1,0,0,0-1-1H14a1,1,0,0,0,0,2h4A1,1,0,0,0,19,18Z" /></svg>
        }
        if (this.props.type === "remove") {
            // return <svg  viewBox="0 0 24 24"><path d="M23.707.293h0a1,1,0,0,0-1.414,0L12,10.586,1.707.293a1,1,0,0,0-1.414,0h0a1,1,0,0,0,0,1.414L10.586,12,.293,22.293a1,1,0,0,0,0,1.414h0a1,1,0,0,0,1.414,0L12,13.414,22.293,23.707a1,1,0,0,0,1.414,0h0a1,1,0,0,0,0-1.414L13.414,12,23.707,1.707A1,1,0,0,0,23.707.293Z"/></svg>
            return <svg viewBox="0 0 24 24"><path d="M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z" /></svg>
        }
        if (this.props.type == "toggle") {
            return <svg viewBox="0 0 24 24"><path d="M23,12a.982.982,0,0,0-.988.878A9.986,9.986,0,0,1,4.924,19H7.669a1,1,0,1,0,0-2h-4a2,2,0,0,0-2,2v4a1,1,0,0,0,2,0V20.562A11.977,11.977,0,0,0,24,13.1,1.007,1.007,0,0,0,23,12Z" /><path d="M21.331,0a1,1,0,0,0-1,1V3.438A11.977,11.977,0,0,0,0,10.9,1.007,1.007,0,0,0,1,12a.982.982,0,0,0,.988-.878A9.986,9.986,0,0,1,19.076,5H16.331a1,1,0,0,0,0,2h4a2,2,0,0,0,2-2V1A1,1,0,0,0,21.331,0Z" /></svg>
        }
        if (this.props.type === "minus") {
            return <svg viewBox="0 0 24 24"><rect x="6" y="11" width="12" height="2" rx="1" /></svg>
        }
        if (this.props.type === "appointment") {
            return <svg viewBox="0 0 24 24"><path d="M19,2H18V1a1,1,0,0,0-2,0V2H8V1A1,1,0,0,0,6,1V2H5A5.006,5.006,0,0,0,0,7V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2ZM2,7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3V8H2ZM19,22H5a3,3,0,0,1-3-3V10H22v9A3,3,0,0,1,19,22Z" /><circle cx="12" cy="15" r="1.5" /><circle cx="7" cy="15" r="1.5" /><circle cx="17" cy="15" r="1.5" /></svg>
        }
        if (this.props.type === "expensecategories") {
            return <svg viewBox="0 0 24 24"><path d="M17,0H7A5.006,5.006,0,0,0,2,5V19a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,17,0ZM7,2H17a3,3,0,0,1,3,3v6H4V5A3,3,0,0,1,7,2ZM17,22H7a3,3,0,0,1-3-3V13H20v6A3,3,0,0,1,17,22Z" /><path d="M11,7h2a1,1,0,0,0,0-2H11a1,1,0,0,0,0,2Z" /><path d="M13,17H11a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" /></svg>
        }
        if (this.props.type === "account") {
            return <svg viewBox="0 0 24 24"><path d="M17,0H7A5.006,5.006,0,0,0,2,5V20a4,4,0,0,0,4,4H17a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,17,0Zm3,5V16H8V2h9A3,3,0,0,1,20,5ZM6,2.172V16a3.98,3.98,0,0,0-2,.537V5A3,3,0,0,1,6,2.172ZM17,22H6a2,2,0,0,1,0-4H20v1A3,3,0,0,1,17,22Z" /></svg>
        }
        if (this.props.type === "accountmanagement") {
            return <svg viewBox="0 0 24 24"><path d="M22.2,2.163a4.992,4.992,0,0,0-4.1-1.081l-3.822.694A4,4,0,0,0,12,3.065,4,4,0,0,0,9.716,1.776L5.9,1.082A5,5,0,0,0,0,6V16.793a5,5,0,0,0,4.105,4.919l6.286,1.143a9,9,0,0,0,3.218,0L19.9,21.712A5,5,0,0,0,24,16.793V6A4.983,4.983,0,0,0,22.2,2.163ZM11,20.928c-.084-.012-.168-.026-.252-.041L4.463,19.745A3,3,0,0,1,2,16.793V6A3,3,0,0,1,5,3a3.081,3.081,0,0,1,.54.049l3.82.7A2,2,0,0,1,11,5.712Zm11-4.135a3,3,0,0,1-2.463,2.952l-6.285,1.142c-.084.015-.168.029-.252.041V5.712a2,2,0,0,1,1.642-1.968l3.821-.7A3,3,0,0,1,22,6Z" /></svg>
        }
        if (this.props.type === "onlinehomesections") {
            return <svg viewBox="0 0 24 24"><path d="M24,11.5A4.476,4.476,0,0,0,22.294,8,4.481,4.481,0,0,0,19.5,0H4.5A4.481,4.481,0,0,0,1.706,8a4.443,4.443,0,0,0,0,7A4.481,4.481,0,0,0,4.5,23h15a4.481,4.481,0,0,0,2.794-8A4.476,4.476,0,0,0,24,11.5ZM2,4.5A2.5,2.5,0,0,1,4.5,2H5V3A1,1,0,0,0,7,3V2H9V3a1,1,0,0,0,2,0V2h8.5a2.5,2.5,0,0,1,0,5H4.5A2.5,2.5,0,0,1,2,4.5Zm20,14A2.5,2.5,0,0,1,19.5,21H4.5a2.5,2.5,0,0,1,0-5H5v1a1,1,0,0,0,2,0V16H9v1a1,1,0,0,0,2,0V16h8.5A2.5,2.5,0,0,1,22,18.5ZM4.5,14a2.5,2.5,0,0,1,0-5H5v1a1,1,0,0,0,2,0V9H9v1a1,1,0,0,0,2,0V9h8.5a2.5,2.5,0,0,1,0,5Z" /></svg>
        }
        if (this.props.type === "onlinebanner") {
            return <svg viewBox="0 0 24 24"><path d="M19,0H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,19,0Zm1,11h2v2H20Zm0-2V7h2V9Zm-2,2H6V2H18ZM4,13H2V11H4ZM4,9H2V7H4ZM2,15H4v2H2Zm4-2H18v9H6Zm14,2h2v2H20ZM22,5H20V2.184A3,3,0,0,1,22,5ZM4,2.184V5H2A3,3,0,0,1,4,2.184ZM2,19H4v2.816A3,3,0,0,1,2,19Zm18,2.816V19h2A3,3,0,0,1,20,21.816Z" /></svg>
        }
        if (this.props.type === "onlineprofile") {
            return <svg viewBox="0 0 24 24"><path d="M19,4H15V3A3,3,0,0,0,9,3V4H5A5.006,5.006,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A5.006,5.006,0,0,0,19,4ZM11,3a1,1,0,0,1,2,0V5a1,1,0,0,1-2,0ZM22,19a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V9A3,3,0,0,1,5,6H9.184a2.982,2.982,0,0,0,5.632,0H19a3,3,0,0,1,3,3ZM10,10H5a1,1,0,0,0-1,1v8a1,1,0,0,0,1,1h5a1,1,0,0,0,1-1V11A1,1,0,0,0,10,10ZM9,18H6V12H9Zm11-3a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h5A1,1,0,0,1,20,15Zm0-4a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h5A1,1,0,0,1,20,11Zm-2,8a1,1,0,0,1-1,1H14a1,1,0,0,1,0-2h3A1,1,0,0,1,18,19Z" /></svg>
        }
        if (this.props.type == "onlineproductdetail") {
            return <svg viewBox="0 0 24 24"><path d="M16,16a1,1,0,0,1-1,1H13v2a1,1,0,0,1-2,0V17H9a1,1,0,0,1,0-2h2V13a1,1,0,0,1,2,0v2h2A1,1,0,0,1,16,16Zm6-5.515V19a5.006,5.006,0,0,1-5,5H7a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,7,0h4.515a6.958,6.958,0,0,1,4.95,2.05l3.484,3.486A6.951,6.951,0,0,1,22,10.485ZM15.051,3.464A5.01,5.01,0,0,0,14,2.684V7a1,1,0,0,0,1,1h4.316a4.983,4.983,0,0,0-.781-1.05ZM20,10.485c0-.165-.032-.323-.047-.485H15a3,3,0,0,1-3-3V2.047C11.838,2.032,11.679,2,11.515,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3Z" /></svg>
        }
        if (this.props.type === "onlinepages") {
            return <svg viewBox="0 0 24 24"><path d="M19,0H13A5.006,5.006,0,0,0,8,5v.1A5.009,5.009,0,0,0,4,10v.1A5.009,5.009,0,0,0,0,15v4a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5v-.1A5.009,5.009,0,0,0,20,14v-.1A5.009,5.009,0,0,0,24,9V5A5.006,5.006,0,0,0,19,0ZM2,15a3,3,0,0,1,3-3h6a2.988,2.988,0,0,1,2.638,1.6l-3.455,3.463-.475-.479A1.992,1.992,0,0,0,7,16.473l-4.621,3.96A2.96,2.96,0,0,1,2,19Zm12,4a3,3,0,0,1-3,3H5a2.971,2.971,0,0,1-1.118-.221L8.288,18l.476.481a2,2,0,0,0,2.828,0L14,16.068Zm4-5a3,3,0,0,1-2,2.816V15a5.006,5.006,0,0,0-5-5H6A3,3,0,0,1,9,7h6a3,3,0,0,1,3,3Zm4-5a3,3,0,0,1-2,2.816V10a5.006,5.006,0,0,0-5-5H10a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3ZM4,15a1,1,0,1,1,1,1A1,1,0,0,1,4,15Z" /></svg>
        }
        if (this.props.type === "") {
            return <svg viewBox="0 0 24 24"><path d="M18,24H6a5.006,5.006,0,0,1-5-5V5A5.006,5.006,0,0,1,6,0H18a5.006,5.006,0,0,1,5,5V19A5.006,5.006,0,0,1,18,24ZM6,2A3,3,0,0,0,3,5V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V5a3,3,0,0,0-3-3Zm10,8H8A3,3,0,0,1,8,4h8a3,3,0,0,1,0,6ZM8,6A1,1,0,0,0,8,8h8a1,1,0,0,0,0-2ZM6,13a1,1,0,1,0,1,1A1,1,0,0,0,6,13Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,10,13Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,14,13ZM6,17a1,1,0,1,0,1,1A1,1,0,0,0,6,17Zm4,0a1,1,0,1,0,1,1A1,1,0,0,0,10,17Zm8-4a1,1,0,1,0,1,1A1,1,0,0,0,18,13Zm1,5a1,1,0,0,0-1-1H14a1,1,0,0,0,0,2h4A1,1,0,0,0,19,18Z" /></svg>
        }
        if (this.props.type === "option") {
            return <svg viewBox="0 0 24 24"><circle cx="12" cy="2" r="2" /><circle cx="12" cy="12" r="2" /><circle cx="12" cy="22" r="2" /></svg>
        }
        if (this.props.type === "filter") {
            return <svg viewBox="0 0 24 24"><path d="M1,4.75H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2ZM7.333,2a1.75,1.75,0,1,1-1.75,1.75A1.752,1.752,0,0,1,7.333,2Z" /><path d="M23,11H20.264a3.727,3.727,0,0,0-7.194,0H1a1,1,0,0,0,0,2H13.07a3.727,3.727,0,0,0,7.194,0H23a1,1,0,0,0,0-2Zm-6.333,2.75A1.75,1.75,0,1,1,18.417,12,1.752,1.752,0,0,1,16.667,13.75Z" /><path d="M23,19.25H10.931a3.728,3.728,0,0,0-7.195,0H1a1,1,0,0,0,0,2H3.736a3.728,3.728,0,0,0,7.195,0H23a1,1,0,0,0,0-2ZM7.333,22a1.75,1.75,0,1,1,1.75-1.75A1.753,1.753,0,0,1,7.333,22Z" /></svg>
        }
        if (this.props.type === "edit") {
            return <svg viewBox="0 0 24 24"><path d="M18.656.93,6.464,13.122A4.966,4.966,0,0,0,5,16.657V18a1,1,0,0,0,1,1H7.343a4.966,4.966,0,0,0,3.535-1.464L23.07,5.344a3.125,3.125,0,0,0,0-4.414A3.194,3.194,0,0,0,18.656.93Zm3,3L9.464,16.122A3.02,3.02,0,0,1,7.343,17H7v-.343a3.02,3.02,0,0,1,.878-2.121L20.07,2.344a1.148,1.148,0,0,1,1.586,0A1.123,1.123,0,0,1,21.656,3.93Z" /><path d="M23,8.979a1,1,0,0,0-1,1V15H18a3,3,0,0,0-3,3v4H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2h9.042a1,1,0,0,0,0-2H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H16.343a4.968,4.968,0,0,0,3.536-1.464l2.656-2.658A4.968,4.968,0,0,0,24,16.343V9.979A1,1,0,0,0,23,8.979ZM18.465,21.122a2.975,2.975,0,0,1-1.465.8V18a1,1,0,0,1,1-1h3.925a3.016,3.016,0,0,1-.8,1.464Z" /></svg>
        }
        if (this.props.type === "view") {
            return <svg viewBox="0 0 24 24"><path d="M16,6a1,1,0,0,1,0,2H8A1,1,0,0,1,8,6Zm7.707,17.707a1,1,0,0,1-1.414,0L19.886,21.3A4.457,4.457,0,0,1,17.5,22,4.5,4.5,0,1,1,22,17.5a4.457,4.457,0,0,1-.7,2.386l2.407,2.407A1,1,0,0,1,23.707,23.707ZM17.5,20A2.5,2.5,0,1,0,15,17.5,2.5,2.5,0,0,0,17.5,20ZM13,22H7a3,3,0,0,1-3-3V5A3,3,0,0,1,7,2H19a1,1,0,0,1,1,1v8a1,1,0,0,0,2,0V3a3,3,0,0,0-3-3H7A5.006,5.006,0,0,0,2,5V19a5.006,5.006,0,0,0,5,5h6a1,1,0,0,0,0-2Z" /></svg>
        }
        if (this.props.type === "marker") {
            return <svg viewBox="0 0 24 24"><path d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z" /><path d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z" /></svg>
        }
        // if(this.props.type ==="map"){
        //     return 
        // }
        if (this.props.type === "back") {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="back-arrow"><g data-name="Layer 2"><g data-name="arrow-back"><rect width="24" height="24" opacity="0" transform="rotate(90 12 12)" /><path d="M19 11H7.14l3.63-4.36a1 1 0 1 0-1.54-1.28l-5 6a1.19 1.19 0 0 0-.09.15c0 .05 0 .08-.07.13A1 1 0 0 0 4 12a1 1 0 0 0 .07.36c0 .05 0 .08.07.13a1.19 1.19 0 0 0 .09.15l5 6A1 1 0 0 0 10 19a1 1 0 0 0 .64-.23 1 1 0 0 0 .13-1.41L7.14 13H19a1 1 0 0 0 0-2z" /></g></g></svg>
        }

        if (this.props.type === "call") {
            return <svg viewBox="0 0 414.937 414.937">
                <g>
                    <path d="M159.138,256.452c37.217,36.944,80.295,72.236,97.207,55.195c24.215-24.392,39.12-45.614,92.854-2.761   c53.734,42.874,12.696,71.727-10.757,95.363c-27.064,27.269-128.432,1.911-228.909-97.804C9.062,206.71-17.07,105.54,10.014,78.258   c23.46-23.637,52.006-64.879,95.254-11.458c43.269,53.394,22.161,68.462-2.054,92.861   C86.31,176.695,121.915,219.501,159.138,256.452z M213.104,80.203c0,0-11.227-1.754-19.088,6.113   c-8.092,8.092-8.445,22.032,0.082,30.552c5.039,5.039,12.145,6.113,12.145,6.113c13.852,2.598,34.728,6.997,56.944,29.206   c22.209,22.208,26.608,43.084,29.206,56.943c0,0,1.074,7.106,6.113,12.145c8.521,8.521,22.46,8.174,30.552,0.082   c7.861-7.86,6.113-19.087,6.113-19.087c-4.399-28.057-17.999-57.365-41.351-80.716C270.462,98.203,241.153,84.609,213.104,80.203z    M318.415,96.958c40.719,40.719,58.079,86.932,52.428,124.379c0,0-1.972,11.859,5.773,19.604   c8.718,8.718,22.535,8.215,30.695,0.062c5.243-5.243,6.385-13.777,6.385-13.777c4.672-32.361-1.203-97.464-64.647-160.901   C285.605,2.887,220.509-2.988,188.147,1.677c0,0-8.527,1.136-13.777,6.385c-8.16,8.16-8.656,21.978,0.061,30.695   c7.746,7.746,19.604,5.773,19.604,5.773C231.484,38.879,277.696,56.24,318.415,96.958z" />
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </svg>
        }
        if (this.props.type === "whatsapp") {
            return <svg viewBox="0 0 308 308">
                <g id="XMLID_468_">
                    <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156   c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687   c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887   c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153   c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348   c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802   c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922   c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0   c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458   C233.168,179.508,230.845,178.393,227.904,176.981z" />
                    <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716   c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396   c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z    M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188   l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677   c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867   C276.546,215.678,222.799,268.994,156.734,268.994z" />
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </svg>
        }
        if (this.props.type === "map") {
            return <svg viewBox="0 0 512 512">
                <g>
                    <g>
                        <g>
                            <path d="M256,0C148.341,0,60.754,87.586,60.755,195.245c0,56.66,16.898,115.289,50.225,174.26     c28.99,51.297,65.23,93.243,90.526,119.398C215.912,503.797,235.265,512,256,512c20.735,0,40.088-8.203,54.494-23.098     c25.296-26.156,61.536-68.101,90.526-119.398c33.327-58.971,50.225-117.6,50.225-174.26C451.245,87.586,363.658,0,256,0z      M288.632,467.755c-8.624,8.917-20.212,13.828-32.63,13.828c-12.418,0-24.006-4.911-32.631-13.828     c-49.372-51.047-132.198-154.33-132.198-272.51c0-90.886,73.941-164.828,164.828-164.828c90.885,0,164.828,73.941,164.83,164.828     C420.83,313.426,338.003,416.708,288.632,467.755z" />
                            <path d="M256,89.691c-58.203,0-105.554,47.352-105.554,105.555c0,58.202,47.351,105.554,105.554,105.554     s105.554-47.352,105.554-105.555C361.554,137.043,314.203,89.691,256,89.691z M256,270.381c-41.43,0-75.136-33.706-75.136-75.136     c0-41.43,33.706-75.136,75.136-75.136c41.43,0,75.136,33.706,75.136,75.136C331.136,236.675,297.43,270.381,256,270.381z" />
                        </g>
                    </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </svg>
        }
        if (this.props.type === "addbig") {
            return <svg viewBox="0 0 24 24"><path d="M23,11H13V1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1V11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H11V23a1,1,0,0,0,1,1h0a1,1,0,0,0,1-1V13H23a1,1,0,0,0,1-1h0A1,1,0,0,0,23,11Z" /></svg>
        }
        return <div></div>
    }

    render() {
        return <React.Fragment>
            {this.renderIcon()}
        </React.Fragment>
    }
}

export default Icon;
