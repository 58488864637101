
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchMenu } from '../../services/api/actions';
import { fetchProducts, isMobile, getID } from '../../services/util';
import Spinner from '../Spinner';
import ProductList from '../ProductList';
import PageFooter from '../PageFooter';
import Footer from '../Footer';
// import Search from '../Search';
import Header from "../Header";

class LevelProductGroup extends Component {

    static propTypes = {
        menu: PropTypes.array.isRequired,
        config: PropTypes.object.isRequired,
        fetchMenu: PropTypes.func.isRequired
    };

    state = {
        products: [],
        isLoading: true,
        title:''
    }

    async componentDidMount() {    
        // console.log("componentDidMount", this.props.match.params.name.toLowerCase());
        this.loadData(this.props.match.params.name.toLowerCase());
    }
    
    async componentWillReceiveProps(nextProps) 
    {
        this.loadData(nextProps.match.params.name.toLowerCase())
    }

    async loadData(selected)
    {
        let productGroup = await getID("productgroup",selected, this.props.config.key);
        // console.log('productGroup',productGroup)
        //fetch products
        let products =[]
        // products= await fetchProducts(0, 1000, "", "", productGroup.id)
        products= await fetchProducts(0, 1000,"","",productGroup.id,"",this.props.config.key, this.props.config.CheckoutConfig.StoreID)

        // console.log('after fetchproducts',products)
        this.setState({products:products, title:productGroup.Name, isLoading:false})
    }

   
    render() {
      
        const { isLoading } = this.state;
        const { products } = this.state;

        return <React.Fragment>
                
                <Header/>

                {isLoading && <Spinner />}

                {!isLoading && <div className="container">
                    
                    <div className="page-title">{this.state.title}</div>
                    
                    <ProductList products={products} displayMode={isMobile() ? "list" : "thumbnail" }/>
                
                </div>}

                <br />
                
                {!isMobile() &&<PageFooter config={this.props.config} />}
            
            <Footer config={this.props.config} show={isMobile()} width="100%"/>

        </React.Fragment>
    };
}


const mapStateToProps = state => ({
    menu: state.api.menu,
    config: state.api.config
});

export default connect(
    mapStateToProps,
    { fetchMenu }
)(LevelProductGroup);
