import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchProducts, isMobile , fetchLatestProducts} from "../../services/util";
import ProductList from "../ProductList";

class ProductSection extends Component {
    constructor() {
        super();
        this.state = {
            products: [],
            currentPage:0,
            recordFrom:0,
        };
    }

    async componentDidMount() {
        // console.log('his.props.section',this.props.section)
        let products = []
        
        if(this.props.section.ShowProductBy==="allProducts")
        {
            this.setState({ scrolling: true });
            let pageNumber = Number(this.state.currentPage) + 1;
            let recordFrom = (pageNumber - 1) * this.props.section.Count;
            this.setState({ recordFrom: recordFrom, currentPage: pageNumber });
            let result = await fetchLatestProducts(
                this.props.config.key,
                recordFrom,
                this.props.section.Count
            );
            products = result.products;

            // let latestProducts = this.state.latestProducts;
            // if (products && products.length > 0) {
            //     for (let index = 0; index < products.length; index++) {
            //         const obj = products[index];
            //         latestProducts.push(obj);
            //     }
            //     this.setState({ latestProducts: latestProducts });
            // }
            this.setState({ scrolling: false });
        }
        else
        {
            products= await fetchProducts(0,
                this.props.section.Count,
                this.props.section.CategoryID,
                this.props.section.SubCategoryID,
                this.props.section.ProductGroupID
                ,"",
                this.props.config.key, this.props.config.CheckoutConfig.StoreID)

            // products = await fetchProducts(
            //     0,
            //     this.props.section.Count,
            //     this.props.section.CategoryID,
            //     this.props.section.SubCategoryID,
            //     this.props.section.ProductGroupID
            // );
            
        }
        console.log('section',this.props.section)
        console.log('products',products)
        console.log('this.props.section.CategoryID',this.props.section.CategoryID)
        this.setState({ products: products });
    }

    returnDisplayMode(){
        if(!this.props.section.hasOwnProperty('DisplayStyle')){
            return "listthumbnail";
        }
        else{
            return this.props.section.DisplayStyle;
        }
    }

    showSection(){
        let show= false
        if(!this.props.section.hasOwnProperty('DisplayDevice')) {
            show= true;
        }
        else if(this.props.section.DisplayDevice ==="both"){
            show=true;
        }
        else if(this.props.section.DisplayDevice ==="mobile" && isMobile()){
            show=true;        
        }
        else if(this.props.section.DisplayDevice ==="desktop" && !isMobile()){
            show=true;        
        }
        return show;
    }

    render() {
        return (
            <React.Fragment>
                {this.showSection() && <React.Fragment>
                <ProductList
                    products={this.state.products}
                    displayMode={this.returnDisplayMode()}
                />
                </React.Fragment>}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    config: state.api.config,
});

export default connect(mapStateToProps, {})(ProductSection);
